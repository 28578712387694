.middleComp{
  width: 1340px;
  margin: 0 auto;
  border: 1px solid #000;
  border-top: none;
  border-bottom: none;
  color: #000;
}
.HPHeading{
    padding: 50px
}
.HPHeading > h2{
    font-family: 'DM-Sans-Reg';;
  font-size: 65px;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}
.middleComp > .diffTalent > .grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 600px);
  gap: 0px; /* Add spacing between grid items if desired */
  border: none;
  margin: 0 auto;
}

.middleComp > .diffTalent > .grid-container > .grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.middleComp > .diffTalent > .grid-container > .grid-item:last-child {
  grid-column: 2 / 3 !important; /* Place the last item in the center column of the 3x3 grid */
  justify-self: center; /* Center the item horizontally within its grid cell */
}
  .middleComp > .diffTalent > .exploreTalentBtn{
  width: 250px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 30px;
  border: 1px solid #000;
  background: #fff;
  color: #000;
    font-family: 'GM-Sans';;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 200% */
  letter-spacing: 0.8px;
  padding-left: 29px;
  margin: 50px auto;
}
.middleComp > .diffTalent > .exploreTalentBtn > img{
  border-radius: 40px;
  height: 40px;
  width: 40px;
  padding: 10px;
  border: 1px Solid #000;
  text-align: right;
  margin-left: 30px;
}
.AllTalent{
  display: none;
}

.middleComp > .talentDelivers{
  border-top: 1px solid #000;
}

.middleComp > .talentDelivers > .grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: repeat(1, ); */
  gap: 70px; /* Add spacing between grid items if desired */
  border: none;
  margin: 0 auto;
}

  .middleComp > .talentDelivers >  .grid-container > .grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  text-align: left;
  justify-content: left;
  padding: 50px;
}
  .middleComp > .talentDelivers >  .grid-container > .grid-item > img {
  align-self: baseline;
  height: 60px;
  width: 60px;
  margin-bottom: 50px;
}
  .middleComp > .talentDelivers >  .grid-container > .grid-item > .contentDiv > h2 {
    font-family: 'GM-Sans';;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 125% */
  letter-spacing: 0.96px;
}
  .middleComp > .talentDelivers >  .grid-container > .grid-item > .contentDiv > p {
    font-family: 'GM-Sans';;
  width: 300px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 125% */
  letter-spacing: 0.64px;
  padding-bottom: 50px;
    border-bottom: 1px solid #000 ;
}



  .middleComp > .talentDelivers >  .grid-container > .grid-item > .contentDiv  > .exploreTalentBtn{
  width: 250px;
  height: 60px;
  flex-shrink: 0;
  border: none;
  background: #fff;
  font-family: "GM-Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 200% */
  letter-spacing: 0.8px;
  margin: 50px auto;
  text-align: left;
  white-space: nowrap;
}

.middleComp > .talentDelivers >  .grid-container > .grid-item > .contentDiv  >.exploreTalentBtn > img{
  border-radius: 40px;
  height: 40px;
  width: 40px;
  padding: 10px;
  border: 1px Solid #000;
  text-align: right;
  margin-left: 13px;
}

.middleComp > .foundingTeam {
  border-top: 1px solid #000;
    background: #F0F0F0;
}

.middleComp > .foundingTeam > .grid-container {
  display: grid;
  grid-template-columns: repeat(2, 550px);
  gap: 90px;
  padding: 50px 75px 100px 75px;
}

.middleComp > .foundingTeam > .grid-container > .grid-item {
  border: none;
  text-align: center;
}
.middleComp > .foundingTeam > .grid-container > .grid-item > p {
  color: #000;
    font-family: 'GM-Sans';;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  line-height: 40px; /* 166.667% */
  letter-spacing: 0.96px;
  text-align: left;
  margin-bottom: 50px;
}

  .middleComp > .foundingTeam > .grid-container > .grid-item > .founderBio{
  display: flex;
  flex-direction: row;
}
  .middleComp > .foundingTeam > .grid-container > .grid-item > .founderBio > p{
  color: #000;
    font-family: 'GM-Sans';;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  line-height: 40px; /* 166.667% */
  letter-spacing: 0.96px;
  text-align: left;
  margin-bottom: 50px;
  display: none;
}
  .middleComp > .foundingTeam > .grid-container > .grid-item > .founderBio > .founderDP{
  padding: 25.5px;
    border: 1px solid #9A9A9A;
  border-radius: 125px;
  display: flex;
  flex-direction: row;
}
  .middleComp > .foundingTeam > .grid-container > .grid-item > .founderBio > .founderDP > img{
  width: 99px;
  height: 99px;
}
  .middleComp > .foundingTeam > .grid-container > .grid-item > .founderBio > .founderInfo{
  width: 370px;
  margin-left: 30px;
  text-align: left;
}
  .middleComp > .foundingTeam > .grid-container > .grid-item > .founderBio > .founderInfo > p{
  margin-bottom: 30px;
  color: #000;
  font-family: "GM-Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.8px;
}
  .middleComp > .foundingTeam > .grid-container > .grid-item > .founderBio > .founderInfo > h2{
  color: #000;
    font-family: 'GM-Sans';;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
  letter-spacing: 0.8px;
    
  }

  
  .middleComp > .foundingTeam > .grid-container > .grid-item > .founderBio > .founderInfo > span > a {
  text-decoration: none;
    color: #9A9A9A;
    font-family: 'GM-Sans';;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.8px;
}
