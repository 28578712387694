
.request-item {
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    margin: 10px 0;
    background-color: #fff;
  }
  
  .request-header-prof {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* justify-content: space-between; */
    
    
  }
  .request-prof{
    display: flex;
    justify-content: space-between;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    padding: 10px 20px;
    width: 660px;
    
  }

  .request-header-prof .request-prof{
    font-size: 15px;
  }
  
  .request-header-prof p {
    margin: 0 0 0 10px;
    flex: 1;
    font-weight: 400;
    line-height: 27px;
    font-size: 15px;
  }
  
  .profile-img {
    border: 1px solid #000;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    background-color: #ccc;
  }
  
  .request-header-prof span {
    color: gray;
    margin-left: 5px;
    font-weight: 400;
    line-height: 27px;
  }
  
  .icon.top-right {
    color: black;
    border-radius: 50%;
    padding: 2px;
  }
  
  .request-actions {
    display: flex;
    gap: 10px;
    background-color: #fff;
  }
  
  .action-item.new-class {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E0E0E0;
    border-radius: 20px;
    padding: 12px;
    width: fit-content;
    padding: 20px;
    gap: 5px;
    white-space: nowrap;
    background-color: white; /* default background color */

  }
  
  .action-item.new-class.active1 {
    background-color: rgba(224, 224, 224, 1); /* active background color */
  }
  
  .action-item.new-class p {
    margin-left: 5px;
    font-weight: 400;
    font-size: 17px;

  }

.new-class:hover{
    background-color: #F7F7F7;
    cursor: pointer;
  }
  
  .action-button.new-class {
    border: 1px solid #E0E0E0;
    border-radius: 20px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    background-color: white; /* default background color */
  }
  
  .action-button.new-class.active1 {
    background-color: rgba(224, 224, 224, 1); /* active background color */
  }

 .new-class:hover{
    background-color: #F7F7F7;
    cursor: pointer;
  }
  
  .details-section2.new-class {
    border-radius: 20px;
    background-color: #f7f7f7;
    padding: 30px;

  }
  .details-section2.new-class .description{
    width: 960px;
    font-weight: 400;
  }
  .details-section2.new-class.rate {

    /* background-color: #f7f7f7; */
    /* padding: 30px; */
    /* margin-top: 1.25rem; */
    display: flex;
    justify-content: center;
    row-gap: 1rem;


  }
  .rate-container{
    width: 600px;
  }

  
  .details-section2 .new-class p {
    margin: 0.625rem 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;

  }
  
  .description {
    width: 60%;
  }
  
  .details-section .new-class span {
    font-weight: bold;
  }
  
  .pool {
    display: flex;
    justify-content: center; /* Center the pool content */
    align-items: center; /* Center the pool content vertically */
    flex-direction: column; /* Change to column for better centering */
    
  }
  .pool-title{
    text-align: right;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    padding-bottom: 14px;
    display: flex;
    max-width: 534px;
    width: 100%;
    
  }
  .comment-title{
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    padding-bottom: 14px;
  }
 
  
  .pool-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center; /* Center items within the column */
    max-width: 534px;
    width: 100%;
  }
  .pool-item.selected {
    background-color: #000000; 
    color: #FFFFFF;
    border: 1px solid #000000;
  }
  .pool-item .selected-text {
    color: white;
  }
  
  
  .pool-item-wrapper {
    max-width: 534px;
    width: 100%;
    
  }
  
  .pool-item {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Center the content inside each pool-item */
    border-radius: 20px;
    padding: 10px;
    background-color: white;
    cursor: pointer;
    column-gap: 0.5rem;
    border: 1px solid #E0E0E0;
    font-weight: 400;
    line-height: 27px;
    height: 70px;
    width: 100%; /* Ensure items take up full width of the container */
  }
  
  /* .pool-item:hover {
    background-color: #F7F7F7;
  }
   */
  .pool-item p {
    margin: 0;
    margin-left: 10px;
  }

 
  .comment-section {
    display: flex;
    flex-direction: column;
    margin-top: 10px; /* Add margin to separate from pool items */
    width: 100%; /* Ensure it takes up the same width as the pool column */
  }
  
  .comment-section textarea {
    width: 100%;
    height: 135px;
    padding: 20px;
    border:none;
    border-radius: 20px;
    resize: none;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 17px;
    line-height: 27px;
  }
  
  .comment-buttons {
    display: flex;
    justify-content: space-between;
    
  }
  
  .comment-buttons button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  

  .comment-buttons .comment-cancel{
    width: 150px;
    height: 70px;
    padding: 23px;
    gap: 10px;
    border-radius: 20px ;
    background-color: #FFFFFF;
    border: 1px solid #E0E0E0 ;
  }

  
  .comment-buttons .comment-cancel:hover{
    background-color: #F7F7F7;

  }
  
  
  .comment-buttons  .comment-add-to-pool {
    width: 184px;
    height: 70px;
    padding: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    border-radius: 20px ;
    color: white;
    background-color: #000000;
    border: 1px solid #E0E0E0 ;
  }

  .comment-section .comment-border{
    border: 1px solid #E0E0E0;
    margin-top: 17px;
  }
  
  .add-to-pool:hover {
    background-color: #F7F7F7;
  }
  
  /* New styles for Rating and Review section */
  .details-section.new-class.rate {
    background-color: pink;
    padding: 0 15px;
    width: 100%;
  }


  .rating-stars {
    display: flex;
    gap: 5px;
    font-size: 24px;



  }

  .rating-stars .star {
    cursor: pointer;
  }
  
  .review-section {
    margin-top: 20px;
  }

  .rating-container {
    background-color: white; /* Default background color */
    border-radius: 40px;
    width: 150px;
    padding: 20px 30px 20px 30px;
    column-gap: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 6px;
  }

  .rate-container p{
    text-align: start;
    font-weight: 600;
  }
  
  .rating-container .star {
    cursor: pointer;
    color: black; /* Default color */
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .rating-container .star:hover {
    color: black; /* Hover color */
  }
  

  
  
  .review-section p {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .review-input textarea {
    width: 100%;
    height: 120px;
    border-radius: 30px;
    padding: 15px 20px;
    border: 1px solid #e0e0e0;
    background-color: #fff;
    resize: vertical;
  }
  
  .review-buttons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    justify-content: space-between;
  }
  
  .review-buttons button {
    padding: 10px 20px;
    border-radius: 15px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    transition: background-color 0.3s ease;
  }
  
  .review-buttons button.cancel-button {
    background-color: #fff;
    color: #000;
    border: 1px solid #E0E0E0;
  }
  
  .review-buttons button.publish-button {
    background-color: #000;
    color: #fff;
    /* border: 1px solid #000; */
  }
  
  .review-buttons button:hover {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
  }
  
  .action-item.new-class.pointerNone {
    pointer-events: none;
    color: #ccc;
  }
  @media screen and (max-width: 870px) {   
    .request-item1 {      
      flex-direction: column;
      border-bottom: 1px solid #e0e0e0;
        border-radius: 0;
    }
    .request-item {      
      flex-direction: column;
      border-bottom: 1px solid #E0E0E0;
        border-radius: 0;
        margin: 20px 0;
    }
    
    .request-header-prof {
      max-width: 100%;    
      margin-bottom: 10px;  
    }
    .request-header-prof span {
      margin-left: 0;
    }
    .request-prof{
      display: flex;
      justify-content: space-between;
      border: 1px solid #e0e0e0;
      border-radius: 20px;
      text-align: left;
    }
    .entireArea{
      padding: 20px !important;
    }
    .messageBox img{
      width: 25px !important;
      height: 25px !important;
    }
    .chatBoxComponent .entireArea .chatArea .message {
      border-radius: 20px;
      font-size: 12px;
      padding: 10px;
      max-width: 500px;
  }
  .request-actions{
    padding-bottom: 20px;
  }
  .action-item.new-class{
    height: 50px !important;
    padding: 10px;
  }
  .action-button.new-class{
    height: 50px;
  }
  
  
  }