.filter-dropdown {
    width: 100%;
    display: flex;
    flex-direction: column;
    top: 0;
    margin-right: 10px;
    min-width: 21%;
}

.filter-dropdown .search-input {
    min-height: 48px;
    padding-left: 20px;
    text-transform: capitalize;
    border: 1px solid #E7E3E9;
}

.filter-dropdown .dropdown-options-container {
    background-color: #fff;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: 270px;
    height: fit-content !important;
    margin-top: 5px;
    /* border: 1px solid #DFE9F3; */
    padding: 0 20px;
    font-size: 0.9rem;
    text-transform: capitalize;
    overflow: scroll;
    position: absolute;
    width: 22.5%;
    top: 50px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 10%);
}

.filter-dropdown .dropdown-options-container .dropdown-option {
    margin: 5px 0;
    width: 100%;
    text-align: left;
    cursor: pointer;
}