.dropdown-field-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  position: relative;
}

.dropdown-field-container label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
  text-align: start;
}

.dropdown-field-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px;
  font-size: 14px;
  border: 1.5px solid #ccc;
  border-radius: 10px;
  /* z-index: 100; */

  transition: border-color 0.3s;
  position: relative;
  cursor: pointer;
}

.dropdown-field-wrapper input {
  outline: none;
  border: none;
  width: 100%;
  cursor: pointer;
  /* z-index: 100; */
}

.dropdown-field-wrapper:focus-within {
  border-color: black;
}

.dropdown-field-wrapper:focus-within,
.dropdown-field-wrapper:active {
  z-index: 10000;
}

/* Dropdown options styling */
.dropdown-field-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  border: 1.5px solid #ccc;
  border-radius: 10px;
  z-index: 9999;
  /* overflow: visible; */
}

.dropdown-field-options div {
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  text-align: left;
}

.dropdown-field-options div:hover {
  background-color: #f2f2f2;
}
