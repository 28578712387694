.home_page_comp_one {
    height: 600px;
    background-image: linear-gradient(to bottom, #dfe9f3, #f7fafd);
    display: flex;
}

.home_page_comp_one .content_section {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    padding-left: 5%;
}

.home_page_comp_one .content_section .text1 {
    font-size: 18px;
    font-family: 'Encode-Sans' !important;
    font-weight: 800;
    color: #ffa0a3;
}

.home_page_comp_one .content_section .text2 {
    font-size: 38px;
    font-family: 'Encode-Sans' !important;
    font-weight: 600;
    line-height: 59px;
    color: #0B1C39;
    margin: 10px 0 10px 0;
    width: 60%;
}

.home_page_comp_one .content_section .text3 {
    font-family: 'Montserrat', sans-serif !important;
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    color: #5C7793;
    width: 50%;
}

.home_page_comp_one .content_section .button_sec {
    margin-top: 60px;
    display: flex;
}

.home_page_comp_one .content_section .button_sec .sec_button{
    margin-left: 20px
}

.home_page_comp_one .vertical_corousal{
    display: flex;
    justify-content: flex-end;
    width: 50%;
    padding-right: 10%;
}

.home_page_comp_one .content_section .primaryBtn {
    background: #0b1c39;
    color: #ffffff;
}

.home_page_comp_one .vertical_carousel_2{
    padding-top: 70px;
}

.home_page_comp_one .vertical_carousel_3{
    padding-top: 30px;
}
@media screen and (max-width: 768px) {
.home_page_comp_one .vertical_carousel_4{
    padding-top: 30px;
    display: none;
}
}

@media screen and (max-width: 450px) {
    .home_page_comp_one{
        height: fit-content !important;
        padding-bottom: 40px;
        flex-direction: column;
    }
    .home_page_comp_one .content_section {
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-left: 5%;
        padding-top: 30px;
        margin-bottom: 90px;
    }

    .home_page_comp_one .content_section .text1{
        font-size: 14px;
    }

    .home_page_comp_one .content_section .text2{
        font-size: 32px;
        line-height: 39px;
        width: 300px;
        padding-top: 20px !important;
        padding-bottom: -20px !important;
    }

    .home_page_comp_one .content_section .text3{
        width: 290px;
        margin-bottom: -35px !important;
    }
    .home_page_comp_one .content_section .text3 .text3_sub{
        color: #405870;
        font-weight: bold;
    }

    .home_page_comp_one .vertical_corousal{
        justify-content: center;
        height: 350px;
        width: unset;
        padding-right: unset;
    }

    .home_page_comp_one .vertical_corousal .vertical_carousel_2{
        margin-top:-45px;
    }

    .home_page_comp_one .vertical_corousal .vertical_carousel_3{
        margin-top:-15px;
    }
}

@media screen and (max-width: 820px) and (min-width: 451px) {
    .home_page_comp_one{
        justify-content: space-around;
        height: 550px;
        width: 100%;
    }
    .home_page_comp_one .content_section{
        padding-left: 5%;
        width: 50%;
        justify-content: flex-start;
        padding-top: 30px;
        margin-bottom: 40px;
    }

    .home_page_comp_one .content_section .text1{
        font-size: 14px;
    }

    .home_page_comp_one .content_section .text2{
        font-size: 32px;
        line-height: 39px;
        width: 300px;
    }

    .home_page_comp_one .content_section .text3{
        width: 290px;
    }
    .home_page_comp_one .content_section .text3 .text3_sub{
        color: #405870;
        font-weight: bold;
    }

    .home_page_comp_one .vertical_corousal{
        justify-content: center;
    }
}
