
  .coverImage {
    /* flex-direction: column; */
    flex-wrap: wrap;
    width: 100%;
    border-radius: 5px;
    margin: 10px auto;
  } 
  
  .coverImage >.uploadSection{
    margin-right: auto;
    border: 1px solid #C5C5C5;
    border-radius: 5px;
    display:flex;
    flex-direction:column;
    text-align:center;
    align-items: center;
    padding-top: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .coverImage >.uploadSection>img{
    margin-bottom: 10px;
    width: 18px;
   
  }
  .coverImage >.uploadSection>button{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #6E6E6E;
    background: #E5E5E5;
    border-radius: 5px;
    padding: 5px 10px;
    border: none;
}
  
 
  .coverImage >.uploadSection>span{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    color: #6E6E6E;
    margin-top: 10px;
  
  }
  .coverImage >.uploadSection>input{
    position: relative;
    top: -52px;
    height: 24px;
    width: 180px;
    opacity: 0;
    cursor: pointer;
    visibility: hidden;
  }

.coverImage .box{
    width: 100%;
     
}

.coverImage .box button {
    background-color: rgb(249, 223, 223);
    color: rgb(255, 101, 115);
    border: none;
    padding: 8px 15px;
    font-size: 16px;
    margin-top: 50px;
    /* float: right; */
    
 }