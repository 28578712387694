/* EditPoolCategory.css */

.editPoolDialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
    height: 150px;
    padding: 20px;
    background: #fff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
    opacity: 0;
  }
  
  .editPoolDialog[open] {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  
  .editInputFields {
    display: flex;
    gap: 0.5rem;
  }
  .editInputFields input {
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s;
  }
  
  .editInputFields input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .editPoolButtons {
    position: absolute;
    right: 20px;
    display: flex;
    gap: 0.5rem;
  }
  
  .dialogButton {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .dialogCloseBtn {
    background-color: #f0f0f0;
  }
  
  .dialogCloseBtn:hover {
    background-color: #e0e0e0;
  }
  
  .dialogDeleteBtn {
    background-color: #007bff;
    color: #fff;
  }
  
  .dialogDeleteBtn:hover {
    background-color: #0056b3;
  }
  
  .dialogButton:active {
    transform: scale(0.98);
  }
  
  /* Overlay for dimming the background */

  