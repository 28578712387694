.advantage_card {
    width: 40%;
    height: fit-content !important;
    padding: 29px;
    display: flex;
    align-items: center;
    background: linear-gradient(180deg, #f7fafd 0%, #eff6fb 100%);
    border-radius: 10px;
    margin-bottom: 20px;
}

.advantage_card .image_sec {
    min-width: 100px;
    height: 100px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.advantage_card .image_sec .adv_imgage {
    width: 25px;
    height: 25px;
}

.advantage_card .image_sec .serial_no{
    background: linear-gradient(93.21deg, #ffa0a3 0%, #ff656a 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 18px;
    font-weight: 700;
}

.advantage_card .content_sec {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 30px;
}

.advantage_card .content_sec .title {
    font-family: "lexend";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #0b1c39;
}

.advantage_card .content_sec .description {
    font-family: "lexend";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: left;
    color: #0b1c39;
    margin-top: 19px;
}

@media screen and (max-width: 768px) {
    .advantage_card{
        flex-direction: column;
        width: 100%;
    }
    .advantage_card .image_sec{
        min-width: 60px;
        height: 60px;
        margin-bottom: 10px;
    }
    .advantage_card .content_sec{
        margin: 0;
        align-items: center;
    }

    .advantage_card .content_sec .title{
        font-size: 14px;
    }

    .advantage_card .content_sec .description{
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        width: 85%;
    }
}