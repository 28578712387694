
.header__cont {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    position: sticky;
    top: 0;
    background: #fff6f6;
    z-index: 1000;
  }
  link {
    text-decoration: none;
  }
  .headerbgblue {
    color: white;
    background: #0b1c38 !important;
  }
  .header__secs {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .headers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1128px;
    padding: 0 5%;
    position: relative;
  }
  .headers .header__secs button{
    height: 100%;
    border: none;
    color: white;
    background: #ff656a;
    width: 11.5rem;
    cursor: pointer;
    font-family: "Poppins";
    height: 40px;
    justify-content: center;
    text-align: center;
    float:left !important;
    font-size: 1rem;
   
    border-radius: 0px;
    }
    .headers .logo {
        object-fit: contain;
        height: 20px;
        cursor: pointer;
      }