.none{
  display: none !important;
}

.aeditprofile__cont {
  display: flex;

  /* background: #e5e5e5; */
  background: #ffffff;
  min-height: 100vh;
}
.aeditprofile {
  /* flex: 0.82; */
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 3rem 1.3%;
  box-sizing: border-box;
  width: 100%;
  background: #ffffff;
  font-family: Lexend;
}
.aeditprofile .navbar__style{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #E3E7E9;
  height: 50px;
  color: #191E22;
  position: sticky;
  top: 0;
  margin-top: -20px;
  z-index: 10000;
  width: 100%;
}
.navbar__position{
  position: sticky;
  top:20px
}
.navbar__style .actives{
  background-color:  #191E22;
  height: 100%;
  padding-top: 14px;
  justify-content: center;
  text-align: center;
  color: #FFFFFF !important;
}
.aeditprofile .navbar__style .anavlink{
  margin: auto;
  text-decoration: none;
  margin-left: 0;
  font-size: 1rem;
  padding: auto;
  line-height: 21px;
  cursor: pointer;
  color: #191E22;
  width: 170px; 
}
.navbar__style > .navbar__style > .actives .anavlink{
  color: #FFFFFF !important;
}
/* do mark the later */
.adashboardTitles{
  font-weight: 900;
  width: 100%;
  background-color: #F8F8F8;
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 42px;
  font-family: 'Poppins';
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}


.aeditprofile input {
  color: #0b1c39 !important;
}
.aeditprofile ::placeholder {
  font-family: "Poppins";
  color: #cfcfcf !important;
}
.aeditprofile .basdetails {
  width: 100%;
}
.aeditprofile .basdetails .basdetails__data {
  display: flex;
  flex-direction: column;
  row-gap: 0.7rem;
  text-align: left;
  margin: 1rem 0% 1rem 5%;
}
.aeditprofile .basdetails .basdetails__data .basdetails__data__forms {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.aeditprofile .basdetails .basdetails__data .basdetails__data__forms .form {
  display: flex;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
}
.basdetails__data .basdetails__data__forms .form .inp__fields {
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
}
.basdetails__data .basdetails__data__forms .form .flxs {
  flex: 0.18;
}
.basdetails__data .basdetails__data__forms .form .flxl {
  flex: 0.42;
}
@media only screen and (max-width:1100px){
.basdetails__data .basdetails__data__forms .form .inp__fields .input,
.basdetails__data .basdetails__data__forms .form .inp__fields .select {
  padding: 0.4rem 0rem;
  outline: none;
  font-family: "Poppins";
  color: #0b1c39 !important;
  border: 1px solid lightgrey;
  background: white;
  border-radius: 3.5px;
}
}
@media only screen and (max-width:968px){
  .basdetails__data .basdetails__data__forms .form .inp__fields .input,
  .basdetails__data .basdetails__data__forms .form .inp__fields .select {
    padding: 0.4rem 0.4rem;
    
  }
  }
.basdetails__data
  .basdetails__data__forms
  .form
  .inp__fields
  .input
  ::placeholder,
.basdetails__data
  .basdetails__data__forms
  .form
  .inp__fields
  .select
  ::placeholder {
  color: #9d9d9d;
}
.basdetails__data .basdetails__data__forms .form .inp__fields .labelf {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.basdetails
  .basdetails__data
  .basdetails__data__forms
  .agency__overview
  .part1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.basdetails .basdetails__data .basdetails__data__forms .agency__overview {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
}
.basdetails
  .basdetails__data
  .basdetails__data__forms
  .agency__overview
  .agency__textarea {
  width: 100%;
  height: 8rem;
  outline: none;
  padding: 0.5rem;
  box-sizing: border-box;

  color: #9d9d9d;
  border: 1px solid lightgray;
  border-radius: 3.5px;
}

@media screen and (max-width: 950px) {
  .aeditprofile .basdetails .basdetails__data .basdetails__data__forms .form {
    flex-direction: column;
  }
}

@media screen and (max-width: 950px) {
  .aeditprofile {
    flex: 1;
  }
}
.aeditprofile ::placeholder {
  font-family: "Poppins";
}
.aeditprofile .aeditprofile__title {
  font-weight: bolder;
  color: #5f5f5f;
}
.aeditprofile .section__cont {
  display: flex;
  flex-direction: column;
  row-gap: 0.6rem;
}
.aeditprofile .section__cont .section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.aeditprofile .section__cont .section .part__right {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1.3rem;
}
.aeditprofile .section__cont .section .part__right .img__cont {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.aeditprofile .section__cont .section .part__right .img__cont .msg__not {
  object-fit: contain;
  height: 16px;
}
.aeditprofile .section__cont .section .part__right .img__cont .dotg {
  position: absolute;
  right: -10%;
  top: -10%;
}
.aeditprofiles .section__cont .section .part__right .date__day {
  padding: 0.2rem 0.4rem;
  border: 1px solid #828282;
  border-radius: 3px;
}

/* Services */

.aeditprofile .contact__details {
  display: flex;
  flex-direction: column;
  row-gap: 0.7rem;
  width: 100%;
}
.aeditprofile .contact__details .contact__details__data {
  display: flex;
  flex-direction: column;
  row-gap: 0.7rem;
  text-align: left;
  margin: 1rem 0% 1rem 5%;
  
}
.aeditprofile
  .contact__details
  .contact__details__data
  .contact__details__forms {
  display: flex;
  flex-direction: column;
  row-gap: 1.8rem;

  width: 100%;
}
.aeditprofile
  .contact__details
  .contact__details__data
  .contact__details__forms
  .form {
  display: flex;
  row-gap: 20px;
  column-gap: 4rem;
}
.aeditprofile
  .casestudy
  label{
    font-weight: 900;
    color: #6E6E6E;
    margin-top: 20px;

  }
  .chooseImages{
    margin-top: 10px;
  }
  .aeditprofile
  .casestudy .service__btn{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
  
  }
  .aeditprofile
  .casestudy .service__btn button{
    height: 72px;
    width: 157px;
    background-color: white;
    color: black;
    border-radius: 10px;
    border:none;
    margin-right: 30px;
    margin-top: 15px;
  
 

  }
.labelStyle{
  width: 235px;
}
.wfixform .wfixinp__cont .labelcrosscont {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}
.wfixform .wfixinp__cont .labelcrosscont .labelfw {
  width: 100%;
}
.wfixform .wfixinp__cont .labelcrosscont .redcross {
  object-fit: contain;
  height: 9px;
  cursor: pointer;
  filter: brightness(0);
}
@media screen and (min-width: 950px) {
  .aeditprofile .wfixform {
    flex-wrap: wrap;
    float: left;
    width: 100%;
  }
  /* .aeditprofile .wfixform .wfixinp__cont {
    width: 250px ;
  } */
  .aeditprofile .wfixform .wfixinp {
    width: 235px;
  }
}

/* Client & Case Study */
.aeditprofile .casestudy {
  display: flex;
  flex-direction: column;
  row-gap: 0.7rem;
  width: 100%;
  text-align: left;
}
.aeditprofile .casestudy .casestudy__data,
.aeditprofile .casestudy .casestudy__data2 {
  display: flex;
  flex-direction: column;
  row-gap: 0.7rem;
  text-align: left;
  margin: 0 0% 1rem 5%;
}
/* .aeditprofile .casestudy .casestudy__data {
  overflow-x: auto;
} */

.casestudy .pricing__table {
  border-collapse: separate;
  border-spacing: 6px 15px;
  /* min-width: 800px; */
}
.casestudy .pricing__table .table__heading__row .heading__row__part {
  border: none;
  /* background: purple; */
  padding: 0;
  margin-bottom: 1rem !important;
}
.casestudy .pricing__table .table__heading__row .heading__row__part .theading {
  color: rgb(112, 112, 112);
  font-weight: 600;
  font-size: 0.88rem;
}
.casestudy .pricing__table .table__heading__row .heading__row__part th,
.casestudy .pricing__table .table__heading__row .heading__row__part td {
  padding: 0;
}

.casestudy .pricing__table tr td {
  vertical-align: top;
}
.casestudy .pricing__table .editnremove__cont {
  display: flex;
  justify-content: space-between;
  column-gap: 0.2rem;
}
.casestudy .pricing__table .editnremove__cont .editnremove {
  color: #191E22 !important;
  cursor: pointer;
}

/* Awards */

.aeditprofile .contact__details__data .contact__details__forms .award__form {
  column-gap: 4vmin !important;
  align-items: flex-end;
}
@media screen and (max-width: 950px) {
  .aeditprofile .contact__details__data .contact__details__forms .award__form {
    align-items: initial;
  }
}
.contact__details__data .contact__details__forms .award__form .flxl {
  flex: 0.5;
}
.contact__details__data
  .contact__details__forms
  .form
  .inp__fields
  .upload__btn__cont {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
}

/* Upload btn */
.aeditprofile .mainupload__btn__cont {
  display: flex;
  /* justify-content: space-between; */
  gap: 90px;
  margin-top: 50px;
}
.aeditprofile .mainupload__btn__cont .mainupload__btn {
  outline: none;
  border: none;
  padding: 0.6rem 1.5rem;
  color: white;
  background: #191E22;
  cursor: pointer;
}

@media screen and (max-width: 950px) {
  .aeditprofile
    .contact__details
    .contact__details__data
    .contact__details__forms
    .form {
    flex-direction: column;
  }
  .contact__details__data .contact__details__forms .case__textarea__cont {
    flex-direction: column;
  }
  .contact__details__data .contact__details__forms .case__statics__form {
    flex-direction: column;
  }
  .contact__details__data .contact__details__forms .case__statics__form .part {
    column-gap: 0.5rem;
  }
}

@media screen and (max-width: 700px) {
  .aeditprofile .hideeditremove {
    display: none !important;
  }
}
/* Case Study Box */
.aeditprofile .casestudy .contact__details__forms {
  row-gap: 2rem;
}
.aeditprofile .casestudy .contact__details__forms .case__study__tops {
  display: flex;
  column-gap: 2rem;
  row-gap: 0.5rem;
  flex-wrap: wrap;
 
}
.aeditprofile .casestudy .contact__details__forms .case__overview {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
}

.aeditprofile
  .casestudy
  .contact__details__forms
  .case__overview
  .case__textarea {
  width: 100%;
  height: 8rem;
  color: #9d9d9d;
  border: 1px solid lightgray;
  border-radius: 3.5px;
  outline: none;
  padding: 0.5rem;
  box-sizing: border-box;
}
.aeditprofile .casestudy .contact__details__forms .case__textarea__cont {
  display: flex;
  column-gap: 2rem;

  row-gap: 0.5rem;
  column-gap: 3rem;
  width: 100%;
}
.aeditprofile
  .casestudy
  .contact__details__forms
  .case__textarea__cont
  .case__textarea__part {
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;

  width: 100%;
}
.aeditprofile
  .casestudy
  .contact__details__forms
  .case__textarea__cont
  .case__textarea__part
  .case__textarea {
  width: 100%;
  height: 8rem;
  color: #9d9d9d;
  border: 1px solid lightgray;
  border-radius: 3.5px;
  outline: none;
  padding: 0.5rem;
  box-sizing: border-box;
}
.aeditprofile .casestudy .contact__details__forms .case__statics__cont {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.aeditprofile .casestudy .contact__details__forms .case__statics__title {
  display: flex;
  justify-content: space-between;
}
.aeditprofile .casestudy .contact__details__forms .case__statics__form {
  display: flex;
  justify-content: space-between;
  column-gap: 3rem;
  row-gap: 0.5rem;
  width: 100%;
  /* background: purple; */
}
.aeditprofile .casestudy .contact__details__forms .case__statics__form .part {
  display: flex;
  justify-content: space-between;
  column-gap: 2rem;
  row-gap: 0.5rem;
  width: 100%;
}
.aeditprofile
  .casestudy
  .contact__details__forms
  .case__statics__form
  .inp__fields {
  width: 100%;
}
.aeditprofile
  .casestudy
  .contact__details__forms
  .case__statics__form
  .inpsmall {
  flex: 0.15;
}
.aeditprofile .casestudy .contact__details__forms .case__statics__form .inpbig {
  flex: 0.9;
}

.aeditprofile
  .casestudy
  .contact__details__forms
  .case__statics__form
  .part
  .inp__fields
  .input,
.aeditprofile
  .casestudy
  .contact__details__forms
  .case__statics__form
  .inp__fields
  .select {
  padding: 0.4rem 0.4rem;
  box-sizing: border-box;
  outline: none;

  color: #0b1c39 !important;
  border: 1px solid lightgrey;
  background: white;
  border-radius: 3.5px;
  width: 100%;
}

.aeditprofile .casestudy .contact__details__forms .form .inp__fields {
  margin-bottom: 0.4rem;
}
.aeditprofile .casestudy .contact__details__forms .form .inp__fields .input,
.aeditprofile .casestudy .contact__details__forms .form .inp__fields .select {
  padding: 0.4rem 0.4rem;
  box-sizing: border-box;
  outline: none;

  color: #9d9d9d;
  border: 1px solid lightgrey;
  background: white;
  border-radius: 3.5px;
  width: 100%;
}
/* .aeditprofile .casestudy .contact__details__forms .case__igv__main__cont { */

.contact__details__data .contact__details__forms .inp__fields .select {
  border-radius: 3.5px !important;
}
.aeditprofile
  .contact__details__data
  .contact__details__forms
  .case__igv__main__cont {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.aeditprofile
  .casestudy
  .contact__details__forms
  .case__igv__main__cont
  .case__igv__cont {
  display: flex;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  float: left;
  width: 100%;
}
.aeditprofile
  .casestudy
  .contact__details__forms
  .case__igv__main__cont
  .case__igv__cont
  .case__igv {
  object-fit: contain;
  height: 70px;
  border-radius: 3px;
  cursor: pointer;
}

.aeditprofile .casestudy .contact__details__forms .case__team {
  flex-direction: column;
  row-gap: 1rem;
}
.caseStudies__services{
  display: flex;
  flex-direction: row;
}
.cover__images{
  visibility: hidden;
}
.aeditprofile .casestudy .contact__details__forms .case__team .form {
  display: flex;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
}
.aeditprofile .casestudy .contact__details__forms .case__team .form div {
  width: 100%;
  /* background: purple; */
}
.aeditprofile .casestudy .contact__details__forms .case__team .invite__btn {
  color: white;
  text-align: center;
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 5px;
  background: #9bd38c;
  cursor: pointer;
}
.aeditprofile
  .casestudy
  .contact__details__forms .brand__industry{
    display: flex;
    flex-direction: row;
    justify-items: left;
  }
.aeditprofile
  .casestudy
  .contact__details__forms
  .case__team
  .radio__inp__main__cont {
  display: flex;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
}
.aeditprofile
  .casestudy
  .contact__details__forms
  .case__team
  .radio__inp__cont {
  display: flex;
  column-gap: 0.5rem;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 950px) {
  .aeditprofile .casestudy .contact__details__forms .form {
    flex-direction: column;
  }
  .aeditprofile .casestudy .contact__details__forms .case__textarea__cont {
    flex-direction: column;
  }
  .aeditprofile
    .casestudy
    .contact__details__forms
    .case__textarea__cont
    .case__textarea__part {
    flex-direction: column;
  }
  .aeditprofile .casestudy .contact__details__forms .case__statics__form {
    flex-direction: column;
  }
  .aeditprofile .casestudy .contact__details__forms .case__statics__form .part {
    column-gap: 0.5rem;
  }
}
.imagelayouts{
  display: flex;
  flex-direction: row;
  margin-top:20px ;
}
.imagelayouts img{
  height: 220px;
  width: 220px;
  margin-top: 20px;
  margin-right: 20px;
}