.InternalActivityTab {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: start;
  width: 100%;
}

.InternalActivityTab .grid-container {
  display: grid;
  grid-template-columns: repeat(17, 1fr);
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
}

.grid-container .grid-item-1 {
  grid-column: span 9; /* Span 5 columns */
  height: 60px;
}

.grid-container .grid-item-2 {
  grid-column: span 3; /* Span 2 columns */
}
.grid-container .grid-item-3 {
  grid-column: span 5; /* Span 5 columns */
}
.grid-container .table-headings {
  font-weight: 600;
  font-size: 20px;
  color: #7f7f7f;
  display: flex;
  justify-content: start;
  align-items: center;
  height: 40px;
}

.InternalActivityTab .request-header-prof {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;

  height: 100%;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  padding: 0 20px;
}

.InternalActivityTab  .request-header-prof .profile-img {
  border: 1px solid #000;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-color: #ccc;
}

.InternalActivityTab  .request-header-prof .extraDetails {
  margin-left: auto; /* Pushes extraDetails to the end */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.InternalActivityTab .request-header-prof p {
  width: max-content;
  flex: none;
}
.InternalActivityTab .request-header-prof span {
  color: gray;
  margin-left: 5px;
}

.InternalActivityTab .grid-container .grid-item-3.actionButtons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.InternalActivityTab .deleteDropdownComponent {
  display: flex;
  gap: 10px;
  text-align: left;
}

.InternalActivityTab .inviteDetailContainer {
  display: flex;
  flex-direction: column;
  gap: 25px;
  text-align: left;
}

.InternalActivityTab .inviteDetailContainer .detailColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: start;
}

.InternalActivityTab .inviteDetailContainer .verticalLabelInput {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.InternalActivityTab .inviteDetailContainer .horizontalLabelInput {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.InternalActivityTab .inviteDetailContainer label {
  font-size: 18px;
  font-weight: 600;
}

.InternalActivityTab .inviteDetailContainer .detailInput {
  outline: none;
  border: 1px solid #ffffff;
  border-radius: 30px;
  height: 45px;
  padding: 0 20px;
}

.InternalActivityTab
  .inviteDetailContainer
  .detailColumns
  .detailInput.fullName {
  width: 100%;
}

.InternalActivityTab
  .inviteDetailContainer
  .detailColumns
  .detailInput.userPrice {
  width: 150px;
}

.InternalActivityTab
  .inviteDetailContainer
  .detailColumns
  .detailInput.topCandidate {
  width: 80px;
}

.InternalActivityTab
  .inviteDetailContainer
  .detailColumns
  .detailInput.userPrice {
  width: 150px;
}

.InternalActivityTab .inviteDetailContainer .detailInput.userComments {
  padding-top: 10px;
  width: 100%;
  height: max-content;
}

.InternalActivityTab .inviteDetailContainer .skillAndCustomTags {
  display: flex;
  gap: 20px;
  flex-direction: column;
  border: 1px solid #ffffff;
  padding: 20px;
  border-radius: 20px;
  background-color: white;
}

.InternalActivityTab .seachBarWithSuggestion {
  position: relative;
}

.InternalActivityTab .seachBarWithSuggestion .searchBar {
  display: flex;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 44px;
  outline: 1px solid #f7f7f7;
  background-color: #f7f7f7;
  border-radius: 30px;
}

.InternalActivityTab .seachBarWithSuggestion .searchBar input {
  width: 100%;
  padding: 10px;
  outline: none;
  border: none;
  background-color: transparent;
}

.InternalActivityTab .suggestionBox {
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.InternalActivityTab .skillAndTagContainer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.InternalActivityTab .skillOrTagItem {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
}

.crossIcon {
  display: none;
}

.InternalActivityTab .skillOrTagItem:hover .crossIcon {
  display: block;
}

.InternalActivityTab .inviteDetailContainer .inviteDetailButtons {
  display: flex;
  justify-content: space-between;
}
