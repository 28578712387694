.components{
  text-align: left;
  overflow-x: hidden;
}
.case-study {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 1px 0px  rgba(0, 0, 0, 0.25);
  font-family: 'Lexend';
  overflow-x: hidden;
  border: 1px solid #E7E3E9;
  margin-bottom: 20px;
  border-top: 0;
}
.mainContent {
  z-index: 1;
}

.case-study-page > .case__studies {
  width: 740px;
  margin: 0 auto;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(4, 310px);
  gap: 0;
  justify-content: center;
  border-top: 1px solid #E7E3E9;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
  }
  .mainContent {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    border: 0;
  }
  .case-study{
    margin: 0 20px;
  }
}