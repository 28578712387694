.sapsidebar {
  flex: 0.18;
  display: flex;
  flex-direction: column;
  background: #f8f8f8;
  height: 100vh;
  position: sticky;
  left: 0;
  top: 0;
  bottom: 0;
  min-width: 250px;
}
.sapsidebar .sapsidebar__top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 0.5rem;
  padding: 3rem 3% 3rem 3%;
  box-sizing: border-box;
  width: 100%;
}
.sapsidebar .sapsidebar__top .sapsidebarlogo {
  object-fit: contain;
  max-height: 110px;
  width: 100%;
  width: 120px;
  cursor: pointer;
}
.sapsidebar .sapsidebarlinks {
  display: flex;
  flex-direction: column;
}
.sapsidebar .sapsidebarlinks .sapsidebarlink {
  padding: 0.7rem 9%;
  white-space: nowrap;
}
.sapsidebar .sapsidebarlinks .active {
  color: #ff656a !important;
  background: rgba(255, 0, 0, 0.1);
}
