/* Utilities */
.font__lexend {
  font-family: "lexend";
}

/* Team Members */
.team__members {
  margin-bottom: 40px;
  margin: 0 auto;
  width: 80%;
}

/* Heading Part */
.members__heading {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #dfe9f3;
  border-bottom: 1px solid #dfe9f3;
  padding: 20px 0;
  margin-bottom: 30px;
  margin-top: 1rem;
}

.members__title {
  font-weight: 500;
  font-size: 20px;
  color: #0b1c39;
  text-align: start;
  margin: 0;
}

.members__subtitle {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.heading__icon {
  width: 20px;
}

.icon__text {
  font-size: 14px;
  color: #0b1c39;
  margin: 0;
}

/* Team Members Container */
.members__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 30px;
  padding: 0 8% 40px 8%;
}

/* Mobile Device */
@media only screen and (max-width: 768px) {
  .members__container {
    grid-template-columns: 1fr;
  }
}

/* Team Member */
/* Member */
.member__info {
  column-gap: 20px;
}

.member__photo {
  width: 100px;
  height: 100px;
}

.member__name {
  font-size: 14px;
  color: #0b1c39;
}

.member__designation {
  font-weight: 500;
  font-size: 14px;
  color: #5c7793;
}

.member__username {
  font-size: 10px;
  color: #ff656a;
  margin-top: 5px;
}

/* Social Icon */
.icon__container {
  height: 21px;
}

.social__icon {
  height: 16px;
  width: 16px;
}
