.asidebaralt {
  display: none;
  align-items: center;
  justify-content: space-between;
  background: white;
  height: 42px;
  margin-top: -3rem;
  padding: 0.6rem 1rem;
  margin-left: -1.3%;
  margin-right: -1.3%;
  position: sticky;
  top: 0;
  box-shadow: 0px 5px 5px rgba(75, 44, 44, 0.07);
  z-index: 400;
}
@media screen and (max-width: 950px) {
  .asidebaralt {
    display: flex;
  }
}
.asidebaralt .navbar__burger {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 1.35rem;
}
.asidebaralt .navbar__burger span {
  height: 1px;
  width: 100%;
  margin-bottom: 4px;
  background: #ff6566;
}
.asidebaralt .unispade__logo {
  object-fit: contain;
  height: 20px;
  cursor: pointer;
}
.asidebaralt .profile__pic {
  object-fit: contain;
  border-radius: 10rem;
  height: 40px;
  cursor: pointer;
}
