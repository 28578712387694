@media screen and (max-width: 1340px) {
  .homePage {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    .topComp {
      width: 100%;
      padding: 0 8px;
      .heroSection {
        width: 100%;
        gap: 115px;
        h1 {
          text-align: left;
          font-size: 47px;
        }
        p {
          width: 100%;
          text-align: left;
          margin: 0 0;
        }
        .exploreTalentBtn {
          margin: 0 !important;
        }
      }
      .statsSection {
        width: 100%;
        flex-direction: column;
        border: 1px solid #000;
        .leftStatsSection,
        .rightStatsSection {
          flex-direction: column !important;
          width: 100%;
          justify-content: center;
          align-items: center;
          height: auto;
          padding: 20px 0;
          .infoStats {
            margin-left: 0;
            width: 86%;
            text-align: center;
          }
        }
        .rightStatsSection {
          border-top: 1px solid #000;
        }
      }
    }
    .talentPoolComp {
      width: 100%;
      .HPHeading {
        padding: 55px 8px;
        h2 {
          font-size: 41px;
        }
      }
      .mainCarousel > .expertSlide {
        flex-direction: column;
        gap: 21px;
        width: 100%;
        .expertBio {
          margin: 0 8px;
          .respnsiveCrouselPointerHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            .carouselPointers {
              right: 20px;
              display: flex;
              button {
                width: 50px;
                height: 50px;
              }
            }
          }
          .carouselPointers {
            bottom: 0px;
            display: none;
          }
          span {
            align-items: center;
            gap: 37px;
            margin-bottom: 10px;
            h3 {
              margin: 0;
            }
          }
        }
        .talentShowcase {
          padding: 21px;
          width: 100%;
          position: relative;
          right: -21px;
        }
      }
    }
    .middleComp {
      width: 100%;
      .diffTalent {
        padding-left: 8px;
        .HPHeading {
          padding: 50px 0;
        }
        .grid-container {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 600px);
          gap: 50px 0;
          .grid-item:last-child {
            grid-column: 1/3 !important;
          }
        }
      }
      .talentDelivers {
        .HPHeading {
          padding: 50px 8px;
          border-bottom: 1px solid #000;
        }
        .grid-container {
          margin: 0;
          width: 100%;
          margin-right: auto;
          grid-template-columns: repeat(2, 1fr);
          gap: 40px;
          .grid-item {
            align-items: start;
            padding: 8px;
            .contentDiv{
              border-bottom: 1px solid #000;
              p{
                border: none;
              }
              button{
                margin: 10px auto ;
              }
            }
            
          }
          .grid-item:last-child {
            grid-column: 1/3 !important;
            .contentDiv{
              border: none;
            }
          }
        }
      }
      .foundingTeam {
        border-top: none;
        border: 1px solid #000;
        margin: 0 15px;
        .HPHeading {
          padding: 50px 0;
          /* width: max-content; */
          margin: 0 auto;
          h2 {
            text-align: center;
          }
        }
        .grid-container {

          padding: 50px 0px 100px 0px;
          margin: 0;
          width: 100%;
          margin-right: auto;
          grid-template-columns: repeat(1, 1fr);
          gap: 0px;
          .grid-item {
            align-items: start;
            padding: 8px 19px;
            border: none;
          }
        }
      }
    }
    .bottomComp {
      display: flex;
      flex-direction: column;
      padding: 0 8px;
      .HPHeading {
        padding: 50px 0;
        h2{
          margin-right: 58px;
          color: black;
          font-size: 46px;
        }
      }

      .respPHidden {
        display: none;
      }
      p {
        width: 85%;
        padding: 0;
      }
      button {
        margin: 50px 0;
        left: 0;
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .homePage {
    .middleComp {
      .diffTalent {
        display: flex;
        flex-direction: column;
        gap: 37px;
        .HPHeading > h2 {
          font-size: 46px;
        }
        .grid-container {
          display: flex;
          overflow-x: auto;
          width: 100%;
          gap: 10px;
          .grid-item{img{
            width: 70vw;
          }}
          
          .grid-item:last-child {
            display: none;
          }
        }
        .AllTalent{
          display: flex;
          img{
            width: 80vw;
          }
        }
      }
      .talentDelivers {
        .HPHeading > h2 {
          font-size: 46px;
        }
        .grid-container {
          display: flex;
          flex-direction: column;
        }
      }
      .foundingTeam {
        .grid-container .grid-item {
          border-bottom: 1px solid #000;
          padding: 57px 8px;
          p {
            display: none;
          }
          .founderBio {
            flex-direction: column;
            gap: 35px;
            .founderDP {
              width: max-content;
            }
            p {
              display: block;
            }
            .founderInfo {
              margin-left: 0;
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              font-size: 20px;
            }
          }
        }
        .grid-item:last-child {
          border: none;
        }
      }
    }
  }
}
