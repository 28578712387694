@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.userProfileSingleSession {
  margin-top: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  font-family: "Poppins", sans-serif;

  width: 75%;
}

.sessionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: white;
}
.sessionHeader-title .heading {
  font-weight: 500;
  font-size: 32px;
  letter-spacing: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  text-align: left;
}

.sessionHeader-title {
  display: flex;
  align-items: baseline;

  gap: 24px;
}
.mobile-icon {
  display: none;
}

.backButton {
  background-color: #f7f7f7;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.duration {
  align-self: self-start;
  width: 192px;
  height: 50px;
  font-size: 22px;
  color: black;
  background: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  font-weight: 400;
  line-height: 29px;
}

.sessionContent {
  display: flex;
  padding: 16px;
  background: #f7f7f7;
}

.sessionDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 2;
  max-width: 500px;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  text-align: left;
}

.sessionDetails p {
  margin: 8px 0;
  text-align: left;
}

.sessionDetails ul {
  margin: 8px 0;
  padding-left: 20px;
  list-style-position: inside; /* Ensures bullets align with text */
}

.sessionDetails ul li {
  margin: 4px 0;
  text-align: start; /* Ensures text is left-aligned */
}

.sessionProfile {
  flex: 1;
  display: flex;
  flex-direction: row; /* Makes child elements align horizontally */
  overflow-x: auto; /* Enables horizontal scrolling */
  gap: 16px; /* Adds space between profile cards */
}
/* Hides the scrollbar for WebKit-based browsers (Chrome, Safari) */
.sessionProfile::-webkit-scrollbar {
  display: none;
}

.sessionProfile {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.profileCard {
  background-color: white;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 300px;
  min-width: 300px; /* Ensures consistent width for profile cards */
}

.profileCard p {
  margin: 8px 0;
  text-align: left;
  font-weight: 400;
  font-size: 15px;
}

.profileInfo {
  display: flex;
  align-items: center;
  margin-top: 16px;
  font-weight: bold;
  justify-content: center;
}

.profileInfo .profileImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.sessionFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  /* border-top: 1px solid #e0e0e0; */
  /* background-color: #f8f8f8; */
}

.sessionFooter .price {
  font-size: 22px;
  font-weight: 600;
  width: 128px;
  height: 50px;
  background: #f7f7f7;
  border-radius: 40px;
  padding: 10px;
}

.bookButton {
  background-color: #000;
  color: #fff;
  padding: 10px 23px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.duration-mob {
  display: none;
}

@media only screen and (max-width: 768px) {
  .userProfileSingleSession {
    width: auto;
  }
  .sessionContent {
    flex-direction: column;
  }

  .sessionHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .backButton {
    display: none;
  }

  .duration {
    display: none;
  }
  .duration-mob {
    display: flex;
    width: 180px;
    height: 40px;
    margin-top: 5px;
    margin-bottom: 15px;
    color: black;
    background: white;
    border: 1px solid #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    font-weight: 400;
    line-height: 29px;
  }

  .session-mobile {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .sessionHeader-title {
    justify-content: space-between;
    width: 100%;
  }

  .mobile-icon {
    /* text-align: start; */
    display: flex;
    justify-content: center;
    text-align: right;
    height: 44px;
    width: 44px;

    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #e0e0e0;
  }

  .sessionProfile {
    flex-wrap: wrap;
    height: 20px; /* Adjust height for the mobile view */
    flex-direction: column;
    overflow-y: auto;
    gap: 16px; /* Space between profile cards in column layout */
  }

  .sessionFooter {
    display: flex;
    justify-content: space-between;
  }

  .price {
    width: auto;
    height: auto;
    background: none;
    padding: 0;
    font-size: 20px;
  }

  .bookButton {
    width: 40%;
    padding: 12px 0;
    font-size: 18px;
    border-radius: 50px;
  }
}
