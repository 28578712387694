.MarketerDashb{
    display: flex;
    height: 90vh;
    width: 100%;
    overflow-y: hidden;
}
@media screen and (max-height: 700px) {
    .MarketerDashb{
        height: 89vh;
    }
}