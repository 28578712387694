.homePageLatest{
    font-family: 'lexend';
    width: 1440px;
    border-left: 1px solid #E7E3E9;
    border-right: 1px solid #E7E3E9;
    border-bottom: 1px solid #E7E3E9;
    margin: 0 auto;
    margin-bottom: 8rem;
    overflow-x: hidden;
    cursor: default;
}
@media screen and (min-width: 1500px){
    .homePageLatest{
        width: 93%;
    }    
}

@media screen and (max-width: 1400px){
    .homePageLatest{
        width: 95%;
    }
}
@media screen and (max-width: 1280px){
    .homePageLatest{
        width: 100%;
    }
}
@media screen and (max-width: 620px){
    .headingSection > h2{
        text-align: center !important;
        padding: 0 10px !important;
        font-size: 18px !important;
        line-height: 24px !important; /* 133.333% */
        letter-spacing: 0.36px !important;
    }    
}
