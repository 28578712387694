.ApplicationActivityTab {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: start;
  width: 100%;
}

.ApplicationActivityTab .applicationActivityArea {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}


.ApplicationActivityTab .applicationActivityArea .oneRequestSent {
display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 60px;
}

.ApplicationActivityTab .applicationActivityArea .oneRequestSent .aboutPool {
  flex: 1; /* Allow it to take up remaining space */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  font-weight: 500;
  font-size: 18px;
  padding: 0 20px;
  height: 60px;
  cursor: pointer;
  max-width: 750px;
}

.ApplicationActivityTab .applicationActivityArea .statusBar {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.ApplicationActivityTab .applicationActivityArea .deleteDropdownComponent {
  display: flex;
  gap: 10px;
  text-align: left;
}
