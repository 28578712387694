.react-autosuggest__container {
    position: relative;
  }
  
  .react-autosuggest__input {
    width: 100%;
    /* height: 38px; */
    color: #0b1c39;
    /* padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    border: 1px solid #aaa;
    font-size: 16px;
    border-radius: 4px; */
    
    padding: 0.4rem 0.4rem;
    outline: none;
    font-family: "Poppins";
    color: #0b1c39 !important;
    border: 1px solid lightgrey;
    background: white;
    border-radius: 3.5px;
    }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 31px;
    width: 50%;
    border: 1px solid #aaa;
    background-color: #fff;
    /* font-family: Helvetica, sans-serif; */
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    outline: none;
    font-family: "Poppins";
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 0.4rem 0.4rem;
    font-family: "Poppins";
    /* padding: 10px 20px; */
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
  