.home_page_comp_two {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
}

.home_page_comp_two .comp_two_content_sec {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 55px 0 0px 0;
    width: 100%;
    background-color: #fff;
    z-index: 2;
    padding-bottom: 70px;
}

.home_page_comp_two .corousal {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home_page_comp_two .comp_two_content_sec .comp_two_text1 {
    font-family: "Montserrat" !important;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    color: #405870;
    width: 520px;
}

.home_page_comp_two .comp_two_content_sec .comp_two_text2 {
    font-family: "lexend";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #0b1c39;
}

.home_page_comp_two .comp_two_content_sec .comp_two_sub {
    font-weight: bold;
}

.home_page_comp_two .comp_two_btn_sec {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home_page_comp_two .comp_two_btn_sec .comp_two_text3 {
    font-family: "lexend";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #405870;
    margin-top: 20px;
    width: 215px;
}

@media screen and (max-width: 768px) {
    .home_page_comp_two .comp_two_content_sec{
        margin:0;
        padding-top: 55px;
        padding-bottom: 100px;
    }
    .home_page_comp_two .comp_two_content_sec .comp_two_text1{
        font-size: 24px;
        line-height: 29px;
        width: 375px;
    }

    .home_page_comp_two .corousal{
        overflow: hidden;
        width: 100%;
    }
}
