.FirstUserprofilecompo {
    padding: auto;
    text-align: left;
}
.FirstUserprofilecompo > .backimage {
    background-image: url("../img/back_image.png");
    background-repeat: no-repeat;
    width: 100vw;
    background-size: cover;
    height: 200px;
}
.FirstUserprofilecompo > .detail {
    width:80vw;
    margin: auto;
}
.FirstUserprofilecompo > .detail >.profileDetail{
    display: flex;

    justify-content: space-between;
   
}
.FirstUserprofilecompo > .detail > .text_detail{
    display: flex;
    flex-wrap: wrap;
    gap: 5px 30px;   
    justify-content:space-between;
}
.FirstUserprofilecompo > .detail  .text_detail> div{
    display: flex;
}
.FirstUserprofilecompo > .detail .text_detail> div> img{
    height: 150px;    
    margin-top: -50px;
    margin-right: 20px;
}
.FirstUserprofilecompo > .detail  >.text_detail> div >div >h1{
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 37px;
    align-items: center;
    color: #0B1C39;
}
.FirstUserprofilecompo > .detail  >.text_detail> div >div >span{
    font-family: "lexend";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #FF656A;
}
.FirstUserprofilecompo > .detail  >.text_detail>button{
    color: #FFFFFF;
    border: transparent;
    border-radius: 5px;
    padding: 0px 5px;
    margin-right: 20px;
    height: 50px;
    background: #FF656A;
    margin-top: 5px;
   
}
.FirstUserprofilecompo > .detail  >.text_detail>button>img{
    height: 20px;
}
.FirstUserprofilecompo > .detail>.profileDetail>.profile{
    margin-left: 170px;
    margin-top: 15px;
}
 
.FirstUserprofilecompo > .detail>.profileDetail>.profile> .socialmedia{
    border-top: 1px solid #DFE9F3;
    border-bottom: 1px solid #DFE9F3;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-wrap: wrap;     
    gap: 15px 30px;
}
.FirstUserprofilecompo > .detail>.profileDetail>.profile> .socialmedia .socialIcon{
    height: 20px;
}
.FirstUserprofilecompo > .detail>.profileDetail>.profile>  .Roles{
    display: flex;
    flex-wrap: wrap;   
    gap: 0px 20px;
    padding-top: 10px;
}
.FirstUserprofilecompo > .detail>.profileDetail>.profile>  .Roles p{
    font-family: "lexend";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #0B1C39;
    border: 1px solid #DFE9F3 ;
    border-radius:20px ;
    padding: 2px 10px;
}
.FirstUserprofilecompo > .detail>.clients{
    border: 1px solid #DFE9F3;
    border-radius: 10px;
    padding: 10px;
    margin: auto;
}
.FirstUserprofilecompo > .detail>.clients >h3{
    color: #5C7793;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
}
.FirstUserprofilecompo > .detail>.clients >div{
    margin-top: 20px;
    display: flex;
    gap: 0px 20px;
    flex-wrap: wrap;
}
.FirstUserprofilecompo > .detail>.clients >div > p {
    font-family: "lexend";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    gap: 3px;
    color: #0B1C39;
    background: #F2F8FF;
    border-radius: 44px;
    padding: 5px;
}
.FirstUserprofilecompo > .detail>.clients >div > p >img{
    height: 12px;
}
 
@media only screen and (max-width: 800px) {
    .FirstUserprofilecompo > .detail {
        padding: 0px 15px;
        width:fit-content !important;
        display: inline-block;
        
    }
    .FirstUserprofilecompo > .detail >.profileDetail{
        gap: 10px;
        display: inline-block;
    }
    .FirstUserprofilecompo > .detail>.profileDetail>.profile{
        margin-left: 0px;
    }
}