.userMessageAndNudgePage {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: start;
    width: 100%;
    height: 100vh; /* Ensure the parent takes the full viewport height */
    margin-bottom: 15px;
  }
  
  .userMessageAndNudgePage .flexBetweenRadius10 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    font-weight: 900;
    font-size: 18px;
    padding: 0 20px;
    height: 60px;
    cursor: pointer;
  }
  
  .deactiveTab {
    background-color: white;
  }
  
  .width-full {
    width: 100%;
  }
  
  .icon20size {
    width: 20px;
    height: 20px;
    border-radius: 2px;
  }

  .userMessageAndNudgePage .contentArea {
    width: 100%;
    flex: 1;
    overflow-y: auto;
  }
  
  .userMessageAndNudgePage .messageTags {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 20px;
  }

  .userImage {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }