.experienceTypeContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: fit-content;
    margin: 5rem auto;

    .inputContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
        font-size: 1.4rem;
        color: rgb(58, 58, 58);

        input[type="radio"] {
            accent-color: black;
            -ms-transform: scale(1.7);
            -webkit-transform: scale(1.7);
            transform: scale(1.7);
            transition: all 0.5s ease-in-out;
        }
    }
}

.selectionTypeBtns {
    position: fixed;
    bottom: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-top: 60px;
}

.selectionTypeBtns>.cancelBtn {
    width: 150px;
    height: 40px;
    border: none;
    background-color: #f3f3f3;
    color: black;
}

.selectionTypeBtns>.saveBtn {
    width: 150px;
    height: 40px;
    border: none;
    color: white;
    background-color: black;
}