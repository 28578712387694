.agency-card {
    width: 100%;
    height: fit-content !important;
    background-color: #FFFFFF;
    border: 1px solid #E7E3E9;
    box-sizing: border-box;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.agency-card:hover {
    box-shadow: 2px 2px 7px rgba(64, 88, 112, 0.3);
}

.agency-card .image-container {
    height: auto;
    max-width: 25%;
    border: 1px solid #F7F7F7;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 15px;
    min-width: 100px;
}


.agency-card .desc-container {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
}

.agency-card .desc-container .agency-name-location {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
}

.agency-card .desc-container .agency-name-location .agency-name {
    font-family: 'lexend';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #0B1C39;
    display: flex;
    text-align: left;
    margin-right: 10px;
}

.agency-card .desc-container .agency-name-location .agency-website {
    font-family: 'lexend';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #525464;
    margin-top: 5px;
    display: none;
}

.agency-card .desc-container .agency-name-location .agency-location {
    font-family: 'lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #8CA6C0;
}

.agency-card .image-container .agency-img {
    border: 1px solid #E7E3E9;
    max-width: 100%;
    height: auto;
}
.agencylogoInitials{
    background: black;
    color: white;
    width: 50px;
    height: 50px !important;
    margin: 0;
    padding: 5px 0;
    font-size: 22px;
}

.agency-card .image-container .agency-initials {
    max-width: 100%;
    height: auto;
    min-height: 100px;
    background-color: #0b1c38;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    font-size: 1.3rem;
}

.agency-card .desc-container .services-container {
    display: flex;
    flex-wrap: wrap;
}

.agency-card .desc-container .client-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
}


.agency-card .desc-container .services-container .services {
    display: flex;
    justify-content: left;
    align-items: center;
    width: fit-content !important;
    padding: 4px 16px 4px 1px;
    height: 24px;
    background: #FFF;
    font-family: 'lexend';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    color: rgba(11, 28, 57, 0.5);
    text-transform: capitalize;
    margin-right: 5px;
    margin-top: 8px;
}

.agency-card .desc-container .client-container .agency-client-logo_name {
    height: fit-content !important;
    background: #FFF;
    transition: background 0.5s ease;
    border: 1px solid #E7E3E9;
    width: fit-content !important;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-top: 8px;
    cursor: default;
}

.agency-card .desc-container .client-container .agency-client-logo_name:hover {
    background: #FFEAEB;
    transition: background 0.5s ease;
}
.agency-client-logoInitials{
    background-color: black;
    color: white;
    margin: 0;
    height: 25px !important;
    width: 25px !important;
}

.agency-card .desc-container .client-container .agency-client-logo_name .agency-client-logo {
    height: 18px;
    width: 18px;
    border-radius: 50%;
}

.agency-card .desc-container .client-container .agency-client-logo_name .agency-client-name {
    font-family: 'lexend';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    color: rgba(11, 28, 57, 0.5);
    margin-left: 5px;
}

.agency-card .desc-container .agency-desc {
    font-family: 'lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #525464;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    overflow: hidden;
    margin: 15px 5px 15px 0;
}

@media screen and (max-width: 768px) {
    .agency-card {
        flex-direction: column;
    }

    .agency-card .image-container {
        min-width: 120px;
        min-height: 120px;
    }

    .agency-card .desc-container {
        align-items: center;
    }

    .agency-card .desc-container .agency-name-location {
        flex-direction: column;
        align-items: center;
    }

    .agency-card .desc-container .agency-name-location .agency-website {
        display: flex;
    }

    .agency-card .desc-container .agency-name-location .agency-location {
        margin-top: 5px;
    }

    .agency-card .desc-container .services-container {
        margin-top: 30px;
        display: flex;
        justify-content: center;
    }

    .agency-card .desc-container .client-container {
        display: flex;
        justify-content: center;
    }

    .agency-card .desc-container .agency-desc {
        margin: 15px 30px 15px 30px;
        text-align: center;
        -webkit-line-clamp: 3;
    }
}