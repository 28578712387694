.primaryBtn {
    background: #191E22;
    color: #ffffff;
}

.secondaryButton{
    background-color: #DFE9F3;
    color: #5C7793
}

.btnStyle{
    font-family: "lexend";
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 30px;
    height: 40px;
    cursor: pointer;
    width: fit-content !important;
}