.talentPoolComp {
  width: 1340px;
  margin: 0 auto;
  background: #f0f0f0;
  border: 1px solid #000;
  border-top: none;
  padding-bottom: 50px;
  overflow: hidden;
}
.talentPoolComp > .mainCarousel {
  display: flex;
  flex-direction: row;
}
.talentPoolComp > .mainCarousel > .expertSlide {
  display: flex;
  flex-direction: row;
}
.talentPoolComp > .mainCarousel > .expertSlide > .expertBio {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 370px;
  margin-left: 50px;
}
.talentPoolComp > .mainCarousel > .expertSlide > .expertBio > img {
  height: 100px;
  width: 100px;
  margin-bottom: 30px;
}
.talentPoolComp > .mainCarousel > .expertSlide > .expertBio > h2 {
  color: #000;
  font-family: 'GM-Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 187.5% */
  letter-spacing: 0.64px;
}
.talentPoolComp > .mainCarousel > .expertSlide > .expertBio > p {
  color: #000;
  font-family: 'GM-Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 187.5% */
  letter-spacing: 0.64px;
  margin-bottom: 0;
}
.talentPoolComp > .mainCarousel > .expertSlide > .expertBio > span {
  display: flex;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #d9d9d9;
  width: 270px;
}
.talentPoolComp > .mainCarousel > .expertSlide > .expertBio > span > h3 {
  color: #000;
  font-family: 'GM-Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 187.5% */
  letter-spacing: 0.64px;
}
.talentPoolComp > .mainCarousel > .expertSlide > .talentShowcase {
  display: flex;
  flex-direction: row;
  width: 920px;
  overflow: scroll;
  padding: 100px 50px 100px 100px;
  padding-left: 100px;
  background: #fff;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
}
.talentPoolComp > .mainCarousel > .expertSlide > .talentShowcase > .talentItem {
  margin-right: 30px;
}

.expertSlide {
  flex: 0 0 100%; /* Each slide takes up 100% of the width */
  transition: transform 0.5s ease-in-out;
  .talentRoles {
    min-height: 120px;
  }
}
@keyframes fade-in-animation {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fade-out-animation {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
}

.fade-out {
  animation-name: fade-out-animation;
}

.fade-in {
  animation-name: fade-in-animation;
  opacity: 1;
  transition: opacity 0.7s ease-in-out; /* Use transition for fade-in/fade-out effect */
  animation-duration: 0.9s; /* Duration of animation */
  animation-fill-mode: both; /* Apply styles before and after animation */
}

.expertSlide .acive {
  display: visible;
}

.expertBio {
  /* Your expert bio styles here */
}

.talentShowcase {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: transparent transparent; /* For Firefox */
  &::-webkit-scrollbar {
    width: 5px !important; /* Adjust the width of the scrollbar */
  }
  .respnsiveCrouselPointerHeader{
    display: none;
  }
  /* Style for the custom scrollbar */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* Width and color for the custom scrollbar thumb */
  &::-webkit-scrollbar-thumb {
    background-color: transparent; /* Grey color */
    border-radius: 15px;
    width: 5px !important;
    height: 5px ;
    opacity: 0; /* Initially hide the scrollbar */
    transition: opacity 0.3s ease-in-out; /* Add transition for smooth appearance */
  }

  /* Show the scrollbar thumb when scrolling */
  &:hover::-webkit-scrollbar-thumb {
    opacity: 0.5; /* Adjust the opacity to make it visible */
  }
  
}
.carouselPointers {
  position: relative;
  bottom: -320px;
}

.prevButton,
.nextButton {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 10px;
  cursor: pointer;
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 80px;
  flex-direction: row;
}

.prevButton {
  left: 10px;
  margin-right: 20px;
}

.nextButton {
  right: 10px;
}
