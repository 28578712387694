.modal-form-container {
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid #ccc;
  max-height: 95%;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modal-form-container .modal-form-content {
  background-color: #fff;
  border-radius: 8px;
  width: 500px;
}

.modal-form-container .modal-form-close-button {
  align-self: flex-end;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
}

.modal-form-container .modal-form-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
}

.modal-form-container .modal-form-footer .modal-form-cancel-button {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  background-color: #fff;
  color: #000;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border: 1px solid #000;
}

.modal-form-container .modal-form-footer .modal-form-cancel-button:hover {
  background-color: #000;
  color: #fff;
}

.modal-form-container .modal-form-footer .modal-form-submit-button {
  padding: 0.5rem 1rem;
  border: 1px solid #000;
  border-radius: 10px;
  background-color: #000;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.modal-form-container .modal-form-footer .modal-form-submit-button:hover {
  background-color: #fff;
  color: #000;
}
