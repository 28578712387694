.NewHomPage {
  text-align: left;
}

.NewHomPage .page-main-heading {
  font-weight: 700;
  font-size: 80px;
  line-height: 108px;
  color: black;
  text-align: center;
}

.NewHomPage .second-main-heading {
  font-weight: 700;
  font-size: 60px;
  line-height: 81px;
  text-align: center;
  color: black;
}

.NewHomPage .component-sub-heading-first-line {
  font-weight: 500;
  font-size: 40px;
  line-height: 54px;
  color: #232c33;
}

.NewHomPage .component-sub-heading-second-line {
  font-weight: 400;
  font-size: 40px;
  line-height: 54px;
  color: #232c33;
}

.NewHomPage .component-heading-description-light {
  font-weight: 400;
  font-size: 25px;
  line-height: 33.75px;
}
.NewHomPage .component-heading-description-dark {
  font-weight: 500;
  font-size: 25px;
  line-height: 33.75px;
}

.NewHomPage .component-heading-description-darker {
  font-weight: 600;
  font-size: 25px;
  line-height: 33.75px;
}

@media screen and (max-width: 480px) {
  .NewHomPage .page-main-heading {
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    color: black;
    text-align: left;
  }

  .NewHomPage .second-main-heading {
    font-weight: 700;
    font-size: 30px;
    line-height: 40.5px;
    text-align: left;
    color: black;
  }

  .NewHomPage .component-sub-heading-first-line {
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #232c33;
  }

  .NewHomPage .component-sub-heading-second-line {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #232c33;
  }

  .NewHomPage .component-heading-description-light {
    font-weight: 400;
    font-size: 15px;
    line-height: 20.25px;
  }
  .NewHomPage .component-heading-description-dark {
    font-weight: 500;
    font-size: 15px;
    line-height: 20.25px;
  }

  .NewHomPage .component-heading-description-darker {
    font-weight: 600;
    font-size: 15px;
    line-height: 20.25px;
  }
}
