.basicinfo_container {
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  margin-top: 20px;
}

.BasicInfo {
  text-align: start;
  width: 640px;
  max-width: 600px;
  margin: 0 auto;
  overflow: auto;
  padding: 20px 0;
}

.BasicInfo > .Fields {
  width: 100%;
  margin: 0 auto;
}

/* .BasicInfo>.Fields>p {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.01em;
    color: #b4aebd;
    margin-top: 15px;
    margin-bottom: 3px;
} */

.BasicInfo > .Fields > div {
  /* border: 1px solid #e7e3e9; */
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 40px 20px;
}

.BasicInfo > .Fields > div > .InfoDiv {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 5px;
}

.BasicInfo > .Fields > div > .InfoDiv > .info-title {
  display: flex;
  gap: 2px;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 15px;
}

.BasicInfo > .Fields > div > .InfoDiv > span {
  font-weight: 400;
  font-size: 10px;
}

.BasicInfo > .Fields > div > .InfoDiv > .inputContainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;

  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 0 10px;
  padding-left: 20px;
  min-height: 60px;
}

.BasicInfo > .Fields > div > .InfoDiv > .inputContainer > .iconBoxWithText {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 5px 10px;
}
.BasicInfo
  > .Fields
  > div
  > .InfoDiv
  > .inputContainer
  > .iconBoxWithText:hover {
  background-color: #e0e0e0;
}
.BasicInfo > .Fields > div > .InfoDiv > .inputContainer > .iconBoxWithText p {
  margin: 0;
  line-height: 1;
  font-size: 14px;
  white-space: nowrap; /* Prevents text from wrapping to the next line */
  overflow: hidden; /* Optional: hides overflow if text is too long */
  text-overflow: ellipsis; /* Optional: adds "..." if text overflows */
}
.BasicInfo > .Fields > div > .InfoDiv .Inactive {
  pointer-events: none;
  color: #e0e0e0;
}

.BasicInfo > .Fields > div > .InfoDiv .errorColor {
  color: red;
}

.BasicInfo > .Fields > div > .InfoDiv > .inputContainer > input {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;

  font-weight: 500;
  font-size: 16px;
  width: 70%;
  max-width: 100%;
  outline: none;
  border: none;
  background-color: transparent !important;
  min-height: 60px;
  line-height: 24px;
}

.BasicInfo > .Fields > div > .InfoDiv > textarea {
  padding: 5px 0;
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  outline: none;
  height: 200px;
  overflow-x: auto;
}
.BasicInfo > .Fields > div > .InfoDiv > textarea::-webkit-scrollbar {
  display: none;
}

.BasicInfo > .Fields > div > .InfoDiv > .inputContainer > input::placeholder,
.BasicInfo > .Fields > div > .InfoDiv > textarea::placeholder {
  color: #b1b1b1;
  font-weight: 400;
  size: 18px;
}

.BasicInfo > .buttons {
  height: 50px;
  width: 100%;
  display: flex;
  background: #ffffff;
  border-top: 1px solid #e7e3e9;
  box-shadow: 0px -4px 10px rgba(231, 227, 233, 0.5);
  justify-content: center;
  gap: 0;
  margin-left: -4.5px;
}

.BasicInfo > .buttons > button {
  border: 1px solid #e7e3e9;
  border-top: none;
  width: 300px;
  height: 50px;
  background-color: transparent;
}

.BasicInfo > .buttons > button:last-child {
  background: #f8f8f8;
}

.basicinfo_container
  > .formButtons
  > .innerFormContainer
  > .formBtn:nth-child(1) {
  border-right: 1px solid #e7e3e9;
}

.basicinfo_container > .formButtons > .innerFormContainer > .formBtn {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none;
}

.basicinfo_container > .formButtons > .innerFormContainer > .formBtn:hover {
  background-color: #dfdfdf;
}

.newFormButtons {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 870px) {
  .basicinfo_container {
    padding: 0;
  }
  .BasicInfo > .Fields {
    width: 100%;
    min-width: none;
  }
  .BasicInfo {
    width: 95%;
    margin: 0 2.5%;
    padding: 0;
  }
  .BasicInfo > .Fields > div {
    width: 100%;
    min-width: none;
    padding: 10px;
  }
  .newFormButtons {
    margin: 10px 0px;
  }
}

@media screen and (max-height: 700px) {
  .basicinfo_container {
    height: 65vh;
  }
}
