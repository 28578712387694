.reviews {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
  width: 100%;
  padding: 3.5rem 0;
  box-sizing: border-box;

  background: #fff6f6;
}
.reviews__subtitle {
  color: rgb(88, 88, 88);
  font-weight: 500;
}

.reciews__sec {
  display: flex;
  justify-content: space-between;
  /* align-items: flex-start; */
  column-gap: 2rem;
  padding: 0 1.5rem;
  box-sizing: border-box;
  text-align: initial;
}
.reciews__sec .userimg {
  object-fit: cover;
  height: 250px;
  width: 250px;
}
.reciews__sec .apostrophe {
  object-fit: contain;
  height: 25px;
}
.reciews__sec .reciews__sec__data {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.reciews__sec .reciews__sec__data .userdesc {
  color: rgb(109, 109, 109);
  font-size: 0.94rem;
  max-width: 600px;
  margin-bottom: 1.6rem;
}
.reciews__sec .reciews__sec__data .username,
.reciews__sec .reciews__sec__data .userplace {
  margin-bottom: 0.25rem;
  font-size: 0.9rem;
}
.reciews__sec__data .pn__btns {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 0.5rem;
  margin-top: 1rem;
}
.pn__btns .pn__prev__btn,
.pn__btns .pn__next__btn {
  cursor: pointer;
}
.pn__btns .dis__btn {
  filter: grayscale(100%);
}
.reviews .rotate180 {
  transform: rotate(180deg);
}
@media screen and (max-width: 850px) {
  .reviews {
    padding: 2rem 5%;
  }
}
@media screen and (max-width: 800px) {
  .reciews__sec {
    text-align: center;
    padding: 0 0.5rem;
  }
  .reciews__sec .userimg,
  .reciews__sec .apostrophe {
    display: none;
  }
  .reciews__sec .reciews__sec__data {
    align-items: center;
  }
}

/* Swiper */
/* .swiper-container {
  width: 80%;
  margin: 0 auto;
}
.swiper_slides {
  padding: 0px 50px;
  height: 100%;
  width: 100%;
  position: relative;
  display: block;
  text-align: left;
} */
/* .swiper-button-next {
  margin-top: 0px;
  position: absolute;
  top: 50%;
  right: -40px;
  width: 45px;
  height: 45px;
  transform: translateY(-50%);
}
.swiper-button-prev {
  position: absolute;
  top: 50%;
  left: -40px;
  width: 45px;
  height: 45px;
  transform: translateY(-50%);
  margin-top: 0px;
} */

/* Swiper test */
.single-carousel {
  padding-bottom: 48px !important;
  background-color: #fff6f6;
}

.single-next:after,
.single-prev:after {
  font-family: swiper-icons;
  font-size: 10.54px !important;
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-variant: initial;
  line-height: 1;
}

.single-prev {
  position: absolute;
  top: 20% !important;
  left: 20% !important;
  margin-right: 8px !important;
  background-color: #ff6566;
  padding: 10px 20px 10px 20px !important;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black !important;
  font-weight: 800 !important;
}
.single-next {
  position: absolute;
  top: 20% !important;
  right: 20% !important;
  padding: 10px 20px 10px 20px !important;
  background-color: #ff6566;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black !important;
  font-weight: 800 !important;
}

.carousel-cnt {
  padding-top: 84px;
}

.caraousel-txtbox {
  padding-left: 2rem;
}

.carousel-text {
  padding-left: 80px;
  padding-right: 48px;
}

.carousel-img {
  padding-left: 48px;
}

@media (max-width: 768px) {
  .fab2 {
    padding: 3.5rem 2rem 3.5rem 2rem;
  }

  .fab3 {
    padding-top: 112px;
    padding-left: 5%;
    padding-right: 5%;
  }
  .fab3c {
    padding-top: 5%;
  }

  .fab3c1,
  .fab3c2 {
    padding: 5% 5% 5% 5%;
    text-align: center;
  }

  .fab-img {
    padding: 3% 7% 3% 7%;
  }

  .fab3c {
    display: flex;
    flex-flow: column;
  }
  .fab3c2 {
    order: 1;
  }
  .fab-img {
    order: 2;
  }

  .single-prev {
    position: absolute;
    top: 87% !important;
    left: 40% !important;
  }
  .single-next {
    position: absolute;
    top: 87% !important;
    right: 40% !important;
  }

  .fab1 h1 {
    padding-top: 2rem;
  }

  .fab1 h3 {
    margin: 5% !important;
  }

  .fab5 {
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 10%;
  }
  .fab3c2 {
    padding: 10% 0% 10% 10%;
  }
}

@media (max-width: 500px) {
  .fab2 {
    padding: 3.5rem 1rem 3.5rem 1rem;
  }

  .fab3 {
    padding-top: 3rem;
  }
  .fab4 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .single-prev {
    position: absolute;
    top: 90% !important;
    left: 40% !important;
  }
  .single-next {
    position: absolute;
    top: 90% !important;
    right: 40% !important;
  }
}
