/* Case Study Card */
.caseStudy__card {
  width: 310px;
  border: 1px solid #eaeaea;
  padding-bottom: 0;
}

.caseStudy__card > .top_part {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  padding-bottom: 12px;
}

.caseStudy__card > .top_part > .verified_icon {
  width: 20px;
  height: 20px;
}
.caseStudy__card > .top_part > .details{
  display: flex;
  align-items: center;
  gap: 10px;
}
.caseStudy__card > .top_part > .details>img{
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border: .5px solid #E3E7E9;
  border-radius: 40px;
  background: lightgray 50% / cover no-repeat;
}

.caseStudy__card > .top_part > .details > .name {
  margin: 0;
  font-weight: 700;
  color: #525464;
  font-size: 10px;
  text-align: start;
}
.caseStudy__card > .main > .display_image {
  margin: 0 20px;
  width: 310px;
  border-radius: 5px;
  width: 270px;
  height: 200px;
  cursor: pointer;
}

.caseStudy__card > .main {
  position: relative;
  margin-bottom: 1rem;
  height: 200px;
}

.caseStudy__card > .sound_icon {
  position: absolute;
  height: 35px;
  width: 35px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  top: 5px;
  right: 5px;
}

.caseStudy__card > a {
  border: none;
  background: none;
  color: #CEC8D3;
  font-size: 12px;
  display: flex;
  padding: 0 20px;
  text-decoration: none;
}

.caseStudy__card > .ptitle{
  font-family: "lexend";
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: start;
  padding: 0 20px;
  padding-top: 14px;
  height: 100px;
  margin-bottom: 0 !important;
  cursor: pointer;
}
.caseStudy__card > .aname {
  text-align: left;
  padding: 15px 20px 0px 20px;
  /* display: inline; */
  display: flex;
  align-items: center;
  gap: 10px;
}
.caseStudy__card > .aname>img{
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 40px;
  background:  lightgray 50% / cover no-repeat;
  border: 0.5px solid #E3E7E9;
}
.caseStudy__card > .aname > p {
  color:#191E22;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.12px;
  margin-bottom: 0 !important;
}  