.agency__profile {
  width: 93%;
  margin: 0 auto;
  border-left: 1px solid #E7E3E9;
  border-right: 1px solid #E7E3E9;
  margin-bottom: 3rem !important;
  position: relative;
}

.agency__profile::after {
  content: "";
  position: absolute;
  bottom: -1;
  left: -4%;
  width: 100vw;
  height: 1px;
  background-color: #E7E3E9;
}

.agency__profile .agency__profile_header {
  background: #FFF;
  display: flex;
  justify-content: space-between;
  padding: 35px 8%;
  border-bottom: 1px solid #E3E7E9;
}
  .agency__profile_header .left {
    display: flex;
    align-items: flex-start;
    gap: 45px;
  }
  
  .agency__profile_header .agency_logo {
    width: 100px;
    border-radius: 5px;
    border: 1px solid #E7E3E9;
  }
  .agency__profile_header .brandlogoInitials{
    background-color: black;
    font-size: 40px;
    color: white;
    height: 70px;
    width: 70px;
    padding: 3px 0;
  }
  
  .agency__profile_header .info_div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 6px;
    margin-bottom: 8px;
  }
  
  .agency__profile_header .agency_name {
    font-family: "lexend";
    font-weight: 700;
    font-size: 20px;
    color: #405870;
    margin: 0;
  }
  
  .agency__profile_header .verified_icon {
    width: 22px;
  }
  
  .agency__profile_header .agency_website {
    font-size: 14px;
    color: #5c7793;
    margin: 0;
    text-align: start;
  }
  
  .agency__profile_header .agency_infos {
    display: flex;
    gap: 45px;
    margin-top: 18px;
  }
  
  .agency__profile_header .agency_infos .agency_info {
    display: flex;
    gap: 16px;
    align-items: center;
  }
  
  .agency__profile_header .agency_infos img {
    width: 22px;
  }
  
  .agency__profile_header .agency_infos p {
    font-family: "lexend";
    font-weight: 500;
    font-size: 14px;
    margin: 0;
  }
  
  .agency__profile_header .right {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .agency__profile_header .right button {
    font-family: "lexend";
    font-size: 14px;
    border-radius: 0;
  }
  
  .agency__profile_header .agheaderBtn {
    border: 1px solid #E7E3E9;
    color: #FFF;
    background: #191E22;
    font-weight: 400;
    padding: 10px 15px;
  }
  
  .vistWebBtn{
    color: #191E22;
    background: #FFF;
    font-weight: 400;
    padding: 7.5px 15.5px;
    border: 0;
  }
  .agency__profile_content {
    padding: 0;
  }
  
  .agency__profile_tags {
    padding: 30px 8%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  .agency__profile_tag {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #191E22;
    background: #FFF;
    padding: 10px 20px;
    border-radius: 0;
    border: 1px solid #E7E3E9;
    text-transform: capitalize;
  }
  
  .agency__profile_description {
    font-size: 14px;
    line-height: 17px;
    text-align: start;
    margin-bottom: 35px;
    font-weight: 400;
    padding: 0 8%;
  }
  
  .agency__profile_heading {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    height: 50px;
    color: #191E22;
    padding: 0 8%;
    text-align: start;
    margin: 0;
    position: relative;
  }
  
  .agency__profile_heading::before,
  .agency__profile_heading::after {
    content: "";
    position: absolute;
    top: 0;
    left: -4%;
    width: 100vw;
    height: 1px;
    background-color: #E7E3E9;
  }
  
  .agency__profile_heading::after {
    top: auto;
    bottom: 0;
  }
  
  .agency__profile_clients {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    justify-content: left;
    padding: 30px 8% 0 8%;
  }
  
  .agency__profile_clients div {
    width: 100px;
    height: 100px;
    display: flex;
  }
  
  .agency__profile .case__studies {
    padding: 0 8% 35px 8%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
  }
  
  .agency__profile .case__studies .by {
    color: #ff656a;
    text-align: start;
    font-size: 10px;
  }
  
  .agency__profile .case__studies .display_image {
    height: 196px;
    width: auto;
    max-width: 300px;
  }
  
  .agency__profile .package_box {
    border: 1px solid #e8eff7;
    border-radius: 10px;
    padding: 30px;
    margin-top: 30px;
  }   
  
  .package_box .package_name {
    font-weight: 700;
    text-align: start;
    color: #191E22;
    margin: 0;
  }
  
  .package_box .pricing {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
    border-bottom: 1px solid #E7E3E9;
    padding-bottom: 25px;
  }
  
  .package_box .price {
    color: #191E22;
    font-weight: 700;
    font-size: 14px;
    margin: 0;
  }
  
  .package_box button {
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
    background: #191E22;
    border-radius: 3px;
    padding: 5px 40px;
    display: flex;
  }
  
  .agency__profile .view_all {
    background: #E7E3E9;
    border-radius: 3px;
    font-weight: 700;
    font-size: 14px;
    color: #5c7793;
    border: none;
    padding: 6px 40px;
    margin: 30px 0;
  }
  
  .agency__profile .review_box {
    border: 1px solid #e8eff7;
    border-radius: 10px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .agency__profile .review_box .details {
    background: #e8eff7;
    border-radius: 44px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 3px 8px 3px 3px;
    gap: 2px;
    margin-bottom: 22px;
  }
  
  .agency__profile .review_box .details .logo {
    width: 26px;
    height: 26px;
    border-radius: 100px;
  }
  
  .agency__profile .review_box .details .name {
    margin: 0;
    font-family: "lexend";
    font-weight: 500;
    color: #191E22;
    font-size: 10px;
    text-align: start;
  }
  
  .review_box .reviewer {
    font-weight: 700;
    font-size: 14px;
    color: #ff656a;
    margin: 0;
  }
  
  .reviewer_designation {
    font-weight: 500;
    font-size: 14px;
    color: #5c7793;
  }
  
  .agency__profile .agency__profile_reviews {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    padding-top: 30px 8% 0 8%;
  }
  .agency__profile .caseStudy__card {
    border: 1px solid #dfe9f3;
  }
  
  @media screen and (max-width: 1500px){
  
    .agency__profile .case__studies {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }  
    .agency__profile{
      overflow: hidden;
    }
  }
  
  @media screen and (max-width: 1100px){
  
    .agency__profile .case__studies {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }  
  }
  
  
  @media screen and (max-width: 850px){
    
    .agency__profile_header{
      flex-direction: column;
      row-gap: 1rem;
    }
    .agency__profile_header .agheaderBtn{
      width: 80%;
      align-self: center;
    }
    .agency__profile .agency__profile_reviews {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
    .agency__profile .case__studies {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }  
    .agency__profile .case__studies .display_image {
      height: 196px;
      width: 196px;
    }
    
  
  }
  
  @media screen and (max-width: 550px){
    
    .agency__profile_header{
      flex-direction: column;
      row-gap: 2rem;
    }
    .agency__profile_header .agency_name{
      text-align: left;
    }
    .agency__profile_header .agency_infos{
      flex-direction: column;
      row-gap: 1rem;
    }
    .agency__profile .case__studies {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }  
    .agency__profile .case__studies .display_image {
    height: 196px;
    width: auto;
    max-width: 300px;
  }
  
  
  }