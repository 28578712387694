.NewLoaderPage{
    position: relative;
    top: 100%;
    background-color: white; 
    z-index: 1000; 
  }
  #new-loader-container {
   text-align: center;
   opacity: 0; 
   animation: fadeInOut 1.5s infinite; 
   z-index: 2;
 }

 @keyframes fadeInOut {
   0% { opacity: 0; }
   30% { opacity: 0; } /* Add a delay before the loader becomes visible */
   50% { opacity: 1; }
   70% { opacity: 1; } /* Add a delay before the loader starts to fade out */
   100% { opacity: 0; }
 }
