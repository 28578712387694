.vertical-carousel-container {
    height: 100%;
    margin-right: 5px;
    overflow: hidden;
    position: relative;
}

.vertical-carousel-container .vertical-carousel-wrapper {
    display: flex;
    flex-direction: column;
    animation: scroll 20s linear infinite;
    /* here 16 is the total no of elements(the elements are actually doubled. So if images are 5 the elements in the DOM should be 10) */
    height: calc(165px * 14)
}

.vertical-carousel-container .vertical-carousel-wrapper .carousel-item .vertical-carousel-img {
    max-width: 90%;
    height: auto;
}

.carousel-item {
    /* min-width: 160px;
    min-height: 160px;
    height: 160px;
    width: 160px; */
    margin-bottom: 5px;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes scroll {
    0% {
        transform: translateY(0);
    }

    /* here 8 is the total no of images */
    100% {
        transform: translateY(calc(-165px * 7))
    }
}

@media screen and (max-width: 768px) {
    .vertical-carousel-container{
        max-width: 105px;
        width: 105px;
        margin: unset
    }

    .vertical-carousel-container .vertical-carousel-wrapper{
        height:calc(105 * 14)
    }
    .vertical-carousel-container .vertical-carousel-wrapper .carousel-item{
        min-width: 100px;
        min-height: 100px;
        height: 100px;
        width: 100px;
    }
    @keyframes scroll {
        0% {
            transform: translateY(0);
        }
    
        /* here 8 is the total no of images */
        100% {
            transform: translateY(calc(-105px * 7))
        }
    }
}