.brand_details>.brand_agency_name{
    margin-left: 10vw;
    margin-top: 50px;
    padding-bottom: 20px;
    width: 80vw; 
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap:20px 100px;
    border-bottom: 1px solid  #DFE9F3 ;
}
.brand_details>.brand_agency_name >.all_tags{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .brand_details>.brand_agency_name >.all_tags>.tag{
    background-color: #E8EFF7;
    border-radius: 20px;
    padding: 7px 30px 7px 5px;
  }

.brand_details>.brand_agency_name .tag{
    display: flex;
    gap: 10px;
    padding: 3px 5px;
  }
 
  .brand_details>.brand_agency_name .tag>img{
    margin: auto;
    height: 30px;
    border-radius: 20px;
 }

 .brand_details>.brand_agency_name .tag>p{
    margin: auto;
    font-family: "lexend";
    font-size: 15px;
    color: #0B1C39;
 }
 .brand_details>.brand_agency_name >.verify_tag>p{
  font-size: 15px;
}
 

/* ------------------------------------*/

.brand_details>.style_ind_service{
  display: flex;
  width: 80vw;
  margin-left: 10vw;
  gap: 1.5rem;
  padding-top :  15px;
  padding-bottom :  15px;
  border-bottom: 1px solid  #DFE9F3 ;
}
.brand_details>.style_ind_service p{
  font-family: "lexend";
  padding: 7px 10px;
  background-color:  #E8EFF7;
  border-radius: 20px; 
  font-size: 15px;
  color: #0B1C39;
}
.brand_details>.style_ind_service h3{
  font-weight: 600;
}
.serviceName{
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
}
