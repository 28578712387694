@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500&display=swap");

.UserSection {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 auto;
  width: 350px;
  overflow-x: auto;
  z-index: 999; 
  padding: 20px;
}

.UserSection::-webkit-scrollbar {
  display: none; /* This hides the scrollbar */
}

.UserSection {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.UserSection .userProfile {
  display: flex;
  flex-direction: column; /* Stack the elements vertically */
  justify-content: space-evenly;
  align-items: center;    /* Center the child elements */
  text-align: center;    /* Center vertically */
  background-color: #F7F7F7;
  min-height: 300px;
  border-radius: 30px;
  border: none;
  text-align: center; 
  position: sticky;
  padding: 0 10px;
  /* Adjust the min-height as needed */
}

.UserSection .userProfile img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  max-width: 150px;
  min-height: 150px;
}
.UserProfileSidebar .userProfile .userProfileInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.UserSection .userProfile .UserProfileInfo p:nth-child(1){
  font-family: sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: black;
}

.UserSection .userProfile .UserProfileInfo p:nth-child(2){
  font-family: sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: black;
}

.UserSection .userProfile .UserProfileInfo p:nth-child(3){
  font-family: sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: black;
}


.UserSection > .topBottomesidebar {
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #e7e3e9;
  padding-left: 10px;
  height: 50px;
  cursor: pointer;
}
.UserSection > .bottom_sidebar {
  display: flex;
  flex-direction: column;
  margin-top: -50px;
  height: fit-content;
  position: relative;
}
.UserSection > .bottom_sidebar .coompanyProfile {
  display: flex;
  gap: 10px;
  z-index: 2;
  max-height: 50px;
}
.UserSection > .bottom_sidebar .coompanyProfile a {
  text-decoration: none;
}
.UserSection > .bottom_sidebar .coompanyProfile button {
  border: none;
  border-bottom: 1px solid #E7E3E9;
  padding: 10px;
  width: 100%;
  background-color: transparent;
  font-weight: 300;
  font-size: 16px;
  line-height: 32px;
  text-align: left;
  width: 240px;
}
.UserSection > .bottom_sidebar .coompanyProfile .companyProfileWrapper {
  display: none;
  width: 300px;
  min-height: 200px;
  overflow-y: scroll;
  position: absolute;
  top: -90%;
  left: 100%;
  z-index: 1;
  background: #f8f8f8;
  border: 1px solid #e7e3e9;
}
.UserSection > .bottom_sidebar .coompanyProfile .companyProfileWrapper button {
  border: none;
  border-bottom: 1px solid #E7E3E9;
  padding: 10px;
  width: 100%;
  background-color: transparent;
  font-weight: 300;
  font-size: 16px;
  line-height: 32px;
  text-align: left;
  display: flex;
  align-items: center;
}
.UserSection > .bottom_sidebar .coompanyProfile:hover .companyProfileWrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -90%;
  left: 100%; 
}
.UserSection > .bottom_sidebar .coompanyProfile:hover .companyProfileWrapper img {
  width: 50px;
  margin-right: 20px;
  pointer-events: none;
}
.UserSection > .bottom_sidebar .coompanyProfile:hover .companyProfileWrapper p {
  margin: 0;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  color: var(--black-525464-d, #525464);
  margin-right: 5px;
}
.UserSection > .bottom_sidebar .coompanyProfile:hover .companyProfileWrapper span {
  pointer-events: none;
}
.UserSection > .bottom_sidebar .coompanyProfile > button > span {
  visibility: hidden;
  margin-left: 20px;
  font-size: 22px;
}
.UserSection > .bottom_sidebar .coompanyProfile:hover > button {
  color: black;
  font-weight: 600;
}
.UserSection > .bottom_sidebar .coompanyProfile:hover > button > span {
  visibility: visible;
}
.UserSection > .topBottomesidebar .logOut {
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #e7e3e9;
  padding-left: 10px;
  height: 50px;
  cursor: pointer;
}
.UserSection > .topBottomesidebar > a,
.UserSection > .topBottomesidebar .logOut a {
  font-weight: 300;
  font-size: 16px;
  line-height: 32px;
  color: #b4aebd;
  margin: auto 10px;
  width: fit-content !important;
  text-decoration: none;
}
.UserSection > .topBottomesidebar > img,
.UserSection > .topBottomesidebar .logOut img {
  height: 13.2px;
  width: 13.2px;
  margin: auto 0;
  margin-left: 10px;
}

.UserSection > .UserSectionPP > img {
  width: 100px;
  height: 100px;
  border-radius: 70px;
  background-color: rgb(189, 189, 189);
  margin: auto;
}
.UserSection > .UserSectionPP {
  height: 60px;
}
.UserSection > .Fields {
  display: flex;
  flex-direction: column;
  margin-bottom: 8rem;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
}
.UserSection > .Fields .sideBarAnchorTags {
  border: none;
}

.UserSection > .Fields .sideBarButtons {
  border: none;
  color: black;
  border-bottom: 1px solid #E7E3E9;
  text-decoration: none;
  border-top: none;
  padding: 0 30px;
  width: 100%;
  background-color: transparent;
  font-weight: 400;
  font-size: 18px;
  text-align: left;
  height: 60px;
}

.UserSection > .Fields .sideBarButtons.selectedStyle {
  border: none;
  font-weight: 700;
}

.UserSection > .bottom_sidebar {
  border-top: 1px solid #e7e3e9;
}

.UserSection > .mobileMenuIcon {
  display: none;
  cursor: pointer;
}

/* Show the mobile menu on screens 870px and smaller */
@media only screen and (max-width: 870px) {
  /* Make the burger icon visible for mobile */
  .UserSection > .mobileMenuIcon {
    display: block;
    height: 20px;
    width: 30px;
    position: absolute;
    top: 15px;
    right: 28px;
    cursor: pointer;
    z-index: 1000; /* Ensure the icon is on top */
  }
  .UserSection.compact {
    height: 75px; /* Set compact height */
  }
  
  .UserSection.active {
    height: 64vh; /* Set full height when the menu is active */
  }

  .UserSection > .mobileMenuIcon >img {
    height: 50px;
    width: auto;
  }
  /* Ensure the sidebar takes the full viewport height */
  .UserSection {
    top: 81px;
    position: absolute;
    height: 100vh;
    width: 100vw;
    background: transparent;
    border: none;
    padding: 10px;
  }

  /* Hide the profile section for mobile */
  .UserSection > .userProfile {
    display: none !important;
  }

  /* Adjust the buttons and fields to fit mobile */
  .UserSection > .Fields,
  .UserSection > .topBottomesidebar,
  .UserSection > .UserSectionPP {
    display: none; /* Hide by default */
    transition: all 0.2s linear;
  }

  /* Make the active section visible */
  .UserSection > .Fields.active,
  .UserSection > .topBottomesidebar.active {
    display: flex;
    flex-direction: column;
    z-index: 999;
    transform-origin: right;
    transition: all 0.2s linear;
  }

  /* Ensure fields take full width and have proper padding */
  .UserSection > .Fields.active {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
    width: 100%;
    height: 64vh; /* Take full height of the viewport */
    overflow-y: auto; /* Allow scrolling */
    background-color: #f8f8f8; /* Set background color */
    margin-bottom: 0px;
  }

  /* Style the sidebar buttons on mobile */
  .UserSection > .Fields.active .sideBarButtons {
    width: 100%;
    font-size: 1rem;
    border: 1px solid #e7e3e9;
    border-radius: 8px;
    background-color: #fff;
    padding: 12px 16px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Light shadow */
    transition: background-color 0.2s ease, box-shadow 0.2s ease;
  }

  /* Add hover effect for better interactivity */
  .UserSection > .Fields.active .sideBarButtons:hover {
    background-color: #f0f0f0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }

  /* Remove the light outline around the buttons on focus */
  .UserSection > .Fields.active .sideBarButtons:focus {
    outline: none;
    box-shadow: none; /* Remove focus shadow */
  }

  /* Adjust the sidebar for log out and bottom sections */
  .UserSection > .topBottomesidebar.active {
    min-height: 45px;
    padding: 10px;
    cursor: pointer;
  }

  /* Set dropdown height to fit the page */
  .UserSection > .bottom_sidebar {
    position: relative;
    height: 100%; /* Take full height */
    overflow-y: auto; /* Allow scrolling inside */
    top: -130px;
  }

  /* Set dropdown content to take full height */
  .UserSection > .bottom_sidebar .coompanyProfile .companyProfileWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh; /* Full height of viewport */
    overflow-y: auto;
    background-color: #fff;
  }

  /* Style dropdown buttons */
  .UserSection > .bottom_sidebar .coompanyProfile .companyProfileWrapper button {
    border: none;
    border-bottom: 1px solid #E7E3E9;
    padding: 10px;
    background-color: transparent;
    font-weight: 300;
    font-size: 16px;
    line-height: 32px;
    text-align: left;
  }

  /* Adjust dropdown items */
  .UserSection > .bottom_sidebar .coompanyProfile:hover .companyProfileWrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: -90%;
    left: 100%;
  }

  /* Fullscreen overlay to allow clicking outside the sidebar */
  .overlay {
    visibility: collapse;
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1; /* Make sure it's behind the menu */
  }

  /* Show overlay when active */
  .overlay.active {
    display: block;
    z-index: 100;
    width: 100vw;
    height: 100vh;
  }

  /* Hide menu and overlay when clicked outside */
  body.menu-active .UserSection > .Fields.active,
  body.menu-active .overlay.active {
    display: none;
  }
}
