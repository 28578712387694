.portdet__cont .pd__top__cont .heading{
    font-weight: 700;
    color: white;
    font-size: 35px;
}
.sec__right .para__cont .Overview{
    text-align: left;
    width: 100%;
    justify-content: left;
    
}
.portfolio__details .part h4{
    font-weight: 700;
}
.portfolio__details{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.portfolio__details .part{
    width: 450px;
    text-align: left;
    display: flex;
    flex-direction: column;
    padding-right: 0 !important;
}
.portdet__cont  #sec__right{
    flex: 1;
}
#border__bottom{
    border-bottom: 1px solid black;
    padding-bottom: 40px;
}
.statistics{
    display: flex;
    flex-direction: row;
    background-color: #F2F2F2;;
    width: 100%;
    height: 150px;
    padding-top: 80px;
    padding-bottom: 160px;
    padding-left: 50px;
    padding-right: 50px;
}
.statistics .stats{
    display: flex;
    flex-direction: column;
    width: 33%;
    text-align: left;
    justify-content: left;
    margin-left: 30px;
}
.statistics .stats p{
    font-weight: 900;
    color: black;
    font-size: 25px;
}
.statistics .stats h4{
    font-size: 16px;
    color: #00000080;

}
.challenges h2{
    font-size: 16px;
    text-align: left;
    font-weight: 900;
}
.images__9{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.images__9 img{
    width: 320px;
    height: 320px;
    margin-top: 30px;
    margin-right: 20px;
}
.vertical{
    justify-content: center;
    align-items: center;
    text-align: center;
}
.vertical img{
    height:726px;
    width: 690px;
    margin-left: 15rem;   
}
@media only screen and (max-width:1050px) {
    .vertical img{
        margin-left: 9rem;
    }
}
@media only screen and (max-width:800px) {
    .vertical img{
        margin-left: 0;
    }
}
.fit__width{
    justify-content: center;
}
.fit__width img{
    width:100%
}
.full__width{
    justify-content: center;
}
.full__width img{
    width: 125%;
    margin-left: -10rem;
}
.carousels{
    width:100%;
    height: 500px;
}
.carousels img{
    width: 100%;
    height: 500px;
    padding-left: 300px;
    padding-right: 300px;
}
@media screen and (max-width: 680px){
    .statistics{
        height: auto;
        width: 100%;
        padding: 2rem;        
    }
}

