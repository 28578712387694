.landscape100{
    display: flex;
    flex-direction: column;
}
.landscape100>img{
    margin: 0 -350px;
    width: 1440px;
    height: auto;
    justify-content: center;
    z-index: 20;
    border-top: 1px solid #E7E3E9;
    border-bottom: 1px solid #E7E3E9;
    padding: 50px 0;
    background-color: white;
}
.landscape80{
   max-width: 1240px;
   height: auto !important;
   box-sizing: border-box;
   z-index: 20;
   border-top: 1px solid #E7E3E9;
   border-bottom: 1px solid #E7E3E9;
}
.landscape80>img{
    width: 100%;
    height: auto !important;
    max-width: 1240px;
    padding: 50px 0;
    background-color: white;
}
.grid{
    height: auto;
    display: flex;
    margin: 0 -250px;
    padding: 50px 0;
    max-width: 1240px;
    box-sizing: border-box;
    flex-wrap: wrap;
    gap:20px ;
    justify-content: center;
    background-color: white;
    border: 1px solid #E7E3E9;
}
.grid>img{
    width: 32%;
    background-color: white;
}
.vertical{
    background-color: white;
}
.vertical>img{
    padding: 30px 0;
    margin: auto;
    max-width: 80%;
    height: auto;
    border-bottom: 1px solid #E7E3E9; 
}
 
.landscapeGrid{
    margin: 0 -350px;
    padding: 50px 0;
    background-color: white;
    max-width: 1440px;
    justify-content: center;
    border-top: 1px solid #E7E3E9;
    border-bottom: 1px solid #E7E3E9;
}
.landscapeGrid .image__slide {
    height: fit-content !important;
    width: auto;
    max-height: 42vh;
    margin-left: 20px;
}
.landscapeGrid .image__slide:first-child{
    margin-left: 0px;
}  

  


@media screen and (max-width: 1245px){
    .landscape100>img{
        width: 100%;
        margin: 0;
    }
}    
  iframe{
    width: 100%;
    height: 500px;
    margin: 0 auto;
    justify-content: center;
}
 @media screen and (max-width: 1480px){
    iframe{
        height: 400px;
    }
    .grid{
        max-width: 1240px;
    }
    .grid>img{
        width: 32%;
    }  
}
@media screen and (max-width: 1050px){
    .grid{
        max-width: 1050px;
        margin: 0px;

    }
    .grid>img{
        width: 30%;
    }
}
@media screen and (max-width: 768px){
    .grid{
        max-width: 768px;
        margin: 0px;
        border: 0;
        gap: 10px;
    }
    .grid>img{
        max-width: 30%;
    }
}

/* iFrame */

iframe{
    width: 100%;
    height: 400px;
    margin: 0 auto;
    justify-content: center;
    padding-top: 30px;
    border-top: 1px solid #E7E3E9;
}
@media screen and (max-width: 680px){
    iframe{
        height: 300px;
    }
    .landscapeGrid .image__slide {
        margin-left: 0;
      }
}
