.saplogin {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
  margin: 4rem 5% 3rem 5%;
}
.saplogin .logo {
  object-fit: contain;
  height: 38px;
  cursor: pointer;
}

.saplogin .login__form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.6rem;
  width: 100%;
  max-width: 480px;
  padding: 3rem 1.5rem;
  box-sizing: border-box;
  border-radius: 3px;
  border: 2px solid rgb(223, 223, 223);
}
.saplogin .login__form label {
  font-size: 0.85rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.saplogin .login__form .epass {
  color: #0b1c39;
  background: #fafafa;
  height: 2.7rem;
  border-radius: 2px;
  border: 1.5px solid rgb(230, 230, 230);
  font-family: "Poppins";
  outline: none;
  font-size: 0.85rem;
  padding: 0.5rem 1rem;
  width: 100%;
  box-sizing: border-box;
}
.saplogin .login__form .epass::placeholder {
  color: rgb(190, 190, 190);
  opacity: 1; /* Firefox */
}
.saplogin .login__form .epass::-webkit-outer-spin-button,
.saplogin .login__form .epass::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.saplogin .login__form .log__for {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 0.5rem;
  width: 100%;
}
.saplogin .login__form .log__for label {
  font-size: 0.75rem;
  font-weight: 500;
  display: flex;
  column-gap: 0.5rem;
  cursor: pointer;
  text-align: left;
}
.saplogin .login__form .login__btn {
  font-size: 0.9rem;
  margin-top: 1rem;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.89);
  background: #ff656a;
  height: 3rem;
  width: 100%;
  font-family: "Poppins";
}
