.file-upload-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    padding: 20px 20px;
    border: 1px solid #e0e0e0;
    transition: all 0.3s ease;
    border-radius: 10px;
    background-color: white;
  }
/*   
  .file-upload-wrapper:hover {
    border-color: #888;
    background-color: #f1f1f1;
  } */
  
  .file-upload-input {
    display: none;
  }
  
  .file-upload-label {
    display: inline-block;
    padding: 10px 10px;
    cursor: pointer;
    background-color: black;
    color: white;
    border: none;
    border-radius: 10px;
    transition: background-color 0.3s ease;
  }
  
  
  .file-upload-button {
    padding: 10px 10px;
    cursor: pointer;
    border: 1px solid #e0e0e0;
    color: black;
    border-radius: 10px;
    background-color: white;
    transition: background-color 0.3s ease;
  }
  
  .file-upload-button:hover {
    background-color: #e0e0e0;
  }
  
  /* .file-upload-button:active {
    background-color: #1e7e34;
  } */
  