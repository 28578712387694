.dropdownListContainer {
    position: absolute;
    top: 100%; /* Position the dropdown below the input */
    left: 0;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    margin-top: 5px;
    padding: 10px;
  
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 5px;
    
  }
  
  .dropdownListContainer div {
    padding: 8px 15px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    text-align: left;
  }
  
  .dropdownListContainer div:hover {
    background-color: #f0f0f0;
  }