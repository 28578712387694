.media-component {
    margin: 20px 40px;
    display: flex;
    padding: 20px 40px;
    background: #F8F8F8;
    border: 1px solid #C5C5C5;
    border-radius: 10px;
    flex-direction: column;
}

.image-sec-collapse {
    height: 32px;
    background: #F8F8F8;
    border: 1px solid #C5C5C5;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    margin: 10px 40px;
}

.image-sec-collapse .text-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #6E6E6E;
}

.arrow {
    border: solid #6E6E6E;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    height: 3px;
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.media-component .title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #6E6E6E;
    border-bottom: 1px solid #C5C5C5;
    display: flex;
    width: 100%;
    height: fit-content !important;
    padding-bottom: 5px;
    justify-content: space-between;
    align-items: center;
}

.media-component .image-layout {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #C5C5C5;
    padding: 20px 0;
}

.media-component .image-layout img {
    height: 150px;
    width: 150px;
    margin-right: 20px;
}

.media-component .image-layout .layout-radio {
    opacity: 0;
}

.media-component .image-layout .selected-layout {
    color: #FF656A !important;
}

.media-component .image-layout .layout-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    justify-content: center;
    color: #6E6E6E;
}

.media-component .image-selection-wrapper {
    display: flex;
    max-height: 290px;
    border-bottom: 1px solid #C5C5C5;
    padding-bottom: 20px;
}

.media-component .upload-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
}

.media-component .upload-wrapper .upload {
    height: 240px;
    width: 100%;
    max-width: 250px;
    max-height: 260px;
    background: #FFFFFF;
    border: 1px solid #C5C5C5;
    border-radius: 10px;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.media-component .upload-wrapper .enable {
    opacity: 1;
}

.media-component .upload-wrapper .disable {
    opacity: 0.5;
}

.media-component .upload-wrapper .upload:first-child {
    margin-top: 20px;
}

.media-component .upload-wrapper .upload .upload-icon {
    margin-bottom: 10px;
}

.media-component .upload-wrapper .upload .upload-btn {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #6E6E6E;
    background: #E5E5E5;
    border-radius: 5px;
    padding: 5px 10px;
    border: none;
}

.media-component .upload-wrapper .upload .formats {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    color: #6E6E6E;
    margin-top:10px
}

.media-component .upload-list-wrapper {
    display: flex;
    flex: 2;
    flex-direction: column;
    overflow: scroll;
    margin: 10px 0;
}

.media-component .upload-list-wrapper .upload-list-item .upload-image-details .myProgress {
    width: 100%;
    background-color: grey;
    margin-top: 15px;
}

.media-component .upload-list-wrapper .upload-list-item .upload-image-details .myBar {
    width: 0%;
    height: 4px;
    background-color: #FF656A;
}

.media-component .upload-list-wrapper .upload-list-item {
    display: flex;
    margin: 10px 0;
}

.media-component .upload-list-wrapper .upload-list-item .upload-item-img {
    height: 70px;
    width: 70px;
    border-radius: 10px;
}

.media-component .upload-list-wrapper .upload-list-item .play-button-wrapper {
    height: 50px;
    width: 60px;
    border: 2px solid #C5C5C5;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.media-component .upload-list-wrapper .upload-list-item .upload-image-details .upload-item-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #6E6E6E;
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.media-component .upload-list-wrapper .upload-list-item .upload-image-details {
    margin-left: 15px;
    width: 100%;
}

.media-component .upload-list-wrapper .embed-link {
    display: flex;
    flex-direction: column;
}

.media-component .upload-list-wrapper .embed-link .input-sec {
    display: flex;
    align-items: center;
}

.media-component .upload-list-wrapper .embed-link .title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: #6E6E6E;
    border-bottom: 0;
}

.media-component .upload-list-wrapper .embed-link .input-sec .input-style {
    background: #FFFFFF;
    border: 1px solid #C5C5C5;
    border-radius: 3px;
    appearance: none;
    flex: 2
}

.media-component .upload-list-wrapper .embed-link .input-sec .embed-btn {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FF656A;
    flex: 1;
}

.media-component .save_delete_button {
    background-color: transparent;
    display: flex;
    gap: 20px;
    margin-top: 15px;
    border: transparent;
    justify-content: flex-end;
    padding-right: 0;
}

.media-component button {
    background-color: transparent;
    border: 1px solid #FF656A;
    color: #FF656A;
    border-radius: 5px;
    padding: 5px 20px;
    transition-duration: 0.4s;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.media-component button:first-child {
    border: none
}

.media-component button:hover:last-child {
    background-color: #FF656A;
    color: white;
}