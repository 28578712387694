.anbusiness__cont {
  display: flex;
  margin-left:3rem ;
  /* background: #e5e5e5; */
  background: #f6f6f6;
  min-height: 100vh;
}
.anbusiness {
  flex: 0.82;
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
  padding: 3rem 1.3%;
  box-sizing: border-box;
  width: 100%;
  background: #f6f6f6;
}
@media screen and (max-width: 1050px) {
  .anbusiness {
    flex: 1;
  }
  .anbusiness__cont {
  
    margin-left:0.2rem ;
    /* background: #e5e5e5; */
    
   
  }
}
.anbusiness ::placeholder {
  font-family: "Poppins";
}
.anbusiness .anbusiness__title {
  font-weight: bolder;
  color: #5f5f5f;
}
.anbusiness .section__cont {
  display: flex;
  flex-direction: column;
  row-gap: 0.6rem;
}
.anbusiness .section__cont .section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.anbusiness .section__cont .section .part__right {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1.3rem;
}
.anbusiness .section__cont .section .part__right .img__cont {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.anbusiness .section__cont .section .part__right .img__cont .msg__not {
  object-fit: contain;
  height: 16px;
}
.anbusiness .section__cont .section .part__right .img__cont .dotg {
  position: absolute;
  right: -10%;
  top: -10%;
}
.anbusiness .section__cont .section .part__right .date__day {
  padding: 0.2rem 0.4rem;
  border: 1px solid #828282;
  border-radius: 3px;
}

/* Filter and result section */

.anbusiness .filter__res__sec {
  display: flex;
  align-items: flex-start;
  column-gap: 1rem;
  row-gap: 1rem;

  box-sizing: border-box;
}

.anbusiness .filter__res__sec .filter__sec,
.anbusiness .filter__res__sec .res__sec {
  width: 100%;
}
/* Filtersec */
.anbusiness .filter__res__sec .filter__sec {
  flex: 0.2;
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  padding: 0.8rem;
  border-radius: 1px;
  background: white;
  box-sizing: border-box;
}
.filter__res__sec .filter__sec .filter__parttab {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.filter__sec .filter__top {
  display: none;
  align-items: center;
  justify-content: space-between;
}
.filter__sec .filter__parttab .part__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filter__sec .filter__parttab .part__top .arrow__down {
  object-fit: contain;
  max-height: 8px;
}
.filter__sec .filter__parttab .part__bottom {
  display: flex;
  flex-direction: column;
  row-gap: 0.4rem;
}
.filter__sec .filter__parttab .part__bottom .inp__field {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.filter__sec .arrow__down {
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Resultsec */
.anbusiness .filter__res__sec .res__sec {
  flex: 0.8;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  box-sizing: border-box;
}
.filter__res__sec .res__sec .res__card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
  row-gap: 1rem;
  background: white;
  width: 100%;
  padding: 1rem 0.6rem;
  box-sizing: border-box;
  border-radius: 1px;
}
.res__sec .res__card .res__card__left {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.res__sec .res__card .res__card__left .typebudget {
  display: flex;
  align-items: center;
  column-gap: 0.6rem;
}
.res__sec .res__card .res__card__left .typebudget .typebtn {
  padding: 0.1rem 0.5rem;
  border-radius: 4px;
  text-transform: capitalize;
}
.res__sec .res__card .res__card__left .typebudget .requested {
  border: 1px solid #0fa958;
}
.res__sec .res__card .res__card__left .typebudget .open {
  border: 1px solid #ffaf03;
}
.res__sec .res__card .res__card__left .typebudget .leadform {
  border: 1px solid #2360bb;
}
.res__sec .res__card .res__card__left .tags {
  display: flex;
  /* column-gap: 0.5rem; */
  row-gap: 0.3rem;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  float: left;
  width: 100%;
}
.res__sec .res__card .res__card__left .tags .tag {
  font-size: 0.75rem;
  padding: 0 0.5rem;
  border-radius: 3px;
  border-left: 1px solid lightgray;
  color: #a3a3a3;
  cursor: pointer;
}

.res__sec .res__card .res__card__left .tags :nth-child(1) {
  border-left: none;
  padding-left: 0;
}

.res__sec .res__card .res__card__right {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  /* background: purple; */
}
.res__sec .res__card .res__card__right .viewproject {
  background: #ffe5e5;
}
.res__sec .res__card .res__card__right .opens {
  padding: 0.6rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
}
/* .res__sec .res__card .res__card__right .brandprofile {

} */
.filter__res__sec .res__sec .bri__att__cont {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-top: 2rem;
  width: 100%;
}
.res__sec .bri__att__cont .bri__att__top {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  row-gap: 0.5rem;
  width: 100%;
}
.bri__att__cont .bri__att__top .part1 {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  row-gap: 0.5rem;

  /* background: purple; */
  width: 100%;
}
.res__sec .bri__att__cont .bri__att__top .babtns {
  padding: 0.5rem;
  border-radius: 4px;
  border: 1.5px solid #f8d4d4;
  cursor: pointer;
  width: 100%;
}
.res__sec .bri__att__cont .bri__att__top .sharebtn {
  border: 1.5px solid #fb7777;
  background: #f7dddd;
}
.res__sec .bri__att__cont .bri__att__top .active {
  background: #f7dddd;
}
.res__sec .bri__att__cont .brief__data {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem;
  text-align: left;
  border-radius: 1px;
  background: white;
}
.res__sec .bri__att__cont .assign__data {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem;
  text-align: left;
  border-radius: 1px;
  background: white;
}
.res__sec .bri__att__cont .assign__data .assign__task {
  display: flex;
  justify-content: space-between;
}
.res__sec .bri__att__cont .badatapart {
  display: none;
  min-height: 400px;
}
.res__sec .bri__att__cont .activetab {
  display: flex;
}
.filter__sec .rotate180 {
  transform: rotate(180deg);
  transition: all 0.3s ease;
}
@media screen and (max-width: 950px) {
  .filter__res__sec .res__sec .res__card {
    flex-direction: column;
    align-items: flex-start;
  }

  .anbusiness .filter__res__sec {
    flex-direction: column;
  }
  /* .anbusiness .filter__res__sec .filter__sec,
  .anbusiness .filter__res__sec .res__sec {
    width: 100%;
  } */
  .filter__res__sec .filter__sec .filter__parttab {
    display: none;
  }
  .filter__sec .filter__parttab .part__top,
  .filter__sec .filter__parttab .part__bottom {
    display: none;
  }
  .filter__sec .filter__top {
    display: flex;
  }

  .res__sec .res__card .res__card__right .opens {
    padding: 0.4rem 1rem;
  }
}
@media screen and (min-width: 950px) {
  .anbusiness .filter__res__sec .filter__sec {
    position: sticky;
    top: 20px;
    left: 0;
  }
}
@media screen and (max-width: 550px) {
  .bri__att__cont .bri__att__top {
    flex-direction: column;
  }
}
