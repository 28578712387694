.case_studies{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.case_studies .camp__img__cont img{ 
    height: 230px;
    width: 230px;
    margin: 0px;
    border-radius: 3px;
    margin-bottom: 20px;
}
.case_studies .camp__img__cont  {
    border: 0.5px solid grey;
    margin-right: 30px;
    border-radius: 1px;
  
    
}
.case_studies .camp__img__conts p{
    font-size: 20px;

    text-align: center;
    margin-left: 14px;
    margin-bottom: 20px;
}
@media only screen and (max-width:440px) {
    .case_studies{
    flex-direction: column;
}
#more_casestudies{
    display: none;
    flex-direction: column;
  
}
}
#more_casestudies{
    display: none;
    margin-top: 30px;
  
}
#show_more_btn{
    cursor: pointer;
}