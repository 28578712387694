.TalentPoolMessagePage {
    width: 100%;
    border : 1px solid #E0E0E0;
    border-radius: 20px;
    padding: 20px;
    flex: 1;
    height: 100%;
    display: grid;
    grid-template-columns: 2fr 4fr;
    gap: 20px;
}

.TalentPoolMessagePage .userChatSideBar {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: 700px;
    overflow-x: auto;
}


.TalentPoolMessagePage .userChatSideBar::-webkit-scrollbar {
    display: none; /* This hides the scrollbar */
  }

.TalentPoolMessagePage .userChatSideBar .chatOuterHeader {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border: 1px solid #E0E0E0;
    background-color: #f7f7f7;
    border-radius: 20px;
    cursor: pointer;
}

.TalentPoolMessagePage .userChatSideBar .chatOuterHeader .userInfo {
    display: flex;
    gap: 10px;
    font-weight: 600;
    text-align: left;
}

.TalentPoolMessagePage .userChatSideBar .chatOuterHeader .userInfo p {
    line-height: 1.3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* Limits to 1 line */
    overflow: hidden;
    text-overflow: ellipsis;

}


.TalentPoolMessagePage .userChatSideBar .chatOuterHeader .lastMessageTime {
    color: #505050;
    font-weight: 400;
    font-size: 14px;
    text-align: right; 
    white-space: nowrap; /* Prevents wrapping of the time string */
    margin-left: auto; /* Ensures it aligns to the far right */
}

.TalentPoolMessagePage .chaSide {
    background-color: pink;
    height: 100%;
}

