.thank_you_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 2px;

    h1{
        font-size: 4rem;
    }

    p{
        text-align: center;
        margin-top: 10px;

        span {
            font-weight: bold;
        }
    }

    .viewTalentPoolBtn{
        padding: 7px 10px;
        margin-top: 40px;
        border-radius: 50px;
        background: transparent;
        border: 1px solid gray;
        outline: none;
        display: flex;
        gap: 8px;
        align-items: center;

        img{
            border: 1px solid gray;
            border-radius: 100%;
            padding: 3px;
        }

        &:hover{
            background-color: #edecec;
            scale: 1.05;
            transition: scale 0.3s;
        }
    }
}

.handshakeImg > img {
    width: 5rem;
}