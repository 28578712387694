.asidebar {
  flex: 0.18;
  display: flex;
  flex-direction: column;
  height: 93vh;
  width: 250px !important;
  position: sticky;
  top: 7vh;
  background-color: #FFFFFF;
  border-right: 1px solid #E3E7E9;
}
.asidebar > ::-webkit-scrollbar{
  width: 10px;
}
.asidebar .asidebar__top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 0.5rem;
  padding: 1rem 3% 1.8rem 3%;
  box-sizing: border-box;
  width: 100%;
}
.asidebar .asidebar__top .asidelogo {
  object-fit: contain;
  max-height: 122px;
  width: 100%;
  max-width: 106px;
  border-radius: 5px;
  border: 1px solid #E3E7E9;
}

 .asidebar__top:hover > .btn {
  display: block;
}
.asidebar__top .btn {
  position: relative;
  bottom: 0;
} 
.asidebar__top .btn button{
  background: #191E22 !important;
  color: #FFFFFF;
}

.asidebar .asidelinks {
  display: flex;
  flex-direction: column;
  overflow-y:scroll;
  width: 104%;
}
.asidebar .asidelinks .asidelink {
  padding: 0.7rem 9%;
  color: #0b1c39 !important;
  border-left: 4px solid transparent;
}
.asidebar .asidelinks .active {
  color: #0b1c39 !important;
  border-left: 4px solid #191E22;
  background: #E3E7E9;
}
.asidebar .report__issue {
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem 9%;
  position: absolute;
  bottom: 0;
}

@media screen and (max-width: 950px) {
  .asidebar {
    flex: 0;
    display: none;
    width: 0;
    transform: translate(-100%);
  }
  .asidebarshowtranslate {
    display: flex;
    position: fixed;
    width: 270px !important;

    box-shadow: 0px 5px 20px rgba(14, 14, 14, 0.1);
    /* background: purple !important; */
    transform: translate(0%) !important;
    z-index: 500;
  }
}
.profile-image{
  position: relative;}
 .profile-image img {
 
  display: block;
 }
 .image_hover_bg{
 background: url("../images/uploadicon.png") no-repeat scroll center center rgba(0, 0, 0, 0);
 height: 100px;
 position: absolute;
 top: 0;
 width: 100px;
 z-index: -1;
 display:none;
 }
 .profile-image:hover .image_hover_bg{display:block;
z-index: 1;opacity: 1;}

.profile-image:hover img{
  filter: blur(1px);
  -webkit-filter: blur(1px);
 
}