.poolAnalysisComponent {
    position: relative;
    text-align: left;
  }

  .poolAnalysisComponent .nameHeading {
    padding: 5px 10px;
    width: 100%;
    margin: 15px 0;
    border: none;
    background-color: #f7f7f7;
    border-radius: 7px
  }
  
  
  
  #date-select {
    border: none;
    outline: none;
    padding: 3px 10px;
    background-color: #f7f7f7;
    border-radius: 7px;
    margin-bottom: 15px;
  }
  
  #date-select option {
    background-color: #ada8a8;
  }
  .poolAnalysisComponent .overallPoolData {
      display: flex;
      gap : 20px;
      margin-bottom: 15px;
  }
  
  .poolAnalysisComponent .overallPoolData div {
    background-color: #f7f7f7;
    padding: 20px;
    text-align: center;
    border-radius: 7px;
    height: 150px;
    width: 100%;
  }
  
  .poolAnalysisComponent .totalPools {
    background-color: #f7f7f7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 5px 10px;
    margin-bottom: 15px;
  } 
  
  .categoryDetailTable {
    width: 100%;
    text-align: center;
    border-collapse: separate;
    border-spacing: 0.5rem;
  }
  
  .categoryDetailTable td,th{
    border : none;
    border-radius: 7px;
    padding: 0.5rem 0.5rem;
    background-color: #f7f7f7;
  }
  
  .categoryDetailTable td:first-child {
    width: 40%; /* Adjust the width as needed */
    text-align: left;
    cursor: pointer;
  }
  
  