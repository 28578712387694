.portdet__cont .pd__top__cont {
  padding: 0 9.5rem;
  background: #0b1c38;
}
.portdet__cont .pd__top {
  flex-direction: column;
  
  row-gap: 1rem;
  padding: 5rem 0rem 2.5rem 0;
  margin: auto;
  justify-content: center;
  text-align: center !important;
  width: 100%;
}

.portdet__cont .pd__top .save__icon {
  margin-left: 0.5rem;
  cursor: pointer;
}
.portdet__cont .pd__top .tags {
  display: flex;
  column-gap: 0.5rem;
  row-gap: 0.3rem;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  float: left;
  margin-bottom: 1.4rem;
  width: 100%;
}
.pd__top .tags .tag {
  font-size: 0.75rem;
  padding: 0.2rem 0.35rem;
  border-radius: 3px;
  color: #d3d6db;
  background: #23334d;
  cursor: pointer;
}
.portdet__cont .pd__top .hrtshr__cont {
  display: flex;
  column-gap: 1rem;
}
.portdet__cont .pd__top .hrtshr__cont img {
  cursor: pointer;
}
.portdet__cont .pd__top .clagncyin {
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .portdet__cont .pd__top__cont {
    padding: 0 5%;
  }
  .portdet .sec__right .para__cont .comments .comments__textarea 
  {
    max-width: 970px;
  }
  .portdet .sec__right .para__cont .photosandvideos__cont {
    display: flex;
    flex-direction: row;
    
    row-gap: 1rem;
  }
  .portdet .sec__right .para__cont .photosandvideos__cont .photosandvideos {
    display: flex;
    flex-direction: column;

    
  }
}
@media screen and (max-width: 600px){
  
  }
  
@media screen and (max-width: 600px) {
  .portdet__cont .pd__top .clagncyin {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.85rem;
  }
  .portdet .sec__right .para__cont .process .process__img {
    object-fit: contain;
    height: auto;
    width: 100%;
  }
  .portdet .sec__right .para__cont .comments .comments__textarea 
  {
    max-width: 350px;
  }
  
}
.portdet .sec__right .portfolio__details{
  display: flex;
  flex-direction: row;
  
}
.portdet .sec__right .portfolio__details .part{
padding-right: 10rem;
}
.portdet .sec__right .para__cont .photosandvideos__cont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;
}

.portdet .sec__right .para__cont .photosandvideos__cont .photosandvideos {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 900px;
  
}


@media screen and (max-width: 1000px){
  .portdet .sec__right .portfolio__details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-content: center;
    align-items: center; 
    margin: auto;
    }
    
  .portdet .sec__right .para__cont .photosandvideos__cont .photosandvideos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
  }
  .sec__right .para__cont .photosandvideos__cont .photosandvideos img {
    /* object-fit: contain; */
    
    
    width:100%;
    height:150px;
    object-fit:cover;
    object-position:50% 50%;
    margin-left: 10px;
    justify-content: center;
    margin-bottom:30px ;
  
  }
}
.portdet .sec__right .para__cont .process .process__img {
  object-fit: contain;
  height: auto;
  width: 100%;
}

.sec__right .para__cont .photosandvideos__cont .photosandvideos img {
  /* object-fit: contain; */
  
  
  width:100%;
  height:150px;
  object-fit:cover;
  object-position:50% 50%;
  margin-right: 30px;

}
.portdet .sec__right .para__cont .challenges .challenges__list {
  color: #ff656a;
  margin-left: 1.2rem;
  list-style: square;


}
.portdet .sec__right .para__cont .challenges .challenges__list li {
  margin-bottom: 0.8rem;
}
.portdet .sec__right .para__cont .awards__logos {
  display: flex;
  column-gap: 3rem;
  row-gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  float: left;
  width: 100%;
}
.portdet .sec__right .para__cont .awards__logos .awards__logo {
  object-fit: contain;
  height: auto;
  max-height: 90px;
}
.portdet .sec__right .para__cont .comments {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 1128px;
}
.portdet .sec__right .para__cont .comments .comments__textarea {
  color: rgb(151, 151, 151);
  background: #fafafa;
  height: 10rem;
  border-radius: 1px;
  border: 1.5px solid rgb(230, 230, 230);
  outline: none;
  font-size: 0.85rem;
  padding: 0.5rem 1rem;
  width: 100%;
  box-sizing: border-box;
  
}

.portdet .sec__right .para__cont .comments .comments__textarea::placeholder {
  color: rgb(151, 151, 151);
  opacity: 1;
}
.portdet .sec__right .para__cont .comments .login__btn {
  font-size: 0.85rem;
  padding: 0.8rem 1.8rem;
  margin-top: 1.5rem;
  font-family: "Poppins";
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 1px;
  color: rgba(255, 255, 255, 0.89);
  background: #ff656a;
}
