.uacademy__cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.uacademy__cont .uacademy {
  display: flex;
  align-items: center;
  justify-content: space-around;
  column-gap: 3rem;
  padding: 3rem 4%;

  box-sizing: border-box;
  width: 100%;
  /* background: #ffebec; */

  position: relative;
}
.uacademy__cont .uacademy .half__pink {
  position: absolute;
  top: 0;
  width: 100%;
  height: 310px;
  background: #ffebec;
  z-index: -10;
}
.uacademy .part__left {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;

  text-align: left;
}
.uacademy .statics__data {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  column-gap: 0.3rem;
  padding: 1.5rem 0.7rem;
  background: white;
  border-radius: 1px;
  box-shadow: 0px 4px 20px rgba(14, 14, 14, 0.07);
}
.uacademy .statics__data .part {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.uacademy .part__right {
  position: relative;
}
.uacademy .part__right .girl {
  object-fit: contain;
  height: auto;
  /* max-height: 500px; */
  width: 100%;
}
.uacademy .part__right .dots1 {
  position: absolute;
  bottom: -10%;
  left: 10%;
}
.uacademy .part__right .dots2 {
  position: absolute;
  top: 5%;
  right: -5%;
  transform: rotate(90deg);
}
.uacademy .regtestcont {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
  text-align: center;
  margin-top: 2rem;
}
.uacademy .regtestcont .regtestbtn {
  border: none;
  color: white;
  padding: 8px 20px;
  font-family: "Poppins";
  background: #ff656a;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .uacademy__cont .uacademy .half__pink {
    height: 350px;
  }
  .uacademy .part__left {
    text-align: center;
  }
  .uacademy .part__right {
    display: none;
  }
}

/* About */
.uacademy__cont .aboutua {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 2rem;
  padding: 3rem 4%;
  box-sizing: border-box;
  text-align: left;
  width: 100%;
}
.uacademy__cont .aboutua .aboutua__card1,
.uacademy__cont .aboutua .aboutua__card2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 4rem;
  padding: 2rem 4%;
  box-sizing: border-box;
  background: #fafafa;
  width: 100%;
}
.aboutua .aboutua__card1 .part__right .part__right__datas {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  text-align: left;
}
.aboutua__card1 .part__right .part__right__datas .part {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.aboutua .aboutua__card1 .part__left,
.aboutua .aboutua__card2 .part__right {
  flex: 0.4;
}
.aboutua .aboutua__card1 .part__right,
.aboutua .aboutua__card2 .part__left {
  flex: 0.6;
}

.aboutua .aboutua__card1 .part__left .girlcont .girlwork2,
.aboutua .aboutua__card2 .part__right .girlcont .girlwork2 {
  object-fit: contain;
  height: auto;
  width: 100%;
}
/* aboutua card1 */
.aboutua .aboutua__card1 .part__right {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
}
.aboutua .aboutua__card1 .part__left {
  display: flex;
  align-items: flex-start;
}
.aboutua .aboutua__card1 .part__left .girlcont,
.aboutua .aboutua__card2 .part__right .girlcont {
  position: relative;
}
.aboutua .aboutua__card1 .part__left .dots3 {
  position: absolute;
  right: -20%;
  bottom: 15%;
}

/* aboutua card 2 */
.aboutua .aboutua__card2 .part__left {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.aboutua .aboutua__card2 .part__right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.aboutua .aboutua__card2 .part__right .dots3 {
  position: absolute;
  left: -20%;
  top: 15%;
}

/* ALTs */
.aboutua .aboutua__card1 .part__right .girlcontalt,
.aboutua .aboutua__card2 .part__left .girlcontalt {
  display: none;
}
.aboutua .aboutua__card1 .part__right .girlcontalt .girlwork2alt,
.aboutua .aboutua__card2 .part__left .girlcontalt .girlwork2alt {
  object-fit: contain;
  height: auto;
  max-height: 300px;
  width: 100%;
}

@media screen and (max-width: 550px) {
  .aboutua .aboutua__card1,
  .aboutua .aboutua__card2 {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0;
    text-align: center;
  }

  .aboutua .aboutua__card1 .part__right,
  .aboutua .aboutua__card2 .part__left {
    flex: 1;
  }
  .aboutua .aboutua__card1 .part__right .girlcontalt,
  .aboutua .aboutua__card2 .part__left .girlcontalt {
    display: flex;
  }
  .aboutua .aboutua__card1 .part__left,
  .aboutua .aboutua__card2 .part__right {
    display: none;
  }
}

/* @media screen and (max-width: 450px){
  .aboutua .aboutua__card1 .part__right .girlcontalt .girlwork2alt,
  .aboutua .aboutua__card2 .part__left .girlcontalt .girlwork2alt {
  }
} */
/* JOBS */

.uacademy__cont .jobsua {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 2.5rem;
  padding: 3rem 4%;
  box-sizing: border-box;
  text-align: left;
  width: 100%;
}

.uacademy__cont .jobsua .jobsua__cats {
  display: flex;
  justify-content: space-between;
  column-gap: 0.8rem;
  row-gap: 0.8rem;
  text-align: center;
}

.jobsua .jobsua__cats .cat__card {
  padding: 1rem 0.6rem;
  box-sizing: border-box;
  width: 100%;

  border-left: 4px solid transparent;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.jobsua .jobsua__cats .active {
  border-left: 5px solid #ff656a;
  color: #ff656a;
}
.jobsua .job__desc__cont {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
.jobsua .job__desc__cont .job__desc__part {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
.jobsua .job__desc__cont .dnoneall {
  display: none;
}
.jobsua .job__desc__cont .active {
  display: flex !important;
}
.jobsua .job__desc__part .skilljob {
  display: flex;
  justify-content: space-between;
  row-gap: 1rem;
  column-gap: 1rem;
}
.jobsua .job__desc__part .skilljob .part {
  flex: 0.5;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.jobsua .job__desc__part .skilljob .list__conts {
  display: flex;
  column-gap: 2rem;
  width: 100%;
}
.jobsua .job__desc__part .skilljob .list__conts .lists {
  list-style-type: none;
  display: flex;
  justify-content: flex-start;

  flex-direction: column;
  row-gap: 0.5rem;
}
.jobsua .job__desc__part .curr__job {
  display: flex;
  flex-direction: column;
  row-gap: 0.7rem;
}
.jobsua .job__desc__part .curr__job .comps {
  display: flex;
  column-gap: 0.5rem;
  row-gap: 0.3rem;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  float: left;
  margin-bottom: 1.4rem;
  width: 100%;
}
.jobsua .job__desc__part .curr__job .comps .comp {
  object-fit: contain;
  height: auto;
  max-width: 100px;
  border-radius: 10rem;
  cursor: pointer;
}

.jobsua .job__desc__part .regdown {
  display: flex;
  column-gap: 1rem;
  row-gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
  float: left;
}
.jobsua .job__desc__part .regdown .regis__btn {
  border: none;
  color: white;
  padding: 10px 30px;
  font-family: "Poppins";
  background: #ff656a;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .uacademy__cont .jobsua .jobsua__cats {
    flex-direction: column;
  }
  .jobsua .job__desc__part .skilljob {
    flex-direction: column;
  }
}

/* Hiring Partners */
.uacademy__cont .hirepart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1rem;
  padding: 3rem 4%;
  box-sizing: border-box;
  text-align: left;
  width: 100%;
}
.uacademy__cont .hirepart .part1,
.uacademy__cont .hirepart .part2 {
  display: flex;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  float: left;
  margin-bottom: 1.4rem;
  width: 100%;
}
.uacademy__cont .hirepart .part1 .comp,
.uacademy__cont .hirepart .part2 .comp {
  object-fit: contain;
  height: auto;
  max-width: 100px;
  border-radius: 10rem;
  cursor: pointer;
}
.uacademy__cont .hirepart .regdown {
  display: flex;
  flex-direction: column;
  column-gap: 1rem;
  row-gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
  float: left;
}
.uacademy__cont .hirepart .regdown .regis__btn {
  border: none;
  color: white;
  padding: 8px 30px;
  font-family: "Poppins";
  background: #ff656a;
  cursor: pointer;
}

/* FAQS */
.uacademy__cont .faqs__cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 2rem;
  padding: 3rem 4%;
  margin-bottom: 2rem;
  box-sizing: border-box;
  width: 100%;
}
.uacademy__cont .faqs__cont .faqs {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  text-align: left;
}
.uacademy__cont .faqs__cont .faqs .faqs__div {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}
.uacademy__cont .faqs__cont .faqs .faqs__div .disable__para {
  display: none;
}
.uacademy__cont .faqs__cont .faqs .faqs__div .active {
  display: block !important;
}
.uacademy__cont .faqs__cont .faqs .faq {
  width: 100%;
  text-align: left;
  padding: 1rem;
  box-sizing: border-box;
  font-size: 0.9rem;
  cursor: pointer;
  border-left: 5px solid #ff656a;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
}

/* Benefits */
.uacademy__cont .benefitsua {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 3rem;
  padding: 3rem 4%;
  box-sizing: border-box;
  text-align: left;
  width: 100%;
}
.uacademy__cont .benefitsua .benefits__cont {
  display: grid;
  column-gap: 10rem;
  row-gap: 4rem;
  grid-template-columns: repeat(2, minmax(200px, 1fr));
}
.benefitsua .benefits__cont .benefit {
  position: relative;

  background: #ffebec;
}
.benefitsua .benefits__cont {
  margin-left: 4%;
}
.benefitsua .benefits__cont .benefit__title {
  color: white;
  background: #0b1c39;
  position: absolute;
  padding: 0.7rem 2.2rem;
  top: -25px;
  left: -35px;
  /* left: -50%; */
}
.benefitsua .benefits__cont .benefit__desc {
  padding: 4rem 2rem;
}

@media screen and (max-width: 768px) {
  .uacademy__cont .benefitsua .benefits__cont {
    column-gap: 5rem;
    row-gap: 3.5rem;
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }
  .benefitsua .benefits__cont .benefit__desc {
    padding: 2.5rem 2rem;
  }
  .benefitsua .benefits__cont {
    margin-left: 0;
  }
}

/* Career specialization */
.uacademy__cont .careerua {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 3rem;
  padding: 3rem 4%;
  box-sizing: border-box;
  text-align: left;
  width: 100%;
}

.careerua .career__cards {
  display: grid;
  grid-gap: 5vmin;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
}
.careerua .career__cards .career__card {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1.5rem;
  border-radius: 1px;
  border: 2px solid rgb(223, 223, 223);
  transition: all 0.35s ease;
}
.careerua .career__cards .career__card:hover {
  border: 2px solid #ffebec;
  transition: all 0.35s ease;

  -moz-box-shadow: 10px 10px 5px rgba(34, 34, 34, 0.15);
  -webkit-box-shadow: 10px 10px 5px rgba(34, 34, 34, 0.15);
  box-shadow: 10px 10px 5px rgba(34, 34, 34, 0.15);
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1); */
}
.careerua .career__cards .career__card .card__img {
  object-fit: cover;
  /* height: auto; */
  height: 215px;
  width: 100%;
}
.careerua .career__cards .career__card .tags {
  display: flex;
  column-gap: 0.35rem;
  row-gap: 0.35rem;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  float: left;
  width: 100%;
}
.career__cards .career__card .tags .tag {
  font-size: 0.75rem;
  padding: 0.2rem 0.35rem;
  border-radius: 3px;
  color: grey;
  background: #ffebec;
  cursor: pointer;
}
.career__cards .career__card .appli__placed {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  border-top: 2px solid rgb(223, 223, 223);
  border-bottom: 2px solid rgb(223, 223, 223);
}
.career__cards .career__card .dwnld__btn {
  color: #0b1c3980;
  font-weight: 600;
  transition: all 0.25s ease;
}
.career__cards .career__card .dwnld__btn:hover {
  color: black;
  transition: all 0.25s ease;
}
@media screen and (max-width: 768px) {
  .careerua .career__cards {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }
}

/* Alumni */
.uacademy__cont .alimniua {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 3rem;
  padding: 3rem 4%;
  box-sizing: border-box;
  text-align: left;
  width: 100%;
}
.alimniua .alumni__cards {
  display: grid;
  column-gap: 2rem;
  row-gap: 1.5rem;
  grid-template-columns: repeat(2, minmax(200px, 1fr));
}
.alimniua .alumni__cards .alumni__card {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  border: 2px solid rgb(228, 228, 228);
  padding: 1rem;
}
.alumni__cards .alumni__card .acard__top {
  display: flex;
  column-gap: 1rem;
}
.alumni__cards .alumni__card .acard__top .part__right {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.alumni__cards .alumni__card .acard__top .alumni__img {
  object-fit: contain;
  max-height: 80px;
}

@media screen and (max-width: 550px) {
  .alimniua .alumni__cards {
    row-gap: 1rem;
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }
}

/* Register basic */
.uacademy__cont .registerbasicua {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 3rem;
  padding: 3rem 4%;
  box-sizing: border-box;
  text-align: left;
  width: 100%;
  background: #ffebec;
}

.registerbasicua .rbform {
  display: flex;
  justify-content: space-between;
  row-gap: 1rem;
  column-gap: 1.5rem;
  width: 100%;
}
.registerbasicua .rbform .part {
  flex: 0.3;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
}
.registerbasicua .rbform .part .rbinput {
  padding: 0.4rem 0.5rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Poppins";
  color: #9d9d9d;
  border: 1px solid lightgrey;
  background: white;
  border-radius: 4px;
}
.registerbasicua .rbform .part .mobnocont {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}
.registerbasicua .rbform .part .mobnocont .countrycode {
  flex: 0.1;
  width: 100%;
  text-align: center;
}
.registerbasicua .rbform .part .mobnocont .mobno {
  flex: 0.9;
  width: 100%;
}
.registerbasicua .rbform .part .optcont {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}
.registerbasicua .rbform .part .optcont .optpart {
  flex: 0.25;
  width: 100%;
  text-align: center;
}
.registerbasicua .rbform .verifyotpcont {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  row-gap: 0.5rem;
  align-items: flex-end;
}
.registerbasicua .rbform .verifyotpcont .verifyoptstatuscont {
  display: flex;
  flex-direction: column;

  row-gap: 0.8rem;
}
.registerbasicua .rbform .verifyotpcont .verifyoptstatuscont .verifyoptstatus {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
}
.registerbasicua
  .rbform
  .verifyotpcont
  .verifyoptstatuscont
  .verifyoptstatus
  .optstatusimg {
  object-fit: contain;
  max-height: 20px;
}
.registerbasicua .savecont {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.registerbasicua .savecont .savebtn {
  border: none;
  color: white;
  padding: 8px 30px;
  font-family: "Poppins";
  background: #ff656a;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .registerbasicua .rbform {
    flex-direction: column;
  }
}
