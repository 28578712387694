:root {
    --borderColor: #E7E3E9;
    --color: #9A95A7;
    --darkColor: #525464
}

.darkText {
    color: var(--darkColor);
    font-weight: bold;
}

.userProfileSection {
    padding: 0 30px;
    margin: 0;
    flex-grow: 1;
    position: relative;
}
.userProfileSection > .buttons {
  overflow-x: scroll;
}

.companiesLogo {
    display: flex;
    gap: 19px;
    padding: 30px;
    flex-wrap: wrap;
    border-bottom: 1px solid var(--borderColor);
    .logoWrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        p{
            background-color:black ;
            color: white;
            width: 40px;
            height: 40px;
            margin: 0;
            cursor: pointer;
            border-radius: 40px;
            padding: 5px;
            padding: 5px 0;
            font-size: 19px;
        }
    }
}


.companiesLogo img {
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 40px;

}

.userProfileSection .buttons {
    display: flex;
    gap: 10px;

}

.userProfileSection .buttons button {
    width: 150px;
    min-height: 55px;
    max-width: 160px;
    border-radius: 20px;
    font-size: 1.2rem;
    font-weight: 400;
    border: 1px solid #E0E0E0;
    background-color: transparent;
    flex-shrink: 0;
    
}

.userProfileSection .buttons button.active {
    font-weight: 700;
    background-color: #E0E0E0;
    color: black;
}



@media (max-width: 768px) {
  .userProfileSection > .buttons {
    justify-content: center;
    }
    .buttons button {
      width: 130px;
      font-size: 1rem;
    }
  }
  
  /* Mobile devices */
  @media (max-width: 480px) {
    .userProfileSection > .buttons {
      justify-content: flex-start;
      padding-bottom: 10px;
    }
    .buttons button {
      width: 120px;
      font-size: 0.9rem;
    }

    @media (max-width: 600px) {
      .userProfileSection {
        padding: 0;
        margin: 10px 15px;
        flex-grow: 1;
    }
    }
  }