@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.your-sessions-container {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-start;

  /* display: grid;
  grid-template-rows: auto;
  align-items: start;
  row-gap: 5px;
  column-gap: 5px; */
}

.your-sessions-container .create-session-box {
  background-color: rgb(240, 239, 239);
  width: 280px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 10px;
  border: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.your-sessions-container .create-session-box:hover {
  transform: scale(1.02);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.your-sessions-container .create-session-button {
  background-color: black;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
}

.your-sessions-container .singleSessionBox {
  display: flex;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  width: 280px;
  background-color: white;
  box-sizing: border-box;
}

.your-sessions-container .session-item {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  height: auto;
}

.your-sessions-container .session-item .sessionTypeAndEdit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.your-sessions-container .session-type {
  background-color: lightgrey;
  color: black;
  border-radius: 8px;
  width: 97px;
  height: 30px;
  font-size: small;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.your-sessions-container .session-item .sessionTypeAndEdit .editDeleteDropdown {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  background-color: white;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 10%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.your-sessions-container
  .session-item
  .sessionTypeAndEdit
  .editDeleteDropdown
  button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  width: 100%;
  text-align: start;
}
.your-sessions-container
  .session-item
  .sessionTypeAndEdit
  .editDeleteDropdown
  button:hover {
  background-color: #e0e0e0;
  border-radius: 5px;
}

.your-sessions-container .session-title {
  font-size: 32px;
  color: black;
  display: flex;
  text-align: left;
  line-height: 1.1;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
}

.your-sessions-container .session-item .session-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
  padding: 0 10px;
  border-radius: 15px;
  height: 60px;
  font-size: 17px;
}

.your-sessions-container .session-duration {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  font-weight: 400;
  color: black;
}

.your-sessions-container .session-price {
  color: black;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  /* font-weight: bold; */
}
.your-sessions-container .session-description {
  font-weight: 300;
  font-size: 15px;
  text-align: start;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow-wrap: break-word;
  color: grey;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
}

.your-sessions-container .right-icon {
  padding: 1px;
}

.your-sessions-container .iconSize {
  width: 22px;
  height: 22px;
}

@media screen and (max-width: 870px) {
  .your-sessions-container {
    display: flex;
    justify-content: center; /* Centers .singleSessionBox horizontally */
  }
}
