@font-face {
  font-family: "Encode-Sans";
  src: local("Encode-Sans"),
    url(./assets/fonts/EncodeSansNormal-700-Bold.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"),
    url(./assets/fonts/montserrat.bold.ttf) format("truetype");

  font-family: "Montserrat";
  src: local("Montserrat"),
    url(./assets/fonts/montserrat.regular.ttf) format("truetype");

  font-family: "DM-Sans-Reg";
  src: local("DM-Sans-Reg"),
    url(./assets/fonts/DMSans-VariableFont_opsz\wght.ttf);

  font-family: "DM-Sans-Ita";
  src: local("DM-Sans-Ita"),
    url(./assets/fonts/DMSans-VariableFont_opsz\wght.ttf);

  font-family: "GM-Sans";
  src: local("GM-Sans"), url(./assets/fonts/GeneralSans-Regular.ttf);
}

.formScrollBarDisplay::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  font-family: "Encode-Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --global-gradient: linear-gradient(90deg, #ffff82 0%, #b5d99c 100%);
}

.gradient-bg {
  background: var(--global-gradient);
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 20px; /* Preferred icon size */
  display: flex;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  padding: 0;
  margin: 0;
  cursor: pointer;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}
