 

.markDprofile >.uploadSection{
  margin:0 auto;
  width: 50% ;
  border:3px dashed #e7e3e9;
  display:flex;
  flex-direction:column;
  text-align:center;
  justify-content:center;
  align-items: center;
  height: 150px;
  padding-top: 10px;
  cursor: pointer;
}
.markDprofile >.uploadSection>img{
  margin-bottom: 10px;
  width: 50px;

}
.markDprofile >.uploadSection>h6{
  background: transparent;
  border-radius: 5px;
  padding: 5px 10px;
  border: none;
  font-weight: 500;
  margin-bottom: 0;
  letter-spacing: 0.02em;
  color: #b4aebd;
  max-width: fit-content;

}
.markDprofile >.uploadSection>span{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  color: #6e6e6e;
  margin-top: 10px;

}
.markDprofile >.uploadSection>input{
  /* position: relative;
  top: -52px;
  height: 24px;
  width: 180px;
  opacity: 0;
  cursor: pointer; */
  visibility: hidden;
}


.markDprofile .imageUploadDiv {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.markDprofile .theProfilePic {
  margin:15px auto;
  width: 50% ;
  display:flex;
  flex-direction:column;
  text-align:center;
  justify-content:center;
  align-items: center;
  height: 150px;
  padding-top: 10px;
  gap: 20px;
}
.markDprofile .theProfilePic .changeProfileButton{
  background-color: transparent;
  border:1px solid #E0E0E0;
  height: min-content;
  padding: 5px 15px;
  border-radius: 10px;
}

.markDprofile .theProfilePic .changeProfileButton:hover{
  background-color: black;
  color: white;
}
.markDprofile .imageUploadDiv .btnWrapper {
  display: flex;
  width: min-content;
  gap: 20px;
  align-items: center;
}

.markDprofile .imageUploadDiv .btnWrapper button {
  background-color: transparent;
  border: 1px solid #E7E3E9;
  height: min-content;
  padding: 5px 15px;
  border-radius: 5px;
}

.markDprofile .imageUploadDiv .btnWrapper  .uploadButton{
  background-color: black;
  color: white;
  border: none;
  margin: 20px ;
  font-size: 15px;
  margin-left: auto;
}

.markDprofile .imageUploadDiv .btnWrapper  .discardButton:hover{
  background-color: red;
  color: white;
}


.uploadButton:disabled{
  background-color: #b4aebd;
  color: white;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  margin: 20px ;
  font-size: 15px;

}

@media screen and (max-width: 870px) {
  .markDprofile .theProfilePic {
    margin:15px auto;
    width: 70% ;
    display:flex;
    flex-direction:column;
    text-align:center;
    justify-content:center;
    align-items: center;
    height: 150px;
    padding-top: 10px;
    gap: 20px;
  }
}