.userProfileAbout {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
}

.userProfileAbout .eachCompanyExperience {
  border-radius: 30px;
  background-color: #f7f7f7;
  padding: 30px;
  display: flex;
  justify-content: start;
  gap: 1rem;
  text-align: left;
  width: 100%;
}

.userProfileAbout .experience-item {
  margin-bottom: 15px;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: start;
  gap: 1rem;
}
.companyLogoContainer {
  display: inline;
}

.userProfileAbout .writtenExperience {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: start;
  gap: 20px;
}

.userProfileAbout .experience-item-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  gap: 5px;
}

.userProfileAbout .experience-item-title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userProfileAbout .experience-item-company {
  font-weight: 500;
  font-size: 16px;
}

.userProfileAbout .experience-item-description {
  padding-top: 5px;
  font-weight: 400;
  font-size: 18px;
  word-break: break-word;
  overflow-wrap: break-word;
}

.userProfileAbout .experience-item-duration {
  font-weight: 400;
  font-size: 16px;
  color: #808080;
}

.userProfileAbout .notableThingsHeading {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px !important;
  }
  
  
  .userProfileAbout .experienceNotableThings {
    display: flex;
    gap: 10px;
    justify-content: start;
    flex-wrap: wrap;
  }
  
  .userProfileAbout .experienceNotableThings .experienceFeatures{
    border: 1px solid #e0e0e0;
    background-color: transparent;
    padding: 10px;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
  }
  
  
  .userProfileAbout .experienceNotableThings .experienceFeatures:hover{
    background-color: #e0e0e0;
  }
  
  .userProfileAbout .experienceProjectsList {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }
  
  .userProfileAbout .experienceProjectsList .experienceProjects {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: start;
  }
  
  .userProfileAbout .experienceProjectsList .experienceProjects .experienceProjectsTitle{
    border: 1px solid #e0e0e0;
    background-color: transparent;
    padding: 10px;
    border-radius: 10px;
    font-weight: 400;
    font-size: 18px;
    cursor: pointer;
  
  }
  
  .userProfileAbout .experienceProjectsList .experienceProjects .experienceProjectsAboutBox{
    /* flex: 1; */
    border: 1px solid #e0e0e0;
    background-color: transparent;
    border-radius: 10px;
  
    display: flex;
    padding: 10px;
    font-size: 18px;
    align-items: center;
    gap: 15px;
    /* width: 100%; */
  }
  
  .userProfileAbout .experienceProjectsList .experienceProjects .experienceProjectDescription{
    word-break: break-word; 
    overflow-wrap: break-word; 
  }
  

/* :root {
    --borderColor: #E7E3E9;
    --color: #9A95A7;
    --darkColor: #525464
}

.fontStart {
    text-align: start;
}

.darkText {
    color: var(--darkColor);
    font-weight: bold;
}

.aboutPageExperienceSection > .aboutSecondSection > .projectSectionContainer {
    background-color: #fff;
}

.aboutSection {
    display: flex;
    flex-wrap: wrap;
}

.aboutSection>div:first-child {
    border-right: 1px solid var(--borderColor);
}

.aboutSection>div {
    padding: 20px 40px;
    width: 50%;
}

.aboutSection .socialMediaIcons {
    flex-wrap: wrap;
    gap: 20px;
}

.socialMediaIcons img {
    cursor: pointer;
    padding: 10px 0;
}

.otherLinks {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 20px 0;
}

/* .aboutFirstSection{
    display: flex;
    flex-direction: column;
} */

/* .otherLinks button {
    min-width: 163px;
    padding: 2px 20px;
    text-decoration: none;
    color: var(--color);
    border: 1px solid var(--borderColor);
    background: transparent;
    border-radius: 50px;
} */

/* .aboutPara {
    letter-spacing: 0.5px;
    padding: 15px 0;
}

.reviewSection {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 20px;
    border: 1px solid var(--borderColor);
}

.reviewSection .profileSection {
    display: flex;
    align-items: center;
    gap: 10px;
}

.reviewSection .profileSection>div>* {
    margin: 0;
}

.reviewSection .profileSection>div>p {
    color: var(--darkColor);
}

.reviewSection .profileSection>div>span {
    font-size: 0.8rem;
}

.stars {
    display: flex;
    gap: 5px;
    margin: 0 0 10px 0;
}

.projectSectionContainer {
    display: flex;
    gap: 20px;
}


.projectSection {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.projectSection>p {
    margin: 0 0 8px 0;
}

.projectSection>p:last-child {
    letter-spacing: 0.5px;
    margin: 20px 0 25px 0;
}

.datePeriod {
    color: var(--darkColor);
}

.post,
.company {
    color: var(--darkColor);
    font-weight: bold;
}

@media (max-width: 850px) {
    .aboutSection>div {
        width: 100%;
    }

    .aboutSection>div:first-child {
        border-right: none;
        border-bottom: 1px solid var(--borderColor);
    }
}

@media (max-width: 670px) {
    .aboutSection > div {
        padding: 20px;
    }
} */
