@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800&display=swap");
.Marketerslogin__cont {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff6f6;
  font-family: "Lexend";
  font-style: normal;
  margin-bottom: 50px;
}
.Marketerslogin__cont > .login {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0 0 0 0;
  flex-direction: column;
  width: 100%;
  min-height: 550px;
  background: #ffffff;
  gap: 0;
}

.Marketerslogin__cont > .login h1 {
  margin: 50px auto;
  font-weight: 500;
  font-size: 25px;
  line-height: 50px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #525464;
  margin-top: 0px;
  
}

.Marketerslogin__cont > .login .uLoginForm {
  /* flex: 0.6; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;
  width: 600px;
  margin: 0 auto;
  color: #6e6e6e;  border: 1px solid #e7e3e97e;
  padding: 50px;

}
.Marketerslogin__cont > .login .uLoginForm .logreg__title {
  margin-bottom: 1rem;
}
.Marketerslogin__cont > .login .uLoginForm .login__form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.6rem;
  width: 100%;

  /* background: peru; */
}
.Marketerslogin__cont > .login .uLoginForm .login__form label {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #b4aebd;
}
.Marketerslogin__cont > .login .uLoginForm .login__form .epass {
  color: #0b1c39;
  background: #fafafa;
  height: 2.7rem;
  border-radius: 1px;
  border: 1.5px solid #b4aebd;
  font-family: "Poppins";
  outline: none;
  font-size: 0.85rem;
  padding: 0.5rem 1rem;
  width: 100%;
  box-sizing: border-box;
}

.Marketerslogin__cont > .login .uLoginForm .login__form .epass:focus {
  border: 1.5px solid rgb(0, 0, 0);
}

.Marketerslogin__cont > .login .uLoginForm .login__form .epass__otp {
  border: 1.5px solid rgb(0, 0, 0);
}

.Marketerslogin__cont > .login .uLoginForm .login__form .epass::placeholder {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #b4aebd;
}
.Marketerslogin__cont
  > .login
  .uLoginForm
  .login__form
  .epass::-webkit-outer-spin-button,
.Marketerslogin__cont
  > .login
  .uLoginForm
  .login__form
  .epass::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.Marketerslogin__cont > .login .uLoginForm .login__form .log__for {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 0.5rem;
  width: 100%;
}
.Marketerslogin__cont > .login .uLoginForm .login__form .log__for label {
  font-size: 0.75rem;
  font-weight: 500;
  display: flex;
  column-gap: 0.5rem;
  cursor: pointer;
  text-align: left;
}
.Marketerslogin__cont > .login .uLoginForm .login__form .login__btn {
  font-size: 0.9rem;
  margin-top: 1rem;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 1px;
  color: rgba(255, 255, 255, 0.89);
  background: #000000;
  height: 3rem;
  width: 100%;
  font-family: "Poppins";
}

.Marketerslogin__cont > .login .uLoginForm .login__form .mandatoryFieldSign {
  color: rgb(219, 32, 32);
  font-size: 12px;
  padding-top: 5px;
}

.Marketerslogin__cont > .login .uLoginForm .color-grey {
  color: #b4aebd;
  cursor: pointer
}
.Marketerslogin__cont > .login .uLoginForm .login__form p {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* or 133% */

  display: flex;
  align-items: center;
  letter-spacing: 0.01em;

  /* black/b4aebd/h */

  color: #b4aebd;
}

@media screen and (max-width: 1024px) {
  .Marketerslogin__cont > .login {
    margin-right: 0;
  }
}
@media screen and (max-width: 880px) {
  .Marketerslogin__cont > .login {
    flex-direction: column;

    align-items: center;
    margin: 0 5%;
  }
  .Marketerslogin__cont > .login .sec__left {
    display: none;
  }
  .Marketerslogin__cont > .login .uLoginForm .logreg__title {
    font-size: 1.6rem;
  }
  .Marketerslogin__cont > .login .uLoginForm {
    margin: 3rem 5%;
    align-items: center;
    width: auto !important;
  }
}
/* Container for all auth buttons */
.auth-buttons-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0;
  margin: 1rem 0;
}

.google-auth-container {
  width: 100%;
  margin: 0; 
  padding: 0; 
}

.or-divider {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0.5rem 0; 
  position: relative;
}

.or-line {
  flex: 1;
  height: 1px;
  background-color: #e7e3e9;
  margin: 0; 
}

.or-text {
  padding: 0 1rem;
  color: #b4aebd;
  font-size: 0.8rem;
  font-weight: 500;
  background: white;
  position: relative;
  z-index: 1;
}

/* Google Button Overrides */
div[id^="google-login-div"] {
  width: 100% !important;
  min-width: 100% !important;
}

div[id^="google-login-div"] > div {
  width: 100% !important;
  min-width: 100% !important;
  height: 46px !important;
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 1px !important;
}

div[id^="google-login-div"] > div > iframe {
  width: 100% !important;
  min-width: 100% !important;
}

