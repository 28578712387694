.Singlementorcard {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #f7f7f7;
  width: 360px;
  padding: 30px;
  border-radius: 10px;
  height: max-content;
}

.Singlementorcard .nameAndRole {
  display: flex;
  flex-direction: column;
  gap: 0px;
}
.Singlementorcard .nameAndRole .mentorName {
  font-weight: 500;
  font-size: 22px;
  line-height: 29.7px;
}

.Singlementorcard .nameAndRole .mentorRole {
  font-weight: 500;
  font-size: 12px;
  line-height: 16.2px;
}

.Singlementorcard .aboutPara {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  font-weight: 400;
  font-size: 14px;
  line-height: 18.9px;
  /* text-overflow: ellipsis; */
}

.Singlementorcard .companiesAssociated {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}

.Singlementorcard .companiesAssociated .companyName {
  padding: 5px 10px;
  border-radius: 20px;
  background-color: #e0e0e0;
  border: 1px solid #e0e0e0;
  font-weight: 500;
  font-size: 12px;
}

.Singlementorcard .sessionList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  scrollbar-width: none;
  height: 180px;
}

.Singlementorcard .sessionList::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}

.Singlementorcard .sessionButtons {
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-top: auto;
}

@media screen and (max-width: 760px) {
}

@media screen and (max-width: 480px) {
  .Singlementorcard .aboutPara {
    display: none;
  }

  .Singlementorcard .sessionList {
    display: none;
  }

  .Singlementorcard .sessionButtons {
    align-self: flex-start;
  }
}
