.request {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;

  height: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  border-radius: 15px;
  border: 1px solid #e0e0e0;
  padding: 0 10px;
  cursor: pointer;
}

.request .material-symbols-outlined {
  color: black;

}

.request .profile-img {
  border: 1px solid #000;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-color: #ccc;
}

.request div {
  width: max-content;
  flex: none;
  font-weight: 600;
}
.request span {
  color: gray;
  margin-left: 5px;
}
