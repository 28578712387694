.sapcontent .filtersort__cont {
  display: flex;
  column-gap: 1rem;
}

.sapcontent .additioncontr .additioncontd .additioncont {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
  margin-top: -1rem;
  background: #f8f8f8;

  padding: 2rem 0.6rem 3.5rem 0.6rem;
  box-sizing: border-box;
  width: 100%;
}
.additioncontr .additioncontd .additioncont .basedata {
  display: flex;
  column-gap: 1rem;
}
.additioncontr .additioncontd .additioncont .basedata .part {
  flex: 0.2;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.additioncontr .additioncontd .additioncont .tagscont {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.additioncontr .additioncontd .additioncont .tagscont .tags {
  display: flex;
  column-gap: 0.7rem;
  row-gap: 0.7rem;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  float: left;
  width: 100%;
}
.additioncontr .additioncontd .additioncont .tagscont .tags .tag {
  font-size: 0.8rem;
  font-weight: 600;
  border-radius: 3px;
  padding: 0.5rem 2.5rem;
  box-sizing: border-box;
  border-radius: 3rem;
  color: gray;
  background: rgba(255, 0, 0, 0.1);
  /* cursor: pointer; */
  position: relative;
}

.additioncontr .additioncontd .additioncont .assigndata {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.sapcontent .additioncont {
  display: none !important;
}
.sapcontent .showadditioncontr {
  display: flex !important;
}
.sapcontent .rotate270 {
  transform: rotate(270deg) !important;
}
