@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap");

.session-form {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children horizontally */
  font-family: "Poppins", sans-serif;
}

.session-form-content {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  align-self: center; /* Center itself horizontally */
  width: 500px;
  margin-top: 48px;
}

.session-form-overlay {
  border: 1px solid #e0e0e0;
  border-radius: 30px;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.session-form-container {
  background: white;
  width: 100%;
  /* max-width: 700px; */
  padding: 20px;
  box-shadow: none;
  border-radius: 0;
  height: auto;
}

.session-form {
  display: flex;
  flex-direction: column;
}

.session-form-tabs {
  display: flex;
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
}

.session-type-button {
  padding: 20px 30px 20px 30px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  cursor: pointer;
}
.session-type-button.disabled {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  color: #e0e0e0;
  pointer-events: none;
}

.session-type-button.active {
  border-color: black;
  color: black;
  font-weight: 600;
  border: 2px solid #000000;
}

.session-form-content {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  /* flex-grow: 2; */

  /* align-items: center; */
}

.session-form-field {
  display: flex;
  /* width: 100%; */
  flex-direction: column;
  margin-bottom: 10px;
}

.session-form-field label {
  margin-bottom: 9px;
  display: flex;
  align-items: flex-start;
  font-weight: 500;
}

.session-form-field .mentorInputContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 500px;
  height: 70px;
  box-sizing: border-box;
}
.session-form-field .mentorInputContainer .inputSubtext {
  color: #b1b1b1;
  display: flex;
}
.session-form-field .mentorInputContainer input {
  border: none;
  outline: none;
  background-color: transparent;
  flex-grow: 1;
}
.session-form-field textarea {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 500px;
  height: 70px;
  box-sizing: border-box;
}

.session-form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 500px;
}
/* Apply placeholder color */
.session-form-field input::placeholder,
.session-form-field textarea::placeholder {
  color: #b1b1b1;
}

.session-form-buttons .save-button {
  background-color: black;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  padding: 8px 25px;
  cursor: pointer;
  width: 150px;
  font-weight: 400;
}

.session-form-buttons .cancel-button1 {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 8px 25px;
  cursor: pointer;
  width: 150px;
  font-weight: 400;
  line-height: 17px;
}

@media (max-width: 600px) {
  .session-type-button {
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    height: 50px;
    min-width: fit-content;
  }
  .session-type-button .active {
    display: block !important;
  }

  .session-form-field .mentorInputContainer {
    max-width: 100%;
    width: auto;
  }
  .session-form-field textarea {
    width: auto;
    height: 70px;
  }
  .session-form-content {
    width: 100%;
    margin-top: 20px;
  }
  .session-form-tabs {
    overflow: hidden;
  }
  .session-form-container {
    padding: 20px;
  }

  .session-form-buttons {
    flex-direction: column;
  }

  .session-form-buttons .save-button,
  .session-form-buttons .cancel-button1 {
    width: 100%;
    margin-bottom: 10px;
  }
  .session-form-buttons {
    width: auto;
  }
}
