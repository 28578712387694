.secondary-footer {
    position: fixed;
    width: 100%;
    height: 73px;
    left: 0px;
    bottom: 0;
    background: #0B1C39;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.secondary-footer .text1 {
    font-family: "lexend";
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}

.secondary-footer .text2 {
    font-family: "lexend";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}

.secondary-footer .text2_1 {
    color: #FF656A
}

.secondary-footer .text2_2 {
    color: #FF656A;
    font-weight: bold;
}

@media screen and (max-width: 450px) {
    .secondary-footer{
        padding: 0 10px;
    }
    
    .secondary-footer .text1{
        font-size: 14px;
        line-height: 18px;
    }    

    .secondary-footer .text2{
        font-size: 14px;
        line-height: 18px;
    }
}