
.requestingUnlockContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}


.requestingUnlockContainer .requestColumn{
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    height: 60px;
}

.requestingUnlockContainer .requestColumn .candidateInformation{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 40px;
}

.requestingUnlockContainer  .request-header-prof  {
    display: flex;
    justify-content:start;
    align-items: center;
    gap: 10px;
  
    height: 100%;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    border-radius: 20px;
    border : 1px solid #E0E0E0;
    padding: 0 25px;
  }
  
  
  .requestingUnlockContainer > .request-header-prof .profile-img {
    border: 1px solid #000;
    border-radius: 100%;
    width: 40px;  
    height: 40px;
    background-color: #ccc;
  }
  
  .requestingUnlockContainer .request-header-prof p {
    width: max-content;
    flex: none;
  }
  .requestingUnlockContainer .request-header-prof span {
    color: gray;
    margin-left: 5px;
  }

  .requestingUnlockContainer .requestColumn .selectButtons{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
}