.talentPoolPage {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: start;
  width: 100%;
}
.flexBetweenRadius10 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e0e0e0;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  font-weight: 500;
  font-size: 18px;
  padding: 0 20px;
  height: 60px;
  cursor: pointer;
}

.deactiveTab {
  background-color: white;
}

.width-full {
  width: 100%;
}

.talentPoolPage .talentPoolTabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 20px;
}

.talentPoolPage .filterTab {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 10px;
  align-items: center;
  font-size: 18px;
}

.talentPoolPage .filterTab .profileStatusDropdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  font-size: 18px;
  padding: 7px 15px;

  color: #b1b1b1;
  border-radius: 30px;
  border: 1px solid #f7f7f7;
  position: relative;
  background-color: #f7f7f7;
}

.talentPoolPage .filterTab .profileStatusDropdown .variousProfileStatus {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}
.talentPoolPage .filterTab .statusName {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 7px 15px;
  color: black;
  cursor: pointer;
}

.talentPoolPage .filterTab .statusName:hover {
  background-color: #f7f7f7;
}

.talentPoolPage .filterTab .selectedStatusName {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #f7f7f7;
  background-color: #f7f7f7;
  border-radius: 20px;
  padding: 7px 15px;
  color: black;
  cursor: pointer;
  gap: 10px;
}

.talentPoolPage .filterTab .selectedStatusName:hover .crossIcon {
  display: block;
}

.talentPoolPage .grid-container {
  display: grid;
  grid-template-columns: repeat(18, 1fr);
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
}

.talentPoolPage .grid-container .grid-item-1 {
  grid-column: span 9;
  height: 60px;
}

.talentPoolPage .grid-container .grid-item-2 {
  grid-column: span 4;
}
.talentPoolPage .grid-container .grid-item-3 {
  grid-column: span 5;
  margin-left: 25px;
}
.talentPoolPage .grid-container .table-headings {
  font-weight: 600;
  font-size: 20px;
  color: #7f7f7f;
  display: flex;
  justify-content: start;
  align-items: center;
  height: 40px;
}

.talentPoolPage .request-header-prof {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;

  height: 100%;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  padding: 0 20px;
}

.talentPoolPage > .request-header-prof .profile-img {
  border: 1px solid #000;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-color: #ccc;
}

.talentPoolPage .request-header-prof p {
  width: max-content;
  flex: none;
}
.talentPoolPage .request-header-prof span {
  color: gray;
  margin-left: 5px;
}

.talentPoolPage .grid-container .grid-item-3.actionButtons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-left: auto;
}

.talentPoolPage .deleteDropdownComponent {
  display: flex;
  gap: 10px;
  text-align: left;
}

.inviteDetailContainer {
  display: flex;
  flex-direction: column;
  gap: 25px;
  text-align: left;
}

.inviteDetailContainer .detailColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: start;
}

.inviteDetailContainer .verticalLabelInput {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inviteDetailContainer .horizontalLabelInput {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.inviteDetailContainer label {
  font-size: 18px;
  font-weight: 600;
}

.inviteDetailContainer .detailInput {
  outline: none;
  border: 1px solid #ffffff;
  border-radius: 30px;
  height: 45px;
  padding: 0 20px;
}

.inviteDetailContainer .detailColumns .detailInput.fullName {
  width: 100%;
  border: 1px solid #e0e0e0;
}

.inviteDetailContainer .detailColumns .detailInput.userPrice {
  width: 150px;
  border: 1px solid #e0e0e0;
}

.inviteDetailContainer .detailColumns .detailInput.topCandidate {
  width: 80px;
}

.inviteDetailContainer .detailColumns .detailInput.userPrice {
  width: 150px;
}

.inviteDetailContainer .detailInput.userComments {
  padding-top: 10px;
  width: 100%;
  height: max-content;
}

.inviteDetailContainer .skillAndCustomTags {
  display: flex;
  gap: 20px;
  flex-direction: column;
  border: 1px solid #ffffff;
  padding: 20px;
  border-radius: 20px;
  background-color: white;
}

.seachBarWithSuggestion {
  position: relative;
}

.seachBarWithSuggestion .searchBar {
  display: flex;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 44px;
  outline: 1px solid #f7f7f7;
  background-color: #f7f7f7;
  border-radius: 30px;
}

.seachBarWithSuggestion .searchBar input {
  width: 100%;
  padding: 10px;
  outline: none;
  border: none;
  background-color: transparent;
}

.suggestionBox {
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.skillAndTagContainer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.skillOrTagItem {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
}

.crossIcon {
  display: none;
}

.skillOrTagItem:hover .crossIcon {
  display: block;
}

.inviteDetailContainer .inviteDetailButtons {
  display: flex;
  justify-content: space-between;
}

.searchedResult {
  position: absolute;
  top: 45px;
  left: 0;
  width: 100%;
  background-color: #fff;
  outline: 1px solid #e7e3e9;
  border-radius: 10px;
  padding: 0 10px;
}

.searchedUser {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-bottom: none;
  outline: none;
  padding: 10px 20px;
  margin: 8px 0;
}

.searchedUser:hover {
  background-color: #f8f8f8;
  border-radius: 10px;
}

.horizontal_line {
  margin: 0 auto;
  border: none;
  width: 96%;
  color: rgb(186, 184, 187);
}
.searchedUserDetails img {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 30px;
}
.searchedUserDetails,
.searchedUsernameAndBtn {
  display: flex;
  align-items: center;
  gap: 10px;
}

.searchedUsernameAndBtn {
  gap: 50px;
}

.searchedUsernameAndBtn > button {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  color: #525464;
}
.deleteUser {
  padding: 4px 2px;
  border-top: 1px solid #e7e3e9;
  border-bottom: 1px solid #e7e3e9;
}

.review {
  padding: 4px 2px;
  border-top: 1px solid #e7e3e9;
  border-right: 1px solid #e7e3e9;
  border-bottom: 1px solid #e7e3e9;
}

.deleteUserIcon {
  cursor: pointer;
  padding: 4px 2px;
  border-top: 1px solid #e7e3e9;
  border-right: 1px solid #e7e3e9;
  border-bottom: 1px solid #e7e3e9;
}
.reviewIcon {
  cursor: pointer;
  padding: 8px 2px;
  border-top: 1px solid #e7e3e9;
  border-left: 1px solid #e7e3e9;
  border-bottom: 1px solid #e7e3e9;
}
.comment-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 20px 20px;
  width: 350px;
  border: none;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 0.3rem;
  outline: 0;
}

.close {
  position: absolute;
  top: -35px;
  right: -15px;
  cursor: pointer;
}
.submit-btn {
  width: 100px;
  height: 40px;
  border: none;
  margin-left: 107px;
}

.submit-btn:hover {
  background-color: #1e90ff;
  color: white;
}

textarea {
  width: 100%;
  height: 100px;
  resize: none;
  margin-bottom: 10px;
  outline: none;
}

@media screen and (max-width: 632px) {
  .talentPool_table > table {
    width: 95%;
    margin: 0 2.5%;
  }
  .talentPool_table > table > tr > th {
    display: none;
  }

  .talentPool_table .inputWrapper {
    flex-direction: column;
  }
  .talentPool_table .inputWrapper .iconWrapper {
    padding: 8px 0;
  }
  .talentPool_table > table > .talentPoolAddedUser {
    border: 1px solid #000;
    margin-top: 10px;
  }
  .talentPool_table > table > tr > td,
  .talentPool_table > table {
    border: none;
  }
  .talentPool_table > table > tr > td {
    display: block;
    width: 100%; /* Optional: Set width to 100% to take full width */
    box-sizing: border-box; /* Optional: Include padding and border in the width */
    border: none;
  }
  .searchBar > div {
    margin-bottom: 10px;
  }
  .talentPool_table p {
    margin-bottom: 0;
  }
  .nameData > div {
    margin: 0;
  }
  .nameData,
  .roleData {
    padding: 8px 5%;
  }
  .deleteUserIcon {
    padding: 8px 0;
  }
  .reviewIcon {
    padding: 8px 0;
  }
  .searchedUser {
    align-items: start;
    flex-direction: column;
  }
}
