.caseStudyListingPage{
  font-family: lexend;
}
.caseStudyListingPage > .nopSection{
  position: fixed;
  top: 69px;
  height: 50px;
  width: 100%;
  border-bottom: 1px solid #dfe9f3;
  background: #FFFFFF;
  z-index: 2;
  
}
.caseStudyListingPage > .nopSection > .numberOfProjects{
  text-align: left;
  max-width: 1240px;
  height: 50px;
  margin: 0 auto;
  border-left: 1px solid #dfe9f3;
  border-right: 1px solid #dfe9f3;
  display: flex;
  align-items: center;
  padding-left: 20px;
  z-index: 2;
}
.case_study__listing {
  border-left: 1px solid #dfe9f3;
  max-width: 1240px;
  margin: 50px auto;
  z-index: 1;
}

.case_study__listing .case__studies {
  display: grid;
  grid-template-columns: repeat(4, 310px);
  gap: 0;
  justify-content: center;
}
@media screen and (min-width: 1700px){
  .case_study__listing{
    max-width: 1550px;
  }
  .case_study__listing .case__studies {
    grid-template-columns: repeat(5, 310px);
  } 
}
@media screen and (max-width: 1300px){
  .case_study__listing > .case__studies {
    grid-template-columns: repeat(3, 310px);
  }
  .caseStudyListingPage > .nopSection > .numberOfProjects{
    max-width: 930px;  
  }
  .case_study__listing {
    max-width: 930px;
  }
}
@media screen and (max-width: 952px){
  .case_study__listing > .case__studies {
    grid-template-columns: repeat(2, 310px);
  }
  .caseStudyListingPage > .nopSection > .numberOfProjects{
    max-width: 620px;  
  }
  .case_study__listing {
    max-width: 620px;
  }
}
@media screen and (max-width: 768px){
  .caseStudyListingPage > .nopSection{
    position: fixed;
    top: 80px;
  }
}
@media screen and (max-width: 630px){
  .case_study__listing > .case__studies {
    grid-template-columns: repeat(1, 310px);
  }
  .caseStudyListingPage > .nopSection > .numberOfProjects{
    max-width: 310px;  
  }
  .case_study__listing {
    max-width: 310px;
  }
}