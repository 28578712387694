.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #E0E0E0;

  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}


.slider:before {
  position: absolute;
  content: "";
  height: 20px; /* Reduced height */
  width: 20px;  /* Reduced width */
  left: 7px; /* Adjusted to center the smaller image */
  bottom: 7px;
  background-color: transparent;
  background-image: url('../../assets/Unispade\ favicon\ b5b5b5.png'); /* Replace with the path to your black image */


  background-size: cover;
  -webkit-transition: .4s;
  transition: .4s;
}


input:checked + .slider {
  background-color: white; /* Change the background if needed */
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-image: url('../../assets/Unispade\ Favicon\ 000000.png'); /* Replace with the path to your gray image */

  height: 20px; /* Match the reduced height */
  width: 20px;  /* Match the reduced width */
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
