/* TalentPoolUserConsent.css */

.consent-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  
  .consent-container .message {
    margin-top: 20px;
  }
  
  .consent-container .message h2 {
    color: #4CAF50; /* Green for interested message */
    margin-bottom: 10px;
  }
  
  .consent-container .message.not-interested h2 {
    color: #F44336; /* Red for not interested message */
  }
  
  .consent-container .message p {
    color: #555;
  }

  .home-button {
    margin-top: 20px;
    padding: 5px 10px;
    font-size: 16px;
    color: #fff;
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .home-button:hover {
    background-color: #0056b3;
  }
  