.aonboarding__cont {
  display: flex;

  /* background: #e5e5e5; */
  background: #f6f6f6;
  min-height: 100vh;
}
.aonboarding {
  flex: 0.82;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 3rem 1.3%;
  box-sizing: border-box;
  width: 100%;
  background: #f6f6f6;
}
@media screen and (max-width: 950px) {
  .aonboarding {
    flex: 1;
  }
}
.aonboarding input {
  font-family: "Poppins";
  color: #0b1c39 !important;
}
.aonboarding ::placeholder {
  font-family: "Poppins";
  color: #d3d3d3 !important;
}
.aonboarding .aonboarding__title {
  font-weight: bolder;
  color: #5f5f5f;
}
.aonboarding .section__cont {
  display: flex;
  flex-direction: column;
  row-gap: 0.6rem;
}
.aonboarding .section__cont .section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.aonboarding .section__cont .section .part__right {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1.3rem;
}
.aonboarding .section__cont .section .part__right .img__cont {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.aonboarding .section__cont .section .part__right .img__cont .msg__not {
  object-fit: contain;
  height: 16px;
}
.aonboarding .section__cont .section .part__right .img__cont .dotg {
  position: absolute;
  right: -10%;
  top: -10%;
}
.aonboarding .section__cont .section .part__right .date__day {
  padding: 0.2rem 0.4rem;
  border: 1px solid #828282;
  border-radius: 3px;
}
.aonboarding .section__cont .section__progress__cont {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
}
.aonboarding .section__cont .section__progress__cont .part1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.aonboarding .section__cont .section__progress__cont .part2 {
  position: relative;
  height: 0.7rem;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  background: white;
}
.aonboarding .section__cont .section__progress__cont .part2 .progress__bar {
  position: absolute;
  height: 100%;
  background: #ff656a;
}

.aonboarding .basdetails {
  width: 100%;
}
.aonboarding .basdetails .basdetails__data {
  display: flex;
  flex-direction: column;
  row-gap: 0.7rem;
  text-align: left;
  margin: 1rem 0% 1rem 5%;
}
.aonboarding .basdetails .basdetails__data .basdetails__data__forms {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.aonboarding .basdetails .basdetails__data .basdetails__data__forms .form {
  display: flex;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
}
.basdetails__data .basdetails__data__forms .form .inp__fields {
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
}
.basdetails__data .basdetails__data__forms .form .flxs {
  flex: 0.18;
}
.basdetails__data .basdetails__data__forms .form .flxl {
  flex: 0.42;
}

.basdetails__data .basdetails__data__forms .form .inp__fields .input,
.basdetails__data .basdetails__data__forms .form .inp__fields .select {
  padding: 0.4rem 0.4rem;
  outline: none;
  font-family: "Poppins";
  color: #0b1c39 !important;
  border: 1px solid lightgrey;
  background: white;
  border-radius: 3.5px;
}

.basdetails__data
  .basdetails__data__forms
  .form
  .inp__fields
  .input
  ::placeholder,
.basdetails__data
  .basdetails__data__forms
  .form
  .inp__fields
  .select
  ::placeholder {
  color: #bebebe;
}
.basdetails__data .basdetails__data__forms .form .inp__fields .labelf {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.basdetails
  .basdetails__data
  .basdetails__data__forms
  .agency__overview
  .part1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.basdetails .basdetails__data .basdetails__data__forms .agency__overview {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
}
.basdetails
  .basdetails__data
  .basdetails__data__forms
  .agency__overview
  .agency__textarea {
  width: 100%;
  height: 8rem;
  outline: none;
  padding: 0.5rem;
  box-sizing: border-box;

  color: #0b1c39 !important;
  border: 1px solid lightgray;
  border-radius: 3.5px;
}

/* Contact details */
.aonboarding .contact__details {
  display: flex;
  flex-direction: column;
  row-gap: 0.7rem;
  width: 100%;
}
.aonboarding .contact__details .contact__details__data {
  display: flex;
  flex-direction: column;
  row-gap: 0.7rem;
  text-align: left;
  margin: 1rem 0% 1rem 5%;
}
.aonboarding
  .contact__details
  .contact__details__data
  .contact__details__forms {
  display: flex;
  flex-direction: column;
  row-gap: 1.8rem;

  width: 100%;
}
.aonboarding
  .contact__details
  .contact__details__data
  .contact__details__forms
  .form {
  display: flex;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
}
.contact__details__data .contact__details__forms .form .inp__fields {
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
}

.contact__details__data .contact__details__forms .form .flxxs {
  flex: 0.1;
}
.contact__details__data .contact__details__forms .form .flxs {
  flex: 0.18;
}
.contact__details__data .contact__details__forms .form .flxl {
  flex: 0.4;
}
.contact__details__data .contact__details__forms .form .flxs25 {
  flex: 0.25;
}
.contact__details__data .contact__details__forms .form .inp__fields .input,
.contact__details__data .contact__details__forms .form .inp__fields .select {
  padding: 0.4rem 0.4rem;
  outline: none;
  font-family: "Poppins";
  color: #0b1c39 !important;
  border: 1px solid lightgrey;
  background: white;
  border-radius: 3.5px;
}

.contact__details__data
  .contact__details__forms
  .form
  .mobinpcont
  input::-webkit-outer-spin-button,
.contact__details__data
  .contact__details__forms
  .form
  .mobinpcont
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.contact__details__data
  .contact__details__forms
  .form
  .inp__fields
  .input::placeholder,
.contact__details__data
  .contact__details__forms
  .form
  .inp__fields
  .select
  ::placeholder {
  color: #bebebe !important;
}
.contact__details__data .contact__details__forms .form .inp__fields .labelf {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Clients */
.contact__details__data .contact__details__forms .client__form {
  justify-content: space-between !important;
}
.contact__details__data
  .contact__details__forms
  .client__form
  .inp__fields
  .logo__conts {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  max-width: 180px;
}
.contact__details__data
  .contact__details__forms
  .client__form
  .inp__fields
  .logo__conts
  .logo {
  object-fit: contain;
  height: auto;
  max-height: 80px;
  width: 100%;
}
/* .contact__details__data .contact__details__forms .client__form .reviews__cont {
  width: 100%;
  background: purple;
} */
/* .contact__details__data
  .contact__details__forms
  .client__form
  .inp__fields
  .review__star__img {
  object-fit: contain;
  height: auto;
  max-height: 25px;
  width: 100%;
} */
.contact__details__data
  .contact__details__forms
  .client__form
  .inp__fields
  .textarea {
  height: 6rem;

  width: 100%;
  outline: none;
  padding: 0.5rem;
  box-sizing: border-box;

  color: #0b1c39 !important;
  border: 1px solid lightgray;
  border-radius: 3.5px;
}
.contact__details__data
  .contact__details__forms
  .client__form
  .client__para__cont {
  max-width: 350px;
}
.aonboarding .done__add__cont {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.aonboarding .done__add__cont .done__btn {
  font-family: "Poppins";
  padding: 0.5rem 0.8rem;
  background: #ff656a;
  outline: none;
  border: 1px solid lightgray;
  color: white;
  border-radius: 3px;
  cursor: pointer;
}

/* Case study */
.contact__details__data .contact__details__forms .case__study__tops {
  display: flex;
  column-gap: 2rem;
  row-gap: 0.5rem;
  flex-wrap: wrap;
  float: left;
}
.contact__details__data .contact__details__forms .case__overview {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
}

.contact__details__data
  .contact__details__forms
  .case__overview
  .case__textarea {
  width: 100%;
  height: 8rem;
  color: #0b1c39 !important;
  border: 1px solid lightgray;
  outline: none;
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 3.5px;
}
.contact__details__data .contact__details__forms .case__textarea__cont {
  display: flex;
  column-gap: 2rem;

  row-gap: 0.5rem;
  column-gap: 3rem;
  width: 100%;
}
.contact__details__data
  .contact__details__forms
  .case__textarea__cont
  .case__textarea__part {
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;

  width: 100%;
}
.contact__details__data
  .contact__details__forms
  .case__textarea__cont
  .case__textarea__part
  .case__textarea {
  width: 100%;
  height: 8rem;
  color: #0b1c39 !important;
  border: 1px solid lightgray;
  outline: none;
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 3.5px;
}
.contact__details__data .contact__details__forms .case__statics__cont {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.contact__details__data .contact__details__forms .case__statics__title {
  display: flex;
  justify-content: space-between;
}
.contact__details__data .contact__details__forms .case__statics__form {
  display: flex;
  justify-content: space-between;
  column-gap: 3rem;
  row-gap: 0.5rem;
  width: 100%;
  /* background: purple; */
}
.contact__details__data .contact__details__forms .case__statics__form .part {
  display: flex;
  justify-content: space-between;
  column-gap: 2rem;
  row-gap: 0.5rem;
  width: 100%;
}
.contact__details__data
  .contact__details__forms
  .case__statics__form
  .part
  .inp__fields {
  width: 100%;
}
.contact__details__data
  .contact__details__forms
  .case__statics__form
  .part
  .inpsmall {
  flex: 0.15;
}
.contact__details__data
  .contact__details__forms
  .case__statics__form
  .part
  .inpbig {
  flex: 0.9;
}

.contact__details__data
  .contact__details__forms
  .case__statics__form
  .part
  .inp__fields
  .input,
.contact__details__data
  .contact__details__forms
  .case__statics__form
  .part
  .inp__fields
  .select {
  padding: 0.4rem 0.4rem;
  box-sizing: border-box;
  outline: none;

  color: #0b1c39 !important;
  border: 1px solid lightgrey;
  background: white;
  border-radius: 3.5px;
  width: 100%;
}

/* Images , gifs and videos */
.contact__details__data .contact__details__forms .case__igv__main__cont {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.contact__details__forms .case__igv__main__cont .case__igv__cont {
  display: flex;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  float: left;
  width: 100%;
}
.contact__details__forms .case__igv__main__cont .case__igv__cont .case__igv {
  object-fit: contain;
  height: 70px;
  border-radius: 3px;
  cursor: pointer;
}
/* case team */
.contact__details__data .contact__details__forms .case__team {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.contact__details__forms .case__team .invite__btn {
  color: white;
  text-align: center;
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 5px;
  background: #9bd38c;
  cursor: pointer;
}
.contact__details__forms .case__team .radio__inp__main__cont {
  display: flex;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
}
.contact__details__forms .case__team .radio__inp__cont {
  display: flex;
  column-gap: 0.4rem;
  justify-content: center;
  align-items: center;
}

/* Awards */
.contact__details__data .contact__details__forms .more__awards__btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact__details__data .contact__details__forms .award__form {
  column-gap: 5vmin !important;
}
.contact__details__data .contact__details__forms .award__form .flxl {
  flex: 0.5;
}
.contact__details__data
  .contact__details__forms
  .form
  .inp__fields
  .upload__btn__cont {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
}
.contact__details__forms .form .inp__fields .upload__btn__cont .uploadbtn {
  font-size: 0.86rem;
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 1rem;
  box-sizing: border-box;
}

/* Upload btn */
.aonboarding .mainupload__btn__cont {
  display: flex;
  justify-content: space-between;
}
.aonboarding .mainupload__btn__cont .mainupload__btn {
  outline: none;
  border: none;
  border-radius: 3px;
  padding: 0.5rem 2rem;
  font-family: "Poppins";
  color: white;
  background: #ff656a;
  cursor: pointer;
}

@media screen and (max-width: 950px) {
  .aonboarding .basdetails .basdetails__data .basdetails__data__forms .form {
    flex-direction: column;
  }
  .aonboarding
    .contact__details
    .contact__details__data
    .contact__details__forms
    .form {
    flex-direction: column;
  }
  .contact__details__data .contact__details__forms .case__textarea__cont {
    flex-direction: column;
  }
  .contact__details__data .contact__details__forms .case__statics__form {
    flex-direction: column;
  }
  .contact__details__data .contact__details__forms .case__statics__form .part {
    column-gap: 0.5rem;
  }
}
