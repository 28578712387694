.ayourprofile__cont {
  display: flex;

  /* background: #e5e5e5; */
  background: #f6f6f6;
  min-height: 100vh;
}
.ayourprofile {
  flex: 0.82;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 3rem 1.3%;
  box-sizing: border-box;
  width: 100%;
  background: #f6f6f6;
}
@media screen and (max-width: 950px) {
  .ayourprofile {
    flex: 1;
  }
}
.ayourprofile ::placeholder {
  font-family: "Poppins";
  color: #cfcfcf !important;
}
.ayourprofile .section__cont {
  display: flex;
  flex-direction: column;
  row-gap: 0.6rem;
}
.ayourprofile .section__cont .section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.ayourprofile .section__cont .section .part__right {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1.3rem;
}
.ayourprofile .section__cont .section .part__right .img__cont {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.ayourprofile .section__cont .section .part__right .img__cont .msg__not {
  object-fit: contain;
  height: 16px;
}
.ayourprofile .section__cont .section .part__right .img__cont .dotg {
  position: absolute;
  right: -10%;
  top: -10%;
}
.ayourprofile .section__cont .section .part__right .date__day {
  padding: 0.2rem 0.4rem;
  border: 1px solid #828282;
  border-radius: 3px;
}

/* Basic details */
.ayourprofile .basic__details {
  display: flex;
  flex-direction: column;
  row-gap: 0.7rem;
  width: 100%;
}
.ayourprofile .basic__details .basic__details__data {
  display: flex;
  flex-direction: column;
  row-gap: 0.7rem;
  text-align: left;
  margin: 1rem 0% 1rem 5%;
}

.ayourprofile .basic__details .basic__details__data .basic__details__forms {
  display: flex;
  flex-direction: column;
  row-gap: 1.8rem;

  width: 100%;
}
.ayourprofile
  .basic__details
  .basic__details__data
  .basic__details__forms
  .form {
  display: flex;
  row-gap: 0.5rem;
  column-gap: 1rem;
}
.basic__details__data .basic__details__forms .form .inp__fields {
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
}

.basic__details__data .basic__details__forms .form .flxxs {
  flex: 0.1;
}
.basic__details__data .basic__details__forms .form .flxs {
  flex: 0.18;
}
.basic__details__data .basic__details__forms .form .flxl {
  flex: 0.4;
}
.basic__details__data .basic__details__forms .form .flxs25 {
  flex: 0.25;
}
.basic__details__data .basic__details__forms .form .inp__fields .input,
.basic__details__data .basic__details__forms .form .inp__fields .select {
  padding: 0.4rem 0.4rem;
  outline: none;
  font-family: "Poppins";
  color: #0b1c39 !important;
  border: 1px solid lightgrey;
  background: white;
  border-radius: 3.5px;
}

.basic__details__data
  .basic__details__forms
  .form
  .inp__fields
  .input::placeholder,
.basic__details__data
  .basic__details__forms
  .form
  .inp__fields
  .select
  ::placeholder {
  color: #9d9d9d;
}

.basic__details
  .basic__details__data
  .basic__details__forms
  .aboutme__overview
  .part1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.basic__details
  .basic__details__data
  .basic__details__forms
  .aboutme__overview {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
}
.basic__details
  .basic__details__data
  .basic__details__forms
  .aboutme__overview
  .aboutme__textarea {
  width: 100%;
  height: 8rem;
  outline: none;
  padding: 0.5rem;
  box-sizing: border-box;
  color: #0b1c39 !important;
  border: 1px solid lightgray;
}

.basic__details .basic__details__data .save__add__cont {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.basic__details .basic__details__data .save__add__cont .save__btn {
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  font-family: "Poppins";
  background: #ff656a;
  outline: none;
  border: 1px solid lightgray;
  color: white;
  border-radius: 3px;
  cursor: pointer;
}

/* Credited works */
.ayourprofile .creditedworks {
  display: flex;
  flex-direction: column;
  row-gap: 0.7rem;
  width: 100%;
  box-sizing: border-box;
}
.ayourprofile .creditedworks .creditedworks__data {
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  row-gap: 0.8rem;
  text-align: left;
  margin: 1rem 0% 1rem 5%;
  overflow: hidden;
}
.ayourprofile .creditedworks .creditedworks__data2 {
  margin: 1rem 0% 1rem 5%;
}

.creditedworks .creditedworks__data .creditedwork {
  display: flex;
  flex-direction: column;
  column-gap: 1rem;
  row-gap: 0.5rem;
  width: 100%;
}

.creditedworks .creditedworks__data .creditedwork .creditedwork__img__cont {
  position: relative;
  /* background: purple; */
  width: 100%;
}
.creditedworks__data
  .creditedwork
  .creditedwork__img__cont
  .creditedwork__imgtext {
  position: absolute;
  color: white;
  padding: 0.2rem 0.5rem;
  bottom: 10px;
}
.creditedworks .creditedworks__data .creditedwork .creditedwork__img {
  object-fit: contain;
  height: auto;
  width: 100%;
  max-width: 250px;
}
.creditedworks .creditedworks__data .creditedwork .creditedwork__detail {
  /* padding: 0.5rem; */
  text-align: left;
}
@media screen and (max-width: 600px) {
  .ayourprofile .creditedworks .creditedworks__data {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  .ayourprofile .creditedworks .creditedworks__data .creditedwork {
    width: 100%;
    max-width: 250px;
  }
}

@media screen and (max-width: 950px) {
  .ayourprofile
    .basic__details
    .basic__details__data
    .basic__details__forms
    .form {
    flex-direction: column;
  }
}
