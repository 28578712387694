.trustedbrands {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
 
  /* width: 1128px; */
  width: 100%;
  
  box-sizing: border-box;
}
.trustedbrands__title {
  color: #0b1c39;
  font-size: 1.5rem;
  font-weight: 500;
}
.trustedbrands__logos {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  flex-wrap: wrap;
  align-items: center;
  float: left;
  width: 100%;
  
  /* background: pink; */
}
.trustedbrands__logos #img1 {
  width: 170px;
  height: 135px;
  margin-top:30px;
  background: url("../images/applecombine.png") no-repeat 20px 45px ;
  display: inline-block;
  border: 0.5px solid #F1F1F1;
  transition: 0.5s;
}
.trustedbrands__logos #img1:hover {
  width: 170px;
  height: 135px;
  margin-top:30px;
  background: url("../images/applecoloured.png") no-repeat 20px 45px #FFF6F6  ;

}

.trustedbrands__logos #img2 {
  width: 170px;
  height: 135px;
  margin-top:30px;
  background: url("../images/teamV.png") no-repeat 20px 45px ;
  display: inline-block;
  border: 0.5px solid #F1F1F1;
  transition: 0.5s;
}
.trustedbrands__logos #img2:hover {
  width: 170px;
  height: 135px;
  margin-top:30px;
  background: url("../images/variancecoloured.png") no-repeat 20px 45px #FFF6F6  ;
}
.trustedbrands__logos #img3 {
  width: 170px;
  height: 135px;
  margin-top:30px;
  background: url("../images/brandup.png") no-repeat  15px 45px;
  display: inline-block;
  border: 0.5px solid #F1F1F1;
  transition: 0.5s;
}
.trustedbrands__logos #img3:hover {
  width: 170px;
  height: 135px;
  margin-top:30px;
  background: url("../images/brandupcoloured.png") no-repeat 15px 45px #FFF6F6 ;
}
.trustedbrands__logos #img4 {
  width: 170px;
  height: 135px;
  margin-top:30px;
  background: url("../images/klick.png") no-repeat 30px 20px;
  display: inline-block;
  border: 0.5px solid #F1F1F1;
  transition: 0.5s;
  
}
.trustedbrands__logos #img4:hover {
  width: 170px;
  height: 135px;
  margin-top:30px;
  background: url("../images/klickcoloured.png") no-repeat 30px 20px #FFF6F6  ;
}
.trustedbrands__logos #img5 {
  width: 170px;
  height: 135px;
  margin-top:30px;
  background: url("../images/lexicon_black.png") no-repeat 20px 40px  ;
  display: inline-block;
  border: 0.5px solid #F1F1F1;
  transition: 0.5s;
}
.trustedbrands__logos #img5:hover {
  width: 170px;
  height: 135px;
  margin-top:30px;
  background: url("../images/lexicon.png") no-repeat 20px 40px #FFF6F6 ;
}
.trustedbrands__logos #img6 {
  width: 170px;
  height: 135px;
  margin-top:30px;
  background: url("../images/gempundit.png") no-repeat 20px 10px ;
  display: inline-block;

  border: 0.5px solid #F1F1F1;
  transition: 0.5s;
}

.trustedbrands__logos #img6:hover {
  width: 170px;
  height: 135px;
  margin-top:30px;
  background: url("../images/gemcoloured.png") no-repeat 20px 10px  #FFF6F6 ;
}
.trustedbrands__logos #img7 {
  width: 170px;
  height: 135px;
  margin-top:30px;
  background: url("../images/amity.png") no-repeat 37px 45px  ;
  display: inline-block;
 
  
  border: 0.5px solid #F1F1F1;
}
.trustedbrands__logos #img7:hover {
  width: 170px;
  height: 135px;
  margin-top:30px;
  background: url("../images/amitycoloured.png") no-repeat 37px 45px #FFF6F6 ;
}
.trustedbrands__logos #img8 {
  width: 170px;
  height: 135px;
  margin-top:30px;
  background: url("../images/aethonblack.png") no-repeat 15px 50px;
  display: inline-block;
  border: 0.5px solid #F1F1F1;
  transition: 0.5s;
}
.trustedbrands__logos #img8:hover {
  width: 170px;
  height: 135px;
  margin-top:30px;
  background: url("../images/aethoncoloured.png") no-repeat 15px 50px #FFF6F6  ;
}
.trustedbrands__logos #img9 {
  width: 170px;
  height: 135px;
  margin-top:30px;
 
  background: url("../images/decathlon.png") no-repeat 22px 44px;
  display: inline-block;
  border: 0.5px solid #F1F1F1;
  transition: 0.5s;
}
.trustedbrands__logos #img9:hover {
  width: 170px;
  height: 135px;
  margin-top:30px;
  background: url("../images/decathloncoloured.png") no-repeat 22px 44px #FFF6F6  ;
}
.trustedbrands__logos #img10 {
  width: 170px;
  height: 135px;
  margin-top:30px;
  margin-right:30px ;
  background: url("../images/fertilityb.png") 20px 40px no-repeat ;
  
  display: inline-block;
  border: 0.5px solid #F1F1F1;
  transition: 0.5s;
}
.trustedbrands__logos #img10:hover {
  width: 170px;
  height: 135px;
  margin-top:30px;
  background: url("../images/fertility.png") no-repeat 20px 40px #FFF6F6  ;
}

@media only screen and (max-width:769px){
  .trustedbrands__logos img{
    margin-top:3rem ;
  }
  .trustedbrands__logos #img6 {
    margin-left: 0px;
  }
}
@media only screen and (max-width:700px){
  .trustedbrands__logos img{
    margin-top:2rem ;
  }
  .trustedbrands__logos {
    flex-direction: column;
    margin: auto;
  }
}
/* .trustedbrands__logos img {
  object-fit: cover;
  max-height: 50px;
} */
