.pricing__cont .pricing__top__cont {
  padding: 0 9.5rem;
  background: #0b1c38;
}
.pricing__cont .pricing__top {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
  padding: 6rem 0;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .pricing__cont .pricing__top__cont {
    padding: 0 5%;
  }
}
.pricing__cont .pricing {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  margin: 3rem 0 5rem 0;
  width: 100%;
}
.pricing__cont .pricing .pricing__title {
  font-weight: 500;
  color: #0b1c39 !important;
}
.pricing__cont .pricing .pricing__title .color__orange {
  font-weight: 600;
}
.pricing__cont .pricing .pricing__filters__cont {
  margin: 0.5rem 5% 1rem 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
}
.pricing__cont .pricing .pricing__filters__cont::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 550px) {
  .pricing__cont .pricing .pricing__filters__cont {
    justify-content: flex-start;
  }
  .pricing .pricing__filters__cont .pricing__filters .pricing__filter {
    padding: 0.1rem 0.2rem;
    font-size: 0.8rem;
  }
}
.pricing__cont .pricing .pricing__filters__cont .pricing__filters {
  display: flex;
  column-gap: 0.5rem;
  background: #fff6f6;
  padding: 0 1rem;
  border-radius: 4rem;
}
.pricing .pricing__filters__cont .pricing__filters .pricing__filter {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  padding: 0.6rem 1rem;
  margin: 0.5rem 0;
  border-radius: 4rem;

  cursor: pointer;
}
.pricing .pricing__filters__cont .pricing__filters .active {
  font-weight: 600;
  background: white;
  box-shadow: 0px 4px 20px rgba(14, 14, 14, 0.07);
}
.pricing__cont .pricing .pricing__table__cont {
  overflow-x: auto;
  margin: 0 9.5rem;
}
.pricing__cont .pricing .pricing__table {
  margin: 0 auto;
}
@media screen and (max-width: 1024px) {
  .pricing__cont .pricing .pricing__table__cont {
    margin: 0 5%;
  }
}

.pricing__cont .pricing table,
.pricing__cont .pricing tr,
.pricing__cont .pricing td {
  border: 1px solid lightgray;
  border-left: none;
  border-collapse: collapse;
  border-spacing: 0;
  padding: 1rem;
}

.pricing__table .table__heading__row .heading__row__part {
  text-align: left;
  padding: 1rem;
  border-left: 1px solid lightgray;
  vertical-align: bottom;
}
.pricing__table .table__heading__row .heading__row__part:nth-child(1) {
  border-left: none;
}
.pricing__table .table__heading__row .heading__row__part th,
.pricing__table .table__heading__row .heading__row__part td {
  padding: 1rem;
}

.pricing__cont .pricing .pricingcards {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  margin: 0 9.5rem;
  text-align: left;
}
.pricing__cont .pricing .pricingcards .pricingcard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid lightgray;
  border-radius: 1px;
  padding: 2rem;
}
.pricing__cont .pricing .pricingcards .pricingcard__desc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.45rem;
  margin: 2.5rem 0;
}
.pricing__cont .pricing .pricingcards .pricingcard__desc .tick__desc {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
}

@media screen and (max-width: 1024px) {
  .pricing__cont .pricing .pricingcards {
    margin: 0 5%;
  }
}
@media screen and (max-width: 768px) {
  .pricing__cont .pricing .pricingcards .pricingcard {
    padding: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .pricing__table__cont {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .pricing__cont .pricing .pricingcards {
    display: none;
  }
}
