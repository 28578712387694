:root {
    --borderColor: #E7E3E9;
    --color: #9A95A7;
    --darkColor: #525464
}

.consult > .box > p {
    margin: 0;
    padding: 0;
}

.consult > .box > .fontStart {
    text-align: start;
}

.consult > .box > .darkText {
    color: var(--darkColor);
    font-weight: bold;
}


.consult {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    padding: 20px 30px;
}

.consult > .box {
    width: 350px;
    height: 277px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    flex-shrink: 0;
    border: 1px solid var(--borderColor);
    border-radius: 20px;
    padding: 30px;
}

.consult > .box > *:not(:first-child) {
    color: var(--darkColor)
}

.consult > .box > h6 {
    margin: 0;
}

.consult > .box > div:last-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    width: 100%;
}

.consult > .box > div:last-child > button {
    border: 1px solid var(--borderColor);
    background-color: transparent;
    padding: 10px;
    border-radius: 10px;
}

.consult > .box > div:last-child > button:hover {
    background-color: var(--borderColor);
}