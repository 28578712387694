.aproposal__cont {
  display: flex;

  /* background: #e5e5e5; */
  background: #f6f6f6;
  min-height: 100vh;
}
.aproposal .done__btn {
  padding: 0.5rem 0.8rem 0.5rem 0.8rem;
  box-sizing: border-box;
  background: #ff656a;
  outline: none;
  font-family: "Poppins";
  border: 1px solid lightgray;
  color: white;
  border-radius: 3px;
  cursor: pointer;
}
.aproposal {
  flex: 0.82;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 3rem 1.3%;
  box-sizing: border-box;
  width: 100%;
  background: #f6f6f6;
}
@media screen and (max-width: 950px) {
  .aproposal {
    flex: 1;
  }
}
.aproposal ::placeholder {
  font-family: "Poppins";
  color: #cfcfcf !important;
}

.aproposal ::placeholder {
  font-family: "Poppins";
}
.aproposal .aproposal__title {
  font-weight: bolder;
  color: #5f5f5f;
}
.aproposal .section__cont {
  display: flex;
  flex-direction: column;
  row-gap: 0.6rem;
}
.aproposal .section__cont .section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.aproposal .section__cont .section .part__right {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1.3rem;
}
.aproposal .section__cont .section .part__right .img__cont {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.aproposal .section__cont .section .part__right .img__cont .msg__not {
  object-fit: contain;
  height: 16px;
}
.aproposal .section__cont .section .part__right .img__cont .dotg {
  position: absolute;
  right: -10%;
  top: -10%;
}
.aproposal .section__cont .section .part__right .date__day {
  padding: 0.2rem 0.4rem;
  border: 1px solid #828282;
  border-radius: 3px;
}

/* Title & overview */
.aproposal .tao__details {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.aproposal .tao__details .tao__details__data {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  text-align: left;
  margin: 1rem 0% 1rem 5%;
}
.tao__details__data .tao__level1 {
  display: flex;
  column-gap: 2rem;
  row-gap: 0.5rem;
}

@media screen and (min-width: 950px) {
  .tao__details__data .wfixform {
    flex-wrap: wrap;
    float: left;
    width: 100%;
  }
  /* .tao__details__data .wfixform .wfixinp__cont {
    width: 230px;
  } */
  .tao__details__data .wfixform .labelcrosscont {
    width: 235px;
  }
  .tao__details__data .wfixform .wfixinp {
    width: 235px;
  }
}

.flx05 {
  flex: 0.05;
}
.flx1 {
  flex: 0.1;
}
.flx2 {
  flex: 0.2;
}
.flx3 {
  flex: 0.3;
}
.flx4 {
  flex: 0.4;
}
.flx5 {
  flex: 0.5;
}
.flx6 {
  flex: 0.6;
}
.flx7 {
  flex: 0.7;
}
.flx8 {
  flex: 0.8;
}
.flx9 {
  flex: 0.9;
}
.flx10 {
  flex: 1;
}
.tao__details__data .tao__level1 .part {
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
}
.tao__details__data .tao__level1 .part .lvlinput {
  padding: 0.4rem 0.4rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Poppins";
  color: #0b1c39 !important;
  border: 1px solid lightgrey;
  background: white;
  border-radius: 3.5px;
}
.tao__details__data .tao__level1 .part .lvltextarea {
  height: 8rem;
  width: 100%;
  border-radius: 3.5px;
}

.tao__details__data .tao__level1 .part .lvlinput::placeholder {
  color: #9d9d9d;
}

.tao__details .tao__details__data .case__statics__cont {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.tao__details .tao__details__data .case__statics__title {
  display: flex;
  justify-content: space-between;
}
.tao__details .tao__details__data .case__statics__form {
  display: flex;
  justify-content: space-between;
  column-gap: 3rem;
  row-gap: 0.5rem;
  width: 100%;
  /* background: purple; */
}
.tao__details .tao__details__data .case__statics__form .part {
  display: flex;
  justify-content: space-between;
  column-gap: 2rem;
  row-gap: 0.5rem;
  width: 100%;
}
.tao__details .tao__details__data .case__statics__form .part .inp__fields {
  width: 100%;
}
.tao__details .tao__details__data .case__statics__form .part .inpsmall {
  flex: 0.15;
}
.tao__details .tao__details__data .case__statics__form .part .inpbig {
  flex: 0.9;
}
.tao__details
  .tao__details__data
  .case__statics__form
  .part
  .inp__fields
  .input,
.tao__details
  .tao__details__data
  .case__statics__form
  .part
  .inp__fields
  .select {
  padding: 0.4rem 0.4rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Poppins";
  color: #0b1c39 !important;
  border: 1px solid lightgrey;
  background: white;
  border-radius: 3.5px;
  width: 100%;
}
/* Clients & Projects */
.aproposal .cap__details {
  width: 100%;
}
.aproposal .cap__details .cap__details__data {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  text-align: left;
  margin: 1rem 0% 1rem 5%;
}

/* statement of work */
.aproposal .tao__details .sow__details__data {
  flex: 0.8;
  background: purple;
}

.tao__details .tao__details__data .tao__level1 .saw__col {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  column-gap: 1rem;
  width: 100%;
  /* background: purple; */
}
.tao__details .tao__details__data .saw__level1 {
  row-gap: 1rem !important;
}
.tao__details__data .tao__level1 .saw__col .reccur {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  /* background: pink; */
}
.tao__details__data .saw__level1 .saw__textarea {
  height: 8rem;
  border-radius: 3.5px;
}

/* Statement of work deliverables */
.tao__details .left__sowcont {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  margin: 0 !important;
  margin-right: 18% !important;
  /* background: purple; */
}
.tao__details .right__sowcont {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  margin: 0 !important;
  margin-left: 18% !important;
}
@media screen and (max-width: 950px) {
  .tao__details .tao__details__data .case__statics__form {
    flex-direction: column;
  }
  .tao__details .tao__details__data .case__statics__form .part {
    column-gap: 0.5rem;
  }
  .giveflx10 {
    flex: 1 !important;
  }
  .tao__details__data .flxdircolmin {
    flex-direction: column !important;
  }
  .tao__details .tao__details__data .saw__level1 {
    flex-direction: column;
  }
  .tao__details .tao__details__data .tao__level1 .saw__col {
    align-items: flex-end;
  }
  .tao__details .left__sowcont,
  .tao__details .right__sowcont {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
