.Singlebuttoncomponent {
  height: 70px;
  border-radius: 10px;
  border: 1px solid #232c33;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.Singlebuttoncomponent .Singlebuttoncomponent-text {
  line-height: 27;
  font-size: 20px;
  font-weight: 600;
  color: white;
}

@media screen and (max-width: 480px) {
  .Singlebuttoncomponent {
    height: 40px;
  }

  .Singlebuttoncomponent .Singlebuttoncomponent-text {
    line-height: 20.25px;
    font-size: 15px;
  }
}
