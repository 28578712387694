  .brandSidebar{
    width: 250px;
    height: auto;
    background-color: #ffffff;
    border: 1px solid #E7E3E9;
    border-top: 0;
    border-bottom: 0;
    margin-left: auto;
    z-index: 1;
  }
  .brandSidebar > .brandDetails{
      padding: 20px;
      border-bottom: 1px solid #E7E3E9;
      text-align: left;
  }
  .brandSidebar > .brandDetails > .brandLogo> .brandLogoA > img{
      height: 100px;
      width: 100px;
      background: #D9D9D9;
      margin-bottom: 20px;
      border: 0.1px solid #E7E3E9;
  }
  .brandSidebar > .brandDetails > .brandData > .brandName{
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0.02em;
      color: #191E22;
      text-align: left;
      margin-bottom: 0;
  }
  .brandSidebar > .brandDetails > .brandData > .brandProjects{
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      display: flex;
      letter-spacing: 0.02em;
      color: #191E22;
      text-align: left;
      margin-bottom: 2rem;     
  }
  .brandSidebar > .brandDetails > .brandData > .teamMembers > img{
    height: 20px;
    width: 20px;
    margin-right: -4px !important;  
  } 
  .brandSidebar > .brandDetails > .brandData > .teamMembers{
      display: flex;
      text-align: left;
      display: none;
  }
  .brandSidebar > .brandDetails > .brandData > .teamMembers > p{
    margin-left: 13px;
  }
  @media (max-width: 1240px) {
    .brandSidebar {
        display: none;    
    }
  }