/* ConfirmationModal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other elements */
  animation: fadeIn 0.3s ease-in-out; /* Fade-in animation for overlay */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px; /* Ensure the modal has a max width */
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(-30px);
  animation: slideDown 0.3s ease-in-out forwards; /* Slide-down animation for modal */
}

.modal-buttons {
  margin-top: 20px;
}

.modal-buttons button {
  margin: 0 10px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: white;
  border: none;
  color: black;
  border : 2px solid black;
  border-radius: 4px;
  border-color: black;
  transition: background-color 0.3s ease-in-out;
}

.modal-buttons button:hover {
  background-color: black;
  color : white;
  border-color : none;
}

@keyframes slideDown {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
