.discountCouponContainer {
  flex: 0.82 1;
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  padding: 3rem 1.3%;
  box-sizing: border-box;
  width: 100%;
  height: max-content;
}

.discountCouponContainer .discountCouponHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.discountCouponHeader .searchContainer {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 0.5rem;
  gap: 4px;
}

.discountCouponHeader .searchIcon {
  font-size: 1.2rem;
  color: #333;
}

.discountCouponHeader .searchInput {
  border: none;
  outline: none;
  font-size: 1rem;
}

.discountCouponContainer .discountCouponHeader .addButton {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 10px;
  background-color: #000;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.discountCouponContainer .discountCouponHeader .addButton:hover {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}

.discountCouponContainer .table-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  height: 100%;
}
