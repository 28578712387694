.firstcompo {
    display: flex;
    flex-direction: column;
    color: #6E6E6E;
    margin: 2.5% 2.5% 0 2.5%;
}

.case-study-heading {
    background-color: #F8F8F8;
    display: flex;
    align-items: center;
    border-radius: 10px;
    height: 50px;
    padding-left: 42px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #625656;
}

.Brand_working,
.service_working {
    display: flex;
    flex-wrap: wrap;
    margin-left: 42px;
    gap: 0px 5%;
    margin-top: 30px;
}

.field {
    min-width: 20%;
}

.field>select {
    background: #FFFFFF;
    border: 1px solid #C5C5C5;
    border-radius: 3px;
    display: flex;
    width: 100%;
    height: 32px;
}

.field>p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #6E6E6E;
    margin-bottom: 0;
    display: flex;
}

.service_working {
    flex-direction: column;
    align-items: flex-start;
}

.service_working>p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    color: #6E6E6E;
    margin-bottom: 10px;
}

.Services {
    display: flex;
    flex-wrap: wrap;
    gap: 5px 15px;
}

.service {
    background: #FF656A1A;
    border: 1px solid #FF656A33;
    border-radius: 20px;
    padding: 5px 15px;
    font-size: 15px;
    margin-bottom: 5px;
}

.service span {
    margin-right: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #6E6E6E;
}

.service img {
    height: 10px;
    width: 10px;
    cursor: pointer;
}