.input-field-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.input-field-container .input-field-label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
  text-align: start;
}

.input-field-container .input-field-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px;
  font-size: 14px;
  border: 1.5px solid #ccc;
  border-radius: 10px;
  transition: border-color 0.3s;
}

.input-field-container .input-field-wrapper .input-field-input {
  outline: none;
  border: none;
  width: 100%;
}

.input-field-container .input-field-wrapper .sub-placeholder-info {
  color: #ccc;
  white-space: nowrap;
}

.input-field-container .input-field-wrapper:focus-within {
  border-color: black;
}

.input-field-container .checkbox-field-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: start;
}
.input-field-container .checkbox-field-wrapper .checkbox-field-input {
  width: 20px;
  height: 20px;
}

.input-field-container .form-error {
  color: red;
  font-size: 12px;
  text-align: start;
}
