.home_page_comp_six {
    height: fit-content !important;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home_page_comp_six .comp_six_title_sec {
    margin-top: 55px;
    display: flex;
    flex-direction: column;
}

.home_page_comp_six .comp_six_title_sec .comp_six_text1 {
    font-family: "lexend";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    color: #405870;
}

.home_page_comp_six .comp_six_title_sec .comp_six_text2 {
    font-family: "lexend";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #0B1C39;
    width: 390px;
}

.home_page_comp_six .comp_six_card_sec {
    margin-top: 60px;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.home_page_comp_six .comp_six_card_sec .card {
    display: flex;
    flex-direction: row;
    width: 30%;
}

.home_page_comp_six .comp_six_card_sec .card .image_sec {
    height: 60px;
    min-width: 60px;
}

.home_page_comp_six .comp_six_card_sec .card .content_sec .description {
    font-size: 12px;
    margin-top: 5px
}

.home_page_comp_six .comp_six_btn_sec {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
}

.home_page_comp_six .comp_six_btn_sec .comp_six_text3 {
    font-family: "lexend";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #0B1C39;
    margin-top: 12px;
}

.home_page_comp_six .comp_six_btn_sec .sub_sec {
    color: #FF656A;
}

@media screen and (max-width: 768px) {
    .home_page_comp_six .comp_six_title_sec {
        align-items: center;
    }

    .home_page_comp_six .comp_six_title_sec .comp_six_text1 {
        font-size: 24px;
        line-height: 29px;
    }

    .home_page_comp_six .comp_six_title_sec .comp_six_text2 {
        font-size: 14px;
        line-height: 150%;
        margin-top: 20px;
        width: 60%;
    }

    .home_page_comp_six .comp_six_card_sec {
        flex-wrap: unset;
        flex-direction: column;
    }

    .home_page_comp_six .comp_six_card_sec .card {
        flex-direction: column;
        width: unset;
    }

    .home_page_comp_six .comp_six_btn_sec {
        margin-top: 35px;
    }

    .home_page_comp_six .comp_six_btn_sec .comp_six_text3 {
        font-size: 10px;
        line-height: 150%;
        margin-bottom: 20px;
        width: 60%;
    }
}
