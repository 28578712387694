.sapagencies {
  flex: 0.82;
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  padding: 3rem 1.3%;
  box-sizing: border-box;
  width: 100%;
}
.editable-content {
  display: block;
}
.display {
  display: none;
}
.css-menu{
  z-index: 9999;
  background-color: black;
}
.Select-menu {
  position: fixed !important;
}
.Select.is-open {
  z-index: 1000 !important;
}
.marketing-content > td > p {
  background: rgb(233, 226, 226);
  border-radius: 5%;
  text-align: center;
  padding: 2px;
}
.cursor__pointer {
  
  border: none;
}
.sapagencies .sapagencies__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sapagencies .sapagencies__top .sapagencies__data__inpcont {
  display: flex;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 2rem;
  padding: 0.4rem;
  max-width: 300px;
}
.sapagencies .sapagencies__top .sapagencies__data__inpcont .search__icon {
  object-fit: contain;
  height: 15px;
  cursor: pointer;
}
.sapagencies .sapagencies__top .sapagencies__data__inpcont .search__input {
  border: none;
  outline: none;
  font-size: 0.9rem;
  padding: 0 0.5rem;
  box-sizing: border-box;
  color: grey;
  font-family: "Poppins";
}
.sapagencies .sapagencies__top .sap__select {
  padding: 0.25rem 0.4rem;
  box-sizing: border-box;
  outline: none;
  border: 1.5px solid lightgray;
  border-radius: 1.5px;
  font-family: "Poppins";
}

/* .sapagencies .saptable__lists {
 
} */
.sapagencies
  .saptable__lists
  .pricing__table
  .table__heading__row
  .heading__row__part {
  text-align: center;
}
.sapagencies .saptable__lists .pricing__table {
  border-collapse: separate;
  border-spacing: 0 15px;
  width: 100%;
  /* min-width: 800px; */
}
.sapagencies .saptable__lists .pricing__table tr {
  transition: all 0.3s ease;
}
.sapagencies .saptable__lists .pricing__table .table__heading__row:hover {
  background: transparent;
}
.sapagencies .saptable__lists .pricing__table tr:hover {
  background: #ffeef0;
  transition: all 0.3s ease;
}

.sapagencies .saptable__lists .pricing__table tr td {
  vertical-align: middle;
}
.sapagencies .saptable__lists .pricing__table .saptablerow {
  background: #f8f8f8;

  /* background: purple; */
  padding: 1rem 0.2rem !important;
  box-sizing: border-box;
}
.saptable__lists .pricing__table .saptablerow .image__name__cont {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10%;
  font-weight: 600;
}

.sapagencies .saptable__lists .pricing__table tr td .yonobtns {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
}
.saptable__lists .pricing__table tr td .yonobtns .yonobtn {
  font-size: 0.85rem;
  padding: 0.2rem 0.5rem;
  box-sizing: border-box;
  border: 1.5px solid #ffd4d9;
  cursor: pointer;
}
.saptable__lists .pricing__table tr td .yonobtns .activetog {
  font-weight: 600;
  background: #ffd4d9;
}
.saptable__lists .pricing__table .pad1hrow {
  padding: 1.5rem 0.5rem !important;
  box-sizing: border-box;
}
.saptable__lists .pricing__table .pad2row {
  padding: 2rem 0.5rem !important;
  box-sizing: border-box;
}
.saptable__lists .pricing__table .rotate90 {
  transform: rotate(90deg);
}
.saptable__lists .pricing__table .color__orange {
  color: #ff656a !important;
}
.saptable__lists .pricing__table .saptablerow .image__name__cont .saprow__logo {
  /* object-fit: contain; */
  height: 50px;
  width: 50px;
}
.sapagencies .saptable__lists .pricing__table tr .access__dash {
  color: #ff656a !important;
  cursor: pointer;
}

.saptable__lists table tr .textleftfirr {
  text-align: left !important;
}

/* Sap services */
.sapagencies .sapagencies__top .sapagencies__dataselect__cont {
  display: flex;
  align-items: center;
  column-gap: 5%;
  width: 100%;
  /* background: purple; */
}

.sapservicestable table {
  table-layout: fixed;
  width: 100%;
}
.sapservicestable table {
  text-align: left !important;
}
.sapservicestable table td {
  width: 100%;
}

.sapservicesaddedit .saptablerow {
  background: #ffeef0 !important;
}

.vector1 {
  position: relative;
  left: 16.67%;
  right: 33.33%;
  top: 29.17%;
  bottom: 8.33%;

  height: 15px;
  width: 12px;
  left: 8px;
  top: 12px;
  border-radius: 0px;
  border: 2px solid #fd666e;
}

.vector2 {
  position: relative;
  left: 33.33%;
  right: 16.67%;
  top: 8.33%;
  bottom: 25%;

  height: 16px;
  width: 12px;
  left: 4px;
  top: 2px;
  border-radius: 0px;
  border: 2px solid #fd666e;
}
