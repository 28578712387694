.MentorlineupContainer {
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  gap: 80px;
  background-color: white;
  box-sizing: border-box;
}

.MentorlineupContainer .CategoryProductMentorsList {
  display: flex;
  flex-direction: row;
  gap: 40px;
  overflow: auto;
  width: 100%;
}
.MentorlineupContainer .CategoryProductMentorsList::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 480px) {
  .MentorlineupContainer {
    padding: 30px 0 30px 30px;
    align-items: start;
    gap: 40px;
  }

  .MentorlineupContainer .CategoryProductMentorsList {
    gap: 10px;
  }
}
