@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");

* {
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}
.App {
  text-align: center;
  font-family: "lexend";
  display: flex;
  flex-direction: column;
}
a {
  text-decoration: none;
}
.color__black {
  color: #191e22;
}

p {
  margin-bottom: 0 !important;
}

.joyride-beacon {
  display: none !important;
}
