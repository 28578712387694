.sapfilters {
  flex: 0.82;
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  padding: 3rem 1.3%;
  box-sizing: border-box;
  width: 100%;
}
.sapfilters .sapfilter__types {
  display: flex;
  column-gap: 6%;
  width: 100%;
}
.sapfilters .sapfilter__types .sapfilter__type {
  font-size: 0.85rem;
  padding: 0.5rem 2rem 0.5rem 0;
  box-sizing: border-box;
  cursor: pointer;
}
.sapfilters .sapfilter__types .active {
  font-weight: 600;
  border-bottom: 2px solid #ff7f84;
}

.sapfilters input::placeholder {
  color: grey;
}
.sapfilters .sapfilter__datas {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
.sapfilters .sapfilter__datas .sapfilter__data {
  display: none;
  flex-direction: column;
  row-gap: 2rem;
}
.sapfilters .sapfilter__datas .activetab {
  display: flex !important;
}
.sapfilters .sapfilter__datas .sapfilter__data__inpcont {
  display: flex;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 2rem;
  padding: 0.4rem;
  max-width: 300px;
}
.sapfilters .sapfilter__datas .sapfilter__data__inpcont .search__icon {
  object-fit: contain;
  height: 15px;
  cursor: pointer;
}
.sapfilters .sapfilter__datas .sapfilter__data__inpcont .search__input {
  border: none;
  outline: none;
  font-size: 0.9rem;
  padding: 0 0.5rem;
  box-sizing: border-box;
  color: grey;
  font-family: "Poppins";
}
.sapfilters
  .sapfilter__datas
  .sapfilter__data__inpcont
  .search__input::placeholder {
  color: lightgray;
}

.sapfilters .sapfilter__datas .tags {
  display: flex;
  column-gap: 0.7rem;
  row-gap: 0.7rem;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  float: left;
  width: 100%;
}
.sapfilters .sapfilter__datas .tags .tag {
  font-size: 0.8rem;
  border-radius: 3px;
  padding: 0.5rem 2.5rem;
  box-sizing: border-box;
  border-radius: 3rem;
  color: rgb(97, 97, 97);
  background: #f8f8f8;
  /* cursor: pointer; */
  position: relative;
}
.sapfilters .sapfilter__datas .tags .tag .redcross {
  display: none;
  object-fit: contain;
  height: 8.5px;
  position: absolute;
  right: 10%;
  top: 50%;
  transform: translate(50%, -50%);
  cursor: pointer;
}
.sapfilters .sapfilter__datas .tags .tag:hover .redcross {
  display: block;
  object-fit: contain;
  height: 8.5px;
  position: absolute;
  right: 10%;
  top: 50%;
  transform: translate(50%, -50%);
  cursor: pointer;
}

.sapfilters .sapfilter__datas .sapfilter__data__addcont {
  display: flex;
  align-items: center;
  max-width: 300px;
}
.sapfilters .sapfilter__datas .sapfilter__data__addcont .add__input {
  border: none;
  outline: none;
  font-size: 0.9rem;
  padding: 0.5rem;
  box-sizing: border-box;
  border: 1.5px solid lightgray;
  border-radius: 2px;
  color: grey;
  font-family: "Poppins";
  width: 100%;
}
.sapfilters
  .sapfilter__datas
  .sapfilter__data__addcont
  .add__input::placeholder {
  color: lightgrey;
}
.sapfilters .sapfilter__datas .sapfilter__data__addcont .add__btn {
  padding: 0.4rem 1rem;
  border: none;
  outline: none;
  background: transparent;
  color: #ff7f84;
  font-weight: 600;
  cursor: pointer;
  font-family: "Poppins";
}
