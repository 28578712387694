.componentFour{
    width: 1240px;
    margin: 0 auto;
    border: 1px solid #E7E3E9;
    border-bottom: 0;
}
.componentFour .headingSection{
    display: table;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #E7E3E9;
    width: 100%;
    text-align: left;
}
.componentFour .headingSection > h2{
    height: 100px;
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    font-size: 25px;
    font-weight: 400;
    line-height: 60px;
    padding-left: 20px;
}

.ctaSection{
    display: flex;
    height: 490px;
    background-color: #191E22;
    justify-content: space-around;
}
 .ctaSection > .leftSection{
    width: 740px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
 .ctaSection > .leftSection > h2{
    color: #F8F8F8;
    font-size: 45px;
    font-weight: 300;
    line-height: 70px;
    text-align: left;
    padding: 50px;
    padding-right: 0;
}
 .ctaSection > .leftSection > button{
    font-size: 14px;
    line-height: 20px;
    padding: 10px 20px;
    margin-right: 50px;
    border: 1px solid #E7E3E9;
    color: #F8F8F8;
    background: #191E22;
    margin-left: 50px;
    width: 137px;    
}
 .ctaSection > .rightSection{
    width: 500px;
}
 .ctaSection > .rightSection > img{
    padding: 50px 50px 50px 80px;
}
@media screen and (min-width: 1500px){
    .componentFour{
        width: 84%;
    }
    .componentFour > .ctaSection > .leftSection{
        width: 800px;
    }
}
@media screen and (max-width: 1240px){
    .componentFour{
        width: 100%;
        margin: 0 auto;
        border: 1px solid #E7E3E9;
        border-bottom: 0;
    }
    .componentFour > .ctaSection{
        display: flex;
        height: 520px;
        background-color: #191E22;
    }    
    .componentFour > .ctaSection > .leftSection{
        width: 55%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
    }
    .componentFour > .ctaSection > .rightSection{
        width: 45%;
    }
}
@media screen and (max-width: 1000px){
    .componentFour{
        width: 100%;
    }
    .componentFour > .ctaSection{
        flex-direction: column-reverse;
        height: auto;
    }
    .componentFour > .ctaSection > .leftSection{
        width: auto;
    }
    .componentFour > .ctaSection > .leftSection > h2{
        text-align: center;
        padding: 0 50px;
    }
    .componentFour > .ctaSection > .leftSection > button{
        margin: 20px auto 50px auto;
    }
    .componentFour > .ctaSection > .rightSection{
        width: auto;
    }
    .componentFour > .ctaSection > .rightSection > img{
        padding: 50px;
        margin: 0 auto;
        min-width: 370px;
        width: 65%;
    }
}
@media screen and (max-width: 600px){
    .componentFour > .ctaSection > .leftSection > h2{
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 300;
        line-height: 40px; /* 160% */
    }
}