.userActivityPage {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: start;
  width: 100%;
}

.flexBetweenRadius10 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e0e0e0;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  font-weight: 500;
  font-size: 18px;
  padding: 0 20px;
  height: 60px;
  cursor: pointer;
}

.deactiveTab {
  background-color: white;
}

.width-full {
  width: 100%;
}

.icon20size {
  width: 20px;
  height: 20px;
  border-radius: 2px;
}

.userActivityPage .ActivityTabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 20px;
}


.userActivityPage .filterSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  width: 100%;
}


.userActivityPage .filterTab {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 20px;
  font-size: 18px;
}

.userActivityPage .filterTab .filterIcon{
  display: flex;
  justify-content: start;
  gap: 20px;
  align-items: center;
}

.userActivityPage .filterTab .filteringOption {
  display: flex;
  justify-content: start;
  gap: 20px;
  align-items: center;
}


.userActivityPage .filterTab .selectedFilters {
  display: flex;
  gap: 20px;
  justify-self: start;
  align-items: center;
}

.userActivityPage .filterTab .selectedFilters .singleFilter {

  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #f7f7f7;
  background-color: #f7f7f7;
  border-radius: 20px;
  padding: 7px 15px;
  color: black;
  cursor: pointer;
  gap: 10px;
}

.userActivityPage .filterTab .selectedFilters .singleFilter:hover .crossIcon{
  display: block;
}



.userActivityPage .filterTab .filterTabDropdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  font-size: 18px;
  color: #b1b1b1;
  border-radius: 30px;
  border: 1px solid #f7f7f7;
  padding: 7px 15px;
  background-color: #F7F7F7;
  position: relative;
}

.userActivityPage .filterTab .filterTabDropdown .variousOptions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}
.userActivityPage .filterTab .filterTabDropdown .variousOptions > div {

  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #E0E0E0;
  border-radius: 20px;
  padding: 7px 15px;
  color: black;
  cursor: pointer;
}

.userActivityPage .filterTab .filterTabDropdown .variousOptions > div:hover {
  background-color: #F7F7F7;
}

.userActivityPage .selectAllOption {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.userActivityPage .filterTab .singleFilter.PoolCategory {
  border-color: #E9E000;
  background-color: #FFFB95;
}

.userActivityPage .filterTab .singleFilter.PoolOwner {
  border-color: #FFA8A8;
  background-color: #FFD6D6;
}

.userActivityPage .filterTab .singleFilter.Status {
  border-color: #5182FF;
  background-color: #BACDFF;
}

