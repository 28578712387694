.uaregister__cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.uaregister__cont .uaregister__heading {
  background: #ffebec;
  width: 100%;
  padding: 2rem 4%;
  box-sizing: border-box;
}
.uaregister__cont .uaregister {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 2rem;
  box-sizing: border-box;
  padding: 4rem 4%;
  text-align: left;
  width: 100%;
}

.uaregister .rbform {
  display: flex;
  justify-content: space-between;
  row-gap: 1rem;
  column-gap: 1.5rem;
  width: 100%;
}
.uaregister .rbform .part {
  flex: 0.3;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
}
.uaregister .rbform .part .rbinput,
.uaregister .rbform .part .select {
  padding: 0.4rem 0.5rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Poppins";
  color: #9d9d9d;
  border: 1px solid lightgrey;
  background: white;
  border-radius: 4px;
}
.uaregister .rbform .part .mobnocont {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}
.uaregister .rbform .part .mobnocont .countrycode {
  flex: 0.1;
  width: 100%;
  text-align: center;
}
.uaregister .rbform .part .mobnocont .mobno {
  flex: 0.9;
  width: 100%;
}
.uaregister .rbform .part .dobcont {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}
.uaregister .rbform .part .dobcont .dobpart {
  flex: 0.33;
  width: 100%;
  text-align: center;
}
.uaregister .rbform .part .dobcont .dobpart::placeholder {
  color: rgb(197, 197, 197);
}
.uaregister .rbform .part .optcont {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}
.uaregister .rbform .part .optcont .optpart {
  flex: 0.25;
  width: 100%;
  text-align: center;
}
.uaregister .rbform .part .optcont .optpart::placeholder {
  color: rgb(197, 197, 197);
}
.uaregister .rbform .part .my__inpcont {
  display: flex;
  column-gap: 0.5rem;
}
.uaregister .rbform .part .my__inpcont .my__inp {
  flex: 0.5;
  text-align: center;
  padding: 0.4rem 0;
  box-sizing: border-box;
  outline: none;
  font-family: "Poppins";
  color: #9d9d9d;
  border: 1px solid lightgrey;
  background: white;
  border-radius: 4px;
}
.uaregister .rbform .part .my__inpcont .my__inp ::placeholder {
  color: rgb(197, 197, 197);
}
.uaregister .rbform .verifyotpcont {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  row-gap: 0.5rem;
  align-items: flex-end;
}
.uaregister .rbform .verifyotpcont .verifyoptstatuscont {
  display: flex;
  flex-direction: column;

  row-gap: 0.8rem;
}
.uaregister .rbform .verifyotpcont .verifyoptstatuscont .verifyoptstatus {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
}
.uaregister .verifyotpcont .verifyoptstatuscont .verifyoptstatus .optstatusimg {
  object-fit: contain;
  max-height: 20px;
}
.uaregister .mob__ver {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}
.uaregister .mob__ver .mob__ver__img {
  object-fit: contain;
  max-height: 20px;
}

.uaregister .email__form {
  display: flex;
  /* justify-content: space-between; */
  align-items: flex-end;
  row-gap: 1rem;
  column-gap: 1.5rem;

  width: 100%;
}
.uaregister .email__form .part {
  flex: 0.3;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
}
.uaregister .email__form .part .rbinput {
  padding: 0.4rem 0.5rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Poppins";
  color: #9d9d9d;
  border: 1px solid lightgrey;
  background: white;
  border-radius: 4px;
}
.uaregister .gender__form {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
}
.uaregister .gender__form .gf__inps__cont {
  display: flex;
  column-gap: 1rem;
}
.uaregister .gender__form .gf__inps__cont .gf__inp__cont {
  display: flex;
  align-items: center;
  column-gap: 0.2rem;
}
.uaregister .tcdetails {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.uaregister .choosespe {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  width: 100%;
}
.uaregister .choosespe .spe__details {
  display: flex;
  column-gap: 1.5rem;
  row-gap: 1rem;
  justify-content: space-between;
}
.uaregister .choosespe .spe__details .part1 {
  flex: 0.3;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 0.8rem;
}
.uaregister .choosespe .spe__details .part2 {
  flex: 0.7;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 0.8rem;
}
.uaregister .choosespe .spe__details .level {
  display: flex;
  justify-content: space-between;
  column-gap: 0.5rem;
  width: 100%;
}
.uaregister .choosespe .spe__details .select {
  padding: 0.4rem 0.5rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Poppins";
  color: #9d9d9d;
  border: 1px solid lightgrey;
  background: white;
  border-radius: 4px;
  width: 100%;
}
.uaregister .choosespe .spe__details .spelists {
  display: flex;
  justify-content: space-between;
  /* align-items: flex-end; */
  width: 100%;
}
.uaregister .choosespe .spe__details .spelist {
  margin-left: 20px;
}
.uaregister .yoe__cont {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  width: 100%;
}
.uaregister .yoe__cont .part .level {
  display: block;
  margin-bottom: 0.5rem;
}
.uaregister .yoe__cont .part .rbinput,
.uaregister .yoe__cont .part .select {
  padding: 0.4rem 0.5rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Poppins";
  color: #9d9d9d;
  border: 1px solid lightgrey;
  background: white;
  border-radius: 4px;
}
.uaregister .notecont {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1.5rem;
  box-sizing: border-box;
  border-radius: 1px;
  background: #f8f8f8;
}
@media screen and (max-width: 768px) {
  .uaregister .rbform {
    flex-direction: column;
  }
  .uaregister .email__form {
    flex-direction: column;
  }
  .uaregister .choosespe .spe__details {
    flex-direction: column;
  }
  .uaregister .choosespe .spe__details .spelists {
    flex-direction: column;
  }
}
