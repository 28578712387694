.LinkInUrlPage {
    position: relative;
}
  
  .LinkedInUrlContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    animation: fadeIn 1s ease-in-out;
    z-index: 999;
    width: 50%;
    border: 2px solid black;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
  }
  
  .LinkedInUrlContainer .input-group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .LinkedInUrlContainer .input-field {
    padding: 10px;
    margin-bottom: 10px;
    border: 2px solid #0073b1;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    width: 100%;
  }
  
  .LinkedInUrlContainer  .input-field:focus {
    border-color: #005582;
    box-shadow: 0 0 10px rgba(0, 115, 177, 0.5);
  }
  
  .LinkedInUrlContainer  .connect-button {
    padding: 10px 20px;
    background-color: #0073b1;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, transform 0.2s;
  }
  
  .LinkedInUrlContainer  .connect-button:hover {
    background-color: #005582;
    transform: scale(1.05);
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  