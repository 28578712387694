.agency__profile .agency__profile_header {
  background: linear-gradient(180deg, #dfe9f3 0%, #f7fafd 100%);
  display: flex;
  justify-content: space-between;
  padding: 65px 10%;
}

.agency__profile_header .left {
  display: flex;
  align-items: flex-start;
  gap: 45px;
}

.agency__profile_header .agency_logo {
  width: 100px;
  border-radius: 5px;
}
.agency__profile_header .agencylogoInitials{
  background-color: black;
  font-size: 40px;
  color: white;
  height: 70px !important;
  width: 70px  !important;
  padding: 3px 0;
}

.agency__profile_header .info_div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 6px;
  margin-bottom: 8px;
}

.agency__profile_header .agency_name {
  font-family: "lexend";
  font-weight: 700;
  font-size: 20px;
  color: #405870;
  margin: 0;
}

.agency__profile_header .verified_icon {
  width: 22px;
}

.agency__profile_header .agency_website {
  font-size: 14px;
  color: #5c7793;
  margin: 0;
  text-align: start;
}

.agency__profile_header .agency_infos {
  display: flex;
  gap: 45px;
  margin-top: 18px;
}

.agency__profile_header .agency_infos .agency_info {
  display: flex;
  gap: 16px;
  align-items: center;
}

.agency__profile_header .agency_infos img {
  width: 22px;
}

.agency__profile_header .agency_infos p {
  font-family: "lexend";
  font-weight: 500;
  font-size: 14px;
  margin: 0;
}

.agency__profile_header .right {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.agency__profile_header .right button {
  font-family: "lexend";
  font-size: 14px;
  border-radius: 3px;
}


.agency__profile_content {
  padding: 0 10%;
}

.agency__profile_tags {
  padding: 30px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.agency__profile_tag {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #0b1c39;
  background: #e8eff7;
  padding: 10px 20px;
  border-radius: 44px;
}

.agency__profile_description {
  font-size: 14px;
  line-height: 17px;
  text-align: start;
  margin-bottom: 35px;
  font-weight: 400;
}

.agency__profile_heading {
  font-weight: 700;
  font-size: 20px;
  color: #0b1c39;
  padding: 15px 0;
  text-align: start;
  margin: 0;
}

.agency__profile_clients {
  display: flex;
  gap: 50px;
  flex-wrap: wrap;
  justify-content: left;
  padding-top: 30px;
  align-items: center;
}
.clientProfiles{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .agencylogoInitials{
    border-radius: 50%;
    width: 40px !important;
    height: 40px !important;
    margin: auto;
  }
  span {
    white-space: nowrap;
    /* overflow: hidden; */
/* Optional: This will add an ellipsis (...) if the content is too wide to fit in one line */
  }
  
}

.agency__profile_clients div {
  width: 100px;
  height: 100px;
  display: flex;
}
.agency__profile_clients > div > a > img {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  border: 1px solid #E7E3E9;
}
.agency__profile .case__studies {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  padding-bottom: 35px;
}

.agency__profile .case__studies .by {
  color: #ff656a;
  text-align: start;
  font-size: 10px;
}

.agency__profile .case__studies .display_image {
  height: 196px;
  width: auto;
  max-width: 300px;
}

.agency__profile .package_box {
  border: 1px solid #e8eff7;
  border-radius: 10px;
  padding: 30px;
  margin-top: 30px;
}   

.package_box .package_name {
  font-weight: 700;
  text-align: start;
  color: #0b1c39;
  margin: 0;
}

.package_box .pricing {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  border-bottom: 1px solid #dfe9f3;
  padding-bottom: 25px;
}

.package_box .price {
  color: #0b1c39;
  font-weight: 700;
  font-size: 14px;
  margin: 0;
}

.package_box button {
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  background: #0b1c39;
  border-radius: 3px;
  padding: 5px 40px;
  display: flex;
}

.agency__profile .view_all {
  background: #dfe9f3;
  border-radius: 3px;
  font-weight: 700;
  font-size: 14px;
  color: #5c7793;
  border: none;
  padding: 6px 40px;
  margin: 30px 0;
}

.agency__profile .review_box {
  border: 1px solid #e8eff7;
  border-radius: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.agency__profile .review_box .details {
  background: #e8eff7;
  border-radius: 44px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 3px 8px 3px 3px;
  gap: 2px;
  margin-bottom: 22px;
}

.agency__profile .review_box .details .logo {
  width: 26px;
  height: 26px;
  border-radius: 100px;
}

.agency__profile .review_box .details .name {
  margin: 0;
  font-family: "lexend";
  font-weight: 500;
  color: #0b1c39;
  font-size: 10px;
  text-align: start;
}

.review_box .reviewer {
  font-weight: 700;
  font-size: 14px;
  color: #ff656a;
  margin: 0;
}

.reviewer_designation {
  font-weight: 500;
  font-size: 14px;
  color: #5c7793;
}

.agency__profile .agency__profile_reviews {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  padding-top: 30px;
}

@media screen and (max-width: 1500px){

  .agency__profile .case__studies {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }  
  .agency__profile{
    overflow: hidden;
  }
}

@media screen and (max-width: 1100px){

  .agency__profile .case__studies {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }  
}


@media screen and (max-width: 850px){
  
  .agency__profile_header{
    flex-direction: column;
    row-gap: 1rem;
  }
  .agency__profile_header .agheaderBtn{
    width: 80%;
    align-self: center;
  }
  .agency__profile .agency__profile_reviews {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .agency__profile .case__studies {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }  
  .agency__profile .case__studies .display_image {
    height: 196px;
    width: 196px;
  }
  

}

@media screen and (max-width: 550px){
  
  .agency__profile_header{
    flex-direction: column;
    row-gap: 2rem;
  }
  .agency__profile_header .agency_name{
    text-align: left;
  }
  .agency__profile_header .agency_infos{
    flex-direction: column;
    row-gap: 1rem;
  }
  .agency__profile .case__studies {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }  
  .agency__profile .case__studies .display_image {
  height: 196px;
  width: auto;
  max-width: 300px;
}


}