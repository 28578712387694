.CreditsDataList {
  background: white;
  height: fit-content !important;
  max-height: 10rem;
  overflow-y: scroll;
  border: 1px solid;
  padding: 5px;
  width: 200px;
  margin-top: 5px;
}
.CreditsDataList:empty {
  display: none;
}
.CreditsDataList > p {
  width: fit-content !important;
  font-size: 12px;
  color: #6E6E6E;
}
.creditInput{
    width: 200px;
}