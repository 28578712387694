.brandListing{
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    margin-right: 50px;
}
.brandListing .search_section{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
    margin-left: 100px;
    justify-content:left;
    text-align: left;
}
.brandListing .search_section input{
    height: 56px;
    width: 260px;
    background-color:  #FAFAFA;
    border: 1px solid #D9D9D9;
    padding-left: 20px;

}
.brandListing h6{
    margin-top: -35px;
    padding-right: 150px;
    text-align: right;


}

.brandListing .images{
    display: flex;
    flex-wrap:wrap;
    width: 100%;
   align-items: center;
    justify-content: center;
    margin-bottom: 50px;
   box-sizing: border-box;
  
   
}

.brandListing .images .image_text{
    display: flex;
    flex-direction: column;
    text-align: left;
}
.brandListing .images .image_text label{
    font-family: "lexend";
    font-weight: 800;
    font-size: 16px;
    margin-left: 5px;
    margin-top: 5px;
}
.brandListing .images .image_text img{
    width: 284px;
    height: 284px;
    margin-right: 24px;
    margin-top: 46px;
}
.brandListing .btn{
    font-weight: 600;
    
   
    background-color: #FF656A;
    color:white;
    width: 154px;
    font-family: "Poppins";
    height: 50px;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center; 
    margin: auto;
 margin-bottom: 30px;
}
@media only screen and (max-width:800px) {
    .brandListing .search_section{
        margin-left: 30px;
    }
    .brandListing h6{
      display: none;
    }
}