.bankDetailPage {
    text-align: left;
    position: relative;
}


.existingDetails {
  border: none;
  padding: 1.5rem;
  background-color: #F7F7F7;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
}

.existingDetails .singleDetailCard {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns of equal width */
  grid-template-rows: 1fr 1fr;    /* Two rows of equal height */
  gap: 20px 100px; 
}

.existingDetails .singleDetailCard .grid-item {
  background-color: #cccccc;
  padding: 20px;
  text-align: center;
  border: 1px solid #000;
}
.existingDetails .singleDetailCard .info-fields {
  display: block;
  font-weight: 500;
  font-size: smaller;
}

.existingDetailsButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}

.bankDetailForm {
    border: 2px solid rgb(181, 179, 179);
    border-radius: 7px;
    padding: 25px;
    z-index: 9999;
  }
  
  .bankDetailForm .bankDetailGrid {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns of equal width */
    grid-template-rows: 1fr 1fr;    /* Two rows of equal height */
    gap: 30px; /* Optional: space between grid items */
  }
  
  .bankDetailForm .bankDetailGrid .grid-item {
    background-color: #cccccc;
    padding: 20px;
    text-align: center;
    border: 1px solid #000;
  }
  
  .bankDetailForm .bankDetailGrid .grid-child label {
    display: block;
    font-weight: 500;
    font-size: smaller;
  }
  
  .bankDetailForm .bankDetailGrid .grid-child input {
    margin-top: 5px;
    width: 100%; /* Full width minus 4px to avoid touching the next input field */
    padding: 5px 10px;
    border-radius: 5px;
    border: 2px solid rgb(181, 179, 179);
    box-sizing: border-box;
  }
  
  .bankDetailButtons {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .bankDetailButtons .saveButton, 
  .existingDetailsButton .editDetailsButton {
    background-color: white;
    color: black;
    border-radius: 6px;
    padding: 5px 10px;
    cursor: pointer;
    border: 2px solid black;
  }

  
  .bankDetailButtons .saveButton:hover, 
  .existingDetailsButton .editDetailsButton:hover {
    color: white;
    background-color: black;
  }
  
  .bankDetailButtons .cancelButton,
  .existingDetailsButton .deleteDetailsButton {
    background-color: white;
    color: black;
    border-radius: 6px;
    padding: 5px 10px;
    cursor: pointer;
    border: 2px solid black;
  
  
  }
  
  .bankDetailButtons .cancelButton:hover,
  .existingDetailsButton .deleteDetailsButton:hover  {
    background-color: red;
    border-color: red;
    color: white;
  }
  
  .addAccountButton {
    background-color: white;
    color: black;
    border-radius: 6px;
    padding: 5px 10px;
    cursor: pointer;
    border: 2px solid black;
  }

  .addAccountButton:hover {
    color: white;
    background-color: black;
  }