.forBrands .fab1 {
  background-color: #0b1c39;
  padding-top: 10%;
  padding-bottom: 7%;
}

.forBrands .button {
  border: none;
  border-radius: 0;
}

.forBrands .join-btn {
  margin-top: 3rem;
  height: 60px;
  width: 190px;
  background-color: #ff6566;
  color: white;
  border: none;
  cursor: pointer;
  font-family: "Poppins";
}

.forBrands .fab1 .spanOut {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 300;
}

.forBrands .fab1 .spanIn {
  color: #ff6566;
  font-weight: 600;
}

.forBrands .fab1 .tagline {
  color: rgba(255, 255, 255, 0.8);
  padding-top: 8px;
  margin-left: 30%;
  margin-right: 30%;
  font-weight: 400;
  font-size: 0.95rem;
}

.forBrands .fab2 {
  background-color: #fafafa;
  padding: 3.5rem 9.75rem 3.5rem 9.75rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.forBrands .fab2 .columns {
  margin-left: 0rem;
  margin-right: 0rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.forBrands .fab2 .column {
  padding: 0 0 0 0;
  width: 100%;
}

.forBrands .fab2 .column .tags {
  font-size: 32px;
  color: #ff6566;
  font-weight: 600;
}

.forBrands .fab2 .column p {
  color: #6e6e6e;
}

.forBrands .fab3 {
  padding-top: 112px;
  padding-left: 9.75rem;
  padding-right: 9.75rem;
}

.forBrands .fab3 .columns {
  margin-left: 0rem;
  margin-right: 0rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.forBrands .fab3 .fab3c {
  display: flex;
  padding-top: 112px;
}

.forBrands .fab3 .column {
  padding: 0 0 0 0;
  display: block;
  float: left;
  width: 50%;
}
.forBrands .fab3 .fab3c1 {
  text-align: left;
  padding: 10% 10% 10% 0%;
}
.forBrands .fab3 .fab-img img {
  /* padding: 3% 7% 3% 7%; */
  max-width: 100%;
  height: auto;
}
.forBrands .fab3 .fab3c2 {
  padding: 10% 0% 10% 10%;
  text-align: left;
}

.forBrands .fab3 .b {
  font-size: 32px;
  color: #0b1c39;
  font-weight: 300;
}

.forBrands .fab3 .p {
  font-size: 32px;
  color: #ff6566;
  font-weight: 600;
}
.forBrands .fab3 .rel {
  font-size: 24px;
  color: #0b1c39;
  font-weight: 300;
}

.forBrands .fab3 .rec {
  font-size: 24px;
  color: #ff6566;
  font-weight: 600;
}

.forBrands .fab3 .reviews {
  margin-top: 150px;
  margin-bottom: 150px;
}

.forBrands .fab3 .slider {
  margin-top: 400px;
}

@media screen and (max-width: 1024px) {
  .forBrands .fab3 {
    padding: 8%;
  }
  .forBrands .fab3c {
    margin-left: 0;
  }
}

@media screen and (max-width: 768px) {
  .forBrands .fab2 {
    padding-left: 0;
    padding-right: 0;
  }
  .forBrands .fab3 .column {
    padding: 0 0 0 0;
    display: block;
    float: left;
    width: 100%;
  }

  .forBrands .fab2 {
    padding: 3.5rem 2rem 3.5rem 2rem;
  }
  .forBrands .fab3 {
    padding-top: 112px;
    padding-left: 5%;
    padding-right: 5%;
  }
  .forBrands .fab3c {
    padding-top: 5%;
  }

  .forBrands .fab3c1,
  .fab3c2 {
    padding: 5% 5% 5% 5%;
    text-align: center;
    text-align: center !important;
  }

  .forBrands .fab-img {
    margin-top: 30px;
    margin-bottom: -50px;
    padding: 3% 7% 3% 7%;
  }

  .forBrands .fab3c {
    display: flex;
    flex-flow: column;
  }
  .forBrands .fab3c2 {
    order: 1;
  }
  .forBrands .fab-img {
    order: 2;
  }
  .forBrands .fab1 .tagline {
    margin-left: 30px;
    margin-right: 30px;
  }
  .forBrands .fab1 h1 {
    padding-top: 2rem;
    margin-left: 20px;
    margin-right: 20px;
  }

  .forBrands .fab1 h3 {
    margin: 5% !important;
  }
  .forBrands .fab3c2 {
    padding: 10% 0% 10% 10%;
  }
}
