.links_main_container{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #E0E0E0;
    border-radius: 20px;
    margin-top: 20px;
}

.links_form {
    padding: 30px 0 0 0;  
    text-align: start;
    width: 640px;
    max-width: 600px;
    margin: 0 auto;
    overflow: auto;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
}

.socialMediaProfile {
    width: 100%;
}

.linksContainer {
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.linkDiv {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
    width: 100%;
}

.linkDiv input {
    width: 100%;
    padding:  0 10px;
    padding-left: 20px;
    border-radius: 10px;
    background: white;
    border: 1px solid #E0E0E0;
    min-height: 60px;
    font-size: 16px;
    line-height: 24px;
}

.linkDiv .linksInputContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;
    padding:  0 10px 0 0 ;
    border-radius: 10px;
    background: white;
    border: 1px solid #E0E0E0;
    min-height: 60px;
    font-size: 16px;
    line-height: 24px;

}
.linkDiv .linksInputContainer input {
    flex-grow: 1;
    outline: none;
    border: none;
    background-color: transparent;
    width: 90%;
    max-width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    min-height: 60px;


}

.linkImgAndImage {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
}

.linkIconName {
    font-weight: 500;
    font-size: 15px;
}
.newLinkContainer{
    width: 70%;
    margin: auto;
    text-align: left;
}
.newLinkAddBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid #E0E0E0;
    border-radius: 20px;
    padding: 50px 100px ;
    margin: 20px;
}

.links_main_container .linkFormDecidingButtons {
    width: 640px;
    max-width: 600px;
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}
.otherLinksContainer {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.otherLinksContainer .otherLinkUrl {
    color: #B4AEBD;
}

.otherNewLink {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%
}

.save button {
    width: 100px;
    background-color: #F8F8F8;
    border: 1px solid #E7E3E9;
    outline: none;
    margin-top: 20px;
    padding: 10px;
}

.save button:hover{
    background-color: #e7e7e7;
}

.save button:disabled {
    background-color: #E7E3E9;
    color: #B4AEBD;
    cursor: not-allowed;
}

.newLinkTitle, .newLinkUrl {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.addNewLinkBtn {


    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 10px;
    cursor: pointer;

}

.formButtons {
    position: fixed;
    bottom: 0;
    background: white;
    width: -webkit-fill-available;
    box-shadow: 0px -4px 10px 0px rgba(231, 227, 233, 0.50);
}

.innerFormContainer {
    display: flex;
    justify-content: center;
    border: 1px solid #E7E3E9;
    /* width: 100%; */
    max-width: 600px;
    margin: 0 auto;
    height: 50px;
}

.links_main_container > .formButtons > .innerFormContainer > .formBtn:nth-child(1) {
    border-right: 1px solid #E7E3E9;
}

.links_main_container > .formButtons > .innerFormContainer > .formBtn {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: none;
}

.links_main_container > .formButtons > .innerFormContainer > .formBtn:hover{
    background-color: #dfdfdf;
}

@media screen and (max-width: 870px) {
    .links_main_container{
        max-height: 79vh;
    }
    .links_form {
        width: 95%;
        margin: 0 2.5%;
    }
    .newLinkContainer{
        width: 95%;
        margin: 0 2.5%;
    }
    .newLinkAddBox {
        padding: 20px ;
        margin: 0px;
    }
    .links_main_container .linkFormDecidingButtons {
        width: 100%;
        display: flex;
        padding: 0 10px;
    }
}
@media screen and (max-height: 700px) {
    .links_main_container{
        max-height: 65vh;
    }

}