@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.userPaymentSingleSession {
  margin-top: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
}

.sessionHeader {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

/* .sessionHeader-title {
  display: flex;
  align-items: center;
} */

/* .backButton {
  background: none;
  border: none;
  cursor: pointer;
} */

.payment-page-duration-slot {
  color: black;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 9px;
  font-weight: 400;
}

.payment-page-duration-slot .payment-page-duration-item {
  border-radius: 40px;
  background: #f7f7f7;
  font-size: 15px;
  width: 236px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 23px;
  font-weight: 400;
}

.payment-section {
  background: #f7f7f7;
  padding: 20px 10px; /* Adjust padding for mobile */
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100%; Ensure it spans full width */
  box-sizing: border-box; /* Include padding in width calculation */
}

.payment-section-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Adjust to fill full width */
  max-width: 500px; /* Limit width for larger screens */
}

.payment-section-title p {
  text-align: start;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  padding-bottom: 7px;
}

.agenda-input {
  width: 500px;
  height: 250px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  resize: none;
}
.duration-footer1 {
  display: none;
}

.userPaymentSingleSession .payment-section-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  width: 100%;
}

.userPaymentSingleSession
  .payment-section-footer
  .payment-section-footer-price {
  font-size: 22px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
}

.userPaymentSingleSession
  .payment-section-footer
  .payment-section-footer-price
  .session-discounted-price {
  color: green;
}

.userPaymentSingleSession
  .payment-section-footer
  .payment-section-coupon-and-payment {
  display: flex;
  gap: 10px;
  align-items: center;
}

.userPaymentSingleSession
  .payment-section-footer
  .payment-section-coupon-and-payment
  .payment-section-coupon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.userPaymentSingleSession
  .payment-section-footer
  .payment-section-coupon-and-payment
  .payment-section-coupon
  .coupon-input-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 0 20px;
  min-height: 60px;
  font-size: 18px;
  background-color: #fff;
  width: max-content;
  max-width: 100%;
}

.userPaymentSingleSession
  .payment-section-footer
  .payment-section-coupon-and-payment
  .payment-section-coupon
  .coupon-input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 18px;
  padding: 10px;
  background: transparent;
}

.userPaymentSingleSession
  .payment-section-footer
  .payment-section-coupon-and-payment
  .payment-section-coupon
  .coupon-button {
  border: none;
  border-left: 1px solid #ccc;
  padding: 10px 20px;
  background-color: black;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.2s;
  font-size: 18px;
  border-radius: 20px;
}

/* Cool hover & click animations */
.userPaymentSingleSession
  .payment-section-footer
  .payment-section-coupon-and-payment
  .payment-section-coupon
  .coupon-button:hover {
  background-color: #333;
}

.userPaymentSingleSession
  .payment-section-footer
  .payment-section-coupon-and-payment
  .payment-section-footer-payment {
  padding: 0 20px;
  border: 1px solid;
  border-radius: 20px;
  background-color: black;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  min-height: 60px;
  font-size: 18px;
  width: max-content;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  max-width: 100%;
  text-align: left;
  line-height: 18px;
}

@media (max-width: 600px) {
  .agenda-input {
    width: 390px;
    height: 200px;
  }
  .payment-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .payment-section-title {
    display: flex;
    flex-direction: column;
    margin-top: 34px;
  }
  .duration-footer1 {
    display: flex;
    flex-direction: column;
  }

  .duration-footer1 .duration-item-footer1 {
    border-radius: 40px;
    background: white;
    font-size: 18px;
    width: 236px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 23px;
    font-weight: 400;
    font-size: 15px;
    padding-left: 5px;
    margin: 4px;
    /* margin-left: 35px; */
    border: 1px solid #e0e0e0;
    font-family: "Poppins", sans-serif;
  }
}
