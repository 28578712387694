/* bottomComp.css */

.bottomComp {
    border: 1px solid #000;
    border-left: none;
    border-right: none;
    padding-top: 50px;
    padding-left: 0; /* Reset left padding */
  }
  
  .HPHeading {
    padding-right: 200px;
  }
  
  .bottomComp > p {
    width: 1190px;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    flex-shrink: 0;
    padding-left: 50px;
    color: #000;
    font-family: 'GM-Sans';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 166.667% */
    letter-spacing: 0.96px;
    margin-bottom: 0 !important;
  }
  
  .bottomComp >a> .exploreTalentBtn {
    width: 250px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 30px;
    border: 1px solid #000;
    background: #FFF;
    color: #000;
    font-family: 'GM-Sans';
    font-size: 20px;
    padding-left: 30px;
    margin: 50px 50px 100px 50px; /* Reset margin */
    /* position: relative; */
    left: -543px;
  }
  
  .bottomComp > .exploreTalentBtn > img {
    border-radius: 40px;
    height: 40px;
    width: 40px;
    padding: 10px;
    border: 1px solid #000;
    text-align: right;
    margin-left: 30px;
  }
  