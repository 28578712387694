.topComp{
    width: 1340px;
    margin: 0 auto;
    padding-top: 90px;
}
.borderWithGradient {
    border: 1px solid;
    border-image: linear-gradient(0deg, #000, #FFF) .5;
    border-top: none;
    border-bottom: none;
}
.topComp > .heroSection{
    display: flex;
    width: 1163px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    margin: 0 auto;
    padding-bottom: 100px;
}
.topComp > .heroSection > h1{
    color: #000;
    text-align: center;
    font-family: 'DM-Sans-Reg';
    font-size: 94px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.topComp > .heroSection > p{
    display: flex;
    width: 700px;
    height: 150px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: 'GM-Sans';;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 166.667% */
    letter-spacing: 0.96px;
    margin: 0 auto;
}
.topComp > .heroSection > a> .exploreTalentBtn{
    width: 250px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 30px;
    border: 1px solid #000;
    background: #000;
    color: #fff;
    font-family: 'GM-Sans';;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 200% */
    letter-spacing: -0.2px;
    padding-left: 30px;
    margin: 50px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.topComp > .heroSection > .exploreTalentBtn > img{
    border-radius: 40px;
    height: 40px;
    width: 40px;
    padding: 10px;
    border: 1px Solid #000;
    text-align: right;
    margin-left: 30px;
}
.topComp > .statsSection{
    display: flex;
    flex-direction: row;
    width: 1340px;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
}
.topComp > .statsSection > .leftStatsSection, .topComp > .statsSection > .rightStatsSection{
    display: flex;
    flex-direction: row;
    width: 50%;
    height: 250px;
    padding: 50px;
}
.topComp > .statsSection > .rightStatsSection{
    border-left: 1px solid #000;
}
.topComp > .statsSection > .leftStatsSection > .numberStats, 
.topComp > .statsSection > .rightStatsSection > .numberStats{
    display: flex;
    height: 150px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    display: flex;
    font-family: 'DM-Sans-Reg';;
    font-size: 94px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.topComp > .statsSection > .rightStatsSection > .infoStats, 
.topComp > .statsSection > .leftStatsSection > .infoStats{
    display: flex;
    margin-left: 50px;
    width: 350px;
    height: 150px;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    flex-shrink: 0;
    color: #000;
    font-family: 'GM-Sans';;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 125% */
    letter-spacing: 0.96px;
}
@media screen and (min-width:1340) {
    
}