.bnewproject {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  margin: 4rem 5%;
  column-gap: 0.5rem;
  text-align: left;
}
.bnewproject .level {
  display: flex;
  align-items: flex-start;
  row-gap: 0.6rem;
  column-gap: 1rem;
}
.bnewproject .level .labell {
  flex: 0.4;
  text-align: left;
  font-size: 0.95rem;
}
.bnewproject .level .inputt {
  flex: 0.3;
  border: none;
  border-radius: 1px;
  outline: none;
  font-family: "Poppins";
  padding: 0.4rem 0.5rem;
  box-sizing: border-box;
  color: grey;
  background: #f3f3f3;
  font-size: 0.85rem;
  width: 100%;
}
.bnewproject .level .input-t {
  flex: 0.3;
  border: none;
  border-radius: 1px;
  outline: none;
  font-family: "Poppins";
  padding: 0.4rem 0.5rem;
  box-sizing: border-box;
  color: grey;
  background: #f3f3f3;
  font-size: 0.85rem;
  width: 100%;
  height: 100px;
}
.bnewproject .level .btn{
  height: 100%;
    border: none;
    color: white;
    background: #ff656a;
    width: 8.5rem;
    cursor: pointer;
    font-family: "Poppins";
    height: 40px;
    
    text-align: center;
    margin-left:70px;
    font-size: 1rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.bnewproject .level .submitt .btn{
  height: 100%;
    border: none;
    color: white;
    background: #ff656a;
    width: 8.5rem;
    cursor: pointer;
    font-family: "Poppins";
    height: 40px;
    justify-content: center;
    text-align: center;
    margin-left: 0px;
    font-size: 1rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
.bnewproject .level .selectt {
  flex: 0.15;
  border: none;
  border-radius: 1px;
  outline: none;
  font-family: "Poppins";
  padding: 0.4rem 0.5rem;
  box-sizing: border-box;
  color: grey;
  background: #ececec;
  font-size: 0.85rem;
  width: inherit;
  
}
/* .bnewproject .level .calenderr {
  background: #ececec;
} */
.bnewproject .level .datecalendercont {
  flex: 0.5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.bnewproject .level .datecalender {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  border: none;
  border-radius: 1px;
  outline: none;
  font-family: "Poppins";
  padding: 0.4rem 0.6rem;
  box-sizing: border-box;
  color: grey;
  background: #ececec;
  font-size: 0.85rem;
  cursor: pointer;
 
}
.bnewproject .level .radio__inp__main__cont {
  flex: 0.5;
  display: flex;

  row-gap: 0.5rem;
  column-gap: 1rem;
}
.bnewproject .level .radio__inp__cont2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 0.5rem;
}
.bnewproject .level .radio__inp__cont {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.2rem;
}
.bnewproject .level .selecttextcont {
  flex: 0.5;
  display: flex;
  align-items: center;
  column-gap: 2rem;
}
.bnewproject .level .bigcolgap {
  column-gap: 3rem;
}
.bnewproject .level .submitt span{
 
  margin-left: 60px;

}

@media screen and (max-width: 768px) {
  .bnewproject .level {
    flex-direction: column;
  }
  .bnewproject .level .selecttextcont {
    align-items: flex-start;
  }
  .bnewproject .level .bigcolgap {
    column-gap: 1rem;
  }
  .bnewproject .level .submitt button{
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top:20px;
    margin-left:3.5rem
  
  }
  .bnewproject .level .submitt span{
 
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top:20px
  
  }
  .bnewproject .level.btn{
    margin-left: 0px;
    justify-content: left;
  }
  .bnewproject .level .submitt .btn{
    margin-left: 70px;

  }
}
