/* AvailabilityAndResumeContainer.css */

.availabilityResume_container {
  position: relative;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  margin-top: 20px;
}

.AvailabilityAndResumeContainer {
  padding: 30px 0px 0px 0px;
  text-align: start;
  width: 640px;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.AvailabilityAndResumeContainer::-webkit-scrollbar {
  display: none;
}
.availabilityResume_container .AvailabilityAndResumeContainer .additionalField {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.AvailabilityAndResumeContainer > .Fields {
  width: 100%;
  margin: 0 auto;
}
.AvailabilityAndResumeContainer .additionalField p {
  margin-bottom: 0;
}

.AvailabilityAndResumeContainer .additionalField .toggleButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.AvailabilityAndResumeContainer .additionalField .fieldHeading {
  font-weight: 500;
  font-size: 15px;
  margin: 0;
  color: black;
}

.AvailabilityAndResumeContainer .additionalField .radioButtonBox {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: start;
  font-weight: 300;
  font-size: 18px;
  color: #b1b1b1;
}

.AvailabilityAndResumeContainer .additionalField .selectedText {
  color: black;
}

.AvailabilityAndResumeContainer .additionalField .inputFieldBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
  font-weight: 500;
  font-size: 15px;
}

.AvailabilityAndResumeContainer .additionalField .inputFieldBox input {
  padding: 5px 0;
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  outline: none;
  min-height: 60px;
}

.AvailabilityAndResumeContainer
  .additionalField
  .inputFieldBox
  input::placeholder {
  color: #b1b1b1;
  font-weight: 400;
  size: 18px;
}

.AvailabilityAndResumeContainer .additionalField input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid black;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
}

/* Add the inner circle with gap */
.AvailabilityAndResumeContainer .additionalField input[type="radio"]::before {
  content: "";
  width: 8px; /* Smaller than the outer box to create a gap */
  height: 8px;
  border-radius: 50%;
  background-color: transparent; /* Initially transparent */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Style the checked state with the inner circle filled */
.AvailabilityAndResumeContainer
  .additionalField
  input[type="radio"]:checked::before {
  background-color: black;
}

/* Optional: Add a hover effect */
.AvailabilityAndResumeContainer .additionalField input[type="radio"]:hover {
  border-color: #666;
}

.availabilityResume_container .AvailabilityAndResumeContainer .extraField {
  margin-top: 20px;
}
.availabilityResume_container
  .AvailabilityAndResumeContainer
  .additionalField
  .InfoDiv {
  display: flex;
  flex-direction: column;
}

.availabilityResume_container .profileAvatarList {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
  height: 130px;
  width: 95%;
  overflow-x: auto;
  margin: auto;
  /* background-color: aqua; */
  scrollbar-width: none;
}
.availabilityResume_container .profileAvatarList::-webkit-scrollbar {
  display: none;
}

.availabilityResume_container .profileAvatarList .singleAvatarComponent {
  position: relative;
}
.availabilityResume_container
  .profileAvatarList
  .singleAvatarComponent
  .singleAvatar {
  height: 110px;
  outline: none;
  cursor: pointer;
  transition: transform 0.3s ease; /* Smooth transition */
}
.availabilityResume_container
  .profileAvatarList
  .singleAvatarComponent
  .singleAvatar:hover {
  transform: scale(1.2); /* Scale up the avatar */
}

.availabilityResume_container
  .profileAvatarList
  .singleAvatarComponent
  .selectedAvatar {
  border: 1px solid #e0e0e0;
  background-color: #e0e0e0;
  border-radius: 10px;
}

.availabilityResume_container .newFormButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.availabilityResume_container .newFormButtons {
  width: 640px;
  max-width: 600px;
}

@media screen and (max-width: 870px) {
  .AvailabilityAndResumeContainer {
    width: auto;
  }
}
