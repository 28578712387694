/* For desktop screens */
.footer {
  border-top: 1px solid black;
}


.footerSection {
  border-right: 1px solid black;
  border-left: 1px solid black;
  /* margin-right: 50px;
  margin-left: 50px; */
  margin: 0 auto;
  width: 94%;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 30px;
}

.footerSection > div {
  flex: 1;
  text-align: center;
  padding: 10px; /* Adjust padding as needed */
}

.footer > div:first-child {
  margin: 0 auto;
  width: 94%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-right: 50px;
  margin-left: 50px; */
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  border-left: 1px solid black;
  padding: 10px;
  height: 83px;
}

.explore-heading {
  color: #000000;
  font-weight: 700;
  font-size: 18px;
  font-size: 20px;
  display: flex;
  padding-left: 95px;
  margin-bottom: 10px;
  margin-top: 30px;
  line-height: 30px;
}

.explore-heading.contact {
  padding: 0;
  padding-left: 42px;
}

.explore-options {
  text-align: left;
  display: flex;
  padding-left: 95px;
  margin-bottom: 30px;
  flex-direction: column;
}

.explore-options.contact {
  padding: 0;
  padding-left: 42px;
}

.logo {
  margin-left: 70px;
  /* margin-top: 8px; */
  width: 110px;
  height: 15px;
}

.title {
  padding-left: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 50px;
}

.exploreTalentBtn {
  width: 250px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 30px;
  border: 1px solid #000;
  background: #fff;
  color: #000;
  font-family: "GM-Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.8px;
  padding-left: 29px;
  margin: 50px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.exploreTalentBtn > img {
  position: relative;
  right: 2px;
  border-radius: 40px;
  height: 40px;
  width: 40px;
  padding: 10px;
  border: 1px solid #000;
  text-align: right;
  margin-right: 10px;
}

a {
  text-decoration: none !important;
  color: #000 !important;
  font-family: sans-serif;
}

.footer p {
  margin-bottom: 0 !important;
  size: 20px;
}

@media only screen and (max-width: 1280px) {
  .explore-options.contact {
    padding: 0;
  }
  .explore-heading {
    padding-left: 0px;
  }

  .explore-heading.contact {
    padding: 0;
  }

  .explore-options {
    padding-left: 0px;
  }
}

/* For smaller screens (mobile) */
@media only screen and (max-width: 768px) {
  .logo {
    margin-left: 0;
  }

  .footerSection {
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
    margin-left: 10px;
  }

  .footerSection > div {
    width: 100%;
    text-align: center;
    padding: 10px;
  }

  .title {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 12px !important;
    padding-top: 34px !important;
  }

  .footer > div:first-child {
    width: 100%;
  }

  .explore-heading,
  .explore-options {
    justify-content: center;
    align-items: center;
    /* padding-left: 20px; */
  }

  .footer > .buttonDiv > .exploreTalentBtn {
    width: 200px;
    padding-left: 20px;
  }

  .exploreTalentBtn > img {
    margin-left: 20px;
  }
}

/* For smaller screens (mobile) */
@media only screen and (max-width: 768px) {
  .mobile-bottom-line {
    background-color: black;
    height: 1px;
    margin-bottom: 20px;
  }
  .footerSection {
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
    margin-left: 10px;
  }

  .footerSection > div {
    width: 100%;
    text-align: center;
    padding: 10px;
  }

  .title {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
    padding-right: 20px;
  }

  .explore-heading,
  .explore-options {
    padding-left: 20px;
  }

  .exploreTalentBtn {
    display: none; /* Hide the button on smaller screens */
  }

  .exploreTalentBtn > img {
    margin-left: 20px; /* Adjust margin for the image if needed */
  }
}
