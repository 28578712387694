.MentorlineupContainerHomePage {
  padding: 80px;
  display: flex;
  flex-direction: column;
  gap: 80px;
  background-color: white;
  box-sizing: border-box;
}

.MentorlineupContainerHomePage .MentorCategoryContainer {
  position: relative;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /* width: 90%; */
  gap: 10px;
}

.MentorlineupContainerHomePage .MentorCategoryContainer .MentorCategoryIntro {
  grid-column: span 2;
}

.MentorlineupContainerHomePage
  .MentorCategoryContainer
  .MentorCategoryIntro
  .CategoryDescription {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 10px;
}

.MentorlineupContainerHomePage .MentorCategoryContainer .CategoryMentors {
  display: flex;
  gap: 10px;
  grid-column: span 3;
}

.MentorlineupContainerHomePage
  .MentorCategoryContainer
  .NavigationArrowBoxInMentorlinup {
  position: absolute;
  top: 50%;
  right: -50px;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

@media screen and (max-width: 480px) {
  .MentorlineupContainerHomePage {
    padding: 30px 0 30px 30px;
    align-items: start;
    gap: 40px;
  }

  .MentorlineupContainerHomePage .MentorCategoryContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  .MentorlineupContainerHomePage .MentorCategoryContainer .MentorCategoryIntro {
    padding-right: 30px;
  }

  .MentorlineupContainerHomePage .MentorCategoryContainer .CategoryMentors {
    overflow-x: auto;
  }
  .MentorlineupContainerHomePage
    .MentorCategoryContainer
    .NavigationArrowBoxInMentorlinup {
    display: none;
  }
}
