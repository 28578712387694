.case-study-list {
    display: flex;
    flex-direction: column;
    margin: 0 2.5% 0 2.5%;
}

.case-study-list .list-count {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.case-study-list .list-count .case-study-count {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #6E6E6E;
}

.case-study-list .case-study-list-table {
    display: flex;
    margin-top: 25px;
}

.case-study-list .case-study-list-table table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.case-study-list .case-study-list-table td,
.case-study-list .case-study-list-table th {
    border-bottom: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #6E6E6E;
    padding-left: 15px;
}

.case-study-list .case-study-list-table th:last-child{
    border-right: none;
}
.case-study-list .case-study-list-table td:last-child{
    border-right: none;
}