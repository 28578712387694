
.newproject_heading h6{
  float: right;
  margin-right: 7rem;
  margin-top: -2rem;
}
.logout_btn button{
    float: right;
    padding:5px 10px;
    margin-right:10% ;
}
.bnewproject {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    width:90%;
    margin: 2rem;
    padding :0;
    column-gap: 0.5rem;
    text-align: left;
    
  }
  .bnewproject .levels {
    display: flex;
    flex-direction: column;
    row-gap: 0.6rem;
    column-gap: 1rem;
  }
  .bnewproject .levels .labell {
    flex: 0.4;
    text-align: left;
    font-size: 0.95rem;
    font-weight: 600;
  }
  .bnewproject .levels .inputt {
    flex: 0.3;
    border: none;
    border-radius: 1px;
    outline: none;
    font-family: "Poppins";
    padding: 0.4rem 0.5rem;
    box-sizing: border-box;
    color: rgb(44, 42, 42);
    background: #ffffff;
    font-size: 0.85rem;
    width: 30%;
  }
  .bnewproject .levels .input__1 {
    flex: 0.3;
    border: none;
    border-radius: 1px;
    outline: none;
    font-family: "Poppins";
    padding: 0.4rem 0.5rem;
    box-sizing: border-box;
    color: rgb(44, 42, 42);
    background: #ffffff;
    font-size: 0.85rem;
    width: 394px;
  }
  .bnewproject .levels .input-t {
    flex: 0.3;
    border: none;
    border-radius: 1px;
    outline: none;
    font-family: "Poppins";
    padding-bottom: 5.4rem ;
   
    box-sizing: border-box;
    color: grey;
    background: white;
    font-size: 0.85rem;
   width: 75%;
    
  }
  .bnewproject .levels .btn{
      height: 100%;
      border: none;
      color: white;
      background: #ff656a;
      width: 8.5rem;
      cursor: pointer;
      font-family: "Poppins";
      height: 40px;
      
      text-align: center;
      margin-left:70px;
      font-size: 1rem;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
      border-radius: 10px;
  }
  
  .bnewproject .levels .submitt .btn{
    height: 100%;
      border: none;
      color: white;
      background: #ff656a;
      width: 8.5rem;
      cursor: pointer;
      font-family: "Poppins";
      height: 40px;
      justify-content: center;
      text-align: center;
      margin-left: 0px;
      font-size: 1rem;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
      border-radius: 10px;
  }
  .bnewproject .levels .selectt  {
    flex: 0.15;
    border: none;
    border-radius: 1px;
    outline: none;
    font-family: "Poppins";
    padding: 0.4rem 197px;
    box-sizing: border-box;
 
    background: white;
    font-size: 0.85rem;
    width: 394px;
    
  }
  
  
  /* .bnewproject .level .calenderr {
    background: #ececec;
  } */
 
  .bnewproject .levels .radio__inp__main__conts {
    flex: 0.5;
    display: flex;
    flex-wrap: wrap;
    width: 80vw !important;
    row-gap: 0.5rem;
    column-gap: 1rem;
  }
  .bnewproject .levels .radio__inp__cont2s {
      
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 10px;
    height:100px;
    width:245px !important;
    border:0.5px solid grey;
    row-gap: 0.5rem;
  }
  .bnewproject .levels .radio__inp__cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: auto;
    align-items: center;
    column-gap: 0.2rem;
  }
  .bnewproject .levels .radio__inp__cont input {
      display: flex;
      flex-direction: column;
      display: none;
      
  }
  .bnewproject .levels .selecttextcont {
    flex: 0.5;
    display: flex;
    align-items: center;
    column-gap: 5rem;
  }
  .bnewproject .levels .bigcolgap {
    column-gap: 3rem;
  }
  .bnewproject .levels .submitt span{
   
    margin-left: 60px;
  
  }
  .bnewproject .levels .keyword button{
      background-color: #FD666E33;
      border-radius: 10px;
      justify-content: space-between;
      margin-right: 30px;
    
      flex-wrap: wrap;
      width: 12%;
      height: 30px;
      border:none;
     

  }
  .bnewproject .levels .keyword .color__orange{
   background-color: whitesmoke !important;
    border-radius: 0px;
    justify-content: space-between;
    margin-right: 30px;
  
    flex-wrap: wrap;
    width: 12%;
    height: 30px;
    border:none;
   

}
  
  @media screen and (max-width: 420px) {
    .newproject_heading h6{
      margin-right: -0.3rem;
    }
    .bnewproject {
       
        margin: 0rem;
      
        
      }
      .bnewproject .levels .selectt{
        padding:0.4rem 100px ;
        width: 10px;
      }
      .bnewproject .levels .input-t{
          width:70%
      }
      .bnewproject .levels .selectt{
          width: 170px;
      }
    }
    /* pretty radio */
label > input[type="radio"] {
  display: none;
}
label > input[type="radio"] + *::before {
  content: "";
  margin-left:40% ;
  justify-content: center;
  vertical-align: bottom;
  width: 1rem;
  height: 1rem;
 margin-right: 0.3rem;
 margin-bottom: 5%;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: gray;
  cursor: pointer;
}
.radio__inp__cont2s >  input[type="radio"]:checked + * {
  border:0.5px solid #FD666E;
 }
label > input[type="radio"]:checked + * {
 color:#FD666E
}

label > input[type="radio"]:checked + *::before {
  background: radial-gradient(
    #FD666E 0%, 
    #FD666E 40%, transparent 50%, transparent);
  border-color: 
  #FD666E;
}
fieldset {
  margin: 20px;
  max-width: 400px;
}
label > input[type="radio"] + * {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

  @media screen and (max-width: 768px) {
    .bnewproject .levels .input__1{
        width:200px;
    }
    .bnewproject .levels .keyword button{
       
       display: flex;
       flex-direction: column;
       margin-top: 10px;
       width: 100%;
       text-align: center;
       justify-content: center;
       padding-left:90px ;
  
    }
    .bnewproject {
       
        margin:2rem;
      
        
      }
    .bnewproject .levels {
      flex-direction: column;
    }
    .bnewproject .levels .selecttextcont {
      align-items: flex-start;
    }
    .bnewproject .levels .bigcolgap {
      column-gap: 1rem;
    }
    .bnewproject .levels .submitt button{
      align-items: center;
      justify-content: center;
      text-align: center;
      display: flex;
      flex-direction: column;
      margin-top:20px;
      margin-left:3.5rem
    
    }
    .bnewproject .levels .submitt span{
   
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-top:20px
    
    }
    .bnewproject .levels.btn{
      margin-left: 0px;
      justify-content: left;
    }
    .bnewproject .levels .submitt .btn{
      margin-left: 70px;
  
    }
  }
  