.request-item1 {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  margin: 10px 0;  
  background-color: #fff;
}

.request-header-prof .request-prof{
  width: 600px;
  font-size: 20px;

}

.request-header-prof .request-prof p {
  margin: 0 0 0 10px;
  flex: 1; 
  font-weight: 400;
  line-height: 27px;
  font-size: 20px;
}

.profile-img {
  border: 1px solid #000;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-color: #ccc; 
}

.request-header span {
  color: gray;
  margin-left: 5px;
  font-weight: 400;
  line-height: 27px;
}

.icon-prof.top-right {
  border-radius: 50%;
  padding: 2px;

}

.request-actions-icon {
  display: flex;
  gap: 10px;
  background-color: #fff;
}

.action-item-res {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #E0E0E0;
  border-radius: 20px;
  /* padding: 18px; */
  width: 175px;
  gap: 10px;
  height: 70px;
  font-size: 20px;  
}

.action-item-res:hover {
  cursor: pointer;
}



/* .action-item-res p {
  margin: 0;
  width: 95px;
  margin-left: -4px;
  font-weight: 400;
  font-size: 13px;
  line-height: 27px;
} */

.action-button1 {
  border: 1px solid #E0E0E0;
  border-radius: 20px;
  /* padding: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  background-color: white;
  position: relative;;
}

.red-dot {
  position: absolute;
  top: -11px; /* Adjust as needed */
  right: -11px; /* Adjust as needed */
  width: 25px;
  height: 25px;
  background-color: red;
  border-radius: 50%;
}

.action-button1.Re-Scale {
  border: 1px solid #E0E0E0;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 0 15px;
  font-size: 20px;
  gap: 10px;
  width: fit-content;
}

.action-button1.active {
  background: rgba(224, 224, 224, 1);

}
.action-item-res.active {
  background: rgba(224, 224, 224, 1);
  border-bottom: none;
  color: black;
}

.action-item-res.disableDiv {
  cursor: not-allowed;
  pointer-events: none; 
  background-color: white;
  color: #bab4b4; 
  border: 1px solid #E0E0E0;

}

.active:focus {
  border-bottom: none;

}
.action-button1:hover{
  background:#F7F7F7;
  cursor: pointer;
}

/* Styles for the second section */
.details-section2 {
  border-radius: 20px;
  background-color: #F7F7F7;
  padding: 30px;
}

.details-section2 p {
  margin: 0.625rem 0;
  font-size: 18px;
  
  line-height: 27px;
  text-align: left;
  color: black;
}

.description{
  width: 80%;
}

.details-section2 span {
  font-weight: bold;
}

.calendar-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;
  

  
}
.calendar-container p {
  font-weight: 600;
  line-height: 15px;
  line-height: 27px;

}

.time-slot-container-req {
  margin-top: 10px;
}

.date-picker {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  width: 100%;
}

.time-input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  width: 100%;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.sunday-holiday {
  background-color: #E7E7E7; 
  border-radius: 50%;
  color: #ccc;
  cursor: not-allowed;
  pointer-events: none;
}

/* Requests.css */
.sunday-holiday .react-datepicker__day--selected {
  /* /* background-color: red !important; */
  color: #F7F7F7 !important; 
  background-color: #E7E7E7 !important; 
  border-radius: 50% !important;

}

.available-date {
  background-color: grey;
  color: white;
}



.buttons-container .cancel-button {
  background-color: white;
  color: black;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer;
}

.reschedule-button {
  background-color: black;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px 15px;
  cursor: pointer;
}

/* Flex layout for the reschedule section */
.reschedule-section2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.details-section-container1{
  max-width: 1200px;
  margin: 0 auto;
  
}

.reschedule-section2 > div {
  flex: 1;
  margin-right: 20px;
  
  
  
}

.reschedule-section2 .buttons-container {
  flex: 0 0 200px;
  display: flex;
  flex-direction: column;
}

/* Custom styles for the DatePicker component */
.react-datepicker {
  font-family: 'Arial', sans-serif;
  border: none;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 356px;
}

.react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
  padding: 1rem;
}

.react-datepicker__current-month {
  font-size: 1.2rem;
  font-weight: 500;
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 2.5rem;
  line-height: 2.5rem;
  margin: 0.2rem;
  font-size: 0.875rem;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #282727;
  color: #fff;
  border-radius: 50%;
}

.react-datepicker__day--outside-month {
  color: #ccc;
}

.react-datepicker__navigation {
  top: 1.5rem;
}

.react-datepicker__navigation--previous {
  left: 1rem;
}

.react-datepicker__navigation--next {
  right: 1rem;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day:hover {
  /* background-color: #000; */
  color: #fff;
  border-radius: 50%;
}

.react-datepicker__day--today {
  font-weight: bold;
  background-color: #000;
  color: #fff;

  border-radius: 50%; /* rounded appearance to match other date styles */
}


.react-datepicker__month-container {
  padding-bottom: 1rem;
}
.time-slot2 {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
  background-color: #fff;
  cursor: pointer;
  font-weight: 600;
}

.time-slot2:hover {
  color: black;
  background-color: #F7F7F7;
}

/* .time-selector2.active {
  background-color: black;
  color: white;
} */
.time-slot2.active {
  background-color: black;
  color:white;
}

.time-slot-container p {
  font-weight: 600;
}

/* .time-selector2.time-slot2{
  background-color: black;
  color: white;
} */
/* .time-selector2  {
  background-color: black;
  color: white;
} */

/* Hide scrollbar for Chrome, Safari, and Opera */
.time-selector2::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.time-selector2 {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.time-slot-container-req {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.time-slot-container-req p {
  font-weight: 600;
}

.time-selector2 {
  border: 1px solid #E0E0E0;
  background-color: white;
  width: 500px;
  height: 355px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  border-radius: 20px;
  margin-top: 5px;
}


.iconSize {
  width: 22px;
  height: 22px;
}

@media screen and (max-width: 870px) { 
  .details-section2{
    padding: 15px;
  }
.action-item-res  {
  font-size: 14px;
  border-radius: 20px;
  padding-right: 10px;
  padding-left: 10px;
  width: fit-content;
  gap: 10px;
  height: 50px;
}
.request-actions-icon{
  padding-bottom: 20px;
}
.reschedule-section2 {
  flex-direction: column;
  align-items: center;
}
.calendar-container{
  align-items: center;
}
.calendar-container { 
  margin-right: 0px !important;
}
.time-slot-container-req{
  align-items: center;
  margin: 20px;
  width: 100%;
}
.time-selector {
  width: 100% !important;
}
}