.buserprofile {
  display: flex;
  column-gap: 2rem;
  row-gap: 2rem;
  margin: 2rem 5%;
  text-align: left;
}
.buserprofile .sec__left {
  flex: 0.2;
  display: flex;
  flex-direction: column;
  row-gap: 0.35rem;
  width: 100%;
}
.buserprofile .sec__left .profilepic {
  height: 80px;
  width: 80px;
  border-radius: 50%;
}
.buserprofile .sec__left .name__link {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}
.buserprofile .sec__left .name__link .linkedinlogo {
  object-fit: contain;
  height: 13px;
  cursor: pointer;
}
.buserprofile .sec__right {
  flex: 0.8;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  width: 100%;
}
.buserprofile .sec__right .cat__btns {
  display: flex;
  column-gap: 1rem;
  width: 100%;
}
.buserprofile .sec__right .cat__btns .cat__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem 0.2rem;
  border-radius: 3px;
  border: 2px solid #ffeded;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
}
.buserprofile .sec__right .cat__btns .activecat {
  background: #ffeded;
}

.buserprofile .sec__right .var__tabs {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
}
.buserprofile .sec__right .var__tabs .unotifytab {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.buserprofile .sec__right .var__tabs .unotifytab .notify {
  display: flex;
  justify-content: space-between;
  column-gap: 0.3rem;
  padding: 1.5rem;
  border-radius: 5px;
  box-sizing: border-box;
  background: #f3f3f3;
  cursor: pointer;
}

.buserprofile .sec__right .var__tabs .ueditprofile {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  width: 100%;
}
.buserprofile .sec__right .var__tabs .ueditprofile .level {
  display: flex;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  width: 100%;
}
.sec__right .var__tabs .ueditprofile .level .part {
  flex: 0.3333;
  display: flex;
  column-gap: 0.3rem;
  width: 100%;
  /* background: pink; */
}
.sec__right .var__tabs .ueditprofile .level .part .pl {
  flex: 0.7;
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
  width: 100%;
  /* background: purple; */
}
.sec__right .var__tabs .ueditprofile .level .part .inputt {
  padding: 0.3rem 0.4rem;
  outline: none;
  font-family: "Poppins";
  color: #9d9d9d !important;
  border: 1px solid lightgrey;
  background: #f3f3f3;
  border-radius: 2px;
}
.sec__right .var__tabs .ueditprofile .level .part .pr {
  flex: 0.3;
  display: flex;
  align-items: flex-end;
  width: 100%;
}
.sec__right .var__tabs .ueditprofile .level .part .pr .labell {
  cursor: pointer;
}
.sec__right .var__tabs .ueditprofile .level .part .mobnocont {
  flex: 0.7;
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
}
.sec__right .var__tabs .ueditprofile .level .part .mobnocont .countrycode {
  flex: 0.15;
  width: 100%;
  text-align: center;
}
.sec__right .var__tabs .ueditprofile .level .part .mobnocont .mobno {
  flex: 0.85;
  width: 100%;
}

.buserprofile .sec__right .var__tabs .usertab {
  display: none;
}
.buserprofile .sec__right .var__tabs .activetab {
  display: flex;
}

@media screen and (max-width: 900px) {
  .buserprofile {
    flex-direction: column;
  }
}
@media screen and (max-width: 768px) {
  .buserprofile .sec__right .var__tabs .ueditprofile .level {
    flex-direction: column;
  }
  .buserprofile .sec__right .var__tabs .ueditprofile .level .pl {
    flex: 1;
  }
  .buserprofile .sec__right .var__tabs .ueditprofile .level .pr {
    flex: 0;
  }
  .buserprofile .sec__right .cat__btns {
    column-gap: 0.5rem;
  }
}
