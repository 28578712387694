.homePageCompThree {
    background-image: linear-gradient(to bottom, #dfe9f3, #f7fafd);
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
}

.homePageCompThree .comp_three_content_sec {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 56px;
    width: 100%;
}

.homePageCompThree .comp_three_content_sec .comp_three_text1 {
    font-family: "lexend";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    color: #405870;
}

.homePageCompThree .comp_three_content_sec .comp_three_text2 {
    font-family: "lexend";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #405870;
    margin-top: 12px;
}

.homePageCompThree .featured_agencies_sec {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homePageCompThree .featured_agencies_sec .card_sec {
    width: 80%;
    display: flex;
    overflow: hidden;
    scroll-behavior: smooth;
}

.homePageCompThree .featured_agencies_sec .prev_action_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 50px;
    margin-left: 15px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
}

.homePageCompThree .featured_agencies_sec .next_action_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
    margin-right: 15px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
}

.homePageCompThree .comp_three_text3 {
    font-family: "lexend";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #8ca6c0;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 55px 6px 0px 6px;
}

@media screen and (max-width: 768px) {
    .homePageCompThree .featured_agencies_sec .prev_action_btn{
        margin-right: 15px;
    }

    .homePageCompThree .featured_agencies_sec .next_action_btn{
        margin-left: 15px;
    }

    .homePageCompThree .featured_agencies_sec .card_sec{
        width: 95%;
    }
}