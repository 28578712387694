.UserEntireProfilePage .talentUpperSection {
    display: flex;
    justify-content: start;
    gap: 20px;
    overflow: scroll;
}
.UserEntireProfilePage .talentUpperSection > .btn {
    min-width: 160px;
    width: auto;
    height: 50px;
    border: 1px solid #E0E0E0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;

}

.UserEntireProfilePage .talentUpperSection > .btn:focus {
    box-shadow: none;
}

.UserEntireProfilePage .talentUpperSection > .selectedBtn {
    background-color: #E0E0E0;
    font-weight: 600;
}

@media screen and (max-width: 870px) {
    .UserEntireProfilePage .talentUpperSection {
        overflow-x: scroll;
        margin-bottom: 10px;
    }
    .UserEntireProfilePage .talentUpperSection > .btn {
        min-width: 109px;
        width: fit-content;
        height: 40px;
    }
    .UserEntireProfilePage .talentUpperSection > .btn:last-child {
        min-width: 210px; /* Apply min-width to the last child */
    }
}