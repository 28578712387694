.service-page-banner{
    width: 100%;
    height: 220px;
    background-color: #191E22;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.service-page-banner .banner-text{
    display: flex;
    justify-content: center;
    color: white;
    font-size: calc(1.375rem + 1.5vw);
    width: 100%;
}

.service-page-banner .sub-banner-text{
    font-size: .85rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 100%;
}