.popularagency {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
  padding: 3.2rem 0;
  width: 95%;
  max-width: 80vw;
  box-sizing: border-box;
  margin: 0 auto;
}

.popularagency .normal__heading {
  font-family: "lexend";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 50px;
  color: #405870 !important;
}

.agencies__cont {
  display: flex;
  justify-content: space-between;
  row-gap: 3rem;
  column-gap: 2rem;
  overflow: scroll;
}

.agencies__cont h6 {
  font-weight: 800;
  margin-bottom: 1.5rem;
  text-align: left;
}

.agencies__cont>ul {
  min-width: fit-content !important;
}

.agencies__cont ul li {
  list-style-type: none;
  text-align: initial;
  margin-bottom: 6px;
}

.agencies__cont ul li a {
  text-decoration: none;
  color: black;
  transition: 0.5s;
}

.agency-category{
  text-decoration: none;
  color: black;
  transition: 0.5s;
}

.agencies__cont ul li a:hover {
  color: #f76368;
}

@media screen and (max-width: 768px) {
  .popularagency {
    row-gap: 1.5rem;
    padding: 3rem 5%;
  }

  .popularagency .normal__heading {
    font-size: 24px;
    line-height: 29px;
  }

  .popularagency .agencies__cont{
    overflow: scroll;
  }

  .popularagency .agencies__cont .tab{
    padding-left: unset;
    min-width: 43%;
  }
}

  /* .agencies__cont :nth-child(3) {
    display: none;
  } */
  @media screen and (max-width: 768px) {
    .popularagency .agencies__cont .tab{
      padding-left: unset;
      min-width: 80%;
    }
  }
