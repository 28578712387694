.camp__cont .camp__top__cont {
  padding: 0 9.5rem;
  background: #0b1c38;
}
.camp__cont .camp__top {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
  padding: 6rem 0;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .camp__cont .camp__top__cont {
    padding: 0 5%;
  }
}

.camp__cont .camp {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin: 2rem 9.5rem;
}
.camp__cont .camp .camp__feature {
  width: 100%;
  position: relative;
}
.camp__cont .camp .camp__feature__img {
  /* object-fit: contain; */
  height: auto;
  width: 100%;
}
.camp__cont .camp .camp__feature__det {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1.5rem;
  width: 100%;
  box-sizing: border-box;
}
.camp__cont .camp .camp__feature__det .part__left {
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
  align-items: flex-start;
  text-align: initial;
}
.camp__cont .camp .camp__feature__det .part__left .feature__btn {
  border: none;
  color: white;
  padding: 10px 20px;
  font-family: "Poppins";
  background: #ff656a;
  cursor: pointer;
}
.camp__cont .camp .camp__feature__det .part__right {
  display: flex;
  column-gap: 0.3rem;
  align-items: center;
  justify-content: center;
}

.camp__cont .camp .camp__img__conts {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}
.camp__cont .camp .camp__img__conts .camp__img__cont {
  position: relative;
  text-align: left;
}
.camp__cont .camp .camp__img__conts .camp__text {
  position: absolute;
  bottom: 0;
  color: rgba(255, 255, 255, 0.808);
  padding: 0 0.5rem 1rem 0.5rem;
}
.camp__cont .camp .camp__img__conts .camp__img {
  object-fit: contain;
  height: auto;
  width: 100%;
}
.camp .camp__img__conts .camp__miscdet {
  display: flex;
  flex-direction: column;
  row-gap: 0.2rem;
}
.camp .camp__img__conts .camp__miscdet .camp__miscdet__t {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.3rem;
}
.camp__img__conts .camp__miscdet .camp__miscdet__t1 {
  display: flex;
  align-items: center;
  column-gap: 0.3rem;
}
.camp__img__conts .camp__miscdet .camp__miscdet__t2 {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  .camp__cont .camp {
    margin: 2rem 5%;
  }
}
@media screen and (max-width: 768px) {
  .camp__cont .camp .camp__feature__det {
    padding: 1rem;
  }
  .camp__cont .camp .camp__feature__img {
    object-fit: cover;
    min-height: 260px;
  }
}
