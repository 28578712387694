.settings_container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.setting {
    padding: 30px 30px 80px;
    text-align: start;
    max-width: 600px;
    margin: 0 auto;
    overflow: auto;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.setting>.Fields {
    width: 100%;
    margin-top: 2rem;
    margin: 0 auto;
}

/* .setting > .setting_form_buttons {
    position: fixed;
    bottom: 0;
    background: white;
    border: 1px solid #E7E3E9;
    width: fit-content;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    border: 1px solid red;
}

.setting > .setting_form_buttons > .formBtn {
    width: 260px;
    display: flex;
    justify-content: center;
}


.setting > .setting_form_buttons > .btn:hover{
    background-color: #F8F8F8;
} */

.settings_container > .formButtons > .innerFormContainer > .formBtn:nth-child(1) {
    border-right: 1px solid #E7E3E9;
}

.settings_container > .formButtons > .innerFormContainer > .formBtn {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: none;
}


.settings_container > .formButtons > .innerFormContainer > .formBtn:hover{
    background-color: #dfdfdf;
}