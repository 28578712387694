.forAgencies .fab1 {
  background-color: #0b1c39;
  padding-top: 10%;
  padding-bottom: 7%;
}

.forAgencies .button {
  border: none;
  border-radius: 0;
}

.forAgencies .join-btn {
  margin-top: 3rem;
  height: 60px;
  width: 190px;
  background-color: #ff6566;
  color: white;
  border: none;
  cursor: pointer;
  font-family: "Poppins";
}

.forAgencies .fab1 .spanOut {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 300;
}

.forAgencies .fab1 .spanIn {
  color: #ff6566;
  font-weight: 600;
}

.forAgencies .fab1 .tagline {
  color: rgba(255, 255, 255, 0.8);
  padding-top: 8px;
  margin-left: 20%;
  margin-right: 20%;
  font-weight: 400;
  font-size: 0.95rem;
}

.forAgencies .fab2 {
  background-color: #fafafa;
  padding: 3.5rem 9.75rem 3.5rem 9.75rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  
  box-sizing: border-box;
}

.forAgencies .fab2 .columns {
  margin-left: 0rem;
  margin-right: 0rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.forAgencies .fab2 .column {
  padding: 0 0 0 0;
  width: 100%;
}

.forAgencies .fab2 .column .tags {
  font-size: 32px;
  color: #ff6566;
  font-weight: 600;
}
@media only screen and (max-width:440px) {
  .forAgencies .fab2 .column .tags{
    font-size: 25px;
  }
  .forAgencies .fab2 .column p{
    font-size: 15px;
  }
}
.forAgencies .fab2 .column p {
  color: #6e6e6e;
}

.forAgencies .fab3 {
  padding-top: 112px;
  padding-left: 9.75rem;
  padding-right: 9.75rem;
}

.forAgencies .fab3 .columns {
  margin-left: 0rem;
  margin-right: 0rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.forAgencies .fab3 .fab3c {
  display: flex;

  padding-top: 112px;
}

.forAgencies .fab3 .column {
  padding: 0 0 0 0;
  display: block;
  float: left;
  width: 50%;
}
.forAgencies .fab3 .fab3c1 {
  text-align: left;
  padding: 10% 10% 10% 0%;
}
.forAgencies .fab3 .fb-img {
  /* padding: 3% 7% 3% 7%; */
  max-width: 100%;
  height: auto;
}
.forAgencies .fab3 .fab3c2 {
  padding: 10% 0% 10% 10%;
  text-align: left;
}

.forAgencies .fab3 .b {
  font-size: 32px;
  color: #0b1c39;
  font-weight: 300;
}

.forAgencies .fab3 .p {
  font-size: 32px;
  color: #ff6566;
  font-weight: 600;
}
.forAgencies .fab3 .rel {
  font-size: 24px;
  color: #0b1c39;
  font-weight: 300;
}

.forAgencies .fab3 .rec {
  font-size: 24px;
  color: #ff6566;
  font-weight: 600;
}

.forAgencies .fab3 .reviews {
  margin-top: 150px;
  margin-bottom: 150px;
}

.forAgencies .fab3 .slider {
  margin-top: 400px;
}

.forAgencies .fab5 {
  /* padding-left: 9.75rem;
  padding-right: 9.75rem; */
  margin-top: 153px;
}

.forAgencies .fab5 .columns {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.forAgencies .fab5 .pricing {
  font-size: 24px;
  color: #ff6566;
  font-weight: 600;
  margin-bottom: 4rem;
}

.forAgencies .fab5 .columns {
  text-align: left;
  margin-left: 0rem;
  margin-right: 0rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.forAgencies .fab5 .onecard {
  padding: 30px;
  margin: 12px;
  position: relative;
}

.forAgencies .fab5 .is-1 {
  width: 40px !important;
}

.forAgencies .fab5 .column {
  display: block;
  float: left;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
}

.forAgencies .fab5 .is-transparent {
  background-color: transparent;
  border: 1px solid #e7e7e7;
  border-radius: 0px;
  box-shadow: none;
}

.forAgencies .fab5 .free {
  font-size: 32px;
  color: #0b1c39;
  font-weight: 600;
}

.forAgencies .fab5 .starter {
  font-size: 24px;
  color: #0b1c39;
  font-weight: 300;
}

.forAgencies .fab5 .rev {
  color: #828282;
  font-size: 16px;
}

.forAgencies .fab5 .button {
  border: none;
  border-radius: 0;
}

.forAgencies .fab5 .starter-btn {
  height: 42px;
  width: 153px;
  background-color: #ff6566;
  color: white;
  border: none;
  position: absolute;
  bottom: 1.5rem;
  font-family: "Poppins";
}

.forAgencies .fab5 .card {
  margin-bottom: 2rem;
}

.forAgencies .fab5 .rs {
  font-size: 32px;
  color: #0b1c39;
  font-weight: 600;
}

.forAgencies .fab5 .perm {
  color: #9f9f9f;
  font-weight: 600;
  font-size: 16px;
}

.forAgencies .fab5 .basic {
  font-size: 24px;
  color: #0b1c39;
  font-weight: 300;
}

.forAgencies .fab5 .rs5 {
  font-size: 32px;
  color: #0b1c39;
  font-weight: 600;
}

.forAgencies .fab5 .perm5 {
  color: #9f9f9f;
  font-weight: 600;
  font-size: 16px;
}

.forAgencies .fab5 .premium {
  font-size: 24px;
  color: #0b1c39;
  font-weight: 300;
}

.forAgencies .fab5 .showmore {
  font-weight: 600;
  font-size: 14px;
  line-height: 185%;
  text-align: center;
}

.forAgencies .card-content {
  padding: 0.5rem;
}

@media screen and (max-width: 1024px) {
  .forAgencies .fab3 {
    padding: 8%;
  }
  .forAgencies .fab3c {
    margin-left: 0;
  }
}

@media screen and (max-width: 768px) {
  .forAgencies .fab2 {
    padding-left: 0;
    padding-right: 0;
  }
  .forAgencies .fab3 .column {
    padding: 0 0 0 0;
    display: block;
    float: left;
    width: 100%;
  }

  
  .forAgencies .fab3 {
    padding-top: 112px;
    padding-left: 5%;
    padding-right: 5%;
  }
  .forAgencies .fab3c {
    padding-top: 5%;
  }

  .forAgencies .fab3c1,
  .fab3c2 {
    padding: 5% 5% 5% 5%;
    text-align: center;
    text-align: center !important;
  }

  .forAgencies .fab-img {
    margin-top: 30px;
    margin-bottom: -50px;
    padding: 3% 7% 3% 7%;
  }

  .forAgencies .fab3c {
    display: flex;
    flex-flow: column;
  }
  .forAgencies .fab3c2 {
    order: 1;
  }
  .forAgencies .fab-img {
    order: 2;
  }
  .forAgencies .fab1 .tagline {
    margin-left: 30px;
    margin-right: 30px;
  }
  .forAgencies .fab1 h1 {
    padding-top: 2rem;
    margin-left: 20px;
    margin-right: 20px;
  }

  .forAgencies .fab1 h3 {
    margin: 5% !important;
  }
  .forAgencies .fab3c2 {
    padding: 10% 0% 10% 10%;
  }
  .forAgencies .fab5 {
    margin-top: 80px;
    padding: 0;
  }
  .forAgencies .fab5 .columns {
    display: flex;
    flex-direction: column;
  }
  .forAgencies .fab5 .onecard {
    width: auto;
    padding: 10px;
    margin: 10px 0 !important;
  }
  .forAgencies .fab5 .is-1 {
    width: 35px !important;
  }
  .forAgencies .fab5 .is-mobile {
    display: flex;
    flex-direction: row;
  }
  .forAgencies .fab5 .column {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .forAgencies .fab5 .pricing {
    margin-bottom: 40px;
  }
  .forAgencies .reviews {
    margin-bottom: 40px !important;
  }
  .forAgencies .fab5 .freecard {
    height: 350px;
  }
}
