/* ConfirmationModal.css */
.confirmation-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other elements */
  animation: fadeIn 0.3s ease-in-out; /* Fade-in animation for overlay */
}

.confirmation-box .confirmation-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(-30px);
  animation: slideDown 0.3s ease-in-out forwards; /* Slide-down animation for modal */
  width: 800px;
  height: 720px;
  overflow-y: auto;
}
.confirmation-box .confirmation-modal-content::-webkit-scrollbar {
  display: none;
}

.confirmation-box .confirmation-modal-content .confirmation-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  gap: 15px;
  margin: auto;
}

.confirmation-box .confirmation-modal-content .confirmation-form .inputAndLabelContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 10px;
}


.confirmation-box .confirmation-modal-content .confirmation-form .inputAndLabelContainer .labelContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
}

.confirmation-box .confirmation-modal-content .confirmation-form .inputAndLabelContainer .labelContainer .labelSubtext {
  color: #8C8C8C;
  font-weight: 300;

}

.confirmation-box .confirmation-modal-content .confirmation-form .inputAndLabelContainer .inputContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  background-color: white;
  height: 60px;
  padding: 0 20px;
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  width: 100%;
  position: relative;
}

.confirmation-box .confirmation-modal-content .confirmation-form .inputAndLabelContainer .inputContainer input {
  border: none;
  background-color: transparent;
  outline: none;
  width: 100%;
}
.confirmation-box .confirmation-modal-content .confirmation-form .inputAndLabelContainer .inputContainer input::placeholder {
  font-weight: 300;
  font-size: 18px;
  color : #B1B1B1;

}

.confirmation-box .confirmation-modal-content .confirmation-form .inputAndLabelContainer .textAreaInput {
  height: 200px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.confirmation-box .confirmation-modal-content .confirmation-form .inputAndLabelContainer .textAreaInput textarea {
  border: none;
  background-color: transparent;
  outline: none;
  width: 100%;
  height: 180px;
  padding: 10px 0;
}




.confirmation-box .confirmation-modal-content .confirmation-form .dropdownTemplateListContainer {
  position: absolute;
  top: 100%; /* Position the dropdown below the input */
  left: 0;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  margin-top: 5px;
  padding: 10px;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 5px;
  
}

.confirmation-box .confirmation-modal-content .confirmation-form .dropdownTemplateListContainer div {
  padding: 8px 15px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  text-align: left;
}

.confirmation-box .confirmation-modal-content .confirmation-form .dropdownTemplateListContainer .singleTemplateInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.confirmation-box .confirmation-modal-content .confirmation-form .dropdownTemplateListContainer div:hover {
  background-color: #f0f0f0;
}


.confirmation-box .confirmation-modal-content .confirmation-form .saveTemplateContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  gap: 10px;
  padding: 20px;
  border: 1px solid #F7F7F7;
  background-color: #F7F7F7;
  border-radius: 10px;
  text-align: left;
}

.confirmation-box .confirmation-modal-content .confirmation-form .saveTemplateContainer .saveTemplateHeadingLine {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: start;
  font-size: 18px;
  font-weight: 500;
}

.confirmation-box .confirmation-modal-content .confirmation-form .saveTemplateContainer .saveTemplateDescription {
  font-size: 18px;
  font-weight: 400;
}
.confirmation-box .confirmation-modal-content .modalButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 500px;
  margin: auto;
  margin-top: 20px;
}


@keyframes slideDown {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
