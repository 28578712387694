.KnowledgesectionContainer {
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  gap: 80px;
  background-color: #f7f7f7;
  align-items: center;
  box-sizing: border-box;
}

.KnowledgesectionContainer .KnowledgeList {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: start;
  flex-wrap: wrap;
}

.KnowledgesectionContainer .KnowledgeList .IndividualKnowledgeItem {
  width: 350px;
  border-radius: 20px;
  padding: 20px;
  background-color: white;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media screen and (max-width: 480px) {
  .KnowledgesectionContainer {
    padding: 30px 0 30px 30px;
    gap: 40px;
    align-items: start;
  }
  .KnowledgesectionContainer .KnowledgeList {
    flex-direction: column;
  }

  .KnowledgesectionContainer .KnowledgeList .IndividualKnowledgeItem {
    width: 300px;
  }
}
