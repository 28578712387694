.horizontal_carousel {
    width: 100%;
    max-width: 1260px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* position: absolute; */
    margin-top: -20px;
}

.horizontal_carousel .carousel_sec {
    display: flex;
    overflow: hidden;
    scroll-behavior: smooth;
    align-items: center;
}

.horizontal_carousel .hidden_elem {
    min-width: 120px;
    min-height: 120px;
    background-color: transparent;
    border-radius: 50%;
    margin: 20px 65px;
}

.horizontal_carousel .img-wrapper {
    min-width: 120px;
    min-height: 120px;
    border-radius: 50%;
    margin: 35px 65px;
    filter: grayscale(1);
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
}
.horizontal_carousel .img_item {
    width: 80px;
    max-width: 100%;
    height: auto;
}

@media screen and (max-width: 768px) {
    .horizontal_carousel .hidden_elem {
        min-width: 70px;
        min-height: 70px;
        margin: 15px 20px 20px 0;
    }
    .horizontal_carousel .img-wrapper{
        min-width: 70px;
        min-height: 70px;
        margin: 15px 20px 20px 0;
    }
    .horizontal_carousel .img_item{
        width: 50px;
    }
}

@media screen and (max-width: 1024px) and (min-width: 769px) {
    .horizontal_carousel .carousel_sec {
        margin-left: -110px;
    }
}
