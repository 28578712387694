@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500&display=swap");

.UserProfileSidebar {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 auto;
  width: 300px;
}

.UserProfileSidebar .userProfile {
  display: flex;
  flex-direction: column; /* Stack the elements vertically */
  justify-content: space-evenly;
  align-items: center;    /* Center the child elements */
  text-align: center;    /* Center vertically */
  background-color: #F7F7F7;
  min-height: 340px;
  border-radius: 30px;
  border: none;
  text-align: center; 
  margin-top: 0;
  padding: 0 10px;
  /* Adjust the min-height as needed */
}


.UserProfileSidebar .userProfile img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  max-width: 150px;
  min-height: 150px;
}
.UserProfileSidebar .userProfile .userProfileInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.UserProfileSidebar .userProfile .UserProfileInfo p:nth-child(1){
  font-family: sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: black;
}

.UserProfileSidebar .userProfile .UserProfileInfo p:nth-child(2){
  font-family: sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: black;
}
.UserProfileSidebar .userProfile .UserProfileInfo p:nth-child(3){
  font-family: sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: black;
}


.UserProfileSidebar .ApplyToUserPool {
  min-height: 60px;
  display: flex;
  text-align: center;

}

.UserProfileSidebar .ApplyToUserPool button{
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: 10px;
  background-color: black;
  color: white;
  width: 100%;

}

.UserProfileSidebar .userAboutSideBar {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 1rem;
  font-weight: 400;
}

.UserProfileSidebar .userAboutSideBar .userSocialLinks{
  display: flex;
  justify-content: start;
  gap: 5px;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
}

.userAboutSideBar > .location > .material-symbols-outlined{
  align-items: center;
  padding-right: 5px;
}
.userAboutSideBar > .location{
  display: flex;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 400;
}
.userAboutSideBar > .about{
  padding-bottom: 30px;
  border-bottom: 1px solid #E0E0E0;
}
.socialLinkItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  color: black;
  height: 40px;
  padding: 10px;
  cursor: pointer;
  border: 0px solid; /* Optional, just a placeholder for no border */
  transition: background-color 0.2s ease-in-out;
}

.socialLinkItem:hover {
  background-color: #f0f0f0; /* Optional: Adds a hover effect */
  border-radius: 10px;
}

.socialLinkLabel {
  font-size: 16px;
  margin-right: 10px;
}

.socialLinkIcon {
  font-size: 20px;
  color: black;
}

@media (max-width: 600px) { 

  .UserProfileSidebar {
      width: 100%;
      margin: 0 auto;
      padding: 0;
      box-sizing: border-box;
  }
  .UserProfileSidebar .userProfile {
    margin: 20px 15px 10px 15px;
    padding: 0 10px;
    /* Adjust the min-height as needed */
  }
  .UserProfileSidebar .userAboutSideBar {
    margin: 0 15px;
  }
  .userAboutSideBar > .location{
    margin-top: 0px;
    margin-bottom: 10px;
  }
  .UserProfileSidebar .ApplyToUserPool button{
    margin: 0 15px;
  }
  .UserProfileSidebar .userAboutSideBar .userSocialLinks{
    margin-top: 0px;
  }
  .userAboutSideBar > .about{
    padding-bottom: 10px;
    border-bottom: 1px solid #E0E0E0;
  }
  .UserProfileSidebar .ApplyToUserPool {
    width: 100%;
    margin-bottom: 10px;
  }
}