.talentUpperSection {
    display: flex;
    justify-content: start;
}
.talentUpperSection > div{
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 0 10px;
}
.talentUpperSection > div > p{
    font-weight: 400;
}

.talentUpperSection > .btn {
    min-width: 160px;
    width: auto;
    height: 50px;
    outline: 1px solid #E7E3E9;
    border: none;
    border-radius: 0;
    color: #525464;
    display: flex;
    align-items: center;
}

.talentUpperSection > .btn:focus {
    box-shadow: none;
}

.talentUpperSection > .selectedBtn {
    background-color: #F7F5F7;
    font-weight: 600;
}

@media screen and (max-width: 632px) {
    .talentUpperSection {
        overflow-x: scroll;
        margin-bottom: 10px;
    }
}