.request-item1 {
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    margin: 10px 0;  
    background-color: #fff;
  }

  
  .request-header-upcom {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 20px;
    border: 1px solid #E0E0E0;
    border-radius: 20px;
    width: 600px; 
    column-gap: 5px;
    font-size: 15px;
  }

  .sunday-holiday {
    background-color: #E7E7E7; 
    border-radius: 50%;
    color: #ccc;
    cursor: not-allowed;
    pointer-events: none;
  }
  
  /* Requests.css */
  .sunday-holiday .react-datepicker__day--selected {
    /* /* background-color: red !important; */
    color: white !important; 
    background-color: #E7E7E7 !important; 
    border-radius: 50% !important;
  }
  
  .available-date {
    background-color: grey;
    color: white;
  }
  
  
  
  .request-header p {
    margin: 0 0 0 10px;
    flex: 1; 
    font-weight: 400;
    line-height: 27px;
  }
  
  .profile-img {
    border: 1px solid #000;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    background-color: #ccc; 
  }
  
  .request-header-upcom span {
    color: gray;
    margin-left: 5px;
    font-weight: 400;
    line-height: 27px;
  }
  
  .icon.top-right {
    color: black;
    border-radius: 50%;
    padding: 5px;
  }
  
  .request-actions {
    display: flex;
    gap: 10px;
    background-color: #fff;
  }
  
  .action-item {
    display: flex;
    align-items: center;
    border: 1px solid #E0E0E0;
    border-radius: 20px;
    padding: 10px;
    width: 135px;
    gap: 10px;
  }
  
  .action-item p {
    margin: 0;
    margin-left: 5px;
    font-weight: 400;
    font-size: 20px;
  }

  .action-item-res:hover,.action-button:hover{
    background-color: #F7F7F7;
    cursor: pointer;
  }
  
  .action-button {
    border: 1px solid #E0E0E0;
    border-radius: 20px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    background-color: white;
    height: 70px;
  }
  
  .action-button.active {
    background: rgba(224, 224, 224, 1);
  
  }
  .action-item-res.active {
    background: rgba(224, 224, 224, 1);
    color: black;
  }

  .action-item-res:hover {
    cursor: pointer;
  }


  
  .active:focus {
    border-bottom: none;
  
  }
  
  /* Styles for the second section */
  .details-section1 {
    border-radius: 20px;
    background-color: #F7F7F7;
    padding: 30px;
  }
  
  .details-section1 p {
    margin: 0.625rem 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
  }
  
  .description{
    width: 60%;
  }
  
  .details-section span {
    font-weight: bold;
  }
  
  .calendar-container {
    margin-top: 10px;
  }
  .calendar-container p {
    font-weight: 600;
    line-height: 20px;
  }
  
  .time-slot-container {
    margin-top: 10px;
  }
  
  .date-picker {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    width: 100%;
  }
  
  .time-input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    width: 100%;
  }
  
  .buttons-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .cancel-button {
    background-color: white;
    color: black;
    border: 1px solid black;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .reschedule-button {
    background-color: black;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 8px 15px;
    cursor: pointer;
  }
  .details-container{
    display: flex;
    justify-content: space-between;

  }
  .join-button{
    background-color: black;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 8px 15px;
    cursor: pointer;
    height: 45px;

  }


  
  /* Flex layout for the reschedule section */
  .reschedule-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    
    
  
  }
  .details-section-container1{
    max-width: 1200px;
    margin: 0 auto;
    
  }
  
  .reschedule-section > div {
    flex: 1;
    margin-right: 20px;
    
    
    
  }
  
  .reschedule-section .buttons-container {
    flex: 0 0 200px;
    display: flex;
    flex-direction: column;
  }
  
  /* Custom styles for the DatePicker component */
.react-datepicker {
  font-family: 'Arial', sans-serif;
  border: none;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 356px;
}

.react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
  padding: 1rem;
}

.react-datepicker__current-month {
  /* font-size: 1.2rem;
  font-weight: 500; */
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 2.5rem;
  line-height: 2.5rem;
  margin: 0.2rem;
  font-size: 0.875rem;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #282727;
  color: #fff;
  border-radius: 50%;
}

.react-datepicker__day--outside-month {
  color: #ccc;
}

.react-datepicker__navigation {
  top: 1.5rem;
}

.react-datepicker__navigation--previous {
  left: 1rem;
}

.react-datepicker__navigation--next {
  right: 1rem;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day:hover {
  background-color: #000;
  color: #fff;
  border-radius: 50%;
}

.react-datepicker__day--today {
  font-weight: bold;
  color: white; /* Change today's date color here */
  background-color: rgb(172, 172, 172);
  border-radius: 50%; /* rounded appearance to match other date styles */
}

/* .react-datepicker__month-container {
  padding-bottom: 1rem;
} */

.react-datepicker__month-container {
  float: none;
}
/* Add these styles for the time slots */
.time-slot {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
  background-color: #fff;
  cursor: pointer;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.time-selector::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.time-selector {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.time-slot.active {
  background-color: black;
  color: white;
}

.time-selector.time-slot{
  background-color: black;
  color: white;
}

.time-slot p {
  margin: 0 5px;
}

/* Modify the container */
.time-slot-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.time-slot-container p {
  font-weight: 600;
  margin-bottom: 10px;
}

.time-selector{
  border: 1px solid #E0E0E0;
  background-color: white;
  width: 500px;
  height: 355px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  border-radius: 20px;
  margin-top: 5px;

}

.iconSize {
  height: 22px;
  width: 22px;
}