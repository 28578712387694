.MentorsPage .ActivityTabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 20px;
}
.MentorsPage .filterSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}

.MentorsPage .filterTab {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 20px;
  font-size: 18px;
}

.MentorsPage .filterTab .filterIcon {
  display: flex;
  justify-content: start;
  gap: 20px;
  align-items: center;
}

.MentorsPage .filterTab .filteringOption {
  display: flex;
  justify-content: start;
  gap: 20px;
  align-items: center;
}

.MentorsPage .filterTab .selectedFilters {
  display: flex;
  gap: 20px;
  justify-self: start;
  align-items: center;
}

.MentorsPage .filterTab .selectedFilters .singleFilter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #f7f7f7;
  background-color: #f7f7f7;
  border-radius: 20px;
  padding: 7px 15px;
  color: black;
  cursor: pointer;
  gap: 10px;
}

.MentorsPage .filterTab .selectedFilters .singleFilter:hover .crossIcon {
  display: block;
}

.MentorsPage .filterTab .filterTabDropdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  font-size: 18px;
  color: #b1b1b1;
  border-radius: 30px;
  border: 1px solid #f7f7f7;
  padding: 7px 15px;
  background-color: #f7f7f7;
  position: relative;
}

.MentorsPage .filterTab .filterTabDropdown .filterSeachInput {
  border: none;
  outline: none;
  background-color: transparent;
}

.MentorsPage .filterTab .filterTabDropdown .variousOptions {
  position: absolute;
  top: 100%;
  left: 0;
  width: fit-content;
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;

  max-height: 300px;
  overflow-y: auto;
}
.MentorsPage .filterTab .filterTabDropdown .variousOptions > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 7px 15px;
  color: black;
  cursor: pointer;
  width: fit-content;
  text-align: left;
}

.MentorsPage .filterTab .filterTabDropdown .variousOptions > div:hover {
  background-color: #f7f7f7;
}

.MentorsPage .selectAllOption {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.MentorsPage .filterTab .singleFilter.Company {
  border-color: #ffa8a8;
  background-color: #ffd6d6;
}

.MentorsPage .filterTab .singleFilter.Industry {
  border-color: #5182ff;
  background-color: #bacdff;
}

.MentorsPage .filterTab .singleFilter.Expertise {
  border-color: #e9e000;
  background-color: #fffb95;
}

.MentorsPage .mentorsPageContainer {
  margin: 40px 0;
  column-count: 4; /* Number of columns */
  column-gap: 20px; /* Spacing between columns */
  padding: 0 15px;
}

.MentorsPage .mentorsPageContainer .mentorCard {
  /* display: grid;
  grid-template-columns: 3fr 4fr 4fr;
  border: 1px solid #e0e0e0;
  border-radius: 20px; */
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #f7f7f7;
  /* min-width: 320px; */
  padding: 30px;
  border-radius: 10px;
  height: max-content;
  text-align: left;

  break-inside: avoid; /* Prevent breaking elements across columns */
  margin-bottom: 20px; /* Add spacing between items */

  transition: all 0.3s ease;
}

.MentorsPage .mentorsPageContainer .mentorCard:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  scale: 1.02;
}

.MentorsPage .mentorsPageContainer .mentorCard .gridChild {
  padding: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.MentorsPage .mentorsPageContainer .mentorCard .mentorUserInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #f7f7f7;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.MentorsPage
  .mentorsPageContainer
  .mentorCard
  .mentorUserInfo
  .mentorNameAndLinkedIn {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  gap: 10px;
}

.MentorsPage .mentorsPageContainer .mentorCard .mentorUserInfo .mentorName {
  font-weight: 700;
  font-size: 22px;
  line-height: 29.7px;
}

.MentorsPage .mentorsPageContainer .mentorCard .mentorUserInfo .mentorRole {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
}

.MentorsPage
  .mentorsPageContainer
  .mentorCard
  .mentorUserInfo
  .companiesAssociated {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}

.MentorsPage
  .mentorsPageContainer
  .mentorCard
  .mentorUserInfo
  .companiesAssociated
  .companyName {
  padding: 5px 10px;
  border-radius: 20px;
  background-color: #e0e0e0;
  border: 1px solid #e0e0e0;
  font-weight: 500;
  font-size: 12px;
}

.MentorsPage .mentorsPageContainer .mentorCard .aboutPara {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  font-weight: 400;
  font-size: 14px;
  line-height: 18.9px;
}

.MentorsPage
  .mentorsPageContainer
  .mentorCard
  .mentorAboutInfo
  .aboutPara::-webkit-scrollbar {
  display: none;
}

.MentorsPage .mentorsPageContainer .mentorCard .linkedInButton {
  /* display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  height: 44px;
  margin-top: 20px;
  padding: 0 10px;
  background-color: white;
  font-size: 12px; */

  border: none;
}

/* .MentorsPage .mentorsPageContainer .mentorCard .mentorSessionInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 100%;
} */

.MentorsPage .mentorsPageContainer .mentorCard .mentorSessionInfo .sessionList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  scrollbar-width: none;
}

/* For WebKit-based browsers (Chrome, Safari, etc.) */
.MentorsPage
  .mentorsPageContainer
  .mentorCard
  .mentorSessionInfo
  .sessionList::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}
.MentorsPage .mentorsPageContainer .mentorCard .sessionButtons {
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-top: auto;
}

@media (max-width: 1000px) {
  .MentorsPage .mentorsPageContainer {
    margin: 40px 0;
    column-count: 3; /* Number of columns */
    column-gap: 20px; /* Spacing between columns */
    padding: 0 15px;
  }
}

@media (max-width: 800px) {
  .MentorsPage .filterSection {
    gap: 10px;
    align-items: center;
    width: 100%;
    padding: 5%;
  }
  .MentorsPage .filterTab {
    gap: 10px;
  }
  .MentorsPage .filterTab .filterIcon {
    gap: 10px;
    font-size: 16px;
  }

  .MentorsPage .filterTab .filterTabDropdown {
    max-width: 170px;
    font-size: 16px;
    padding: 7px 15px;
    background-color: #f7f7f7;
    /* overflow: hidden; */
    height: 350px;
    overflow-x: auto;
  }

  .MentorsPage .mentorsPageContainer {
    margin: 40px 0;
    column-count: 2; /* Number of columns */
    column-gap: 20px; /* Spacing between columns */
    padding: 0 15px;
  }

  .MentorsPage .mentorsPageContainer .mentorCard .mentorUserInfo {
    border-radius: 20px;
  }
  /* 
  .MentorsPage
    .mentorsPageContainer
    .mentorCard
    .mentorSessionInfo
    .sessionList {
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: fit-content;
  } */

  .MentorsPage .mentorsPageContainer .mentorCard .mentorSessionInfo {
    gap: 15px;
    padding-top: 0;
  }

  @media (max-width: 600px) {
    .MentorsPage .mentorsPageContainer {
      margin: 20px 0;
      column-count: 1; /* Number of columns */
      column-gap: 10px; /* Spacing between columns */
      padding: 0 15px;
    }
  }
}
