.deleteConfirmDialog > .deleteDialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px 20px 20px;
    width: 350px;
    border: none;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    background-color: white;
}

.deleteConfirmDialog > .deleteDialog > p {
    margin-bottom: 30px;
    color: #616161;
}

.deleteConfirmationBtns{
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 20px;
}

.deleteConfirmationBtns > .dialogButton {
    width: 100px;
    height: 40px;
    border: none;
}

.deleteConfirmationBtns > .dialogCloseBtn {
    /* border: 1px solid #cacaca; */
    background-color: #f3f3f3;
    color: black;
}

.deleteConfirmationBtns > .dialogCloseBtn:hover {
    /* border: 1px solid #cacaca; */
    background-color: #e9e9e9;
    color: black;
}

.deleteConfirmationBtns > .dialogDeleteBtn {
    color: white;
    background-color: black;
}

.deleteConfirmationBtns > .dialogDeleteBtn:hover {
    color: white;
    background-color: rgb(32, 32, 32);
}