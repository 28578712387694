.rscontainer {
  /* padding: 16px; */
  /* margin: 16px auto; */
}

.rscontainer .rsresult {
  font-family: "Poppins";
  font-size: 0.9rem;
  margin-bottom: 2px;
}

.rscontainer .rsstars {
  display: flex;
  justify-content: flex-end;
  gap: 1px;
}

.rscontainer .rsstars .rsstar {
  position: relative;
  cursor: pointer;
}

.rscontainer .rsstars .stars_radio-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
}
