.update_discard_button {
    background-color: transparent;
    display: flex;
    gap: 20px;
    
    border: transparent;
    justify-content: flex-start;
    padding-right: 0;
    margin: 2.5% 2.5% 2.5% 2.5%;
    padding: 0 42px;
    font-family: Lexend;;
}

.update_discard_button button {
    background-color: transparent;
    border-radius: 3px;
    padding: 5px 20px;
    transition-duration: 0.4s;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.update_discard_button button:first-child {
    background: #191E22;
    border: 1px solid #E3E7E9;
    color: #FFFFFF;
}

.update_discard_button button:last-child {
    color: #191E22;
    border: none
}