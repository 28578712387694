@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');


.main-container-ment{
  max-height: 650px;
  height: 100vh;
  position: relative;
}

.userProfileMentoring {
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  font-family: "Poppins", sans-serif;
  align-items: flex-start;

}

.userProfileMentoring .singleSession {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: white;
  width: 280px; /* Default width */
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #E0E0E0;
  border-radius: 20px;
  cursor: pointer;
} 


.singleSession .session_type {
  background-color: lightgrey;
  color: black;
  padding: 6px 20px 6px 20px;
  white-space: nowrap;
  border-radius: 8px;
  width: 97px;
  height: 30px;
  font-size: small;
}

.singleSession .session_name {
  font-size: 32px;
  color: black;
  text-align: left;
  line-height: 1.1;
  overflow: hidden;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
}

.singleSession .session_desc {
  font-weight: 300;
  font-size: 15px;
  text-align: start;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: grey;
  overflow: hidden;
  text-overflow: ellipsis;
}

.singleSession .goIntoSessionDetail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
  padding: 0 10px;
  border-radius: 15px;
  height: 60px;
  font-size: 17px;
}

.singleSession .goIntoSessionDetail .session-duration {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  font-weight: 400;
  color: black;
}

.singleSession .goIntoSessionDetail .unlockButton {
  color: black;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.iconSize20 {
  width: 17px;
  height: 17px;
}

/* Button to open review section */
.theReviewButtonDiv {
  width: 100%;
  display: flex;
  justify-content: flex-end; /* Align the button to the right */
  padding-bottom: 12px;
  bottom: 0;
  right: 0;
  position: sticky;
  align-self: flex-end;
  z-index: 1000;
  background-color: transparent;
}

.reviewButton {
  padding: 10px 30px;
  
  font-size: 17px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23c2c0c0" viewBox="0 0 24 24" width="24" height="24"><path d="M7 10l5 5 5-5z"/></svg>') no-repeat left 5px center;
  color: black;
  background-color: #F7F7F7;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  width: 286px;
}


/* Review section styling */
.reviewSection-main {
  margin-top: 20px;
  padding: 20px;
  background-color: #F7F7F7;
  border: 1px solid #E0E0E0;
  border-radius: 12px;
  font-family: "Poppins", sans-serif;
}

.reviewSection {
  display: flex;
  gap: 30px;
}

.reviewHeader {
  text-align: left;
  margin-bottom: 10px;
}

.reviewHeader p {
  font-weight: 500;
  font-size: 17px;
  line-height: 27px;
}

.reviewHeader h2 {
  font-size: 32px;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 42px;
}

.ratingSummary {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  padding-bottom: 26px;
}

.ratingItem {
  text-align: start;
  display: flex;
  gap: 12px;
  padding-top: 26px;
}

.ratingItem h3 {
  font-size: 20px;
  margin: 0;
  font-weight: 700;
}

.border-bottom {
  border: 1px solid #E0E0E0;
  margin-top: 26px;
  width: 100%;
}

.ratingItem p {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
}

.ratingStars {
  font-size: 20px;
  color: rgb(11, 11, 11);
  width: 140px;
  height: 30px;
}

.reviewGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two-column layout */
  gap: 20px;
}

.reviewCard {
  background-color: #FFFFFF;
  padding: 30px;
  border-radius: 20px;
  text-align: start;
  border: 1px solid #E0E0E0;
}

.reviewRating {
  font-size: 20px;
  color: gold;
  margin-bottom: 10px;
}

.reviewText {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}

.reviewAuthor {
  font-weight: 600;
  padding-top: 14px;
  font-size: 14px;
  text-align: start;
}

/* View More button styling */
.viewMoreButton {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #E0E0E0;
  border: 1px solid #E0E0E0;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

/* Media query for responsiveness */

@media only screen and (max-width: 768px) {
  .main-container-ment{
    max-height: 320px;
    height: 100vh;
    position: relative;
  }
  
  .userProfileMentoring .singleSession {
    width: 100%; /* Take full width on smaller screens */
  }

  .singleSession .goIntoSessionDetail .unlockButton {
    color: black;
    background-color: white;
  }

  .reviewSection {
    flex-direction: column;
    border-radius: 0;
    gap: 15px;
  }

  .theReviewButtonDiv {
    width: 100%;
    display: flex;
    justify-content: flex-end; /* Align the button to the right */
    padding: 20px;
    bottom: 0;
    right: 0;
    position: sticky;
    align-self: flex-end;
    z-index: 1000;
    background-color: transparent;
    display: none;
  }

  .reviewButton {
    margin-left: 7rem;
    /* margin-bottom: 9px; */
    width: 286px;
    text-align: center;

  }

  .reviewGrid {
    grid-template-columns: 1fr; /* Single-column layout on smaller screens */
  }

  /* Ensuring .theReviewButtonDiv is sticky in mobile view as well */
  .theReviewButtonDiv {
   
    position: sticky;

  }
}