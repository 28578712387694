.theMessageHeadline {
    height: 60px;
    padding: 0 20px;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border: 0.5px solid #E0E0E0;
    width: 100%;
    font-size: 20px;
  }
  
  .theMessageHeadline:hover {
    background-color: #d7d9d7;
  }
  .bolder-text {
    font-weight: 700;
  }
  
  .theMessageDetails {
  
    padding: 1rem 3rem; 
    padding-bottom: 0;
    margin-top : 0;
    border-bottom-left-radius:30px; border-bottom-right-radius:30px;
    border: 0.5px solid #E0E0E0;
  }

  .theMessageDetails .theMessageInfo {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: start;
    font-size: 16px;
  }

  .theMessageDetails .theMessageInfo div{
    display: flex;
    gap: 10px;
    align-items: center;
  } 

  .theMessageDetails .theMessageInfo .infoTitle{
    font-weight: 600;
    font-size: 17px;
  } 
  .messageResponseButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.5rem 0 1rem 0;
  }
  
  .messageResponseButtons button {
    font-weight: 500;
    padding: 0.5rem 2rem;
    border-radius: 1rem;
  }
  
  .messageResponseButtons button:hover {
    background-color: black;
    color: white;
  }