.brescards .brescards .res__card {
  display: flex;
  border: 2px solid rgba(221, 221, 221, 0.726);
  padding: 0.8rem;
  box-sizing: border-box;
  width: 100%;
}
.brescards .res__card {
  display: flex;
  border: 2px solid rgba(221, 221, 221, 0.726);
  padding: 0.8rem;
  box-sizing: border-box;
}
.brescards .res__card .sec__left {
  flex: 0.8;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 0.6rem;
  border: none;
}
.brescards .res__card .sec__left .details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* row-gap: 0.2rem; */
}
.brescards .res__card .sec__left .details .heartempty {
  object-fit: contain;
  height: 0.8rem;
  margin-left: 0.4rem;
  cursor: pointer;
}
.brescards .res__card .sec__left .details .stars {
  display: flex;
  column-gap: 0.2rem;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.8rem;
}
.brescards .res__card .sec__left .details .stars .num__reviews {
  font-size: 0.75rem;
  padding: 0 0.35rem;
  box-sizing: border-box;
  margin-left: 1rem;
  border-radius: 3px;
  font-weight: 600;
  text-align: center;
  color: #ff8686d6;
  border: 1.5px solid#ffc7c7d6;
  background: #fff9f9;
  cursor: pointer;
}
.brescards .res__card .sec__left .details .tags {
  display: flex;
  column-gap: 0.5rem;
  row-gap: 0.3rem;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  float: left;
  margin-bottom: 1.4rem;
  width: 100%;
}
.brescards .res__card .sec__left .details .tags .tag {
  font-size: 0.75rem;
  padding: 0.2rem 0.35rem;
  box-sizing: border-box;
  border-radius: 3px;
  color: grey;
  font-weight: 500;
  background: rgba(211, 211, 211, 0.582);
  cursor: pointer;
}
.brescards .res__card .sec__left .details .misc__details {
  display: flex;
  column-gap: 2rem;
  row-gap: 0.3rem;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  float: left;
  margin-bottom: 0.5rem;
}
.sec__left .details .misc__details .part {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.3rem;
}
.brescards .res__card .sec__right {
  flex: 0.2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  row-gap: 0.5rem;
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  border: none;
}

.brescards .res__card .sec__right button {
  font-size: 0.7rem;
  border-radius: 1px;
  padding: 12px 23px;
  box-sizing: border-box;
  border: 1px solid #ff656a;
}
.brescards .res__card .sec__right .getquote {
  color: white;
  color: #ff656a;
  background: transparent;
  cursor: pointer;
}
.brescards .res__card .sec__right .viewprofile {
  color: white;
  background: #ff656a;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .brescards .res__card {
    flex-direction: column;
    row-gap: 1rem;
  }
  .brescards .res__card .sec__right {
    flex-direction: row;
    column-gap: 0.5rem;
    justify-content: flex-end;
    align-items: flex-end;
  }
}
