

  .generalPhotoContainer  {
    width: 100%;
    display: flex;
    align-items: center;
    height: 60px;
    gap: 10px;
  }

  .generalPhotoContainer .uploadedContent {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #F7F7F7;
    border-radius: 10px;
    padding: 0 15px;
    color: #B1B1B1;
    height: 60px;
    flex-grow: 1;

  } 

  .generalPhotoContainer .uploadedContent img {
    width: 40px;
    height: 40px;
    border-radius: 10px;
  }

  .generalPhotoContainer .uploadedContent p {
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 400px;
  }
  .generalPhotoContainer .uploadLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    border: 1px solid #e0e0e0;
    background-color: #e0e0e0;
    border-radius: 20px;
  } 