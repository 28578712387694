.PreviewPopup {
  max-width: 100%;
  width: 400px;
  border: 1px solid #E0E0E0;
  border-radius: 20px;
  position: absolute;
  top: 13%;
  right: 0%;
  background-color: white;
  text-align: left;
  color: black;
  height: 600px;
  overflow-y: auto;
  font-family: General, Sans Variable;
  
}

.popup-header {
  display: flex;
  background-color: #f7f7f7;
  gap: 15px;
  align-items: center;
  height: 60px;
  border: 1px solid #E0E0E0;
  padding: 35px;
}

.popup-img {
  border-radius: 80%;
  height: 50px;
  width: 50px;
  background-color: #333;
}

.popup-name {
  font-weight: 500;
  letter-spacing: .2rem;
  color: black;
  font-size: 23px;
}
.popup-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userCommentComponent {
  margin: 15px;
  padding: 30px;
  background-color: #f7f7f7;
  border-radius: 10px;
}

.userCommentComponent .commentTitle {
  font-weight: 800;
  margin-left: 10px;
}

.experience-item {
  margin-bottom: 15px;
  border: none;
  display: flex;
  justify-content: start;
  gap: 10px;
  background-color: #F7F7F7;
}

.writtenExperience {
  display: inline;
}

.companyLogoContainer {
  display: inline;
}

.experience-item-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  gap: 5px;
}

.experience-item-company,
.experience-item-title {
  font-weight: bold;
}

.experience-item-description {
  padding-top: 6px;
  font-weight: 400;
}

.experience-item-duration {
  font-weight: 400;
  color: #505050;
  letter-spacing: .1rem;
}

.closeButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  border-radius: 20px;
  border: 1px solid #E0E0E0;
}

.closeButton:hover {
  background-color: #F7F7F7;
}

.closeButton button {
  padding: 2px 5px;
  background-color: transparent;
  color: black;
  border: 2px solid black;
  border-radius: 7px;
  border: none;

}

.close-button-img{
  width: 23px;
}





.experience-item-button {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  width: 80%;
  margin: auto;

}

.check-availability-button, 
.select-profile-button {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.check-availability-button {
  background-color: white;
  color: black;
  border: 2px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.check-availability-button:hover {
  background-color: #f0f0f0;
}

.select-profile-button {
  background-color: black;
  color: white;
  border: 2px solid #E0E0E0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}


.select-profile-button:hover {
  background-color: #333;
}




.popup-title .currentMemberInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  margin: 20px;
  flex-grow: 1;
}

.popup-title .currentMemberInfo .currentMemberCancel{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}



.popup-title .currentMemberInfo .currentMemberNameInfo{
  

  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap : 10px;
  border-radius: 20px;
  border: 1px solid #E0E0E0;
  font-weight: 600;
  padding: 0 10px;
  background-color:  #F7F7F7;
  font-size: 20px;
  height: 60px;

}

.popup-title .currentMemberInfo  .membersTagsAndSkills {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.popup-title .currentMemberInfo  .membersTagsAndSkills .theHeadingLine {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 15px;
}

.popup-title .currentMemberInfo  .membersTagsAndSkills .theHeadingLine .circleWithTitle {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  font-weight: 600;
}

.popup-title .currentMemberInfo  .membersTagsAndSkills .contentContainer {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 15px;
  padding-left: 15px;
}

.popup-title .currentMemberInfo  .membersTagsAndSkills .contentContainer .singleMentorSkill {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 20px;
  padding: 0 10px;
  background-color: black;
  border : 1px solid black;
  color: white;
font-weight: 500;

}
.popup-title .currentMemberInfo  .membersTagsAndSkills .contentContainer .singleMentorTag {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 20px;
  padding: 0 10px;
  background-color: white;
  border : 1px solid black;
  font-weight: 400;

}
.popup-title .currentMemberInfo  .membersTagsAndSkills .contentContainer .singleUserSkill {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 20px;
  padding: 0 10px;
  background-color: #E0E0E0;
  border : 1px solid #E0E0E0;
  font-weight: 400;


}


.popup-title .currentMemberInfo  .currentMemberOtherInfo{

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  border-radius: 20px;
  border: 1px solid #E0E0E0;
  gap: 10px;
  padding: 20px;

  min-height: 60px;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  flex-wrap: wrap;
  background-color:  #F7F7F7;
  
  }

.popup-title .currentMemberInfo .currentMemberOtherInfo .currentMemberSingleOtherInfo {

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 5px;
  
  border-radius: 10px;
  border: 1px solid #E0E0E0;
  height: 30px;
  min-height: 30px;
  padding: 0 10px;
  background-color: white;
  
}




.PreviewPopup .resume-image-container {
  position: relative;
  width: 40px;
  height: 40px;
}

.PreviewPopup .resume-image-container .resume-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

@media (max-width: 600px) { 
  .PreviewPopup {
    position: fixed;
    width: 100%;
    /* height: 100%; */
    height: calc(100% - 5rem);
    top: 5rem;
    right: 0;
    border: none;
    border-radius: 0;
    background-color: white;
    overflow-y: auto;
    z-index: 1000;
  }
  body.popup-open {
    overflow: hidden;
  }





.popup-title .currentMemberInfo .currentMemberNameInfo{
  padding: 15px;
  font-size: 26px;
}






}
