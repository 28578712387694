.header_new {
  display: flex;
  height: 70px;
  position: sticky;
  top: 0;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 0 13%;
  z-index: 8;
  border-bottom: 1px solid #e7e3e9;
  box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.25);
  z-index: 1000;
}

.header_new .header_wrapper .header_links .bold {
  font-weight: 700;
}

.header_new .header_wrapper .header_links .header-tab-dropdown {
  display: inline-flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  position: relative;
}

.header_new
  .header_wrapper
  .header_links
  .header-tab-dropdown
  .navbar-tab-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 5px;
  width: 200%;
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  padding: 10px;
  gap: 10px;
  align-items: start;
  white-space: nowrap; /* Allows text to wrap */
  word-break: break-word; /* Ensures long words break to fit */
  overflow: hidden;
}
.header_new_mobile {
  display: none;
}

.unispade_logo {
  height: 20px;
  cursor: pointer;
}

.header_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* :not(.MarketerDashb) .header_wrapper{
    display:none;
} */

.header_links a {
  color: #8ca6c0;
  font-size: 0.9rem;
  text-decoration: none;
  margin-right: 30px;
  font-family: "lexend";
}

.header_links .logout {
  color: #000;
  font-size: 0.9rem;
  text-decoration: none;
  margin-right: 30px;
  font-family: "lexend";
}

.header_wrapper .profile_img_wrapper .profile_img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

@media screen and (max-width: 768px) {
  .header_new {
    display: none;
  }

  .header_new_mobile {
    display: flex;
    height: 80px;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 3;
    border-bottom: 1px solid #e7e3e9;
    box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.25);
  }

  .header_new_mobile .header_wrapper .navbar__burger {
    width: 16px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .header_links a,
  .logout {
    color: #8ca6c0;
    font-size: 0.9rem;
    text-decoration: none;
    padding: 5px 0;
  }

  .header_new_mobile .header_wrapper .navbar__burger span {
    height: 2px;
    width: 100%;
    margin-bottom: 4px;
    background: #0b1c39;
  }

  .unispade_logo {
    height: 16px;
  }

  .header_new_mob_dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 20px;
    background-color: #fff;
    position: absolute;
    top: 70px;
    box-sizing: border-box;
    box-shadow: 0 4px 20px rgb(14 14 14 / 7%);
    width: 100%;
    transition: all 0.3s ease;
    margin-left: -5%;
  }
}
