.howitworks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1128px;
  padding: 2.5rem 0;
  background: #f8f8f8;
  box-sizing: border-box;
}
.howitworks__title {
  font-weight: 500;
}
.howitworks__title .color__orange {
  font-weight: 600;
}
.work__cards {
  display: flex;

  column-gap: 3rem;
  text-align: initial;
  padding: 2rem 1rem;
}
/* for the slider */
.howitworks #main {
  width: 450px;
}
.work__card {
  max-width: 200px;
  min-height: 290px;
  padding: 1.5rem;
  border: 2px solid rgb(233, 233, 233);
}
.work__card .icon {
  object-fit: contain;
  height: 30px;
  margin-bottom: 1rem;
}
.work__card .color__orange {
  font-weight: 600;
}
.work__card .para {
  line-height: 22px;
}
@media screen and (max-width: 800px) {
  .howitworks {
    padding: 2rem 5%;
  }
  .work__cards {
    flex-direction: column;
    row-gap: 1rem;
  }
  .work__card {
    max-width: 100%;
    min-height: auto;
  }
}
