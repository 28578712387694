.contactUs {
  text-align: center;
}

.contactUs .head {
  color: #6e6e6e;
  line-height: 160%;
  font-size: 32px;
  margin-top: 10px;
}

.contactUs .contact {
  color: #ff6566;
  font-weight: 600;
}

.contactUs .us {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 300;
}
.contactUs .contactUs__title {
  margin-bottom: 0.8rem;
}

.contactUs .cub1 {
  background-color: #0b1c39;
  padding: 10rem 5%;

  display: flex;
  flex-direction: column;
  /* padding-bottom: 7%; */
}
.contactUs .contact__info__cont {
  margin-top: -6rem;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contactUs .contact__info {
  box-sizing: border-box;
  background: white;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 1.5rem 0;
  /* width: 100%; */
  max-width: 800px;
  margin: 0;
  border-radius: 1px;
  box-shadow: 0px 4px 20px rgba(14, 14, 14, 0.07);
}
.contact__info .section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 1.8rem 3.5rem;
  max-width: 100%;
  height: 100%;
}
.contact__info .section:nth-child(2) {
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
}
.contact__info .section .contact__logo {
  object-fit: contain;
  height: 25px;
  margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  .contact__info__cont {
    padding: 0 5%;
  }
  .contact__info {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .contact__info .section {
    margin: 0 auto;
    width: 100%;
  }

  .contact__info .section:nth-child(2) {
    border: none;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
  }
}
.head_tagline {
  color: rgba(255, 255, 255, 0.8);
  padding-top: 8px;
  padding-left: 5%;
  padding-right: 5%;
  font-weight: 500;
}

.cub1c {
  text-align: center;
  justify-content: center;
  display: flex;
  width: 65%;
  padding: 30px;
  background-color: #ffffff;
  margin-left: 17.5%;
  position: relative;
  top: 190px;
  box-shadow: 0px 4px 20px rgba(14, 14, 14, 0.719);
}
@media screen and (max-width: 768px) {
  .cub1c {
    flex-direction: column;
  }
}
/* .columns {
    margin-left: 0rem;
    margin-right: 0rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
} */

.contactUs .column {
  width: 100%;
  padding: 0 0 0 0;
  border-right-style: solid;
  border-right-color: #e7e7e7;
  border-right-width: 1px;
}

.contactUs .column-mid {
  border-right-style: solid;
  border-right-color: #e7e7e7;
  border-right-width: 1px;
  border-left-style: solid;
  border-left-color: #e7e7e7;
  border-left-width: 1px;
}

.contactUs .cub2 {
  padding-top: 8rem;
  margin-left: 30%;
  margin-right: 30%;
  padding-bottom: 3rem;
}

.contactUs .cub2 p {
  text-align: left;
  font-size: 14px;
  color: #6e6e6e;
  padding-top: 32px;
  line-height: 185%;
}

.contactUs .login-txt-box {
  border: 1px solid #e7e7e7;
  outline: none;
  background-color: #fafafa;
  height: 56px;
  width: 80%;
  cursor: pointer;
  margin: 0;
  padding-left: 1.5rem;
  color: #0b1c39;
  width: 100%;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
}

.contactUs .message-txt-box {
  border: 1px solid #e7e7e7;
  background-color: #fafafa;
  color: #fff6f6;
  cursor: pointer;
  margin: 0;
  outline: none;
  padding-left: 1.5rem;
  width: 100%;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  margin: 0;
  color: #9f9f9f;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  height: 120px;
}

.contactUs .sec__right {
  /* flex: 0.6; */
  /* background-color: black; */
  display: flex;
  margin-top: 3rem;
  margin-bottom: 3rem;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
  /* margin: 2rem 5% 1rem 15%; */
  width: 100%;
  color: #6e6e6e;
  padding: 0 5%;
  box-sizing: border-box;
}

.logreg__title {
  margin-bottom: 1rem;
}
.contactUs .sec__right .login__form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.6rem;
  width: 100%;
  max-width: 480px;
  /* background: peru; */
}
.contactUs .sec__right .login__form label {
  font-size: 0.85rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.contactUs .sec__right .login__form .epass {
  color: rgb(151, 151, 151);
  background: #fafafa;
  height: 2.7rem;
  border-radius: 1px;
  border: 1.5px solid rgb(230, 230, 230);
  outline: none;
  font-size: 0.85rem;
  padding: 0.5rem 1rem;
  width: 100%;
  box-sizing: border-box;
}
.contactUs .sec__right .login__form .epass::placeholder {
  color: rgb(151, 151, 151);
  opacity: 1; /* Firefox */
}
.contactUs .sec__right .login__form .log__for {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 0.5rem;
  width: 100%;
}
.contactUs .sec__right .login__form .log__for label {
  font-size: 0.75rem;
  font-weight: 500;
  display: flex;
  column-gap: 0.5rem;
  cursor: pointer;
  text-align: left;
}
.contactUs .sec__right .login__form .login__btn {
  font-family: "Poppins";
  font-size: 0.9rem;
  margin-top: 1rem;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 1px;
  color: rgba(255, 255, 255, 0.89);
  background: #ff656a;
  height: 3rem;
  width: 100%;
}

.contactUs .column img {
  height: 22px;
  margin-bottom: 10px;
  margin-top: -5px;
}

.contactUs .column h3 {
  font-size: 1rem;
  font-weight: 500;
}
