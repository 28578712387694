/* Credits */

 .userCredits {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #f8f8f8;
    flex-grow: 1;
    overflow-y: auto;
    width: 85%;
    margin: 0 auto;
    margin-bottom: 50px;
    /* border: 1px solid red; */
    
    background-color: transparent;
    overflow-x: hidden;
  }
   .userCredits > .profile_search_results {
    text-align: start;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.02em;
  
    /* black/b4aebd/h */
  
    color: #b4aebd;
    /* margin-left: 20px; */
    /* margin: 10px; */
    /* overflow-y: hidden; */
    border-bottom: 1px solid #DFE9F3;
    /* position: absolute; */
    /* left: 0px; */
    width: 100%;
    padding: 0.5rem;
    /* height: 45px; */
  }
   .userCredits > .user_casestudy_Filter {
    display: flex;
    border: 1px solid #e7e3e9;
  }
   .userCredits > .user_casestudy_Filter > div {
    border-right: 1px solid #e7e3e9;
  }
  .userCredits
    > .user_casestudy_Filter
    > .userFilterCont {
    width: 33.3333%;
  }
   .userCredits > .user_casestudy_Filter > div > p {
    background-color: #f8f8f8;
    border-bottom: 1px solid #e7e3e9;
    margin: 0;
    height: 40px;
  }
  
  .userCredits
    > .user_casestudy_Filter
    > div
    > .filter-dropdown {
    background-color: white;
  }
  .userCredits
    > .user_casestudy_Filter
    > div
    > .filter-dropdown
    > .search-input {
    box-shadow: none;
    border: none;
    border-right: 1px solid #dfe9f3;
    outline: none;
  }
   .userCredits > .userCasestudies {
    display: flex;
    flex-wrap: wrap;
    gap: 0px;
    justify-content: left;
    /* max-height: 40rem; */
    /* height: 100%; */
    max-height: 50.17rem;
    overflow-y: auto;
    /* margin-top: 10px; */
    border-left: 1px solid #e7e3e9;
    border-right: 1px solid #e7e3e9;
    border-bottom: 1px solid #e7e3e9;
    padding: 0 50px ;
  }
  .userCredits #style-3::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .userCredits #style-3::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }

  

  @media screen and (max-width: 1130px) {
    .userCredits > .userCasestudies > .casestudy  {
      width: 33.33%;
    }
  }
  @media screen and (max-width: 880px) {
    .userCredits > .userCasestudies > .casestudy  {
      width: 50%;
    }
  }
  @media screen and (max-width: 580px) {
    .userCredits > .userCasestudies > .casestudy  {
      width: 80%;
      margin: 0 10%;
      border-left: 1px solid #dfe9f3;
    }
  }
  @media screen and (max-width: 768px) {
    .copyProfileLink {
      display: none !important;
    }
  }
  

