.LocalPoolAnalysisComponent {
    position: relative;
    text-align: left;
  }

  .LocalPoolAnalysisComponent .nameHeading {
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 15px 0;
    border: none;
    background-color: #f7f7f7;
    border-radius: 7px
  }
  .LocalPoolAnalysisComponent .nameHeading button {
    padding: 5px 10px;
    background-color: white;
    color: black;
    border-radius: 7px;
    font-weight: 900;
    border: 1px solid black;
  }
  
  .LocalPoolAnalysisComponent .nameHeading button:hover {
    background-color: black;
    color: white;
    border-color: white;
  }
  
  
  
  #date-select {
    border: none;
    outline: none;
    padding: 3px 10px;
    background-color: #f7f7f7;
    border-radius: 7px;
    margin-bottom: 15px;
  }
  
  #date-select option {
    background-color: #ada8a8;
  }
  .LocalPoolAnalysisComponent .overallPoolData {
      display: flex;
      gap : 20px;
      margin-bottom: 15px;
  }
  
  .LocalPoolAnalysisComponent .overallPoolData div {
    background-color: #f7f7f7;
    padding: 20px;
    text-align: center;
    border-radius: 7px;
    height: 150px;
    width: 100%;
  }
  
  .LocalPoolAnalysisComponent .totalPools {
    background-color: #f7f7f7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 5px 10px;
    margin-bottom: 15px;
  } 
  
  .LocalPoolAnalysisComponent .categoryDetailTable {
    width: 100%;
    text-align: center;
    border-collapse: separate;
    border-spacing: 0.5rem;
  }
  
  .LocalPoolAnalysisComponent .categoryDetailTable td,th{
    border : none;
    border-radius: 7px;
    padding: 0.5rem 0.5rem;
    background-color: #f7f7f7;
  }
  
  .LocalPoolAnalysisComponent .categoryDetailTable td:first-child {
    text-align: left;
    cursor: pointer;
  }


  .LocalPoolAnalysisComponent .categoryDetailTable .nameAndEmail {
    display: flex;
    align-items: center;
    width: 100%; /* Ensures the flex container takes full width */
    gap: 5px;
  }

  .LocalPoolAnalysisComponent .categoryDetailTable .nameAndEmail img {
    width: 40px;
    height: 40px;
    border-radius: 50%;

  }

  
  
  