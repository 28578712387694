.experience_main_container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.experience_main_container .eachCompanyExperience {
  border-radius: 30px;
  background-color: #f7f7f7;
  padding: 30px;
  display: flex;
  justify-content: start;
  gap: 1rem;
  text-align: left;
  width: 100%;
}

.experience_main_container .theAddButtonLine {
  display: flex;
  width: 100%;
  justify-content: start;
}

.experience_main_container .experience-item {
  margin-bottom: 15px;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: start;
  gap: 1rem;
}
.experience_main_container .companyLogoContainer {
  display: inline;
}

.experience_main_container .writtenExperience {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: start;
  gap: 20px;
}

.experience_main_container .experience-item-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  gap: 5px;
}

.experience_main_container .experience-item-title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.experience_main_container .experience-item-title .editDeleteComponent {
  position: relative;
}

.experience_main_container
  .experience-item-title
  .editDeleteComponent
  .editDeleteButtons {
  position: absolute;
  right: 90%;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #e0e0e0;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
}

.experience_main_container
  .experience-item-title
  .editDeleteComponent
  .editDeleteButtons
  button {
  gap: 10px;
  border: 1px solid #e0e0e0;
  background-color: white;
  border-radius: 10px;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 500;
}

.experience_main_container
  .experience-item-title
  .editDeleteComponent
  .editDeleteButtons
  button:hover {
  background-color: #e0e0e0;
}

.experience_main_container .experience-item-company {
  font-weight: 500;
  font-size: 16px;
}

.experience_main_container .experience-item-description {
  padding-top: 5px;
  font-weight: 400;
  font-size: 18px;
  word-break: break-word;
  overflow-wrap: break-word;
}

.experience_main_container .experience-item-duration {
  font-weight: 400;
  font-size: 16px;
  color: #808080;
}

.experience_main_container .notableThingsHeading {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px !important;
}

.experience_main_container .experienceNotableThings {
  display: flex;
  gap: 10px;
  justify-content: start;
  flex-wrap: wrap;
}

.experience_main_container .experienceNotableThings .experienceFeatures {
  border: 1px solid #e0e0e0;
  background-color: transparent;
  padding: 10px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}

.experience_main_container .experienceNotableThings .experienceFeatures:hover {
  background-color: #e0e0e0;
}

.experience_main_container .experienceProjectsList {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
}

.experience_main_container .experienceProjectsList .experienceProjects {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: start;
}

.experience_main_container
  .experienceProjectsList
  .experienceProjects
  .experienceProjectsTitle {
  border: 1px solid #e0e0e0;
  background-color: transparent;
  padding: 10px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
}

.experience_main_container
  .experienceProjectsList
  .experienceProjects
  .experienceProjectsAboutBox {
  /* flex: 1; */
  border: 1px solid #e0e0e0;
  background-color: transparent;
  border-radius: 10px;

  display: flex;
  padding: 10px;
  font-size: 18px;
  align-items: center;
  gap: 15px;
  /* width: 100%; */
}

.experience_main_container
  .experienceProjectsList
  .experienceProjects
  .experienceProjectDescription {
  word-break: break-word;
  overflow-wrap: break-word;
}

.experience_form {
  padding: 20px 0px;
  text-align: start;
  width: 640px;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.experience_main_container .mainContainer {
  position: sticky;
  top: 0;
  /* z-index: 100; */
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.experience_main_container .mainContainer .skillAndTagContainer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.experience_main_container .mainContainer .skillOrTagItem {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  background-color: #e0e0e0;
}

.experience_main_container .label {
  color: black;
  margin-bottom: 10px !important;
  font-weight: 500;
  font-size: 15px;
}

.experience_main_container .companyDetails {
  position: relative;
  top: 0;
  width: 100%;
}

.experience_main_container .inputWrapper {
  border: 1px solid #e0e0e0;
  background-color: white;
  border-radius: 10px;

  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 60px;
  position: relative;
}

.experience_main_container .textareaWrapper {
  padding: 5px 0 5px 15px;
  height: 200px;
}

.experience_main_container .checkBoxWrapper {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  flex-direction: row;
  margin: 5px 0;
}

.experience_main_container .AddMoreInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e0e0;
  background-color: #f7f7f7;
  width: 100%;
  height: 60px;
  font-weight: 400;
  font-size: 18px;
  color: #505050;
  border-radius: 10px;
  cursor: pointer;
}

.experience_main_container .inputWrapper > input,
.experience_main_container .inputWrapper > textarea {
  outline: none;
  border: none;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  background-color: transparent;
}

.experience_main_container .input::placeholder,
.experience_main_container .textarea::placeholder {
  color: #b1b1b1;
  font-weight: 400;
  size: 18px;
}

.experience_main_container .inputWrapper > textarea {
  resize: none;
  height: 100%;
  padding: 10px 0;
}

.experience_main_container .inputWrapper .jobTypeDropdown {
  position: absolute;
  top: 100%; /* Position the dropdown below the input */
  left: 0;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  margin-top: 5px;
  padding: 10px;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.experience_main_container .inputWrapper .jobTypeDropdown div {
  padding: 8px 15px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.experience_main_container .inputWrapper .jobTypeDropdown div:hover {
  background-color: #f0f0f0;
}
.experience_main_container .searchedCompany {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  margin-bottom: 10px;
}
.experience_main_container .searchedCompany:hover {
  background-color: #e0e0e0;
}

.experience_main_container .searchedCompanyNameAndLogo {
  display: flex;
  gap: 10px;
  align-items: center;
}

.experience_main_container .searchedCompanyNameAndLogo > img {
  width: 20px;
  height: 20px;
  border-radius: 3px;
}

.experience_main_container .searchedCompanyWebsite {
  color: #b4aebd;
}

.experience_main_container .companySearchedResults {
  position: absolute;
  top: 100%;
  border-radius: 10px;
  background-color: white;
  overflow: auto;
  width: 100%;
  border: 1px solid #e0e0e0;
  z-index: 1000;
  max-height: 200px;
  padding: 10px;

  /* background-color: #FFFFFF;
    border: 1px solid #E7E3E9;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-height: 200px;
    overflow-y: auto; */
}

hr {
  margin: 0 auto;
  border: none;
  color: rgb(186, 184, 187);
}

.experience_main_container .noCompanyExistWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  width: 100%;
}

.experience_main_container .cancelIcon > img {
  background-color: #fff;
  border: 1px solid #e7e3e9;
}

.experience_main_container .plusIcon {
  border: none;
  outline: none;
  width: 100%;
  cursor: pointer;
  background-color: transparent;
}

.experience_main_container .dateWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.experience_main_container .dateWrapper > .inputWrapper {
  width: 50%;
}

.experience_main_container .submitButtonWrapper {
  position: fixed;
  bottom: 20px;
  background-color: #191e22;
  border: 1px solid #e7e3e9;
  /* width: fit-content; */
  margin: 0 auto;
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.experience_main_container .submitButtonWrapper .addExperienceBtn {
  display: flex;
  background-color: #191e22;
  border: none;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: #fff;
  display: flex;
  /* max-width: 600px; */
  justify-content: center;
  font-size: 16px;
  font-style: normal;
  /* padding: 20px; */
  height: 50px;
  font-weight: 700;
  line-height: 16px;
}

.experience_main_container .submitButtonWrapper .addExperienceBtn:hover {
  color: rgb(217, 217, 217);
}

.experience_main_container .selectField {
  border: none;
  width: 100%;
  padding: 5px;
}

.experience_main_container .createNewExperienceCompanyWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  border: 1px solid #e0e0e0;
  padding: 25px;
  border-radius: 10px;
}

.experience_main_container .addingProjectComponent {
  flex-direction: column;
  gap: 10px;
  width: 100%;
  border: 1px solid #e0e0e0;
  padding: 25px;
  border-radius: 10px;
}

.experience_main_container .addingClientComponent {
  flex-direction: column;
  gap: 10px;
  width: 100%;
  border: 1px solid #e0e0e0;
  padding: 25px;
}

.experience_main_container .addingClientComponent .absoluteRightButtons {
  position: absolute;
  right: -100%; /* Positions the element exactly 100% to the right of the parent */
  top: 50%; /* Optional: centers the element vertically */
  transform: translateY(
    -50%
  ); /* Optional: adjusts for perfect vertical centering */
  background-color: blue;
}

.experience_main_container .isMarketingAgencyWrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
}

.experience_main_container .isMarketingAgencyHeading {
  font-size: 12px;
  font-weight: bold;
  color: #191e22;
  margin: 0 5px;
}

.experience_main_container .radioBtnWrapper {
  display: flex;
  align-items: center;
  gap: 14px;
  accent-color: black;
  margin-left: 5px;
}

.experience_main_container
  > .formButtons
  > .innerFormContainer
  > .formBtn:nth-child(1) {
  border-right: 1px solid #e7e3e9;
}

.experience_main_container > .formButtons > .innerFormContainer > .formBtn {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none;
}

.experience_main_container
  > .formButtons
  > .innerFormContainer
  > .formBtn:hover {
  background-color: #dfdfdf;
}

.experienceFormDates {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 870px) {
  .experience_form {
    width: 95%;
    margin: 0 2.5%;
  }
  .experience_main_container .eachCompanyExperience {
    border-radius: 20px;
    padding: 10px;
  }
  .experience_main_container .companyLogoContainer {
    height: 50px;
  }
  .experience_main_container .writtenExperience {
    max-width: 88%;
  }
  .experience_main_container .experience-item-description {
    width: 82vw;
    position: relative;
    left: -50px;
    max-height: 496px;
    overflow: hidden;
  }
  .experience_main_container .experience-item-header {
    width: 85%;
  }
  .experienceFormDates {
    display: block;
  }
  .innerExperienceSection {
    display: none;
  }
  .submitButtonWrapper {
    width: 95%;
    margin: 0 2.5%;
  }
}
