.MarketerDashb .CompanyProfile{
    display: flex;
    flex-direction: column;
    width: 100%;
    /* justify-content: center; */
    align-items: center;
    margin-top: 45px;
    gap: 10px;
    text-align: left;
}
.MarketerDashb .CompanyProfile >div{
    display: flex;
    flex: 0 1;
    align-items: center;
    width: 500px;
    min-height: 50px;
    flex-shrink: 0;
    padding: 0 10px;
    border: 1px solid   #E7E3E9;
    background:  #F8F8F8;
}
.MarketerDashb .CompanyProfile >div p{
    margin: 0;
    color:#B4AEBD;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 150% */
    letter-spacing: 0.32px;
    padding: 5px;
}
.MarketerDashb .CompanyProfile >div span{
    color: var(--black-525464-d, #525464);
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.32px;
}
.MarketerDashb .CompanyProfile >div>span{
    margin-left: auto;
    margin-right: 20px;
    font-size: 25px;
}
.MarketerDashb .CompanyProfile>p{
    margin-left: 0;
}
.MarketerDashb .CompanyProfile .existingCompany{
    display: flex;
    flex-direction: column;
    width: 500px;
    flex: 1 0;
    max-height: 400px !important;
    overflow-y: auto;
}
.MarketerDashb .CompanyProfile .existingCompany button{
    display: flex;
    border: none;
    width: 100%;
    /* justify-content: flex-start; */
    height: 50px;
    padding: 5px;
    align-items: center;
    background-color: transparent;
    border-bottom: 1px solid #E7E3E9;
}
.MarketerDashb .CompanyProfile .existingCompany button>p{
    color: var(--black-525464-d, #525464) !important; 
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px; /* 200% */
}
.MarketerDashb .CompanyProfile .existingCompany button >img{
    height: 40px;
    width: 40px;
    background-color: white;
    margin-right: 20px;
}
.MarketerDashb .CompanyProfile .existingCompany button>span{
    margin-left: auto;
    color:#B4AEBD;
    text-align: right;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px; /* 200% */
}
.MarketerDashb .CompanyProfile .searchCompany{
    display: flex;
    gap: 20px;
    padding: 5px;
}
.MarketerDashb .CompanyProfile .searchCompany >input{
    background-color: transparent;
    border: none;
    outline: none;
    flex:  1;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 150% */
    letter-spacing: 0.32px;
}
.MarketerDashb .CompanyProfile .searchCompany >input::placeholder{
    color:#B4AEBD;
}
.MarketerDashb .CompanyProfile .SelectedCompany{
    display: flex;
    flex-direction: column;
    width: 500px;
    flex: 1 0;
    max-height: 400px !important;
    overflow-y: auto;
    margin-bottom: 50px;
}
.MarketerDashb .CompanyProfile .SelectedCompany button{
    display: flex;
    border: none;
    width: 100%;
    /* justify-content: flex-start; */
    height: 50px;
    padding:  5px;
    align-items: center;
    background-color: transparent;
    border-bottom: 1px solid #E7E3E9;
}
.MarketerDashb .CompanyProfile .SelectedCompany button>p{
    color: var(--black-525464-d, #525464) !important; 
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px; /* 200% */
}
.MarketerDashb .CompanyProfile .SelectedCompany button >img{
    height: 40px;
    background-color: white;
    margin-right: 20px;
}
.MarketerDashb .CompanyProfile .SelectedCompany button>span{
    margin-left: auto;
    color:#B4AEBD;
    text-align: right;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px; /* 200% */
}
.MarketerDashb .CompanyProfile .emaiVerify input{
    color:#5c5c5d;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 150% */
    letter-spacing: 0.32px;
    background-color: transparent;
    border: none;
    outline: none;
    flex:  1;
    margin:  0 20px;
}
.MarketerDashb .CompanyProfile .emaiVerify input::placeholder{
    color:  #B4AEBD;
}
.MarketerDashb .CompanyProfile >p{
    color:  #B4AEBD;
    font-family: Lexend;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.12px;
    width: 500px;
    margin: 0;

}
.MarketerDashb .CompanyProfile >span{
    color:   #CEC8D3;
    font-family: Lexend;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 160% */
    letter-spacing: 0.1px;
    width: 500px;
    margin: 0;
    }
.MarketerDashb .CompanyProfile >.verifyLine{
    font-weight: 300;
    line-height: 24px; /* 150% */
}

.MarketerDashb .CompanyProfile > .accessSelectedCompany {
    display: flex;
    gap: 10px;
    width: 500px;
    margin: auto;
    text-align: left;
    color: black;
    text-decoration: none;
}

.MarketerDashb .CompanyProfile > .accessSelectedCompany:hover {
    cursor: pointer;
}

.MarketerDashb .CompanyProfile > .accessSelectedCompany > p {
    border-bottom: 1px solid rgb(51, 51, 51);
    font-size: 14px;
    color: rgb(51, 51, 51);
    font-weight: 500;
}

.MarketerDashb .CompanyProfile > .accessSelectedCompany > p > span {
    color: rgb(19, 18, 18);
    font-weight: bold;
}

.MarketerDashb .CompanyProfile > .accessSelectedCompany > img {
    width: 20px;
    height: 15px;
    margin-top: 2px;
}