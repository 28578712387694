.home_page_comp_four {
    height: fit-content !important;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home_page_comp_four .comp_four_text1 {
    font-family: "lexend";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    color: #405870;
    margin-top: 55px;
}

.home_page_comp_four .card_sec{
    margin-top: 60px;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly
}

.home_page_comp_four .button_style{
    margin-top: 60px;
    margin-bottom: 55px;
}

@media screen and (max-width: 768px) {
    .home_page_comp_four .card_sec{
        flex-wrap: unset;
        flex-direction: column;
    }
    .home_page_comp_four .button_style{
        margin-top: 20px;
        margin-bottom: 30px;
    }
}