.ApplyToTalentPoolPage {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 0 30px;
}

.ApplyToTalentPoolPage .pageHeadingLine {
  display: flex;
  justify-content: space-between;
  font-size: 28px;
  font-weight: 600;
}

.ApplyToTalentPoolPage .disclaimer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7f7;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 15px 25px;
  text-align: left;
}
.ApplyToTalentPoolPage .disclaimer p {
  font-size: 16px;
  font-weight: 400;
}
.ApplyToTalentPoolPage .disclaimer .removeDisclaimer {
  align-self: self-start;
  min-height: 40px;
  min-width: 40px;
  border-radius: 10px;
  background-color: white;
  display: flex; /* Enable flexbox */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
}
.ApplyToTalentPoolPage .entirePoolList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.ApplyToTalentPoolPage .entirePoolList .categoryInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 0 10px;
  height: 60px;
}

.ApplyToTalentPoolPage .entirePoolList .categoryInfo .pool-data-header {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;

  height: 100%;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
}

.ApplyToTalentPoolPage .entirePoolList.categoryInfo .pool-data-header p {
  width: max-content;
  flex: none;
}
.ApplyToTalentPoolPage .entirePoolList.categoryInfo .pool-data-header span {
  color: gray;
  margin-left: 5px;
}

.red-background-toast {
  background-color: #fc4545 !important; /* Apply a red background */
  color: black; /* Ensure the text is visible on the red background */
  border-radius: 10px; /* Optional: Add some styling */
}

.blue-background-toast {
  background-color: #8faeff !important; /* Apply a blue background */
  color: black; /* Ensure the text is visible on the blue background */
  border-radius: 10px; /* Optional: Add some styling */
}

.red-background-toast .Toastify__toast-icon  {
  display: none;

}

.red-background-toast .Toastify__progress-bar {
  background-color: black !important; /* White progress bar */
}

.blue-background-toast .Toastify__toast-icon  {
  display: none;
}

.blue-background-toast .Toastify__progress-bar {
  background-color: black !important; /* White progress bar */
}



@media (max-width: 600px) {
  .ApplyToTalentPoolPage .disclaimer {
    padding: 10px 15px;
  }
}
