@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.userPaymentDoneSingleSession{
    margin-top: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
}

.paymentBtm{
    display: flex;
    justify-content: end;
    border: none;
    background: white;
}

.paymentBtm button{
    border: none;
    background: white;
}
.paymentdoneFooter{
    display: flex;
    justify-content: end;
    gap: 14px;
    margin: 17px;
}

.confirmationMessage{
    text-align: start;
}

.paymentDone-section{
    background: #f7f7f7;
    padding: 50px 12px;
    display: flex;
    /* justify-content: center; */
}