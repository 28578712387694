.news__mentions {
  margin: 0 auto;
  width: 80%;
}

.mentions__heading {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #dfe9f3;
  border-bottom: 1px solid #dfe9f3;
  padding: 14px 0;
}

.mentions__title {
  font-weight: 500;
  font-size: 20px;
  color: #0b1c39;
  text-align: start;
  margin: 0;
}

.news__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  
  margin-bottom: 90px;
}

.case_study__footer {
  border-top: 1px solid #dfe9f3;
  border-bottom: 1px solid #dfe9f3;
  padding: 14px 0;
  margin: 0 auto 100px;
  width: 80%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 12px;
}

.case_study__footer .case_study__card {
  display: flex;
  align-items: center;
  column-gap: 6px;
  border-right: 1px solid #dfe9f3;
  padding: 0 10px;
}

.case_study__footer .case_study__card:last-child {
  border: 0;
}

.case_study__footer .case_study__icon {
  width: 18px;
}

.case_study__footer .case_study__text {
  font-size: 14px;
  color: #0b1c39;
  margin: 0;
}

/* Mobile Device */
@media only screen and (max-width: 688px) {
  .news__container {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
}

/* News Mention */
.news__mention {
  border: 1px solid #dfe9f3;
  border-radius: 10px;
  padding: 10px 20px;
  text-align: start;
}

.news__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.news__title {
  font-weight: 700;
  font-size: 14px;
  color: #ff656a;
  margin: 0;
}

.news__redirect {
  width: 13px;
  height: 13px;
}

.news__description {
  font-size: 14px;
  color: #0b1c39;
}
