.ProjectList__cont {
  display: flex;
  flex-direction: column;
}

.ProjectList {
  display: flex;
  align-items: flex-start;
  padding: 1rem 5% 3rem 5%;
  column-gap: 0.5rem;
  width: 100%;
  box-sizing: border-box !important;
}
.ProjectList__cont .newproject__cont {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 5% 0 5%;
  position: relative;
}
.ProjectList__cont .newproject__cont .newproject__datas {
  position: absolute;
  display: none;
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  top: 40px;
  z-index: 3;
}

.ProjectList__cont .newproject__cont .newprojectbtn {
  /* background: purple; */
  padding: 0.5rem 2rem;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.ProjectList__cont .newproject__cont .newprojectbtn:hover {
  background: #fff6f6;
  transition: all 0.3s ease;
}

/* alt new project btn */
.ProjectList .fil__res__cont .newproject__contalt {
  display: none;
  justify-content: flex-end;
  margin-bottom: 1rem;
  width: 100%;
  position: relative;
}

.ProjectList .fil__res__cont .newproject__contalt .newprojectbtnalt {
  /* background: purple; */

  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.ProjectList .fil__res__cont .newproject__contalt .newprojectbtnalt:hover {
  background: #fff6f6;
  transition: all 0.3s ease;
}
.ProjectList .fil__res__cont .newproject__contalt .newproject__datasalt {
  position: absolute;
  display: none;
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  top: 40px;
  z-index: 3;
}
.ProjectList__cont .col__pink {
  background: #fff6f6;
}
.ProjectList__cont .shownpdata {
  display: block !important;
}

.ProjectList .fil__res__cont .filter__sec {
  margin-top: 0;
}

.ProjectList .fil__res__cont .filter__sec .filter__pro__type {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.ProjectList .fil__res__cont .filter__sec .filter__pro__type p {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .ProjectList {
    padding: 3rem 5% 3rem 5%;
  }
  .ProjectList__cont .newproject__cont {
    display: none;
  }
  .ProjectList .fil__res__cont .newproject__contalt {
    display: flex;
  }
}

.loaderStyle {
  margin-top: 50px;
}

/* newprojectbtn */
.ProjectList .fil__res__cont {
  margin: 0;
  padding: 0;
  width: 100%;
}
.ProjectList .fil__res__cont .search__res__sec {
  padding: 0;
}

.ProjectList .fil__res__cont .search__res__sec .search__res .card__sec .project__card .part23__cont .part3 p {
  margin-bottom: 0;
}
.ProjectList .card__sec {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}
.ProjectList .card__sec .project__card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1.5rem;
  row-gap: 1rem;
  text-align: initial;
  border: 3px solid #fff6f6;
  padding: 0.7rem 1rem 1.25rem 2rem;
  /* width: 100%; */
}
.ProjectList .card__sec .project__card .part1 {
  flex: 0.6;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 0.6rem;
  width: 100%;
}
.ProjectList .card__sec .project__card .part1 .requestpr {
  font-size: 0.8rem;
  border: 2px solid #feeeca;
  padding: 0 15px;
  border-radius: 5px;
  color: black;
  background: transparent;
  font-family: "Poppins";
  text-transform: capitalize;
  cursor: unset;
}

.ProjectList .card__sec .project__card .part1 .tags {
  display: flex;
  row-gap: 0.3rem;
  column-gap: 0.6rem;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  float: left;
  width: 100%;
}
.ProjectList .card__sec .project__card .part1 .tags .tag {
  color: grey;
  cursor: pointer;
}
.ProjectList .card__sec .project__card .part23__cont {
  flex: 0.4;
  display: flex;
  justify-content: space-between;
  row-gap: 0.5rem;
  column-gap: 1rem;
  width: 100%;
}
.ProjectList .card__sec .project__card .viewproject {
  border: none;
  color: black;
  font-family: "Poppins";
  padding: 10px 2px;
  border-radius: 5px;
  background: #fff6f6;
  cursor: pointer;
  width: 100%;
}
.ProjectList .card__sec .project__card .part3 {
  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}
.ProjectList .card__sec .project__card .part3 .planned {
  font-size: 0.9rem;
}
.ProjectList .card__sec .project__card .part3 .arrowdownp {
  object-fit: contain;
  height: 7px;
}

@media screen and (max-width: 768px) {
  .ProjectList .fil__res__cont {
    row-gap: 1rem;
  }

  .ProjectList .fil__res__cont .filter__sec .filter__pro__type {
    display: none;
    margin: 0;
  }

  .ProjectList .card__sec .project__card {
    flex-direction: column;
    padding: 0.7rem 1rem 1rem 1rem;
  }
}
