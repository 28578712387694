.asubmitreferences__cont {
  display: flex;

  /* background: #e5e5e5; */
  background: #f6f6f6;
  min-height: 100vh;
}

.asubmitreferences .done__btn {
  padding: 0.5rem 0.8rem 0.5rem 0.8rem;
  box-sizing: border-box;
  background: #ff656a;
  outline: none;
  font-family: "Poppins";
  border: 1px solid lightgray;
  color: white;
  border-radius: 3px;
  cursor: pointer;
}

.asubmitreferences {
  flex: 0.82;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 3rem 1.3%;
  box-sizing: border-box;
  width: 100%;
  background: #f6f6f6;
}
@media screen and (max-width: 950px) {
  .asubmitreferences {
    flex: 1;
  }
}
.asubmitreferences ::placeholder {
  font-family: "Poppins";
  color: #cfcfcf !important;
}
.asubmitreferences
  .contact__details
  .contact__details__data
  .contact__details__forms {
  display: flex;
  flex-direction: column;
  row-gap: 1rem !important;

  width: 100%;
}
.asubmitreferences
  .contact__details
  .contact__details__data
  .contact__details__forms
  .form {
  column-gap: 1rem;
}
.asubmitreferences
  .contact__details
  .contact__details__data
  .contact__details__forms
  .form
  .startendinpcont {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
}
.contact__details
  .contact__details__data
  .contact__details__forms
  .form
  .startendinpcont
  .part1,
.contact__details
  .contact__details__data
  .contact__details__forms
  .form
  .startendinpcont
  .part2 {
  /* background: purple; */
  width: 100%;
}
.contact__details
  .contact__details__data
  .contact__details__forms
  .form
  .startendinpcont
  .part1 {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
}
.contact__details
  .contact__details__data
  .contact__details__forms
  .form
  .startendinpcont
  .part2 {
  /* background: purple; */
  display: flex;
  justify-content: flex-end;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
}
.contact__details
  .contact__details__data
  .contact__details__forms
  .form
  .startendinpcont
  .part2
  .labelf {
  display: flex;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
}
.contact__details
  .contact__details__data
  .contact__details__forms
  .form
  .startendinpcont
  .input {
  color: #0b1c39 !important;
  width: 100%;
}

@media screen and (max-width: 950px) {
  .asubmitreferences
    .contact__details
    .contact__details__data
    .contact__details__forms {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem !important;
  }
}
@media screen and (max-width: 768px) {
  .contact__details
    .contact__details__data
    .contact__details__forms
    .form
    .startendinpcont {
    flex-direction: column !important;
    row-gap: 0.5rem;
  }
}
