.addNewCandidateContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.addNewCandidateContainer .searchBarElement {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  height: 60px;
  width: 100%;
  outline: 1px solid #e7e3e9;
  border-radius: 10px;
  border: 1px solid #ffffff;
  position: relative;
}

.addNewCandidateContainer .searchBarElement input {
  width: 80%;
  padding: 10px;
  outline: none;
  border: none;
}

.addNewCandidateContainer .searchBarResultContainer {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;

}

.addNewCandidateContainer .searchBarResultContainer .resultItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #E0E0E0;
  border-radius: 20px;
  padding: 0 10px ;
  height: 60px;
}

.addNewCandidateContainer .searchBarResultContainer  .user-basic-data-header  {
  display: flex;
  justify-content:start;
  align-items: center;
  gap: 10px;

  height: 100%;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
}


.addNewCandidateContainer .searchBarResultContainer  .user-basic-data-header .profile-img {
  border: 1px solid #000;
  border-radius: 100%;
  width: 40px;  
  height: 40px;
  background-color: #ccc;
}

.addNewCandidateContainer .searchBarResultContainer .user-basic-data-header p {
  width: max-content;
  flex: none;
}
.addNewCandidateContainer .searchBarResultContainer .user-basic-data-header span {
  color: gray;
  margin-left: 5px;
}

