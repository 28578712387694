.adashboard__cont {
  display: flex;
  background: #f6f6f6;
  min-height: 100vh;
}
.adashboard {
  flex: 0.82;
  display: flex;
  flex-direction: column;
  row-gap: 1.2rem;
  padding: 3rem 1.3%;
  box-sizing: border-box;
  width: 100%;
  background: #f6f6f6;
}
@media screen and (max-width: 950px) {
  .adashboard {
    flex: 1;
  }
}
.adashboard .adashboard__title {
  font-weight: bolder;
  color: #828282;
}
.adashboard .section,
.adashboard .section2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.adashboard .section2 {
  background: #ffffff;
  border-radius: 4px;
  padding: 0.5rem 0.7rem;
  box-sizing: border-box;
}
.adashboard .section .part__right {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1.3rem;
}
.adashboard .section .part__right .img__cont {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.adashboard .section .part__right .img__cont .msg__not {
  object-fit: contain;
  height: 16px;
}
.adashboard .section .part__right .img__cont .dotg {
  position: absolute;
  right: -10%;
  top: -10%;
}
.adashboard .section .part__right .date__day {
  padding: 0.2rem 0.4rem;
  border: 1px solid #828282;
  border-radius: 3px;
}
.adashboard .section__desc {
  display: flex;
  column-gap: 1rem;
  row-gap: 1rem;
  width: 100%;
}

.adashboard .section__desc .desc__card {
  padding: 0.4rem 0.6rem;
  box-sizing: border-box;
  border-radius: 4px;
  background: #ffffff;
  width: 100%;
}
.adashboard .section__desc .desc__card .desc__card__text {
  margin: 1rem 0.2rem;
}
.adgrey {
  color: #9d9d9d !important;
}

@media screen and (max-width: 768px) {
  .adashboard .section__desc {
    flex-direction: column;
  }
}
