.PoolsPage .ActivityTabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 20px;
}

.lighterFont {
  font-weight: 300;
  font-size: 20px;
}
.PoolsPage .filterSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  width: 100%;
  padding: 20px 60px;
}

.PoolsPage .filterTab {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 20px;
  font-size: 18px;
}

.PoolsPage .filterTab .filterIcon {
  display: flex;
  justify-content: start;
  gap: 20px;
  align-items: center;
}

.PoolsPage .filterTab .filteringOption {
  display: flex;
  justify-content: start;
  gap: 20px;
  align-items: center;
}

.PoolsPage .filterTab .selectedFilters {
  display: flex;
  gap: 20px;
  justify-self: start;
  align-items: center;
}

.PoolsPage .filterTab .selectedFilters .singleFilter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #f7f7f7;
  background-color: #f7f7f7;
  border-radius: 20px;
  padding: 7px 15px;
  color: black;
  cursor: pointer;
  gap: 10px;
}

.PoolsPage .filterTab .selectedFilters .singleFilter:hover .crossIcon {
  display: block;
}

.PoolsPage .filterTab .filterTabDropdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  font-size: 18px;
  color: #b1b1b1;
  border-radius: 30px;
  border: 1px solid #f7f7f7;
  padding: 7px 15px;
  background-color: #f7f7f7;
  position: relative;
}

.PoolsPage .filterTab .filterTabDropdown .filterSeachInput {
  border: none;
  outline: none;
  background-color: transparent;
}

.PoolsPage .filterTab .filterTabDropdown .variousOptions {
  position: absolute;
  top: 100%;
  left: 0;
  width: fit-content;
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}
.PoolsPage .filterTab .filterTabDropdown .variousOptions > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 7px 15px;
  color: black;
  cursor: pointer;
  width: fit-content;
  text-align: left;
}

.PoolsPage .filterTab .filterTabDropdown .variousOptions > div:hover {
  background-color: #f7f7f7;
}

.PoolsPage .selectAllOption {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.PoolsPage .filterTab .singleFilter.PoolCategory {
  border-color: #e9e000;
  background-color: #fffb95;
}

.PoolsPage .filterTab .singleFilter.Company {
  border-color: #ffa8a8;
  background-color: #ffd6d6;
}

.PoolsPage .filterTab .singleFilter.Endorser {
  border-color: #5182ff;
  background-color: #bacdff;
}

.PoolsPage .poolsPageContainer {
  width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 30px auto;
  text-align: left;
}

.PoolsPage .poolsPageContainer .poolCard {
  display: flex;
  height: 60px;
  gap: 20px;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
}

.PoolsPage .poolsPageContainer .poolCard .poolNameInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 600px;
  height: 100%;
  padding: 0 30px;

  border: 1px solid #e0e0e0;
  border-radius: 20px;
}
