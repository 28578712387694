/* .agencyprofile__cont {
} */
.agencyprofile__cont .ap__top__cont {
  padding: 0 9.5rem;
  background: #0b1c38;
}
.agencyprofile__cont .ap__top {
  display: flex;
  padding: 5rem 0 1.6rem 0;
  width: 100%;
}
.ap__top .sec__left {
  flex: 0.8;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 0.6rem;
  
}
.res__logo{
  width:100px;
}
.res_text{
  width:72px;
  height:72px;
  background-color: #0b1c38;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#fff;
  font-weight: bold;
  font-size: 1.3rem;
}
.ap__top .sec__left .res__logo {
  object-fit: contain;
  height: 100px;
  width: 100px;
}

.ap__top .sec__left .details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* row-gap: 0.2rem; */
}
.ap__top .sec__left .details .agency__title {
  color: white;
  margin-bottom: 0.6rem;
}
.ap__top .sec__left .details .heartempty {
  object-fit: contain;
  height: 0.8rem;
  margin-left: 0.6rem;
  cursor: pointer;
}
.ap__top .sec__left .details .heartcolor {
  object-fit: contain;
  height: 1rem;
  margin-left: 0.6rem;
  cursor: pointer;
}
.ap__top .sec__left .details .stars {
  display: flex;
  column-gap: 0.2rem;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.8rem;
}
.ap__top .sec__left .details .stars .num__reviews {
  font-size: 0.75rem;

  padding: 0.1rem 0.35rem;
  margin-left: 1rem;
  border-radius: 3px;
  /* font-weight: 600; */
  color: #d3d6db;
  border: 1px solid#ff656ab0;
  background: #23334d;
  cursor: pointer;
}
.ap__top .sec__left .details .tags {
  display: flex;
  column-gap: 0.5rem;
  row-gap: 0.3rem;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  float: left;
  margin-bottom: 1.4rem;
  width: 100%;
}
.ap__top .sec__left .details .tags .tag {
  font-size: 0.75rem;
  padding: 0.2rem 0.35rem;
  border-radius: 3px;
  color: #d3d6db;
  background: #23334d;
  cursor: pointer;
 
}
.ap__top .sec__left .details .misc__details {
  display: flex;
  column-gap: 2rem;
  row-gap: 0.5rem;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  float: left;
  margin-bottom: 0.5rem;
}
.sec__left .details .misc__details .part {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.3rem;
}
.sec__left .details .misc__details .part p {
  color: rgb(209, 209, 209);
}
.ap__top .sec__right {
  flex: 0.2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  row-gap: 0.5rem;
  margin: 0;
  padding: 0;
}

.ap__top .sec__right button {
  font-size: 0.7rem;
  border-radius: 1px;
  padding: 12px 23px;
  cursor: pointer;
}
.ap__top .sec__right .onebtn {
  color: white;
  border: 1px solid white;
  background: transparent;
  cursor: pointer;
}
.ap__top .sec__right .twobtn {
  color: white;
  border: 1px solid #ff656a;
  background: #ff656a;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .agencyprofile__cont .ap__top__cont {
    padding: 0 5%;
  }
}
@media screen and (max-width: 550px) {
  .ap__top {
    flex-direction: column;
    row-gap: 1rem;
  }
  .ap__top .sec__right {
    flex-direction: row;
    column-gap: 0.5rem;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

.agencyprofile {
  display: flex;
  align-items: flex-start;
  column-gap: 2rem;
  margin: 4rem 9.5rem;
}

.agencyprofile .sec__left {
  flex: 0.2;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  text-align: initial;
  border-left: 0.8px solid rgba(211, 211, 211, 0.39);
  position: sticky;
  top:150px;
  /* max-height: 400px; */
}
.agencyprofile .sec__left a {
  text-decoration: none !important;
  font-size: 0.85rem;
  color: black;
  border-left: 4px solid transparent;
  padding: 0.5rem 1rem;
}
.agencyprofile .sec__left .active {
  font-weight: 600;
  border-left: 4px solid #ff656a;
}

.agencyprofile .sec__right {
  flex: 0.75;
}
.agencyprofile .sec__right .para__cont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 3rem;
}
.agencyprofile .sec__right .para__cont .paira p,
.agencyprofile .sec__right .para__cont p {
  text-align: left;
  line-height: 1.5rem;
}
.agencyprofile .sec__right .para__cont .paira .large__para {
  margin-bottom: 1.5rem;
}
.agencyprofile .sec__right .para__cont .tags {
  display: flex;
  column-gap: 0.5rem;
  row-gap: 0.3rem;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  float: left;
  margin-bottom: 1.4rem;
  width: 100%;
}

.agencyprofile .sec__right .para__cont .tag {
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.2rem 0.7rem;
  border-radius: 3px;
  color: black;

  background: #f0f0f0;
  cursor: pointer;
}
.agencyprofile .sec__right .para__cont .awards__logos {
  display: flex;
  column-gap: 3rem;
  row-gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  float: left;
  width: 100%;
}
.agencyprofile .sec__right .para__cont .awards__logos .awards__logo {
  object-fit: contain;
  height: auto;
  max-height: 90px;
}
.agencyprofile .sec__right .para__cont .services {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;
}
.agencyprofile .sec__right .para__cont .services .services__title {
  font-size: 0.9rem;
  font-weight: 600;
  text-align: left;
}
.services__title {
  font-size: 0.9rem;
  font-weight: 600;
  text-align: left;
}
.agencyprofile .sec__right .para__cont .services__tags {
  display: flex;
  column-gap: 0.5rem;
  row-gap: 0.3rem;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  float: left;
  width: 100%;
  text-decoration: none ;
}
.agencyprofile .sec__right .para__cont .services .services__tag {
  font-size: 0.8rem;
  border-radius: 3px;
  margin-right: 1.5rem;
  text-decoration: underline;
  font-style: italic;
  color: black;

  cursor: pointer;
}
.agencyprofile .sec__right .para__cont .agencies {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;
  width: 100% !important;
}

.agencyprofile .sec__right .para__cont .clients {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;
}
.agencyprofile .sec__right .para__cont .services .client__logos {
  display: flex;
  flex-direction: row;
    column-gap: 3rem;
   justify-content: flex-start;
   align-items: center;
   flex-wrap: wrap;
   float: left;
   width: 100%;
   cursor: pointer;

}
.agencyprofile .sec__right .para__cont .services .client__logos img:hover{
  -ms-transform: scale(1.25); /* IE 9 */
  -webkit-transform: scale(1.25); /* Safari 3-8 */
  transform: scale(1.25); 
}
.agencyprofile .sec__right .client__logo {
  object-fit: contain;
  height: auto;
  height:150px;
  margin-top:15px ;
  width: 176px;
  
}
.agencyprofile .sec__right .para__cont .services .study__imgs {
  display: flex;
  column-gap: 1rem;
  row-gap: 1rem;
}
.sec__right .para__cont .services .study__imgs .study__img__cont {
  position: relative;
}
.sec__right .para__cont .services .study__imgs .study__text {
  position: absolute;
  bottom: 0;
  color: rgba(255, 255, 255, 0.808);
  padding: 0 0.5rem 1rem 0.5rem;
}
.sec__right .para__cont .services .study__imgs .study__img {
  object-fit: contain;
  height: auto;
  max-height: 270px;
  max-width: 270px;
  width: 100%;
}

.sec__right .para__cont .showmorebtn__cont,
.sec__right .para__cont .services .showmorebtn__cont {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 0.5rem;
}
.sec__right .para__cont .showmorebtn__cont button,
.sec__right .para__cont .services .showmorebtn__cont button {
  cursor: pointer;
  border: none;
  background: transparent;
  font-family: "Poppins";
  font-size: 0.8rem;
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  .agencyprofile {
    margin: 3rem 5%;
  }
}
@media screen and (max-width: 800px) {
  .agencyprofile .sec__left {
    display: none;
  }
  .agencyprofile .sec__right {
    flex: 1;
  }
}
@media screen and (max-width: 550px) {
  .agencyprofile .sec__right .para__cont .services .study__imgs {
    width: 100%;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5rem;
  }
}

.agencyprofile .sec__right .para__cont .agencyreviews {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;
  width: 100%;
}
.agencyprofile .sec__right .para__cont .agencyreviews .part1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.sec__right .para__cont .agencyreviews .addreviewbtn {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  border: none;
  background: transparent;
  font-size: 0.8rem;
  font-family: "Poppins";
}
.agencyreviews .part2 {
  /* display: flex; */
  display: grid;
  grid-template-columns: repeat(2, minmax(200px, 1fr));
  grid-gap: 1rem;
}
.agencyreviews .part2 .agc__rew__card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.1rem;
  /* border-radius: 2px;
  border: 1.5px solid rgba(211, 211, 211, 0.39); */
  padding: 1.2rem;
  background: #FFFFFF;
  border: 1px solid #E8EFF7;
  border-radius: 10px;
}

.agencyreviews .part2 .agc__rew__card .para{
  font-family: "lexend";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #5C7793;
}

.agencyreviews .agency-client-logo_name {
  height: fit-content !important;
  background: #eff6fb;
  border-radius: 100px;
  width: fit-content !important;
  padding: 5px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-top: 8px;
  margin-bottom: 25px;
}

.agencyreviews .agency-client-logo_name .agency-client-logo {
  height: 18px;
  width: 18px;
  border-radius: 50%;
}

.agencyreviews .agency-client-logo_name .agency-client-name {
  font-family: "lexend";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: rgba(11, 28, 57, 0.5);
  margin-left: 5px;
}

.agencyreviews .part2 .agc__rew__card .stars {
  display: flex;
  column-gap: 0.2rem;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.8rem;
}

.agencyreviews .part2 .agc__rew__card .review__title {
  margin-bottom: 1rem;
}

.agencyreviews .showmorebtn__cont {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 0.5rem;
  width: 100%;
}
.agencyreviews .showmorebtn__cont button {
  cursor: pointer;
  border: none;
  background: transparent;
  font-family: "Poppins";
  font-size: 0.8rem;
  cursor: pointer;
}

@media screen and (max-width: 630px) {
  .agencyreviews .part2 {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
    grid-gap: 0.7rem;
  }
  .agencyreviews .part2 .agc__rew__card {
    padding: 0.7rem;
  }
}

.agencyprofile .sec__right .para__cont .services .news__cards {
  display: flex;
  column-gap: 1rem;
  row-gap: 1rem;
  width: 100%;
}
.news__cards {
  align-items: flex-end;
}
.sec__right .para__cont .services .news__cards .news__card {
  border: 1.5px solid lightgray;
  padding: 0;
  width: 100%;
}
.sec__right .para__cont .services .news__cards .news__card__img {
  object-fit: contain;
  height: auto;
  width: 100%;
}
.sec__right .para__cont .services .news__cards .news__card__detail {
  padding: 0.5rem;
  text-align: left;
}
@media screen and (max-width: 550px) {
  .agencyprofile .sec__right .para__cont .services {
    width: 100%;
  }
  .agencyprofile .sec__right .para__cont .services .news__cards {
    flex-direction: column;
    align-items: center;
    row-gap: 0.75rem;
    width: 100%;
  }
  .agencyprofile .sec__right .para__cont .services .news__card {
    width: 100%;
    max-width: 300px;
  }
}

.agencyprofile .sec__right .para__cont .teammembers {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;
  width: 100%;
}
.agencyprofile .sec__right .para__cont .teammembers .part1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.teammembers .part2 {
  /* display: flex; */
  display: grid;
  grid-template-columns: repeat(2, minmax(250px, 1fr));
  /* grid-gap: 1rem; */
  width: 100%;
}
.teammembers .part2 .teammembers__card {
  display: flex;
  align-items: flex-start;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  border-radius: 2px;
  /* border: 1.5px solid rgba(211, 211, 211, 0.39); */
  padding: 1.2rem;
}
.teammembers .part2 .teammembers__card .teammembers__img__cont {
  position: relative;
}
.teammembers
  .part2
  .teammembers__card
  .teammembers__img__cont
  .teammembers__img {
  height: 48px;
  width: 48px;
  border-radius: 3rem;
}
.teammembers .part2 .teammembers__card .teammembers__img__cont .linkedinrnd {
  position: absolute;
  bottom: 15%;
  right: -10%;
}
.teammembers .part2 .teammembers__card .teammembers__data {
  text-align: left;
}
.teammembers .part2 .teammembers__card .teammembers__data .redirect__btn {
  margin-left: 0.4rem;
  object-fit: contain;
  height: 13px;
  cursor: pointer;
}

@media screen and (max-width: 630px) {
  .teammembers .part2 {
    grid-template-columns: repeat(1, minmax(250px, 1fr));
  }
  .teammembers .part2 .teammembers__card {
    padding: 0.8rem;
  }
  .teammembers .part2 .agc__rew__card {
    padding: 0.7rem;
  }
}

/* Careers */
.agencyprofile .sec__right .para__cont .careers {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;
  width: 100%;
}
.agencyprofile .sec__right .para__cont .careers .part1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.careers .part2 {
  display: flex;
  flex-direction: column;
  column-gap: 1rem;
  row-gap: 1rem;
  width: 100%;
}
.careers .part2 .careers__card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.7rem;
  column-gap: 0.5rem;
  border-radius: 2px;
  border: 1.5px solid rgba(211, 211, 211, 0.555);
  padding: 1.2rem;
  text-align: left;
}
.careers .part2 .careers__card .part__top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.careers .part2 .careers__card .part__top .card__data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.4rem;
}
.careers .part2 .careers__card .linkedinw {
  background: #3478ae;
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  color: white;
  font-family: "Poppins";
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.7rem;
  cursor: pointer;
  border-radius: 1px;
}
.careers .part2 .careers__card .readmorebtn__cont {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.careers .part2 .careers__card .readmorebtn__cont button {
  cursor: pointer;
  border: none;
  background: transparent;
  font-family: "Poppins";
  font-size: 0.8rem;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .careers .part2 .careers__card .part__top {
    flex-direction: column;
    row-gap: 0.5rem;
  }
}
