.Second_compo {
    display: flex;
    flex-direction: column;
    color: #6E6E6E;
    margin: 2.5% 2.5% 0 2.5%;
    padding: 0 42px;
}

.project_tittle {
    color: #6E6E6E;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.project_tittle>p {
    margin-bottom: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #6E6E6E;

}

.project_tittle>input {
    background: #FFFFFF;
    border: 1px solid #C5C5C5;
    border-radius: 3px;
    width: 100%;
    height: 32px;
    padding-left: 10px;
    outline: none;
}

.basic_details {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 2rem;    
}

.basic_details>.inputfield>.textField {
    background: #F8F8F8;
    padding: 30px 40px;
    border: 1px solid #E3E7E9;
    display: flex;
    flex-direction: column;

}

.basic_details>.inputfield>.textField:hover {
    border-left: 3px solid #191E22;
}

.basic_details>.inputfield>.textField>div {
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    border: 1px solid #C5C5C5;
    margin-bottom: 0px;
    padding: 5px 20px;
    align-items: center;
}

.basic_details>.inputfield>.textField>div>p {
    margin-bottom: 0px;
    font-weight: 800;
}

.basic_details>.inputfield>.textField>div>button {
    background-color: transparent;
    border: transparent;
    height: 100%;
    font-weight: 700;
    color: #FF656A;

}

.basic_details>.inputfield>.textField>span {
    color: #9B9B9B;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.05em;
    display: flex;
    margin-top: 10px;
    text-align: left;
}

.basic_details>.inputfield>.textField>ul {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 21px;
    color: #6E6E6E;
}

.basic_details>.inputfield>.textField>input {
    width: 100%;
    padding: 30px 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.05em;
    outline: none;
    background: #FFFFFF;
    border: 1px solid #C5C5C5;
    border-radius: 3px;
}

.basic_details>.inputfield>.textField>.save_delete_button {
    background-color: transparent;
    display: flex;
    gap: 20px;
    margin-top: 15px;
    border: transparent;
    justify-content: flex-end;
    padding-right: 0;
}

.basic_details>.inputfield>.textField>.save_delete_button>button {
    background-color: transparent;
    border: 1px solid #FF656A;
    color: #FF656A;
    border-radius: 5px;
    padding: 5px 20px;
    transition-duration: 0.4s;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.basic_details>.inputfield>.textField>.save_delete_button>button:first-child {
    border: none
}

.basic_details>.inputfield>.textField>.save_delete_button>button:hover:last-child {
    background-color: #FF656A;
    color: white;
}

.basic_details>.inputfield>.textField>.inputs {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    background-color: transparent;
    border: transparent;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
}

.basic_details>.inputfield>.textField>.inputs>div {
    text-align: start;
    display: flex;
    flex-direction: column;

}

.basic_details>.inputfield>.textField>.inputs>div>span {
    color: #0B1C39;
    font-weight: 800;
    font-size: 12px;
}

.Second_compo .btn-style {
    background-color: transparent;
    border: transparent;
    height: 100%;
    font-weight: 700;
    color: #FF656A;
}

.Second_compo .section-style {
    justify-content: unset !important;
    gap: unset !important;
    width: 100% !important;
    align-items: center;
}

.Second_compo .section-style div:nth-child(1) {
    flex: 1;
    margin-right: 10px;
}

.Second_compo .section-style div:nth-child(2) {
    flex: 3;
    margin-right: 20px;
}

.Second_compo .arrow {
    border: solid #6E6E6E;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    height: 3px;
}

.Second_compo .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.Second_compo .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.Second_compo .credit-heading {
    width: 100%;
    background-color: transparent !important;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 15px;
    padding: 0 !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: rgba(110, 110, 110, 0.7);
    border-top: 0 !important;
    border-bottom: 2px solid rgba(110, 110, 110, 0.4) !important;
    border-right: 0 !important;
    border-left: 0 !important;
}

.Second_compo .credit-div {
    width: 100%;
    background-color: transparent !important;
    border: none !important;
    padding: 0 !important;
}

.Second_compo .credit-section-style {
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: #0B1C39;
    font-weight: 800;
    font-size: 12px;
}

.Second_compo .row-style {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #6E6E6E;
    margin-top: 5px;
}

