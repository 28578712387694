.components>.about{
    display: flex;
    flex-direction: column;
    margin-left: 10vw;
    width: 80vw;
    gap: 30px;
    margin-top: 30px;
}
.about >div{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.about>.overview> h5{
    font-size: 250%;
    font-weight: 350;
    margin: 0px;
    margin-bottom:20px ;
}
.about> .statistics{
    background-color:  #E8EFF7;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap:5vw;
    height: 100%;
    padding-bottom: 80px;
    justify-content: left;
}
.about> .statistics>div{
    width: 200px;
}

.about> .statistics h1{
    font-size: 24px;
    font-weight: bolder;
}
 
.about h2{
    font-weight: 500;
    font-size: 30px;
    font-family: "lexend";
    width: 80vw;
    padding-top :  15px;
    padding-bottom :  15px;
    border-top: 1px solid  #DFE9F3 ;
    border-bottom: 1px solid  #DFE9F3
}
.about h1{ 
 
    font-size: 55px;
    font-weight: 200;
    line-height: 49px;
    letter-spacing: 0em;
    text-align: left;
}
.about > p{
    text-align: justify;
    font-size: 14px;
    margin-left: 10px;
    margin-bottom: 0;
}

@media screen and (max-width: 680px){
    .about>.statistics{
        flex-direction: column;
    }
    .about> .statistics h1{
        margin-bottom: 0 !important;
    }
    .about h1{
        font-size: 32px;
    }
    .about h2{
        font-size: 24px;
    }
     
}

 
 