.popularagency {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
  padding: 0;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
}

.popularagency > .headingSection{
  display: table;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #E7E3E9;
  width: 100%;
  text-align: left;
}
.popularagency > .headingSection > h2{
  height: 100px;
  display: table-cell;
  vertical-align: middle;
  text-align: left;
  font-size: 25px;
  font-weight: 400;
  line-height: 60px;
  padding-left: 20px;
}

.agenciesCont {
  display: flex;
  justify-content: space-between;
  row-gap: 3rem;
  column-gap: 5rem;
  overflow: scroll;
}

.agenciesCont>ul {
  min-width: fit-content !important;
}

.agenciesCont ul li {
  list-style-type: none;
  text-align: initial;
  margin-bottom: 6px;
}

.agenciesCont ul li a {
  text-decoration: none;
  color: var(--black-525464-d, #525464);
  font-size: 16px;
  font-weight: 300;
  line-height: 35px;
  letter-spacing: 0.32px;
  transition: 0.5s;
}
.serviceHeading > .serviceHeadingLink{
  text-decoration: none;
  text-align: left;
  transition: 0.5s;
}
.serviceHeading > .serviceHeadingLink > h3{
  color: var(--black-525464-d, #525464);
  font-size: 20px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.4px;
}
.servicePageTabs > .servicePageText{
  color: var(--black-525464-d, #525464);
  font-size: 16px;
  font-weight: 300;
  line-height: 35px;
  letter-spacing: 0.32px;
}
.agenciesCont ul li a:hover {
  color: #f76368;
}
@media screen and (max-width: 1000px) {
  .popularagency {
    max-width: 100vw;
  }
}
@media screen and (max-width: 768px) {
  .popularagency {
    row-gap: 1.5rem;
    padding: 3rem 5%;
  }

  .popularagency .normal__heading {
    font-size: 24px;
    line-height: 29px;
  }

  .popularagency .agenciesCont{
    overflow: scroll;
  }

  .popularagency .agenciesCont .tab{
    padding-left: unset;
    min-width: 43%;
  }
}

  /* .agenciesCont :nth-child(3) {
    display: none;
  } */
  @media screen and (max-width: 768px) {
    .popularagency .agenciesCont .tab{
      padding-left: unset;
      min-width: 80%;
    }
  }
