.userProfileTalentPool {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

}

.userProfileTalentPool .singleCateogory {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 55px;
    border: 1px solid #E0E0E0;
    padding: 0 30px;
    border-radius: 20px;
    cursor: pointer;
}

.userProfileTalentPool .singleCateogory:hover {
background-color:  #E0E0E0;

}

.userProfileTalentPool .singleCateogory .categoryInfo{
  font-size: 1.2rem;
  font-weight: 400;
}
.userProfileTalentPool .singleCateogory .viewProfileButton {
  align-self: flex-end;
  background-color: transparent;
  display: none;
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
}

.userProfileTalentPoolMobile {
  display: none;
}

.categoryPrice {
  font-weight: 500;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 600px) {
  /* Hide the desktop view on mobile */
  .userProfileTalentPool {
    display: none;
  }

  .userProfileTalentPoolMobile {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }

  .profile-mob {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }

  .profile-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }

  .user-icon {
    font-size: 40px;
    color: #000;
  }

  .userProfiles-relative {
    display: flex;
    gap: 5px;
  }

  .userProfiles-relative img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: 2px solid #fff;
  }

  .person1-logo {
    position: relative;
    z-index: 3;
  }

  .person2-logo {
    position: relative;
    z-index: 2;
    margin-left: -20px;
  }

  .person3-logo {
    position: relative;
    z-index: 1;
    margin-left: -20px;
  }
  .profile-name{
    display: flex;
    column-gap: 3px;
    justify-content: center;
    align-items: center;
    
  }

  .singleCategoryMobile {
    border: 1px solid #E0E0E0;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    max-height: 250px;
    padding: 0 0 20px 0;
  }

  .singleCategoryMobile .categoryHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #E0E0E0;
    padding: 10px;
    /* height: 40px; */
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    font-size: 22px;
    font-weight: 600;
    padding: 20px 50px 20px 20px;
  }


 



  .singleCategoryMobile .userProfiles {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px;
    background-color: #F7F7F7;
    position: relative;
  }

  .singleCategoryMobile .iconSize20 {
    width: 20px;
    height: 20px;
  }

  .userProfileTalentPoolMobile .singleCategoryMobile .categoryFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 10px;
  }

  .userProfileTalentPoolMobile .singleCategoryMobile .categoryFooter .viewTalentPoolButton {
    background-color: #000;
    color: #fff;
    border: none;
    /* padding: 5px 10px; */
    border-radius: 20px;
    cursor: pointer;
    font-size: 1rem;
    padding: 10px;
    width: auto;
  }


}
