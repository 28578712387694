.search-page {
    display: flex;
    background-color: #FFFFFF;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    border-left: 1px solid #e7e3e9;
    border-right: 1px solid #e7e3e9;
    border-bottom: 1px solid #e7e3e9;
    margin-bottom: 3rem;
    overflow-x: hidden;
}
.search-page .filter-container {
    display: flex;
    margin: 0 auto;
    position: relative;
    width: 93%;
    padding: 40px 40px 24px 40px;
    border-left: 1px solid #e7e3e9;
    border-right: 1px solid #e7e3e9;
}

.search-page .filter-container .search-btn {
    height: 49px;
}

  .total-results {
    font-family: 'lexend';
    width: 100vw;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #191E22;
    display: flex;
    height: 50px;
    align-items: center;
    border-top: 1px solid #e7e3e9 ;
    border-bottom: 1px solid #e7e3e9 ;
    padding: 0 6vw;
}

.search-page .content-div {
    display: flex;
    margin: 0 auto;
    width: 93%;
    height: 77vh;
    padding: 0px 40px;
    border-left: 1px solid #e7e3e9;
    border-right: 1px solid #e7e3e9;
}

.search-page .agency-list {
    width: 40%;
    height: 75vh;
    overflow: hidden;
    overflow-y: scroll;
}
.search-page .agency-list .agencyCardSec{
    height: calc(69vh - 6px);
    overflow-y: scroll;
}
.search-page .pagination_sec{
    height: calc(6vh + 6px);
    align-items: center;
    border: 1px solid #e7e3e9;
    margin: 0px !important;
    margin-top: 6px;
    box-shadow: 0px -4px 4px #E7E3E9;
}

.search-page .agency-wrapper {
    display: flex;
}
.search-page .agency-wrapper:first-child{
    padding-top: 12px;
}

.search-page .selected-agencies {
    background-color: #191E22;
    width: 5px;
    margin-bottom: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.search-page .agency-details-wrapper {
    width: 60%;
    height: 75vh;
    overflow: hidden;
    overflow-y: scroll;
    background: #FFFFFF;
    border: 1px solid #e7e3e9;
    box-sizing: border-box;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-right: 0px !important;
    margin-top: 12px;
}

.pagination_sec {
    display: flex;
    width: 100%;
    justify-content: center;
    list-style-type: none;
}

.pagination_sec_li {
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    border-radius: 6px;
    background-color: #d3d3d394;
    color: #6e6e6e;
    font-size: 12px;
}

.pagination_sec_break {
    margin-right: 15px;
    color: #6e6e6e;
}

.pagination_sec_active {
    color: #FD666E;
    background-color: #ff656a0d;
}

.transparent {
    opacity: 0.2;
    background: linear-gradient(0deg, #F0F7FC 55%, rgba(196, 196, 196, 0) 100%);
}

.register_text {
    display: flex;
    position: relative;
    top: -295px;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    flex-wrap: wrap;
    padding-top: 20px !important;
}

.link-text {
    text-decoration: underline;
    color: dodgerblue;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
}

.search-page .service__page {
    -webkit-column-gap: 1rem;
    column-gap: 1rem;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    text-align: left;
    margin-top: 70px;
}

.popup {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.popup .popup-wrapper {
    max-width: 300px;
    background-color: #fff;
    border: 1px solid #DFE9F3;
    border-radius: 5px;
}

.popup .popup-wrapper .close-btn {
    width: 100%;
    justify-content: flex-end;
    display: flex;
    padding-right: 10px;
    padding-top: 10px;
    color: #858D9C;
    cursor: pointer;
    font-size: 18px;
}

.popup .popup-wrapper .popup-content {
    margin-top: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding:0 40px 40px 40px;
}

.popup .popup-wrapper .popup-content .popup-header {
    margin-top: 15px;
    font-family: 'lexend';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 15px;
    display: flex;
    color: #525464;
}

.popup .popup-wrapper .popup-content .popup-text1 {
    font-family: 'lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #525464;
    margin-top: 30px;
}

.popup .popup-wrapper .popup-content .popup-text2 {
    font-family: 'lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #525464;
    margin-top: 20px;
    margin-bottom: 30px;
}
.search-page ::-webkit-scrollbar{
    display: none;
}

@media screen and (max-height: 1000px) {
    .search-page .agency-details-wrapper{
        height: 73vh;
    }
    .search-page .agency-list {
        height: 73vh;
    }
    .search-page .agency-list .agencyCardSec{
        height: calc(67vh - 6px);
    }
}

@media screen and (max-width: 768px) {
    .search-page .filter-container {
        padding: 24px;
        flex-wrap: wrap;
        justify-content: center;
        left: 0;
    }

    .search-page .content-div {
        padding: 12px 0 0 0;
    }

    .search-page .filter-container .filter-dropdown {
        width: 42%;
        margin-top: 5px;
    }

    .search-page .filter-container .search-btn {
        margin-top: 15px;
    }

    .search-page .content-div .agency-list {
        width: 100%;
        height: unset;
    }

    .search-page .content-div .agency-list .selected-agencies {
        display: none;
    }

    .search-page .content-div .agency-details-wrapper {
        display: none;
    }
}
