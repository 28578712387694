.education_main_container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.education_main_container .eachCollegeExperience {
  border-radius: 30px;
  background-color: #f7f7f7;
  padding: 30px;
  display: flex;
  justify-content: start;
  gap: 1rem;
  text-align: left;
  width: 100%;
}

.education_main_container .theAddButtonLine {
  display: flex;
  width: 100%;
  justify-content: start;
}
.education_main_container .experience-item {
  margin-bottom: 15px;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: start;
  gap: 1rem;
}
.education_main_container .userEducationContainer {
  display: inline;
}

.education_main_container .writtenExperience {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: start;
  gap: 20px;
  width: 100%;
}

.education_main_container .education-item-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  gap: 5px;
  width: 100%;
}

.education_main_container .education-item-title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.education_main_container .education-item-title .editDeleteComponent {
  position: relative;
}
.education_main_container
  .education-item-title
  .editDeleteComponent
  .editDeleteButtons {
  position: absolute;
  right: 90%;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #e0e0e0;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
}

.education_main_container
  .education-item-title
  .editDeleteComponent
  .editDeleteButtons
  button {
  gap: 10px;
  border: 1px solid #e0e0e0;
  background-color: white;
  border-radius: 10px;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 500;
}

.education_main_container
  .education-item-title
  .editDeleteComponent
  .editDeleteButtons
  button:hover {
  background-color: #e0e0e0;
}

.education_main_container .education-item-company {
  font-weight: 500;
  font-size: 16px;
}

.education_main_container .education-item-description {
  padding-top: 5px;
  font-weight: 400;
  font-size: 18px;
  word-break: break-word;
  overflow-wrap: break-word;
}

.education_main_container .education-item-duration {
  font-weight: 400;
  font-size: 16px;
  color: #808080;
}

.education_form {
  padding: 20px 0px;
  text-align: start;
  width: 640px;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.mainContainer {
  position: sticky;
  top: 0;
  /* z-index: 100; */
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.label {
  color: black;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 15px;
}

.educationDetails {
  position: relative;
  top: 0;
  width: 100%;
}

.inputWrapper {
  border: 1px solid #e0e0e0;
  background-color: white;
  border-radius: 10px;

  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 60px;
}

.textareaWrapper {
  padding: 5px 0 5px 15px;
  height: 200px;
}

.checkBoxWrapper {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  flex-direction: row;
  margin: 5px 0;
}

.AddMoreInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e0e0;
  background-color: #f7f7f7;
  width: 100%;
  height: 60px;
  font-weight: 400;
  font-size: 18px;
  color: #505050;
  border-radius: 10px;
  cursor: pointer;
}

.inputWrapper > input,
.inputWrapper > textarea {
  outline: none;
  border: none;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  background-color: transparent;
  resize: none;
  height: 100%;
  padding: 10px 0;
}

.input::placeholder,
.textarea::placeholder {
  color: #b1b1b1;
  font-weight: 400;
  size: 18px;
}

.inputWrapper > textarea::-webkit-scrollbar {
  display: none;
}

.searchedCompany {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 10px;
  cursor: pointer;
}

.searchedCompanyNameAndLogo {
  display: flex;
  gap: 5px;
  align-items: center;
}

.searchedCompanyNameAndLogo > img {
  width: 20px;
  height: 20px;
}

.searchedCompanyWebsite {
  color: #b4aebd;
}

.searchedResult {
  position: absolute;
  top: 80px;
  border-radius: 0;
  background-color: #f8f8f8;
  overflow: auto;
  max-height: 200px;
  padding: 10px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);

  /* background-color: #FFFFFF;
      border: 1px solid #E7E3E9;
      border-top: none;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      z-index: 1;
      display: flex;
      flex-direction: column;
      gap: 4px;
      max-height: 200px;
      overflow-y: auto; */
}

hr {
  margin: 0 auto;
  border: none;
  color: rgb(186, 184, 187);
}

.noCompanyExistWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  width: 100%;
}

.cancelIcon > img {
  background-color: #fff;
  border: 1px solid #e7e3e9;
}

.plusIcon {
  border: none;
  outline: none;
  width: 100%;
  cursor: pointer;
  background-color: transparent;
}

.dateWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.dateWrapper > .inputWrapper {
  width: 50%;
}

.submitButtonWrapper {
  bottom: 20px;
  background-color: #191e22;
  border: 1px solid #e7e3e9;
  /* width: fit-content; */
  margin: 0 auto;
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submitButtonWrapper .addExperienceBtn {
  display: flex;
  background-color: #191e22;
  border: none;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: #fff;
  display: flex;
  /* max-width: 600px; */
  justify-content: center;
  font-size: 16px;
  font-style: normal;
  /* padding: 20px; */
  height: 50px;
  font-weight: 700;
  line-height: 16px;
}

.submitButtonWrapper .addExperienceBtn:hover {
  color: rgb(217, 217, 217);
}

.selectField {
  border: none;
  width: 100%;
  padding: 5px;
}

.createNewCompanyWrapper {
  flex-direction: column;
  gap: 30px;
  width: 100%;
  border: 1px solid #e7e3e9;
  padding: 25px;
}

.isMarketingAgencyWrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
}

.isMarketingAgencyHeading {
  font-size: 12px;
  font-weight: bold;
  color: #191e22;
  margin: 0 5px;
}

.radioBtnWrapper {
  display: flex;
  align-items: center;
  gap: 14px;
  accent-color: black;
  margin-left: 5px;
}

.education_main_container
  > .formButtons
  > .innerFormContainer
  > .formBtn:nth-child(1) {
  border-right: 1px solid #e7e3e9;
}

.education_main_container > .formButtons > .innerFormContainer > .formBtn {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none;
}

.education_main_container
  > .formButtons
  > .innerFormContainer
  > .formBtn:hover {
  background-color: #dfdfdf;
}

.educationFormDates {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 870px) {
  .education_form {
    width: 95%;
    margin: 0 2.5%;
  }
  .educationFormDates {
    display: block;
  }
  .submitButtonWrapper {
    width: 95%;
    margin: 0 2.5%;
  }
}
