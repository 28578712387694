.componentOne > .heroSection{
    display: flex;
    flex-direction: row;
    width: 1240px;
    height: 500px;
    margin: 0 auto;
    border-left: 1px solid #E7E3E9;
    border-bottom: 1px solid #E7E3E9;
}
.componentOne > .heroSection > .heroLeft{
    margin-left: 50px;
    text-align: left;
    margin-top: 75px;
    width: 740px;
    height: 500px;
}
.componentOne > .heroSection > .heroLeft > h1{
    font-size: 45px;
    font-style: normal;
    font-weight: 300;
    line-height: 70px;
    display: inline;
}
.heroLeft > h1::after {
    content: "\00A0"; /* Unicode character for non-breaking space */
  }
.componentOne > .heroSection > .heroLeft > h1:last-of-type  {
    font-weight: bold;
}
.componentOne > .heroSection > .heroLeft >.btnSection > button{
    font-size: 14px;
    line-height: 20px;
    padding: 10px 20px;
    border: 1px solid #E7E3E9;
    background: #191E22;
    color: white;
    margin-top: 75px;
}
.componentOne > .heroSection > .heroLeft >.btnSection> .callBtn{
    color: #B4AEBD;
    margin-left: 50px;
    display: none;
}

.componentOne > .heroSection > .heroRight > .grid-container {
    display: grid;
    grid-template-columns: repeat(5, 100px);
    grid-template-rows: repeat(5, 100px);
    gap: 0px; /* Add spacing between grid items if desired */
    border: 1px solid #E7E3E9;
    border-bottom: 0px;
    border-top: 0px;

}
  
.componentOne > .heroSection > .heroRight > .grid-container > .grid-item {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E7E3E9;
}

.componentOne > .heroSection > .heroRight > .grid-container > .grid-item img {
    width: 34.615px;
    height: 30px;
    object-fit: cover; /* Adjust image fit if necessary */
}
  
.componentOne > .heroSection > .heroRight{
    width: 500px;
}
.componentOne > .leverageSection{
    height: 100px;
    width: 1440px;
    border-top: 1px solid #E7E3E9;
    border-bottom: 1px solid #E7E3E9 ;
    display: none;
}
@media screen and (min-width: 1500px){
    .componentOne > .heroSection{
        width: 84%;
    }
    
    .componentOne > .heroSection > .heroRight > .grid-container {
        grid-template-columns: repeat(5, 100px);
        grid-template-rows: repeat(5, 100px); 
        justify-self: end; 
    }
    .componentOne > .heroSection > .heroRight {
        width: 50%;
        display: flex;
        justify-content: flex-end; /* Aligns the grid to the right */
      }         
    .componentOne > .leverageSection{
        width: 100%;
    }
}
@media screen and (max-width: 1280px){
    .componentOne > .heroSection{
        width: 1240px;
    }
}
@media screen and (max-width: 1240px){
    .componentOne > .heroSection{
        width: 100%;
        height: 450px;
    }
    .componentOne > .heroSection > .heroLeft{
        margin-left: 25px;
        text-align: left;
        margin-top: 50px;
        width: 600px;
        height: 450px;
    }
    .componentOne > .heroSection > .heroRight > .grid-container {
        grid-template-columns: repeat(5, 90px);
        grid-template-rows: repeat(5, 90px); 
    }
    .componentOne > .heroSection > .heroRight {
        width: auto;
        display: flex;
        justify-content: flex-end; /* Aligns the grid to the right */
        margin-left: auto;
    }         
    .componentOne > .leverageSection{
        width: 100%;
    }
}
@media screen and (max-width: 1000px){
    .componentOne > .heroSection{
        display: flex;
        flex-direction: column;
        height: auto;
    }
    .componentOne > .heroSection > .heroLeft{
        margin: 50px auto;
        text-align: center;
        width: 80%;
        height: auto;
    }
    .componentOne > .heroSection > .heroRight {
        width: auto;
        display: flex;
        justify-content: center; /* Aligns the grid to the center */
        margin: 0 auto;
    }
    .componentOne > .heroSection > .heroRight > .grid-container {
        display: grid;
        grid-template-columns: repeat(5, 130px);
        grid-template-rows: repeat(5, 130px);
        gap: 0px; /* Add spacing between grid items if desired */
        border: none;
        margin-bottom: 20px;
    }
    .componentOne > .heroSection > .heroRight > .grid-container > .grid-item {
        border: none;
    }
}
@media screen and (max-width: 640px){
     .componentOne > .heroSection > .heroRight > .grid-container {
        grid-template-columns: repeat(5, 100px);
        grid-template-rows: repeat(5, 100px);
    }
}
@media screen and (max-width: 520px){
    .componentOne > .heroSection > .heroRight > .grid-container {
        grid-template-columns: repeat(5, 68px);
        grid-template-rows: repeat(5, 68px);
    }
    .componentOne > .heroSection > .heroLeft > h1{
        font-size: 25px;
        font-style: normal;
        font-weight: 300;
        line-height: 40px;
    }
    .componentOne > .heroSection > .heroLeft >.btnSection> button{
        margin-top: 30px;
    }
    .componentOne > .heroSection > .heroLeft >.btnSection> .callBtn{
        display: none;
    }
}