   .loaderPage{
     display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex: 1 1;
    width: 100vw;
    position: absolute;
    background-color: white;
    z-index: 1000;
   }
   #loader-container {
    text-align: center;
    opacity: 0; /* Set initial opacity to 0 to make the logo invisible */
    animation: fadeInOut 2s infinite; /* Apply the fade in/out animation */
    z-index: 2;
  }

  @keyframes fadeInOut {
    0% { opacity: 0; }
    30% { opacity: 0; } /* Add a delay before the loader becomes visible */
    50% { opacity: 1; }
    70% { opacity: 1; } /* Add a delay before the loader starts to fade out */
    100% { opacity: 0; }
  }
