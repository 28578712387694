.featureagency {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  width: 100%;
  padding: 4rem 0;
  box-sizing: border-box;
}
@media screen and (max-width: 750px) {
  .homepage .featureagency {
    padding-left: 5%;
    padding-right: 5%;
    font-size: 85%;
  }
}
/* .featureagency .featureagency__cat__cont {
  overflow-x: scroll;
} */
.featureagency .featureagency__cat__cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  font-size: 1rem;
  /* min-width: 600px; */
  /* width: 100%; */
  max-width: 1128px;
  width: 88vw;
  overflow-x: scroll;
  /* flex-wrap: wrap;
  float: left; */
}
.featureagency .featureagency__cat__cont::-webkit-scrollbar {
  display: none;
}
.featureagency__cat__cont span {
  font-size: 0.9rem;
  min-width: 170px;
}
.featureagency__cat__cont span:hover {
  color: #ff656a;
  cursor: pointer;
}

.featureagency__cat__cont a {
  text-decoration: none;
  color: black;
}
.selected {
  color: #ff656a;
}

/* .featureagency__data__cont {

} */
.featureagency__cards {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  column-gap: 20px;
  row-gap: 20px;
  margin-top: -10px;
  overflow-x: scroll;
  max-width: 1128px;
  width: 88vw;
}
.featureagency__cards::-webkit-scrollbar {
  display: none;
}
.featureagency__card {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  background: #f8f8f8;
  padding: 1.4rem 1.1rem;
  box-sizing: border-box;
  max-width: 240px;
  text-align: initial;
  /* width: 250px; */
  /* margin-right: 1rem; */
}
.featureagency__card .part1 {
  display: flex;
  width: 200px;
  column-gap: 10px;
}
.featureagency__card .part1 .logo {
  object-fit: contain;
  height: 45px;
  width: 45px;
}
.part1 .part1__comp__det div {
  display: flex;
  column-gap: 5px;
  align-items: center;
}
.part1 .part1__comp__det div image {
  object-fit: contain;
  height: 5px;
}

.featureagency__card .part2 p {
  margin-bottom: 5px;
}
.featureagency__card .part2 .clientlogo {
  object-fit: contain;
  max-height: 50px;
  max-width: 55px;
}

.featureagency__card .part4 {
  display: flex;
  align-items: center;
  column-gap: 6px;
}
.featureagency__card a {
  font-size: 0.8rem;
  font-weight: 600;
  text-decoration: none;
  color: #ff656a;
}

.featureagency__card .part4 .arrowr {
  object-fit: contain;
  height: 12px;
}

/* SWIPER .swiper-container {
  width: 100%;
  height: 100%;
} */

.swiper-slide {
  background: #fff;

  /* Center slide text vertically */
  /* display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex; */
  display: flex;
  /* -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center; */
  align-items: flex-start;

  justify-content: space-between;
}

/* .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
