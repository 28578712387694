.UnispageadvantageContainer {
  padding: 80px;
  display: flex;
  flex-direction: column;
  gap: 80px;
  background-color: white;
  align-items: center;
  box-sizing: border-box;
}

.UnispageadvantageContainer .AdvantagesList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 150px;
  row-gap: 40px;
}

.UnispageadvantageContainer .AdvantagesList .SingleAdvantageContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.UnispageadvantageContainer
  .AdvantagesList
  .SingleAdvantageContainer
  .FeaturesList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.UnispageadvantageContainer
  .AdvantagesList
  .SingleAdvantageContainer
  .FeaturesList
  .Feature {
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 10px 20px;
  background: var(--global-gradient);
}

@media screen and (max-width: 480px) {
  .UnispageadvantageContainer {
    padding: 30px 0 30px 30px;
    align-items: start;
    gap: 40px;
  }

  .UnispageadvantageContainer .AdvantagesList {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
  }

  .UnispageadvantageContainer .AdvantagesList .SingleAdvantageContainer {
    gap: 10px;
  }
}
