.bnewprojectbrief {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  margin: 3.5rem 5%;
  column-gap: 0.5rem;
  text-align: left;
}
.bnewprojectbrief .bnp__top {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
  text-align: center;
  margin: 0 5%;
}
.bnewprojectbrief .bnp__top .progress__bar__cont {
  position: relative;
  height: 0.6rem;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  background: #f3f3f3;
}
.bnewprojectbrief .bnp__top .progress__bar__cont .progress__bar {
  position: absolute;
  height: 100%;
  width: 10%;
  background: #ff656a;
}

/* Brand statement */
.bnewprojectbrief .brandstatement {
  display: flex;
  align-items: flex-end;
  column-gap: 2rem;
  row-gap: 1rem;
  margin: 3rem 5% 0 5%;
}
.bnewprojectbrief .brandstatement .part__left {
  /* flex: 0.7; */
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
}
.bnewprojectbrief .brandstatement .part__left .overviewtext {
  width: 60%;
}
.bnewprojectbrief .brandstatement .textareatextcont {
  display: flex;
  row-gap: 1rem;
  column-gap: 2rem;
}
.bnewprojectbrief .brandstatement .textareatextcont .tpart__left {
  flex: 0.7;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
}
.bnewprojectbrief .brandstatement .textareatextcont .tpart__left .textarea {
  height: 100%;
  min-height: 10rem;
  width: 100%;
  border: 2px solid #ffefef;
  border-radius: 2px;
  padding: 0.5rem;
  box-sizing: border-box;
  color: gray;
  outline: none;
}
.bnewprojectbrief .brandstatement .textareatextcont .tpart__right {
  flex: 0.3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 13px;
  padding: 1rem 3rem;
  box-sizing: border-box;
  text-align: center;
  background: #f3f3f3;
}

/* Project more details */
.bnewprojectbrief .projectmoredetails {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  margin: 2rem 0 0 0;
}
/* Project Overview */
.bnewprojectbrief .projectoverview {
  display: flex;
  align-items: flex-end;
  column-gap: 2rem;
  row-gap: 1rem;
}
.bnewprojectbrief .projectoverview .part__left {
  /* flex: 0.7; */
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
}
.bnewprojectbrief .projectoverview .part__left .overviewtext {
  width: 60%;
}
.bnewprojectbrief .projectoverview .textareatextcont {
  display: flex;
  row-gap: 1rem;
  column-gap: 2rem;
}
.bnewprojectbrief .projectoverview .textareatextcont .tpart__left {
  flex: 0.7;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
}
.bnewprojectbrief .projectoverview .textareatextcont .tpart__left .textarea {
  height: 100%;
  min-height: 10rem;
  width: 100%;
  border: 2px solid #ffefef;
  border-radius: 2px;
  padding: 0.5rem;
  box-sizing: border-box;
  color: gray;
  outline: none;
}
.bnewprojectbrief .projectoverview .textareatextcont .tpart__right {
  flex: 0.3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 13px;
  padding: 1rem 3rem;
  box-sizing: border-box;
  text-align: center;
  background: #f3f3f3;
}

/* Save and exploe */
.bnewprojectbrief .sae__cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
  margin-top: 2rem;
}
.bnewprojectbrief .sae__cont .savebtn {
  background: #ffe0e2;
  padding: 0.5rem 2rem;
  box-sizing: border-box;
  border-radius: 3px;
}

@media screen and (max-width: 768px) {
  .bnewprojectbrief .bnp__top {
    margin: 0;
  }
  .bnewprojectbrief .brandstatement {
    margin: 2rem 0 0 0;
  }
  .bnewprojectbrief .brandstatement .part__left .overviewtext {
    width: 100%;
  }
  .bnewprojectbrief .brandstatement .textareatextcont {
    flex-direction: column;
  }
  .bnewprojectbrief .brandstatement .textareatextcont .tpart__right {
    padding: 1rem 1.5rem;
  }
  .bnewprojectbrief .projectoverview {
    margin: 0 0 0 0;
  }
  .bnewprojectbrief .projectoverview .part__left .overviewtext {
    width: 100%;
  }
  .bnewprojectbrief .projectoverview .textareatextcont {
    flex-direction: column;
  }
  .bnewprojectbrief .projectoverview .textareatextcont .tpart__right {
    padding: 1rem 1.5rem;
  }
}
