.addCategoryBtn {
  /* border: 1px solid red; */
  text-align: right;
  font-size: 16px;
  outline: 1px solid #e7e3e9;
}

.upload-Talent-Pool {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

button:focus:not(:focus-visible) {
  outline: 1px solid #e7e3e9;
}

.upload-Talent-Pool > button {
  padding: 15px 50px;
  color: #b4aebd;
  border: none;
  background-color: transparent;
  outline: 1px solid #e7e3e9;
  cursor: pointer;
  margin: 0;
  font-family: inherit;
}

.close-button {
  background: #191e22;
  border: 1px solid #e7e3e9;
  color: #fff;
  font-weight: 400;
  padding: 10px 15px;
}
.save-button {
  background: #191e22;
  border: 1px solid #e7e3e9;
  color: #fff;
  font-weight: 400;
  padding: 10px 15px;
}
.save-button-wait {
  background: #191e22;
  color: #fff;
  font-weight: 400;
}

.addCategoryBtn button {
  color: #b4aebd;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.poolCategory_table > table {
  width: 100%;
  text-align: center;
  border-collapse: separate;
  border-spacing: 0.5rem;
}

.theActualTable td:first-child {
  width: 80%; /* Adjust the width as needed */
  text-align: left;
  cursor: pointer;
}

.theActualTable td:last-child {
  width: 5%; /* Adjust the width as needed */
}

.poolCategoryInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  color: black;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  font-size: 18px;
  cursor: pointer;
}

.poolCategoryInfo .addCandidatesButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #b4aebd;
  padding: auto;
  border-radius: 8px;
}

.poolCategoryColumn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconSign {
  height: 1rem;
  margin-right: 0.5rem;
}

.iconWrapper {
  width: 74px;
}

.inputWrapper input {
  width: 100%;
  height: 40px;
  border: none;
  border-bottom: 2px solid #e7e3e9;
  background-color: #f8f8f8;
  border-radius: 5px;
  padding: 0 10px;
  outline: none;
}

.categoryDataWrapper {
  width: 316px;
}

.nameWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  /* gap: 10px; */
}

.poolCategory .iconWrapper .crossIcon,
.checkIcon {
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex !important;
}

.poolCategory .iconWrapper .checkIcon:disabled > img {
  cursor: not-allowed;
}

.poolCategory .icons {
  cursor: pointer;
  width: 20px;
}
.poolCategory .pointer-cursor {
  cursor: pointer;
}

.uploadButton {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.uploadButton:hover {
  background-color: #0056b3;
}

.uploadButton span {
  margin-right: 8px; /* Add spacing between text and icon if needed */
}

.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  z-index: 9999;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  border: 5px solid #ddd;
  border-bottom-color: #0f0401;
  margin: -60px 0 0 -60px;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 632px) {
  .theActualTable td:first-child {
    width: fit-content;
    text-align: center;
    cursor: pointer;
  }
  .theActualTable td:last-child {
    width: fit-content; /* Adjust the width as needed */
  }

  /* 
  .poolCategory .addCategoryBtn {
    padding: 15px;
  }


  .poolCategory_table .inputWrapper {
    flex-direction: column;
  }
  .poolCategory_table .inputWrapper .iconWrapper {
    padding: 8px 0;
  }
  .poolCategory_table .inputWrapper .iconWrapper > .checkIcon,
  .poolCategory_table .inputWrapper .iconWrapper > .crossIcon {
    margin: 0 50%;
  } */
}
