.ateam__cont {
  display: flex;

  /* background: #e5e5e5; */
  background: #f6f6f6;
  min-height: 100vh;
}
.ateam {
  flex: 0.82;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 3rem 1.3%;
  box-sizing: border-box;
  width: 100%;
  background: #f6f6f6;
}
@media screen and (max-width: 950px) {
  .ateam {
    flex: 1;
  }
}
.ateam ::placeholder {
  font-family: "Poppins";
  color: #cfcfcf !important;
}

.ateam .section__cont {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}
.ateam .section__cont .section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.ateam .section__cont .section .part__right {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1.3rem;
}
.ateam .section__cont .section .part__right .img__cont {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.ateam .section__cont .section .part__right .img__cont .msg__not {
  object-fit: contain;
  height: 16px;
}
.ateam .section__cont .section .part__right .img__cont .dotg {
  position: absolute;
  right: -10%;
  top: -10%;
}
.ateam .section__cont .section .part__right .date__day {
  padding: 0.2rem 0.4rem;
  border: 1px solid #828282;
  border-radius: 3px;
}
.ateam .section__cont {
  display: flex;
  align-items: flex-end;
}
.ateam .section__cont .search__inp {
  padding: 0.4rem 0.4rem;
  outline: none;
  font-family: "Poppins";
  color: #0b1c39 !important;
  border: none;
  background: white;
  border-radius: 4px;
}

.ateam .teammembers {
  display: flex;
  flex-direction: column;
  row-gap: 0.65rem;
  width: 100%;
}

.ateam .teammembers .teammember {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
  row-gap: 0.7rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: all 0.25s ease;
}
.ateam .teammembers .teammember:hover {
  background: #f7e7e8;
  transition: all 0.25s ease;
}
.ateam .teammembers .teammember .part1img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ateam .teammembers .teammember .part1,
.ateam .teammembers .teammember .part2 {
  display: flex;
  align-items: center;
  justify-content: space-around;

  width: 100%;
  /* background: purple; */
}
.ateam .teammembers .teammember .part1 {
  flex: 0.7;
  column-gap: 0.8rem;
  text-align: left;
}
.ateam .teammembers .teammember .part2 {
  flex: 0.3;
  justify-content: flex-end;
  row-gap: 0.8rem;
  column-gap: 0.8rem;
}
.ateam .teammembers .teammember .teammember__img {
  object-fit: contain;
  max-height: 60px;
}

/* Add team member */
.ateam .teammemberUser {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  column-gap: 5%;
  row-gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-align: left;
  transition: all 0.25s ease;
  background: #f7e7e8;
}
.ateam .teammemberUser .teammemberUser__img {
  object-fit: contain;
  max-height: 60px;
}
.ateam .addinvmemb {
  display: flex;
  flex-direction: column;
  row-gap: 0.7rem;
  width: 100%;
}
.ateam .addinvmemb .addinvmemb__data {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  text-align: left;
  margin: 1rem 0% 1rem 5%;
}
.ateam .addinvmemb .addinvmemb__data .part {
  display: flex;
  column-gap: 1rem;
  row-gap: 0.6rem;
  /* background: purple; */
}
.ateam .addinvmemb .addinvmemb__data .part .inp__fields {
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
  width: 100%;
}

.addinvmemb .addinvmemb__data .part .inp__fields .part__inp,
.addinvmemb .addinvmemb__data .part .inp__fields .part__sel,
.addinvmemb .addinvmemb__data .part .inp__fields .copybtn {
  padding: 0.4rem 0.4rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Poppins";
  color: #0b1c39 !important;
  border: 1px solid lightgrey;
  background: white;
  border-radius: 3.5px;
}
.addinvmemb .addinvmemb__data .part .inp__fields .copybtn {
  color: #ff656a;
  border: 1px solid #ff656a;
}

.addinvmemb .addinvmemb__data .part .radio__inp__main__cont {
  display: flex;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
}
.addinvmemb__data .part .radio__inp__main__cont .radio__inp__main {
  display: flex;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
}
.addinvmemb .addinvmemb__data .part .radio__inp__cont {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .ateam .teammemberUser {
    flex-direction: column;
  }
}

@media screen and (max-width: 950px) {
  .ateam .teammembers .teammember .part1,
  .ateam .teammembers .teammember .part2 {
    flex-direction: column;
  }
  .ateam .teammembers .teammember .part1 {
    align-items: flex-start;
  }
  .addinvmemb .addinvmemb__data .part2 {
    flex-direction: column !important;
    align-items: flex-start;
  }
  .addinvmemb .giveflex1 {
    flex: 1 !important;
  }
}
@media screen and (max-width: 550px) {
  .ateam .teammembers .teammember {
    flex-direction: column;
    padding: 1rem;
  }
  .ateam .teammembers .teammember .part1 {
    align-items: center;
  }
}
