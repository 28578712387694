.ExternalActivityTab {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: start;
    width: 100%;
  }

.ExternalActivityTab .externalActivityArea {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
  
  .ExternalActivityTab .externalActivityArea .oneRequestSent {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 60px;
    
  }
  
  .ExternalActivityTab .externalActivityArea .oneRequestSent > :not(.aboutPool) {
    flex: 0 0 auto; /* Prevent shrinking and growing */
  }
  
  .ExternalActivityTab .externalActivityArea .oneRequestSent .aboutPool {
    flex: 1; /* Allow it to take up remaining space */
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    font-weight: 500;
    font-size: 18px;
    padding: 0 20px;
    height: 60px;
    cursor: pointer;
    max-width: 975px;
  }
  
  .ExternalActivityTab .externalActivityArea .oneRequestSent .requestResponse {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-left: auto;
  }
  