.home_page_comp_five {
    height: 650px;
    background-image: linear-gradient(to bottom, #dfe9f3, #f7fafd);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home_page_comp_five .comp_five_title_sec {
    display: flex;
    flex-direction: column;
    margin-top: 70px;
}

.home_page_comp_five .comp_five_title_sec .comp_five_text1 {
    font-family: "lexend";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    color: #405870;
}

.home_page_comp_five .comp_five_title_sec .comp_five_text2 {
    font-family: "lexend";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    background: linear-gradient(93.21deg, #ffa0a3 0%, #ff656a 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.home_page_comp_five .content_wrapper {
    width: 90%;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.home_page_comp_five .comp_five_content_sec {
    display: flex;
    margin-top: 50px;
    flex: 1;
    border-right: 1px solid #D2D2D2;
}

.home_page_comp_five .comp_five_content_sec:last-child {
    border: none;
}

.home_page_comp_five .comp_five_content_sec .text_sec {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10%;
    padding-right: 10%;
}

.home_page_comp_five .comp_five_content_sec .image_sec {
    width: 50%;
    overflow: hidden;
    height: 480px;
    align-items: flex-end;
    padding-right: 10%;
}

.home_page_comp_five .comp_five_content_sec .text_sec .search_title {
    font-family: "lexend";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #405870;
    margin-bottom: 32px;
}

.home_page_comp_five .comp_five_content_sec .text_sec .search_type {
    display: flex;
    justify-content: center;
    align-items: center;
}

.home_page_comp_five .comp_five_content_sec .text_sec .desc {
    font-family: "lexend";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #405870;
    text-align: left;
    margin-bottom: 40px;
}

.home_page_comp_five .comp_five_content_sec .text_sec .tick {
    transform: rotate(45deg);
    height: 10px;
    width: 5px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
}

.home_page_comp_five .comp_five_content_sec .text_sec .tick_wrapper {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ffa0a3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home_page_comp_five .comp_five_content_sec .text_sec .search_type_text {
    font-family: "lexend";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 33px;
    color: #405870;
    margin-left: 10px;
}

.home_page_comp_five .comp_five_content_sec .text_sec .btnStyle {
    margin-top: 50px;
}

.home_page_comp_five .comp_five_content_sec .image_sec .search-img-1 {
    width: 90%;
    height: 70px;
    margin-bottom: 20px;
}

.home_page_comp_five .comp_five_content_sec .image_sec .search-img-2 {
    width: 90%;
}

.home_page_comp_five .comp_five_content_sec .btnStyleMob {
    display: none;
}

@media screen and (max-width: 768px) {
    .home_page_comp_five {
        padding-bottom: 20px;
        height: unset;
    }

    .home_page_comp_five .content_wrapper{
        flex-direction: column;
        align-items: center;
    }

    .home_page_comp_five .comp_five_title_sec .comp_five_text1,
    .home_page_comp_five .comp_five_title_sec .comp_five_text2 {
        font-size: 24px;
        line-height: 29px;
    }

    .home_page_comp_five .comp_five_content_sec {
        flex-direction: column;
        width: 100%;
        border: none;
    }

    .home_page_comp_five .comp_five_content_sec .text_sec {
        align-items: center;
        width: unset;
        padding-left: unset;
        padding-right: unset;
    }

    .home_page_comp_five .comp_five_content_sec .text_sec .search_title {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 25px;
    }

    .home_page_comp_five .comp_five_content_sec .text_sec .desc {
        text-align: center;
        font-size: 14px;
        line-height: 17px;
        width: 75%;
    }

    .home_page_comp_five .comp_five_content_sec .text_sec .search_type {
        display: none;
    }

    .home_page_comp_five .comp_five_content_sec .text_sec .btnStyle {
        display: none;
    }

    .home_page_comp_five .comp_five_content_sec .image_sec {
        overflow: unset;
        height: unset;
        width: unset;
        padding-right: unset;
    }

    .home_page_comp_five .comp_five_content_sec .image_sec .search-img-1 {
        width: 80%;
        height: 50px;
    }

    .home_page_comp_five .comp_five_content_sec .image_sec .search-img-2 {
        width: 80%;
    }

    .home_page_comp_five .comp_five_content_sec .btnStyleMob {
        display: flex;
        margin-top: 20px;
    }
}