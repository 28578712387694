.bprojectbrief__cont {
  display: flex;
  align-items: flex-start;
  margin: 2rem 5%;
  column-gap: 0.5rem;
}
.backbtncont {
  flex: 0.06;
  display: flex;
}
.bprojectbrief {
  flex: 0.94;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1.5rem;
  box-sizing: border-box;
  text-align: left;
}
.bprojectbrief .protype {
  font-size: 0.7rem;
  border: 1.5px solid #fdbb2c;
  border-radius: 2px;
  padding: 0.1rem 0.5rem;
  margin-bottom : 0;
  text-transform: capitalize;
}
.bprojectbrief .cate__cont {
  display: flex;
  justify-content: space-between;
  /* column-gap: 45%; */
  row-gap: 1rem;
  width: 100%;
}

.bprojectbrief .cate__cont .part__left {
  display: flex;
  align-items: flex-start;
  column-gap: 0.5rem;
  width: 100%;
}

.bprojectbrief .cate__cont .part__right {
  display: flex;
  align-items: center;
  column-gap: 2rem;
  width: 100%;
  justify-content: flex-end;
}
.bprojectbrief .cate__cont .textbasetoggle {
  display: none;
}
.bprojectbrief .cate__cont .activetext {
  display: flex !important;
}
actiontext .bprojectbrief .cate__cont .part__left .catbtn,
.bprojectbrief .cate__cont .part__right .briefbtn {
  /* background: purple; */
  cursor: pointer;
}
.bprojectbrief .cate__cont .part__left .catbtn {
  text-align: center;
  border-radius: 3px;
  padding: 0.3rem 0.2rem;
  width: 120px;
  border: 1.5px solid #ffeaea;
  cursor: pointer;
}
.bprojectbrief .cate__cont .part__left .activecat {
  background: #ffeaea;
}

.bprojectbrief .tabsdatas {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
}
/* Brief datas */
.bprojectbrief .tabsdatas .bbriefcont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1.5rem;
  padding: 1rem;
  box-sizing: border-box;
  border: 1.5px solid lightgray;
  border-radius: 3px;
  width: 100%;
}

.bprojectbrief .tabsdatas .bbriefcont .buddead {
  display: flex;
  column-gap: 10%;
  row-gap: 1.5rem;
  width: 100%;
}
.bprojectbrief .tabsdatas .bbriefcont .brandstate {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

/* Team data */
.bprojectbrief .tabsdatas .bteamcont {
  display: flex;
  column-gap: 0.5rem;
  row-gap: 2rem;
}
.bprojectbrief .tabsdatas .bteamcont .part__left {
  flex: 0.15;
  display: flex;
  flex-direction: column;
  row-gap: 0.4rem;
  column-gap: 0.4rem;
}
.bprojectbrief .tabsdatas .bteamcont .part__left .membdisbtn {
  font-size: 0.9rem;
  text-align: center;
  background: #f6f6f6;
  padding: 0.6rem 0.2rem;
  cursor: pointer;
}
.bprojectbrief .tabsdatas .bteamcont .part__left .activemintog {
  background: #ffeaea;
}
.bprojectbrief .tabsdatas .bteamcont .part__right {
  flex: 0.85;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.bprojectbrief .tabsdatas .bteamcont .part__right .memb__card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 3px;
  padding: 0.3rem 2.5rem 0.3rem 0.5rem;
  box-sizing: border-box;
  /* background: pink; */
  transition: all 0.3s ease;
}
.bprojectbrief .tabsdatas .bteamcont .part__right .memb__card:hover {
  background: #ffeaea;
  transition: all 0.3s ease;
}
.tabsdatas .bteamcont .part__right .memb__card .datapart1 {
  flex: 0.5;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  justify-content: space-between;
  width: 100%;
  /* background: plum; */
}
.tabsdatas .bteamcont .part__right .memb__card .datapart1 .namepos {
  display: flex;
  width: 100%;
  justify-content: space-around;
  column-gap: 0.5rem;
  row-gap: 0.3rem;
}
.tabsdatas .bteamcont .part__right .memb__card .membimg {
  height: 55px;
  width: 55px;
  border-radius: 5rem;
}
/* .tabsdatas .bteamcont .part__right .memb__card .datapart2 {
  flex: 0.5;
} */

/* Agencies data */
/* .bprojectbrief .tabsdatas .bagencycont {
  background: purple;
} */
.bprojectbrief .tabsdatas .bagencycont .fil__res__cont {
  margin: 0rem;
  width: 100%;
  row-gap: 1rem;
}
.tabsdatas .bagencycont .fil__res__cont .search__res__sec {
  padding: 0;
}

.tabsdatas .bagencycont .fil__res__cont .search__res__sec .agenciescont__card {
  display: flex;
  justify-content: space-between;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  width: 100%;
}
.bagencycont .fil__res__cont .search__res__sec .agenciescont__card .part__left {
  display: flex;
  justify-content: space-between;
  column-gap: 0.5rem;
  padding: 0.85rem;
  box-sizing: border-box;
  border: 2px solid #ffeaea;
  border-radius: 3px;
  width: 100%;
}
.fil__res__cont
  .search__res__sec
  .agenciescont__card
  .part__left
  .imganddetails {
  display: flex;
  column-gap: 0.75rem;
}
.search__res__sec .agenciescont__card .part__left .imganddetails .imglogo {
  height: 63px;
  width: 63px;
}
.search__res__sec .agenciescont__card .part__left .imganddetails .data {
  display: flex;
  flex-direction: column;
  row-gap: 0.6rem;
}

.agenciescont__card .part__left .imganddetails .data .name__heart {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}
.agenciescont__card .part__left .imganddetails .data .name__heart .heart {
  object-fit: contain;
  height: 15px;
  cursor: pointer;
}
.agenciescont__card .part__left .imganddetails .data .tags {
  display: flex;
  column-gap: 0.5rem;
  row-gap: 0.3rem;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  float: left;
  width: 100%;
}
.agenciescont__card .part__left .imganddetails .data .starrev {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}
.agenciescont__card .part__left .imganddetails .data .starrev .stars {
  object-fit: contain;
  max-height: 20px;
}
.agenciescont__card .part__left .imganddetails .data .starrev .reviewno {
  font-size: 0.75rem;
  border: 1.7px solid #ffacac;
  border-radius: 5px;
  background: #ffeaea;
  padding: 0 0.4rem;
  box-sizing: border-box;
  cursor: pointer;
}
.agenciescont__card .part__left .imganddetails .data .tags .tag {
  font-size: 0.75rem;
  padding: 0.2rem 0.35rem;
  border-radius: 3px;
  color: #525252;
  background: #f5f5f5;
  cursor: pointer;
}

.search__res__sec .agenciescont__card .part__left .rfpdiscuss__left {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.agenciescont__card .part__left .rfpdiscuss__left .rfpleft {
  border: 1px solid #ffeaea;
  border-radius: 2px;
  padding: 0.5rem 2.3rem;
  box-sizing: border-box;
  cursor: pointer;
}
.agenciescont__card .part__left .rfpdiscuss__left .discussleft {
  color: #ff656a;
  border: 1px solid #ffeaea;
  /* background: #ceeff3; */
  border-radius: 2px;
  padding: 0.5rem 2.3rem;
  box-sizing: border-box;
  cursor: pointer;
}
.agenciescont__card .part__right {
  display: flex;
  justify-content: space-between;
  padding: 0.85rem;
  box-sizing: border-box;
  border: 2px solid #ffeaea;
  border-radius: 3px;
  /* width: 100%; */
}
.agenciescont__card .part__right .rfpdiscuss__right {
  display: none;
  align-items: center;
  column-gap: 0.5rem;
}
.agenciescont__card .part__right .rfpdiscuss__right .rfpright {
  border: 1px solid #ffeaea;
  border-radius: 2px;
  padding: 0.5rem 0;
  width: 90px;
  box-sizing: border-box;
  cursor: pointer;
}
.agenciescont__card .part__right .rfpdiscuss__right .discussright {
  color: #ff656a;
  border: 1px solid #ffeaea;
  /* background: #ceeff3; */
  border-radius: 2px;
  padding: 0.5rem 0;
  width: 90px;
  box-sizing: border-box;
  cursor: pointer;
}
.agenciescont__card .rfp {
  background: #ceeff3;
}
.agenciescont__card .raincheck {
  background: #fed680;
}
.agenciescont__card .viewproposal {
  background: #a0f08c;
}
.agenciescont__card .part__right .peoplecheck {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.agenciescont__card .part__right .peoplecheck .picon {
  object-fit: contain;
  height: 14px;
  cursor: pointer;
}
.agenciescont__card .part__right .peoplecheck .cicon {
  object-fit: contain;
  height: 18px;
  cursor: pointer;
}

/* Invite data */
.bprojectbrief .tabsdatas .binvitecont {
  display: flex;
  flex-direction: column;
  column-gap: 0.5rem;
  row-gap: 1.5rem;
}
.bprojectbrief .tabsdatas .binvitecont .part {
  display: flex;
  column-gap: 2rem;
  row-gap: 0.5rem;
  width: 100%;
}
.bprojectbrief .tabsdatas .binvitecont .inp__fieldt {
  display: flex;
  flex-direction: column;
  row-gap: 0.4rem;
}
.bprojectbrief .tabsdatas .binvitecont .inp__fieldt .inputt {
  border: 1.5px solid #f5d7d8;
  border-radius: 2px;
  outline: none;
  font-family: "Poppins";
  padding: 0.4rem 0.3rem;
  box-sizing: border-box;
  color: grey;
  background: #fafafa;
  font-size: 0.85rem;
  min-width: 275px;
}

/* Tabs properties (should be in bottom) */
.bprojectbrief .tabsdatas .battabsingle {
  display: none;
}
.bprojectbrief .tabsdatas .activetab {
  display: flex;
}
@media screen and (max-width: 768px) {
  .backbtncont {
    display: none;
  }
  .bprojectbrief {
    flex: 1;
  }
  .bprojectbrief .title__type__cont {
    flex-direction: column-reverse;
    align-items: flex-start;
    row-gap: 0.5rem;
  }
  .bprojectbrief .cate__cont {
    flex-direction: column-reverse;
  }
  .bprojectbrief .cate__cont .part__left,
  .bprojectbrief .cate__cont .part__right {
    width: 100%;
    justify-content: space-between;
  }
  .bprojectbrief .cate__cont .part__right .actiontext {
    width: 100%;
    justify-content: space-between;
  }
  .bprojectbrief .cate__cont .part__left .catbtn {
    width: 100%;
  }
  .bprojectbrief .tabsdatas .bbriefcont {
    padding: 0;
    border: none;
  }
  .bprojectbrief .tabsdatas .buddead {
    flex-direction: column;
  }
  /* Agencies data */
  .bagencycont .fil__res__cont .search__res__sec .search__res {
    row-gap: 1.5rem;
  }
  .bagencycont .fil__res__cont .search__res__sec .agenciescont__card {
    flex-direction: column;
  }
  .agenciescont__card .part__left .rfpdiscuss__left {
    display: none !important;
  }
  .agenciescont__card .part__right {
    align-items: center;
    border: none;
    padding: 0;
  }
  .agenciescont__card .part__right .rfpdiscuss__right {
    display: flex;
  }
  .agenciescont__card .part__right .peoplecheck {
    flex-direction: row;
    justify-content: space-between;
    column-gap: 1rem;
  }

  /* Team data */
  .bprojectbrief .tabsdatas .bteamcont {
    flex-direction: column;
  }
  .bprojectbrief .tabsdatas .bteamcont .part__left {
    flex-direction: row;
    justify-content: center;
    font-size: 0.9rem;
  }
  .bprojectbrief .tabsdatas .bteamcont .part__left .membdisbtn {
    border-radius: 4rem;
    padding: 0.5rem 1rem;
  }
  .tabsdatas .bteamcont .part__right .memb__card {
    background: #f6f6f6;
  }
  .tabsdatas .bteamcont .part__right .memb__card .datapart1 {
    flex: 1;
  }
  .tabsdatas .bteamcont .part__right .memb__card .datapart2 {
    display: none;
  }
  .tabsdatas .bteamcont .part__right .memb__card .membimg {
    height: 75px;
    width: 75px;
  }

  .tabsdatas .bteamcont .part__right .memb__card .datapart1 .namepos {
    flex-direction: column;
  }
  .bteamcont .part__right .memb__card .datapart1 .namepos .membname {
    font-weight: 600;
  }
  .bteamcont .part__right .memb__card .datapart1 .namepos .membpos {
    color: grey;
  }
  /* invite data */
  .bprojectbrief .tabsdatas .binvitecont .part {
    flex-direction: column;
  }
}
