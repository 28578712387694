/* Case Study Card */
.case_study__card {
  border: 1px solid #dfe9f3;
  border-radius: 10px;
  padding: 14px 18px 20px;
}

.case_study__card .top_part {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.case_study__card .top_part .details {
  background: #e8eff7;
  border-radius: 44px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 3px 8px 3px 3px;
  gap: 2px;
}

.case_study__card .top_part .details .logo {
  width: 26px;
  height: 26px;
  border-radius: 100px;
}

.case_study__card .top_part .verified_icon {
  width: 20px;
  height: 20px;
}

.case_study__card .top_part .details .name {
  margin: 0;
  font-family: "lexend";
  font-weight: 500;
  color: #0b1c39;
  font-size: 10px;
  text-align: start;
}

.case_study__card .main .display_image {
  width: 100%;
  border-radius: 10px;
}

.case_study__card .main {
  position: relative;
  margin-bottom: 18px;
}

.case_study__card .sound_icon {
  position: absolute;
  height: 35px;
  width: 35px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  top: 5px;
  right: 5px;
}

.case_study__card .sound_icon img {
  width: 100%;
  padding: 5px;
}

.case_study__card button {
  border: none;
  background: none;
  color: #ff656a;
  font-family: "lexend";
  font-weight: 700;
  font-size: 10px;
  display: flex;
  padding-left: 1px;
}

.case_study__card .description {
  font-family: "lexend";
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: start;
  padding-top: 14px;
}

.case_study__card .hashtags {
  display: flex;
}

.case_study__card .hashtag {
  background: #e8eff7;
  border-radius: 44px;
  display: inline-flex;
  align-items: center;
  padding: 3px 8px 3px 2px;
  gap: 4px;
}

.case_study__card .hashtag p {
  font-family: "lexend";
  font-size: 10px;
  color: #0b1c39;
  margin: 0;
}

.case_study__card .hashtag .hashtag_icon {
  height: 16px;
  width: 16px;
}
