/* landingPage.css */

/* Style the container for the entire landing page */
.landingPage {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  background-color: #FFFFFF;
  width: 1240px;
  border-left: 1px solid #E7E3E9;
  border-right: 1px solid #E7E3E9;
  margin: 0 auto;
  color: #191E22;
}
.contactUsForm{
    display: flex;
}

/* Style the left section (Text Section) */
.landingHero {
    color:#191E22;
    display: flex;
    width: 620px;
    height: 550px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
}

.contactUsForm >.landingHero >h2, .cUsForm > h2{
    font-family: Lexend;
    text-align: left;
    font-size: 45px;
    font-style: normal;
    font-weight: 300;
    line-height: 70px; /* 155.556% */
}
.contactUsForm >.landingHero >p, .cUsForm > p{
    font-family: Lexend;
    text-align: left;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px; /* 150% */
}
/* Add these styles to your CSS file */
.contact-form {
    width: 100%;
    max-width: 540px; /* Adjust the width as needed */
    margin: 0 auto; /* Center the form horizontally */
  }
  
  .form-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .form-group {
    flex-basis: 245px; /* 50% width with some spacing between fields */
  }
  
  /* Style labels */
  .form-group label {
    width: 245px;
    height: 50px;
    display: block;
    text-align: left;
    margin-bottom: 5px;
  }
  
  /* Style text inputs */
  .form-group input {
    width: 100%;
    color: #F8F8F8;
    background-color: #191E22;
    border: 0; 
    border-bottom: 1px solid #F8F8F8;
    padding: 10px;
    font-size: 14px;
    outline: none; /* Remove input outline on focus */
  }
  .contactUsForm button[type="submit"] {
    width: 92px;
  height: 40px;
  background-color: #191E22;
  border: 1px solid #f8f8f8;
  color: #f8f8f8;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  outline: none;
  margin-top: 40px; /* Add margin at the top to push the button to the bottom */
  margin-left: auto; /* Automatically align to the right */
  display: block;
  }
  .contactUsForm button[type="submit"]:hover {
    background-color: #F8F8F8; /* Change the background color on hover */
    color: #191E22;
  }
  
  /* Add additional styles for labels and inputs if needed */
  
/* Style the right section (Form Section) */
.cUsForm {
  width: 620px;  
  padding: 20px;
  color: #F8F8F8;
  background-color: #191E22;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
}

/* Style Div 2 */
.brandLeveraging {
  background-color: #fff;
  border: 1px solid #E7E3E9;
  border-right: none;
  border-left: none;
  margin: 0 !important;
}

.headingSection > h2{
    height: 100px;
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    font-size: 25px;
    font-weight: 400;
    line-height: 60px;
    padding-left: 20px;
    border-bottom: 1px solid #E7E3E9;
}
.brandLeveraging > .brandLogosContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 120px;
  padding: 70px 0 90px 0;
  border-bottom: 1px solid #E7E3E9;
}
  
.brandLeveraging > .brandLogosContainer > .brandLogo {
    max-width: 130px; /* Adjust the logo width as needed */
    height: 80px;
}
.brandLeveraging > .brandLogosContainer > .brandLogo:last-of-type,
.brandLeveraging > .brandLogosContainer > .brandLogo:nth-last-of-type(2){
    height: 40px;
    margin-top: 20px;
}  


/* Style Div 3 */
.aSeachStartups {
  padding: 20px;
  background-color: #FFFFFF;
  border-bottom: 1px solid #E7E3E9;
  margin-bottom: 20px;
}
/* Update your existing CSS file */
/* Update your existing CSS file */
.aSeachStartups {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  
  .startup-item {
    background-color: #f7f7f7;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .startup-item img {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  
  .startup-item h3 {
    margin: 0;
  }
  
  .startup-item a {
    margin-top: 10px;
    background-color: #191E22;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .startup-item a:hover {
    background-color: #000;
  }
    

/* Style Div 4 */
/* Add these styles to your CSS file */
.testimonials {
    width: 900px;
    padding: 50px;
    background-color: #fff; /* Optional background color */
    margin: 0 auto;
  }
  
  /* Style the individual testimonials */
  .testimonial {
    display: flex;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.10);
    border: 1px solid #e7e3e9;
    border-radius: 10px;
    flex-direction: column; /* Change to a column layout */
    align-items: flex-start;
    margin-bottom: 20px;
    width: 900px;
    height: 420px;
    flex-shrink: 0;
    font-size: 20px;
    text-align: justify;
  }
  
  .testimonial img {
    max-width: 60px; /* Adjust the image size as needed */
    margin-right: 20px;
  }
  
  /* Style the stars */
  .testimonial .rating {
    margin-top: 10px; /* Add margin to separate stars and text */
    text-align: right; /* Align stars to the right */
  }
  
  /* Style the stars with a pink color */
  .testimonial .rating .star {
    color: #f76c6c;
  }
  
  
/* Style Div 5 */
/* Add these styles to your CSS file */
.fAQs {
  width: 1240px;
  padding: 20px;
  border-top: 1px solid #e7e3e9;
  background-color: #fff;
}

.headingSection {
  text-align: center;
  border-bottom: 1px solid #E7E3E9;
}

.faq-list {
  width: 1000px;
  margin: 0 auto;
  margin-top: 30px;
}

.faq-item {
  margin-bottom: 20px;
  border-bottom: 1px solid #e7e3e9;
  padding: 10px;
  transition: background-color 0.4s ease-in-out;
}

.faq-item button {
  background-color: #FFFFFF;
  border: none;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Aligns items to the start and end of the button */
  align-items: center; /* Vertically center items */
}
.question-text {
  flex: 1; /* Allow question text to take up available space */
}

.landingSvg {
  /* Align the landingSvg to the right */
}

.rotate-svg {
  transition: transform 0.4s ease-in-out;
}

.rotate {
  transform: rotate(180deg);
}

.answer {
  display: none;
  margin-top: 10px;
  text-align: justify;
  transition: background-color 0.4s ease-in-out;
}

.faq-item.open .answer {
  display: block;
  background-color: #F8F8F8;
  padding: 10px;
  border-radius: 5px;
  opacity: 1; /* Make the answer visible when it's open */
}


/* Style Div 6 */
.cta {
  flex-basis: calc(50% - 20px);
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
}
