.firstcomponent{
    background-image: url("../img/Vector.png");
    background-repeat: no-repeat;
    width: 100vw;
    background-size: cover;
    height: 250px;
}
.firstcomponent .brandImage{
    margin-left: 10vw;
    padding-top: 50px;
    height: 400px;
}
.firstcomponent img{
    float: left;
    height: 40%;
}
.firstcomponent hr{
    float: left;
    transform: rotate(90deg);
    width: 130px;
    height: 0px;
    margin-top: 70px;
    background-color:  rgb(255, 255, 255);
    border: 1px solid white;
}

@media screen and (max-width: 550px){
    .firstcomponent img{
        float: left;
        height: 30%;
    }
    .firstcomponent{
        height: 200px;
    }
}

@media screen and (max-width: 400px){
    .firstcomponent img{
        height: 23%;
    }
}