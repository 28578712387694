.sp__top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 0.9rem;
  align-items: center;
  background: #0b1c38;
  padding: 6rem 5%;
}

@media only screen and (max-width: 768px) {
  .fil__res__cont .filter__sec {
    margin-top: 0;
  }
}

.linking__btn {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 30px;
}

.linking__btn .btn__1 {
  position: sticky;
  top: 70px;
  height: 100%;
  border: none;
  color: #fd666e;
  background: #ff656a0d;

  width: 12rem;
  cursor: pointer;
  font-family: "Poppins";
  height: 50px;
  justify-content: center;
  text-align: center;
  justify-content: space-between;
  font-size: 0.8rem;

  font-weight: 600;
  margin-right: 1rem;
  transition: 1s;
}

.fil__res__cont .filter__sec .color__white {
  color: rgba(255, 255, 255, 0.918) !important;
}

.search__box__cont {
  padding: 0 5%;
  margin-top: -1.5rem;
}

.transparent{
  /* opacity: 0.14; */
}

.register_text{
  display: flex;
  position: relative;
  top: -295px;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}

.link-text{
  text-decoration: underline;
  color: dodgerblue;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.search__box {
  /* height: 3rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: white; */
  /* overflow: hidden; */
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1); */
  border-radius: 1px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  position: absolute;
  margin-top: -25px;
  left: 0;
  right: 0;
}

.search__box img {
  object-fit: contain;
  height: 16px;
  padding: 10px 13px;
  background: white;
}

 .search__box input {
  height: 100%;
  font-size: 0.9rem;
  border: none;
  outline: none;
  flex: 1;
}

.search__box button {
  height: 3rem;
  border: none;
  color: white;
  background: #ff656a;
  width: 6rem;
  font-family: "Poppins";
  cursor: pointer;
  right: 0;
  position: absolute;
  margin-top: 47px;
}

.fil__res__cont {
  display: flex;
  align-items: flex-start;
  margin: 5rem 9rem;
  column-gap: 1rem;
}

/* Filtersec */
.search__box .search-dropdown {
  width: 43%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 10%);
  margin-right: 10px;
}

.search__box .search-dropdown .search-input {
  min-height: 48px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  padding-left: 20px;
  text-transform: capitalize;
}

.search__box .dropdown-options-container {
  background-color: #fff;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 270px;
  height: fit-content !important;
  margin-top:5px;
  border: 1px solid black;
  padding: 0 20px;
  width: 100%;
  font-size: 0.9rem;
  text-transform: capitalize;
  overflow: scroll;
}

.search__box .dropdown-options-container .dropdown-option{
  margin: 5px 0;
  width: 100%;
  text-align: left;
}

/* .fil__res__cont .filter__sec {
  flex: 0.25;
  background: #fafafa;
  padding: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  text-align: initial;
  width: 250px;
}

.fil__res__cont .filter__sec .part1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1.5px solid rgb(209, 209, 209);
  padding-bottom: 5px;
}
.fil__res__cont .filter__sec .part1 .arrowdown {
  object-fit: contain;
  height: 7px;
  margin-left: 0.3rem;
  cursor: pointer;
  display: none;

  transition: all 0.3s ease;
}

@media screen and (max-width: 768px) {
  .fil__res__cont .filter__sec .part1 .arrowdown {
    display: inline;
  }
}
.fil__res__cont .filter__sec .part2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.fil__res__cont .filter__sec .part2 .react__select {
  width: 150px;
  margin-left: 0.5rem;
}
.fil__res__cont .filter__sec .part2 p {
  font-size: 0.9rem;
}
.fil__res__cont .filter__sec .part2 .arrowdown {
  object-fit: contain;
  height: 7px;
  cursor: pointer;
}
.fil__res__cont .filter__sec .resetfilters {
  color: rgb(138, 138, 138);
  font-weight: 400;
  border: none;
  font-family: "Poppins";
  background: transparent;
  cursor: pointer;
} */

/* additional filter sec */
.fil__res__cont .filter__sec,
.fil__res__cont .res__sec {
  width: 100%;
}

.fil__res__cont .filter__sec {
  flex: 0.25;
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  padding: 0.8rem;
  margin-top: 4rem;
  border-radius: 1px;
  position: sticky;
  top: 100px;
  background: #fafafa;
  box-sizing: border-box;
  text-align: left;
  margin-bottom: 15px;
}

.fil__res__cont .filter__sec .filter__parttab {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

@media screen and (max-width: 950px) {
  .fil__res__cont .filter__sec .filter__parttab {
    display: none;
  }
}

.fil__res__cont .filter__sec .filter__top {
  /* display: none; */
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.fil__res__cont .filter__parttab .part__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fil__res__cont .filter__sec .filter__top .arrow__down {
  display: none;
}

@media screen and (max-width: 950px) {
  .fil__res__cont .filter__sec .filter__top .arrow__down {
    display: initial;
  }
}

@media screen and (max-width: 768px) {
  .fil__res__cont .filter__parttab .part__top .arrow__down {
    object-fit: contain;
    max-height: 8px;
  }

  .fil__res__cont .filter__sec {
    top: 70px;
    margin-top: 0rem;
    justify-content: center;
    width: 65%;
  }

  .fil__res__cont .filter__sec .filter__top .text__left {
    margin-bottom: 0rem;
  }

  .fil__res__cont .filter__sec .filter__top .resetfilters {
    margin-top: 0px;
  }

  .fil__res__cont .linking__btn {
    width: 100%;
    z-index: 2;
    align-items: flex-start;
    background-color: white;
    top: 72px;
  }
}

.fil__res__cont .filter__parttab .part__bottom {
  display: flex;
  flex-direction: column;
  row-gap: 0.4rem;
}

.fil__res__cont .filter__parttab .part__bottom .inp__field {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.fil__res__cont .arrow__down {
  cursor: pointer;
  transition: all 0.3s ease;
}

.fil__res__cont .filter__sec .resetfilters {
  color: rgb(138, 138, 138);
  font-weight: 400;
  border: none;
  font-family: "Poppins";
  background: transparent;
  cursor: pointer;
  margin-top: -10px;
}

@media screen and (max-width: 768px) {

  /* 
  .searchpage .filter__res__cont {
    flex-direction: column;
  } */
  .searchpage .filter__res__cont .filter__sec,
  .searchpage .filter__res__cont .res__sec {
    width: 100%;
  }

  .searchpage .filter__res__cont .filter__sec .filter__parttab {
    display: none;
  }

  .searchpage .filter__res__cont .filter__sec .filter__parttab .part__top,
  .searchpage .filter__res__cont .filter__sec .filter__parttab .part__bottom {
    display: none;
  }

  .searchpage .filter__res__cont .filter__sec .filter__top {
    display: flex;
  }

  .searchpage .filter__res__cont .filter__sec .rotate180 {
    transform: rotate(180deg);
    transition: all 0.3s ease;
  }

  .res__sec .res__card .res__card__right .opens {
    padding: 0.4rem 1rem;
  }

  .brescards .res__card .res__card__right .opens {
    padding: 0.4rem 1rem;
  }
}

/* @media screen and (min-width: 950px) {
  .searchpage .filter__res__cont .filter__sec {
    position: sticky;
    top: 20px;
    left: 0;
  }
} */
/* additional filter sec ends */

.search__res__sec {
  display: flex;
  flex-direction: column;
  row-gap: 0.7rem;
  padding: 1rem 0;
  flex: 0.8;
  width: 100%;
}

.search__res__sec .tot__res {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.search__res__sec .tot__res .react__select {
  width: 165px;
}

.search__res__sec .tot__res .sortbytext {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
}

.col__grey {
  color: rgba(128, 128, 128, 0.897) !important;
}

.search__res__sec .search__res {
  display: flex;
  flex-direction: column;
  row-gap: 0.7rem;
}

.pagination_sec {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 15px;
  list-style-type: none;
}

.pagination_sec_li {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  background-color: #FFF;
  border: 1px solid #E7E3E9;
  border-radius: 0;
  color: #6e6e6e;
  font-size: 12px;
}

.pagination_sec_break {
  margin-right: 15px;
  color: #6e6e6e;
}

.pagination_sec_active {
  color: #FFF;
  background-color: #191C12;
}

.brescards {
  display: flex;
  flex-direction: column;
  row-gap: 0.7rem;
  width: 100%;
}

.brescards .res__card {
  display: flex;
  border: 2px solid rgba(221, 221, 221, 0.726);
  padding: 0.8rem;
  width: 100%;
}

.search__res .res__card {
  display: flex;
  border: 2px solid rgba(221, 221, 221, 0.726);
  padding: 1rem;
  width: 100%;
}

.res__card .sec__left {
  flex: 0.8;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 0.6rem;
}

.res__card .sec__left .details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* row-gap: 0.2rem; */
}

.res__card .sec__left .details .heartempty {
  object-fit: contain;
  height: 1rem;
  margin-left: 0.4rem;
  cursor: pointer;
}

.res__card .sec__left .details .heartcolor {
  object-fit: contain;
  height: 1.2rem;
  margin-left: 0.4rem;
  cursor: pointer;
}

.res__card .sec__left .details .stars {
  display: flex;
  column-gap: 0.2rem;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.8rem;
}

.res__card .sec__left .details .stars .num__reviews {
  font-size: 0.75rem;

  padding: 0.2rem 0.35rem;
  margin-left: 1rem;
  border-radius: 3px;
  font-weight: 600;
  color: #ff8686d6;
  border: 1.5px solid #ffc7c7d6;
  background: #fff9f9;
  cursor: pointer;
}

.res__card .sec__left .details .tags {
  display: flex;
  column-gap: 0.5rem;
  row-gap: 0.3rem;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  float: left;
  margin-bottom: 1.4rem;
  width: 100%;
}

.res__card .sec__left .details .tags .tag {
  font-size: 0.75rem;
  padding: 0.2rem 0.35rem;
  border-radius: 3px;
  background: rgba(211, 211, 211, 0.582);
  cursor: pointer;
}

.res__card .sec__left .details .misc__details {
  display: flex;
  column-gap: 2rem;
  row-gap: 0.3rem;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  float: left;
  margin-bottom: 0.5rem;
}

.sec__left .details .misc__details .part {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.3rem;
}

.res__card .sec__right {
  flex: 0.2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  row-gap: 0.5rem;
  margin: 0;
  padding: 0;
}

.res__card .sec__right button {
  font-size: 0.7rem;
  border-radius: 1px;
  padding: 12px 23px;
  border: 1px solid #ff656a;
}

.res__card .sec__right :nth-child(1) {
  color: white;
  background: #ff656a;
  cursor: pointer;
}

.res__card .sec__right :nth-child(2) {
  color: white;
  background: #ff656a;
  cursor: pointer;
}

/* Service Page */
.searchpage .service__page {
  display: flex;
  flex-direction: column;
  margin: 2rem 9rem;
  column-gap: 1rem;
  row-gap: 1rem;
  text-align: left;
}

.searchpage .service__page .digmarkimg {
  object-fit: contain;
  max-height: 600px;
  width: 100%;
  /* height: auto;
  width: 100%; */
}

.searchpage .service__page .text__ad__cont {
  display: flex;
  align-items: flex-start;
  column-gap: 2rem;
  row-gap: 2rem;
}

.searchpage .service__page .text__ad__cont .part__left {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
  position: relative;
  /* flex: 0.7; */
}

.service__page .text__ad__cont .part__left .readmore__btn {
  margin-top: -10px;
  padding: 0.5rem 0.6rem;
  border: 1.5px solid grey;
  cursor: pointer;
}

.service__page .text__ad__cont .part__left .banner-fadebottom {
  position: absolute;
  bottom: 50px;
  height: 8rem;
  width: 100%;
  background-image: linear-gradient(180deg, transparent, rgba(255, 255, 255, 0.733), rgb(255, 255, 255));
}

.searchpage .service__page .text__ad__cont .part__right {
  /* flex: 0.3; */
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  border: 2px solid grey;
}

.service__page .text__ad__cont .part__right .mobadimg {
  object-fit: contain;
  height: auto;
  /* max-height: 500px; */
  min-width: 300px;
  width: 100%;
}

.service__page .text__ad__cont .part__right .download__btn {
  background: #e5e5e5;
  padding: 0.7rem 0.5rem;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .searchpage .service__page .text__ad__cont {
    flex-direction: column-reverse;
    align-items: center;
  }

  .searchpage .service__page .text__ad__cont .part__left {
    flex: 1;
  }

  .searchpage .service__page .text__ad__cont .part__right {
    flex: 1;
  }

  .service__page .text__ad__cont .part__right .mobadimg {
    min-width: 0px;
    /* max-width: 200px; */
  }
}

@media screen and (max-width: 1024px) {

  .fil__res__cont,
  .searchpage .service__page {
    margin: 2rem 5%;
  }
}

@media screen and (max-width: 768px) {
  .fil__res__cont {
    flex-direction: column;
  }

  /* .fil__res__cont .filter__sec {
    width: 100%;
    box-sizing: border-box;
    max-height: 350px;
    padding: 20px;
    margin-bottom: 1rem;
    max-height: 450px;
  }
  .fil__res__cont .filter__sec .part1 {
    border: none;
    padding: 0;
  }
  .fil__res__cont .filter__sec .part1 .rotate180 {
    transform: rotate(180deg);
    transition: all 0.3s ease;
  }
  .fil__res__cont .filter__sec .part2 {
    display: none;
  }
  .fil__res__cont .showFilterPart .part2 {
    display: flex !important;
  } */
}

@media screen and (max-width: 550px) {
  .res__card {
    flex-direction: column;
    row-gap: 1rem;
  }

  .res__card .sec__right {
    flex-direction: row;
    column-gap: 0.5rem;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .searchpage .search__box{
    flex-direction: column;
    align-items: flex-start;
    margin-left: 5px;
  }

  .searchpage .search__box .search-dropdown{
    width: 48%;
  }

  .searchpage .search__box .search-dropdown .search-input{
    padding-left: 5px;
  }

  .searchpage .search__box .search-dropdown .dropdown-options-container{
    z-index: 3;
  }

  .searchpage .search__box .search-dropdown:nth-child(2){
    right: 0 !important
  }

  .searchpage .search__box button{
    right: 40%;
    margin-top: 55px;
  }

  .searchpage .fil__res__cont{
    margin : 6rem 5%;
  }
}