.WhyChooseUnispadeMentor {
  padding: 80px;
  display: flex;
  flex-direction: column;
  gap: 80px;
  background-color: white;
  align-items: center;
  box-sizing: border-box;
}

.WhyChooseUnispadeMentor .ReasonsList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 150px;
  row-gap: 40px;
}

.WhyChooseUnispadeMentor .ReasonsList .SingleReasoningContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media screen and (max-width: 480px) {
  .WhyChooseUnispadeMentor {
    padding: 30px 0 30px 30px;
    align-items: start;
    gap: 40px;
  }

  .WhyChooseUnispadeMentor .ReasonsList {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
  }

  .WhyChooseUnispadeMentor .ReasonsList .SingleReasoningContainer {
    gap: 10px;
  }
}
