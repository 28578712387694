.searchagencies__cont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 3.2rem 0;
  background: #0b1c39;
  box-sizing: border-box;
}
.searchagencies {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  width: 100%;
  color: white;
  text-align: initial;
  padding: 0 3%;
}
.searchagencies p {
  font-size: 1.3rem;
}
.reg__btn {
  border: none;
  color: white;
  padding: 15px 25px;
  margin: 5px 5px;
  font-family: "Poppins";
  background: #ff656a;
  cursor: pointer;
}
.make__bold {
  font-weight: 600;
}

.searchagencies button {
  width: 190px;
  border: none;
  color: white;
  padding: 15px 25px;
  margin: 5px 5px;
  background: #ff656a;
  font-family: "Poppins";
  cursor: pointer;
}

@media screen and (max-width: 850px) {
  .searchagencies__cont {
    padding: 2rem 5%;
  }
  .searchagencies {
    flex-direction: column;
  }
  .searchagencies p {
    text-align: center;
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
}
