.FaqsContainer {
  padding: 80px;
  display: flex;
  flex-direction: column;
  gap: 80px;
  background-color: white;
  box-sizing: border-box;
}

.FaqsContainer .FaqsList {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.FaqsContainer .FaqsList .FaqSingleSet {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.FaqsContainer .FaqsList .FaqSingleSet .FaqQuestion {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .FaqsContainer {
    padding: 30px;
    gap: 40px;
    align-items: start;
  }
}
