/* Container */
.session-template-selector-container {
  font-family: "Poppins", sans-serif;
  background-color: rgb(240, 239, 239);
  border-radius: 20px;
  padding: 25px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
}

/* Header */
.session-template-selector-container .session-template-selector-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.session-template-selector-container .session-template-selector-header h2 {
  font-size: 22px;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.session-template-selector-container .session-template-close-button {
  background: none;
  border: none;
  font-size: 24px;
  color: #666;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.session-template-selector-container .session-template-close-button:hover {
  background-color: #eee;
}

/* Filter Area */
.session-template-selector-container .session-template-filter-area {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.session-template-selector-container .session-template-filter-input {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 12px 15px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  width: 100%;
  max-width: 400px;
}

.session-template-selector-container .session-template-filter-input:focus {
  outline: none;
  border-color: #aaa;
}

/* Filter Section */
.session-template-selector-container .filter-section {
  font-family: "Poppins", sans-serif;
  padding: 16px 0;
  width: 100%;
}

.session-template-selector-container .filter-section .filter-tab {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.session-template-selector-container .filter-section .filter-tab-header {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #333;
  font-weight: 500;
}

.session-template-selector-container .filter-section .filter-tab-header svg {
  font-size: 20px;
}

.session-template-selector-container .filter-section .filter-tab-header p {
  margin: 0;
  font-size: 16px;
}

.session-template-selector-container .filter-section .filter-options-container {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.session-template-selector-container .filter-section .filter-dropdown {
  position: relative;
  width: 220px;
}

.session-template-selector-container
  .filter-section
  .filter-dropdown
  .filter-input-container {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 30px;
  background-color: #f8f8f8;
  font-size: 14px;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.session-template-selector-container
  .filter-section
  .filter-dropdown
  .filter-input-container:focus-within {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.05);
}

.session-template-selector-container .filter-section .filter-search-input {
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 14px;
}

.session-template-selector-container
  .filter-section
  .filter-search-input::placeholder {
  color: #999;
}

.session-template-selector-container .filter-section .filter-options-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 8px;
  z-index: 10;
}

.session-template-selector-container .filter-section .filter-option-item {
  padding: 10px 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.session-template-selector-container .filter-section .filter-option-item:hover {
  background-color: #f5f5f5;
}

.session-template-selector-container
  .filter-section
  .filter-selected-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 16px;
}

.session-template-selector-container .filter-section .filter-selected-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  background-color: white;
  color: #333;
  transition: transform 0.2s;
}

.session-template-selector-container
  .filter-section
  .filter-selected-item:hover {
  transform: translateY(-2px);
}

.session-template-selector-container .filter-section .filter-remove-icon {
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.2s;
}

.session-template-selector-container .filter-section .filter-remove-icon:hover {
  transform: rotate(90deg);
}

.session-template-selector-container .filter-section button {
  background-color: #000;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: transform 0.2s, background-color 0.3s;
}

.session-template-selector-container .filter-section button:hover {
  transform: scale(1.05);
  background-color: #333;
}

/* Template Grid */
.session-template-selector-container .session-template-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
}

.session-template-selector-container .session-template-item {
  width: 270px;
  background-color: white;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
}

.session-template-selector-container .session-template-item:hover {
  transform: translateY(-5px);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.session-template-selector-container
  .session-template-item
  .session-template-type-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.session-template-selector-container
  .session-template-item
  .session-template-type {
  background-color: lightgrey;
  color: black;
  border-radius: 8px;
  min-width: 40px;
  max-width: 150px;
  height: 30px;
  font-size: small;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.session-template-selector-container
  .session-template-item
  .session-template-edit-button {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s, color 0.2s;
}

.session-template-selector-container
  .session-template-item
  .session-template-edit-button:hover {
  background-color: #eee;
  color: #333;
}

.session-template-selector-container
  .session-template-item
  .session-template-title {
  font-size: 24px;
  color: black;
  line-height: 1.1;
  font-weight: 500;
  text-align: start;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.session-template-selector-container
  .session-template-item
  .session-template-description {
  font-weight: 300;
  font-size: 15px;
  text-align: start;
  color: grey;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.session-template-selector-container
  .session-template-item
  .session-template-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 1px solid #e0e0e0;
  padding: 0 10px;
  border-radius: 15px;
  height: 60px;
  font-size: 17px;
  margin-top: auto;
}

.session-template-selector-container
  .session-template-item
  .session-template-duration {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  font-weight: 400;
  color: black;
}

.session-template-selector-container
  .session-template-item
  .session-template-price {
  color: black;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

/* Pagination */
.session-template-selector-container .session-template-pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.session-template-selector-container .session-template-pagination-button {
  background-color: #000;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: transform 0.2s, background-color 0.3s;
}

.session-template-selector-container
  .session-template-pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.session-template-selector-container
  .session-template-pagination-button:hover:not(:disabled) {
  transform: scale(1.05);
  background-color: #333;
}

.session-template-selector-container .session-template-pagination-page-number {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

/* Responsive Styles */
@media screen and (max-width: 870px) {
  .session-template-selector-container .session-template-grid {
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .session-template-selector-container .filter-section .filter-tab {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .session-template-selector-container
    .filter-section
    .filter-options-container {
    width: 100%;
  }

  .session-template-selector-container .filter-section .filter-dropdown {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .session-template-selector-container .filter-section {
    padding: 12px 0;
  }

  .session-template-selector-container .filter-section .filter-tab-header {
    width: 100%;
    justify-content: flex-start;
  }

  .session-template-selector-container .filter-section button {
    width: 100%;
  }
}
