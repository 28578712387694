.agency-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 40px 0 0px 40px;
    flex-grow: 1;
}

.agency-details .close-btn-wrapper {
    justify-content: flex-end;
    width: 100%;
    display: none;
    margin-bottom: 10px;
}

.agency-details .close-btn-wrapper .close-btn {
    width: 30px;
    height: 30px;
    background: #FFF;
    color: #E7E3E9;
    font-weight: 700;
    border: 1px solid #E7E3E9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.agency-details .agency-details-logo-name {
    display: flex;
    width: 100%;
}

.agency-details .agency-details-logo-name .image-container {
    height: 80px;
    max-width: 80px;
    border: 1px solid #E7E3E9;
    box-sizing: border-box;
    margin-right: 20px;
    min-width: 80px;
    > .slctdAgencylogoInitials{
        background-color: black;
        color: white;
        height: 100% !important;
        font-size: 45px;
        margin: 0;
    }
    
}


.agency-details .agency-details-logo-name .image-container .agency-img {
    max-width: 100%;
    height: auto;
    cursor: pointer;
}

.agency-details .agency-details-logo-name .image-container .agency-initials {
    max-width: 100%;
    height: auto;
    min-height: 100px;
    background-color: #0b1c38;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    font-size: 1.3rem;
}

.agency-details .agency-details-logo-name .agency-name-website {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.agency-details .agency-details-logo-name .agency-name-website .agency-name-save {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.agency-details .agency-details-logo-name .agency-name-website .agency-name-save img {
    display: none;
    width: 20px;
    height: 14px;
    cursor: pointer;
}

.agency-details .agency-details-logo-name .agency-name-website .agency-name {
    font-family: 'lexend';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    color: #0B1C39;
}

.agency-details .agency-details-logo-name .agency-name-website .agency-website {
    font-family: 'lexend';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: rgba(11, 28, 57, 0.8);
    margin-top: 5px;
}

.agency-details .title-text {
    font-family: 'lexend';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: rgba(11, 28, 57, 0.8);
}

.agency-details .agency-about-sec,
.services-sec,
.clients-sec {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-right: 40px;
}

.agency-details .agency-about-sec .about {
    display: flex;
    width: 100%;
    justify-content: flex-start;
}

.agency-details .agency-about-sec .about .icon-text-wrapper {
    margin-top: 20px;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.agency-details .agency-about-sec .about .icon-text-wrapper .text {
    font-family: 'lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: rgba(11, 28, 57, 0.8);
    margin-top: auto;
}

.agency-details .agency-about-sec .agency-desc {
    font-family: 'lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: rgba(11, 28, 57, 0.8);
    margin-top: 20px;
    text-align: justify;
}

.agency-details .services-sec .services-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.agency-details .services-sec .services {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content !important;
    padding: 4px 8px;
    height: 24px;
    background: #fff;
    border: 1px solid #E7E3E9;
    font-family: 'lexend';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    color: rgba(11, 28, 57, 0.5);
    text-transform: capitalize;
    margin-right: 10px;
    margin-top: 8px;
}

.agency-details .clients-sec .clients-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.agency-details .clients-sec .clients-container .agency-client-logo_name {
    height: fit-content !important;
    background: #FFF;
    transition: background 0.5s ease;
    border: 1px solid #E7E3E9;
    width: fit-content !important;
    padding: 5px 8px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-top: 8px;
    cursor: default;
}

.agency-details .clients-sec .clients-container .agency-client-logo_name:hover {
    background: #FFEAEB;
    transition: background .5s ease;
}

.agency-details .clients-sec .clients-container .agency-client-logo_name .agency-client-logo {
    height: 18px;
    width: 18px;
    border-radius: 50%;
}

.agency-details .clients-sec .clients-container .agency-client-logo_name .agency-client-name {
    font-family: 'lexend';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    color: rgba(11, 28, 57, 0.5);
    margin-left: 5px;
}

.button-sec {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0px -4px 4px #E7E3E9;
    min-height: 60px;
    padding-left: 20px;
    position: sticky;
    bottom: 0;
    margin-top:20px;
}

.button-sec .button-wrapper {
    display: flex;
}

.button-sec .claim-agency-wrapper {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.button-sec .claim-agency-wrapper .agency-claim-state {
    height: 33px;
    padding: 8px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-family: 'lexend';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    color: rgba(11, 28, 57, 0.8);
    cursor: pointer;
    background: #FFFFFF;
    border: 1px solid #DFE9F3;
    border-radius: 5px;

}

.button-sec .claim-agency-wrapper .claim-agency {
    height: 22px;
    width: 18px;
}

.button-sec .contact-btn {
    margin-left: 20px;
    background-color: transparent;
    color: rgba(11, 28, 57, 0.8);
}

@media screen and (max-width: 768px) {
    .search-page .content-div .agency-details-wrapper {
        display: flex;
        position: fixed;
        margin-left: 0px;
        width: 100vw;
        top: 22rem;
        left: 0;
        height: 65%;
    }

    .search-page .content-div .agency-details-wrapper .agency-details {
        margin: 20px 0 40px 10px;
        overflow-y: scroll;
    }

    .agency-details .close-btn-wrapper {
        display: flex;
        flex-direction: row;
    }

    .agency-details .agency-details-logo-name .image-container {
        display: none;
    }

    .agency-details .agency-details-logo-name .agency-name-website .agency-name-save {
        align-items: center;
        justify-content: unset;
    }

    .agency-details .agency-details-logo-name .agency-name-website .agency-name-save img {
        margin-left: 15px;
    }

    .agency-details .clients-sec{
        padding-bottom: 130px;
    }

    .button-sec {
        flex-direction: column;
        position: fixed;
        padding-left: 0;
        padding-top: 10px;
        width: 97%;
    }

    .button-sec .claim-agency-wrapper {
        width: 100%;
        margin: 20px 0 20px 50px;
        flex-direction: row-reverse;
        justify-content: flex-end;
        display: none;
    }

    .button-sec .claim-agency-wrapper .agency-claim-state {
        margin-left:10px;
    }
}