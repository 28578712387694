.HowitworksContainer {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  gap: 80px;
  width: 100%;
  background-color: #f7f7f7;
  height: 100%;
  box-sizing: border-box;
  padding: 40px 0;
}

.HowitworksContainer .EntireRoadmap {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.HowitworksContainer .EntireRoadmap .EachSingleStep .SingleStepHeadingIcon {
  width: 100px;
  height: 100px;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.HowitworksContainer .EntireRoadmap .EachSingleStep .StepInfoAndRedirects {
  display: flex;
  gap: 30px;
  align-items: start;
  justify-content: center;
}

.HowitworksContainer
  .EntireRoadmap
  .EachSingleStep
  .StepInfoAndRedirects
  .StepInfoOnly {
  width: 350px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.HowitworksContainer
  .EntireRoadmap
  .EachSingleStep
  .StepInfoAndRedirects
  .StepRedirectsOnly {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

@media screen and (max-width: 480px) {
  .HowitworksContainer {
    padding: 30px 0 30px 30px;
    align-items: start;
    gap: 40px;
  }

  .HowitworksContainer .EntireRoadmap .EachSingleStep .StepInfoAndRedirects {
    flex-direction: column;
    gap: 20px;
  }

  .HowitworksContainer
    .EntireRoadmap
    .EachSingleStep
    .StepInfoAndRedirects
    .StepInfoOnly {
    width: 100%;
  }
}
