@media (-webkit-device-pixel-ratio: 1.25) {
  .homepage__cont {
    zoom: 1.20;
  }
}

.homepage__cont {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .homepage__cont .half__pink {
    position: absolute;
    top: 0;
    width: 100%;
    height: 305px;
    background: #fff6f6;
    z-index: -10;
  }
  .homepage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1128px;
    padding: 0 5%;
  }
  @media screen and (max-width: 750px) {
    .homepage {
      padding: 0;
    }
  }
  
  /* SEC MAIN */
  .sec__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10vmin;
    max-width: 1128px;
    padding: 2.5rem 0 4rem 0;
    text-align: initial;
    /* background: purple; */
  }
  @media screen and (max-width: 750px) {
    .sec__main {
      padding-left: 5%;
      padding-right: 5%;
    }
  }
  /* .sec__main .sec__left,
  .sec__main .sec__right {
    column-gap: 100px;
  } */
  .sec__main .subtitles {
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 0.6rem;
  }
  .sec__main .paragraphs {
    font-size: 0.95rem;
    margin-bottom: 1.5rem;
    
  }
  .sec__main .subheading {
    font-size: 0.85rem;
    margin-bottom: 1.5rem;
    color:#6E6E6E;
    font-weight: 700;
    transition: 10s;
  }
  .sec__main .sec__right {
    position: relative;
  }
  .sec__main .teamwork__img {
    object-fit: contain;
    height: 450px;
    z-index: 10;
    -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 3s; /* Firefox < 16 */
     -ms-animation: fadein 3s; /* Internet Explorer */
      -o-animation: fadein 3s; /* Opera < 12.1 */
         animation: fadein 3s;
  }
  .sec__main .teamwork__img__dots {
    position: absolute;
    bottom: -6%;
    left: -10%;
    z-index: -100;
  }
  .sec__main .color__orange {
    color: #ff656a;
    font-weight: 600 !important;
  }
  .sec__main .ratios {
    display: flex;
    width: 100%;
  }
  .sec__main .ratios span {
    width: 100%;
  }
  .color__orange {
    color: #ff656a;
  }
  .color__white {
    color: rgba(255, 255, 255, 0.932) !important;
  }
  .color__black {
    color: black !important;
  }
  .sec__left .search_box {
    height: 6rem;
    display: flex;
    margin:2rem;
    margin-left: 0;
    justify-content: left;
    align-items: center;
   
    overflow: hidden;
   
    margin-bottom: 3rem;
  }
 
 
 
  .sec__left .search_box .btn__1 {
    height: 100%;
    border: none;
    color: #0B1C39;
    background: white;
    width:100%;
    cursor: context-menu;
    font-family: "Poppins";
    height: 50px;
    justify-content: center;
    text-align: center;
    justify-content: space-between;
    font-size: 0.8rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    font-weight: 600;
   margin-right: 1rem;
   transition: 1s;
    
  }
  .sec__left .search_box .btn__1:hover{
    background-color:#ff656a ;
    color: white;
  }
  .sec__left .search_box .btn__2:hover{
    background-color:#ff656a ;
    color: white;
  }
  .sec__left .search_box .btn__2 {
    font-weight: 600;
    height: 100%;
    border: none;
    background-color: white;
    color:#0B1C39;
    box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    width:100%;
    cursor: context-menu;
    font-family: "Poppins";
    height: 50px;
    justify-content: space-between;
    margin-right: 3rem;
    text-align: center;
    font-size: 0.8rem;
    transition: 1s;
   
    
  }
  .side-pic{
    object-fit: cover;
   
    height: 400px;
    width:360px;
    background-color: white;
    transition: 3s;

  }
  .img_hover{
    -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 3s; /* Firefox < 16 */
     -ms-animation: fadein 3s; /* Internet Explorer */
      -o-animation: fadein 3s; /* Opera < 12.1 */
         animation: fadein 3s; 
  }
  @media only screen and (min-width:850px){
    .carousel_notvisible{
      display: none;
    }
  } 
  .carousel_notvisible{
    margin-top: 50px;
  }
  @media only screen and (max-width:850px) {
    .sec__main2 .sec__left2{
      display: none;
    }
    .carousel{
    
    justify-content: center;
    align-items: center;
    text-align: center;
      width: 350px;
    }
  }
 
  .carousel-indicators li { visibility: hidden; }
  .side-div{
    
    height: 400px;
    width:360px;
    justify-content: center;
    text-align: center;
    margin: auto;
    /* margin-top: 50px; */
    margin-right: 30px;
    transition: 3s;
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
     -ms-animation: fadein 1s; /* Internet Explorer */
      -o-animation: fadein 1s; /* Opera < 12.1 */
         animation: fadein 1s;
  }
 
  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
  .side-div h1{
    justify-content: center;
    margin: auto;
    font-weight: 900;
    padding-top: 150px;
  }
  .side-div span{
    font-size: 16px;
  }
  .Our_capabilities{
      background-color:#FAFAFA;
      display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1128px;
    padding: 2.5rem 0;
    background: #f8f8f8;
    box-sizing: border-box;
    margin-bottom: 10rem;
  }
  .pricing_title{
      margin-bottom: 30px;
  }
  .Our_capabilities .span{
    margin-top: 30px;
    margin-bottom: 0px;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 400;
    text-decoration: underline rgba(253, 102, 110, 0.2) 5px;
    cursor: pointer;
  }
  .Our_capabilities button{
    height: 100%;
    border: none;
    background-color:rgba(255, 207, 209, 0.2);
    color:#6E6E6E;
    width: 11.5rem;
    cursor: pointer;
    font-family: "Poppins";
    height: 60px;
    justify-content: center;
    text-align: center;
    margin-right: 30px;
    margin-top: 30px;
    border: 2px solid rgb(233, 233, 233);
  }
 .pricing__filters__cont .pricing__filters {
    display: flex;
    column-gap: 0.4rem;
    background: #fff6f6;
    padding: 0 0.9rem;
    border-radius: 3rem;
  }
 .pricing__filters__cont .pricing__filters .pricing__filter {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    padding: 0.5rem 0.9rem;
    margin: 0.4rem 0;
    border-radius: 3rem;
  
    cursor: pointer;
  }
  .pricing__filters__cont .pricing__filters .active {
    font-weight: 500;
    background: white;
    color:#FF656A;
    box-shadow: 0px 3px 18px rgba(14, 14, 14, 0.07);
  }
  .para {
    font-size: 0.85rem;
  }
  
  .mr__0 {
    margin-right: 0 !important;
  }
  .normal__heading {
    color:  #0b1c39 !important;
    font-size: 1.5rem;
    font-weight: 500;
  }
  .fw__600 {
    font-weight: 600 !important;
  }
  
  @media screen and (max-width: 850px) {
    .sec__left .search_box {
      margin: 2rem;
      margin-left:2rem ;
      justify-content: center;
  
    }
    .half__pink {
      height: 260px;
    }
    .sec__main {
      flex-wrap: wrap;
      text-align: center;
      padding-top: 5rem;
    }
    .sec__main .teamwork__img,
    .sec__main .teamwork__img__dots {
      display: none;
    }
    .sec__main .subtitles {
      font-size: 1.2rem;
      font-weight: 500;
    }
    .sec__left .search__box button {
      display: none;
    }
  }
  .mwidthsmall {
    max-width: 500px;
  }
  
  /* SEC MAIN 2 */
  .sec__main2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 5rem;
    max-width: 1128px;
    padding: 4rem 0;
    text-align: initial;
  }
  @media screen and (max-width: 750px) {
    .sec__main2 {
      padding-left: 5%;
      padding-right: 5%;
    }
  }
  /* .sec__main2 .sec__left2,
  .sec__main2 .sec__right2 {
    flex: 0.5;
  } */
  .sec__main2 .sec__left2 {
    position: relative;
  }
  .sec__main2 .sec__left2 .teamwork2 {
    object-fit: contain;
    height: 500px;
  }
  .sec__main2 .sec__left2 .teamwork__img__dots2s {
    position: absolute;
    bottom: 20%;
    right: 0%;
    z-index: 11;
  }
  .sec__main2 .sec__right2 h4 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .sec__main2 .sec__right2 .para {
    margin-bottom: 1.2rem;
  }
  .sec__main2 .sec__right2 .side_div{
    padding: 7px 7px 7px 0px;
    transition: 0.5s;
  }
  .sec__main2 .sec__right2 .side_div:hover {
    
    background-color: #e7e3e3;
    
  }
  .sec__main2 .sec__right2 .side_div h6{
    color:#0B1C39;
    transition: 0.3s;
  }
  .sec__main2 .sec__right2 .side_div h6:hover{
    color:#FF656A
  }
  .sec__main2 .sec__right2 button,
  .sec__main2 .sec__left2 button {
    border: none;
    color: white;
    padding: 15px 25px;
    margin: 5px 0;
    background: #ff656a;
    cursor: pointer;
  
    font-family: "Poppins";
  }
  .alternative__btn {
    font-family: "Poppins";
    visibility: hidden;
    opacity: 0;
  }
  
  .reg__btn {
    border: none;
    color: white;
    padding: 15px 25px;
    margin: 5px 5px;
    font-family: "Poppins";
    background: #ff656a;
    cursor: pointer;
  }
  .reg__btn2 {
    border: none;
    color: white;
    padding: 10px 20px;
    font-family: "Poppins";
    background: #ff656a;
    cursor: pointer;
  }
  .oxblue {
    color: #0b1c39;
  }
  .text__left {
    text-align: left;
  }
  .text__center {
    text-align: center;
  }
  .text__right {
    text-align: right;
  }
  .vertical__bottom {
    vertical-align: bottom;
  }
  .vertical__top {
    vertical-align: top;
  }
  .vertical__center {
    vertical-align: middle;
  }
  .cursor__pointer {
    cursor: pointer;
  }
  @media screen and (max-width: 850px) {
    .sec__main2 {
      flex-direction: column-reverse;
      text-align: center;
    }
    .sec__main2 .sec__left2 .teamwork2 {
      height: auto;
      max-height: 400px;
      width: 100%;
      margin-bottom: 1rem;
    }
    .sec__main2 .sec__left2 .teamwork__img__dots2s {
      display: none;
    }
    .hide__btn {
      display: none;
    }
    .alternative__btn {
      visibility: visible;
      opacity: 1;
    }
  }
  .scrolltotopbtn {
    position: fixed;
    object-fit: contain;
    right: 3%;
    bottom: 10%;
    cursor: pointer;
    z-index: 100;
  }
  .fa-circle-notch {
    animation-name: spin;
    animation-duration: 900ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  /* bg */
  .bgpurple {
    background: purple;
  }
  .bgpink {
    background: pink;
  }
  /* flexs */
  .dnoneimp {
    display: none !important;
  }
  .dflex {
    display: flex !important;
  }
  .flexcol {
    flex-direction: column !important;
  }
  .flexrow {
    flex-direction: row !important;
  }
  .justify__between {
    justify-content: space-between;
  }
  .justify__end {
    justify-content: flex-end;
  }
  .justify__center {
    justify-content: center;
  }
  .justify__start {
    justify-content: flex-start;
  }
  .align__between {
    align-items: space-between;
  }
  .align__end {
    align-items: flex-end;
  }
  .align__start {
    align-items: flex-start;
  }
  .align__center {
    align-items: center;
  }
  
  /* Widths */
  .width__100 {
    width: 100%;
  }
  /* Margin pattern for components */
  .mar0 {
    margin: 0 !important;
  }
  .mart05 {
    margin-top: 0.5rem !important;
  }
  .mart1 {
    margin-top: 1rem !important;
  }
  .marb1 {
    margin-bottom: 1rem !important;
  }
  .mart2 {
    margin-top: 2rem !important;
  }
  .marb2 {
    margin-bottom: 2rem !important;
  }
  .marr2 {
    margin-right: 2rem !important;
  }
  .marl2 {
    margin-left: 2rem !important;
  }
  .marr1 {
    margin-right: 1rem !important;
  }
  .marl1 {
    margin-left: 1rem !important;
  }
  .mar5__cont,
  .mar5 {
    margin: 0 9rem !important;
  }
  
  @media screen and (max-width: 1024px) {
    .mar5__cont,
    .mar5 {
      margin: 0 5% !important;
    }
  }
  @media screen and (max-width: 768px) {
    .mar5__cont {
      margin: 0 !important;
    }
  }@media screen and (max-width: 768px) {
  .sec__left .search_box .btn__2 {
        width:100%;
        font-size: 1rem;
        margin-right: 0.5rem;
  }
  .sec__left .search_box .btn__1 {
    width:100%;
    font-size: 1rem;
    margin-right: 0.5rem;
}
  }
@media screen and (max-width: 458px) {
    .sec__left .search_box .btn__2 {
          width:100%;
          padding: auto;
          font-size: 13px;
          margin-top:-50px ;
    }
    .sec__left .search_box .btn__1 {
      width:100%;
      padding: auto;
      font-size: 13px;
      margin-right: 0.8rem;
      margin-top:-50px ;
  }
}
@media screen and (max-width: 350px) {
  .sec__left .search_box .btn__2 {
        width:100%;
        font-size: 0.7rem;
       
  }
  .sec__left .search_box .btn__1 {
    width:100%;
    font-size: 0.7rem;
    margin-right: 0.6rem;
 
}
}  