.agency_card {
    height: 570px;
    min-width: 46%;
    background-color: #fff;
    border-radius: 10px;
    margin-right: 50px;
    padding: 45px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.agency_card .agency_logo_name {
    height: fit-content !important;
    background: #eff6fb;
    border-radius: 100px;
    width: fit-content !important;
    padding: 5px;
    display: flex;
    align-items: center;
}

.agency_card .agency_logo_name .agency_logo {
    height: 33px;
    width: 33px;
    border-radius: 50%;
}

.agency_card .agency_logo_name .agency_name {
    font-family: "lexend";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #0b1c39;
    margin: 0 12px;
}

.agency_card .title {
    font-family: "lexend";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #c4c4c4;
    margin-top: 12px;
    margin-bottom: 15px;
}

.agency_card .services {
    width: fit-content !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 15px;
    background: #eff6fb;
    border-radius: 100px;
    margin: 0px 10px 15px 0;
    font-family: "lexend";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #0b1c39;
    text-transform: capitalize;
}

.agency_card .service_sec {
    display: flex;
    flex-wrap: wrap;
}

.agency_card .grab_btn {
    background: #0b1c39;
    color: #fff;
    margin-top: 20px;
}

@media screen and (max-width: 768px) {
    .agency_card {
        min-width: 100%;
        margin-right: 0;
        padding: 25px;
        height: fit-content !important;
        align-items: center;
    }

    .agency_card .service_sec {
        flex-wrap: unset;
        flex-direction: column;
        align-items: center;
    }
}
