.componentTwo{
    width: 1240px;
    margin: 0 auto;
    border-left: 1px solid #E7E3E9;
    border-bottom: 1px solid #E7E3E9;
}
.componentTwo > .headingSection{
    display: table;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #E7E3E9;
    width: 100%;
    text-align: left;
}
.componentTwo > .headingSection > h2{
    height: 100px;
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    font-size: 25px;
    font-weight: 400;
    line-height: 60px;
    padding-left: 20px;
}
.componentTwo > .caseStudySection{
    display: flex;
}
.componentTwo > .caseStudySection > .case__studies {
    display: grid;
    grid-template-columns: repeat(4, 310px);
    gap: 0;
    justify-content: center;
}
.componentTwo > .viewMore{
    text-align: right;
    border-right: 1px solid #E7E3E9;
    padding: 15px;
}
.componentTwo > .viewMore > a{
    color: var(--black-b-4-aebd-h, #B4AEBD);
    text-align: right;
    font-size: 16px;
    font-weight: 300;
    line-height: 32px;
    text-decoration: none;
}
@media screen and (min-width: 1500px){
    .componentTwo{
        width: 84%;
        border-right: 1px solid #E7E3E9;
    }
    .componentTwo > .caseStudySection{
        display: flex;
        width: 100%;
    }
    .componentTwo > .caseStudySection > .case__studies {
        width: 100%;
        grid-template-columns: repeat(4, 25%);
    }
    .componentTwo > .caseStudySection > .case__studies > .caseStudy__card:last-of-type {
        border-right: none;
    }
}
@media screen and (max-width: 1240px){
    .componentTwo{
        width: 100%;
    }
    .componentTwo > .caseStudySection{
        display: flex;
        width: 100%;
    }
    .componentTwo > .caseStudySection > .case__studies {
        width: 100%;
        grid-template-columns: repeat(4, 25%);
    }
    .componentTwo > .caseStudySection > .case__studies > .caseStudy__card:last-of-type {
        border-right: none;
    }    
}
@media screen and (max-width: 1080px){
    .componentTwo{
        width: 100%;
    }
    .componentTwo > .caseStudySection{
        display: flex;
        width: 100%;
    }
    .componentTwo > .caseStudySection > .case__studies {
        width: 100%;
        grid-template-columns: repeat(2, 50%);
    }
    .componentTwo > .caseStudySection > .case__studies > .caseStudy__card:last-of-type {
        border-right: none;
    }
    .componentTwo > .caseStudySection > .case__studies >.caseStudy__card {
        width: -webkit-fill-available;
    }
    .componentTwo > .caseStudySection > .case__studies >.caseStudy__card > .main > .display_image {
        border-radius: 5px;
        width: 88%;
        height: auto;
    }
    .componentTwo > .caseStudySection > .case__studies > .caseStudy__card > .main {
        position: relative;
        margin-bottom: 1rem;
        height: auto;
      }
      
}

@media screen and (min-width: 1500px){
    .componentTwo > .caseStudySection > .case__studies >.caseStudy__card {
      width: 100%;
    }
    .caseStudy__card > .main {
        height: fit-content;
    }
    .componentTwo > .caseStudySection > .case__studies >.caseStudy__card > .caseStudy__card > .main {
      width: 100%;
    }
    .componentTwo > .caseStudySection > .case__studies >.caseStudy__card > .main > .display_image {
      border-radius: 5px;
      width: 88%;
      height: auto;
      min-width: 270px;
      min-height: 200px;  
    }
  }
  @media screen and (max-width: 1240px) and (min-width: 1080px){
    .componentTwo > .caseStudySection > .case__studies >.caseStudy__card {
      width: 100%;
    }
    .componentTwo > .caseStudySection > .case__studies >.caseStudy__card > .caseStudy__card > .main {
      height: 210px;
    }
    .componentTwo > .caseStudySection > .case__studies >.caseStudy__card > .main > .display_image {
      width: 85% !important;
      border-radius: 5px;
      width: auto;
      height: auto;
    }
  }
  @media screen and (max-width: 620px){
    .componentTwo > .caseStudySection > .case__studies {
        width: 100%;
        grid-template-columns: repeat(1, 100%);
    }
  }