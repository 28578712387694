.componentThree{
    border-left: 1px solid #E7E3E9;
    border-right: 1px solid #E7E3E9;
    width: 1240px;
    margin: 0 auto;
}
.componentThree .headingSection{
    display: table;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #E7E3E9;
    width: 100%;
    text-align: left;
}
.componentThree .headingSection > h2{
    height: 100px;
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    font-size: 25px;
    font-weight: 400;
    line-height: 60px;
    padding-left: 20px;
}
.componentThree > .brandLogosContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 120px;
  padding: 70px 0 90px 0;
  border-bottom: 1px solid #E7E3E9;
}
  
.componentThree > .brandLogosContainer > .brandLogo {
    max-width: 130px; /* Adjust the logo width as needed */
    height: 80px;
}
.componentThree > .brandLogosContainer > .brandLogo:last-of-type,
.componentThree > .brandLogosContainer > .brandLogo:nth-last-of-type(2){
    height: 40px;
    margin-top: 20px;
}  
.componentThree > .advantageContainer {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #E7E3E9;
  }
  
  .componentThree > .advantageContainer > .advantageItem {
    margin: 75px 130px 75px 130px;
    width: 100%;
    display: flex;
  }
  .componentThree > .advantageContainer > .advantageItem > .imageContainer > h3 {
    display: none;
  }
  .componentThree > .advantageContainer > .advantageItem.even > .imageContainer {
    order: 1;
  }
  
  .componentThree > .advantageContainer > .advantageItem.even > .textContainer {
    order: 2;
  }
  
  .componentThree > .advantageContainer > .advantageItem.odd > .imageContainer {
    order: 2;
  }
  
  .componentThree > .advantageContainer > .advantageItem.odd > .textContainer {
    order: 1;
  }
  .componentThree > .advantageContainer > .advantageItem.odd > .imageContainer img {
    margin-left: 65px;
  }

  
  .componentThree > .advantageContainer > .advantageItem > .textContainer {
    width: 480px;
  }

  .componentThree > .advantageContainer > .advantageItem > .imageContainer{
    position: relative;
    width: 480px;
  }
  .componentThree > .advantageContainer > .advantageItem > .imageContainer > img{
    display: block;
    width: 400px;
    height: auto;
  }
  .componentThree > .advantageContainer > .advantageItem > .imageContainer > .textOverlay {
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0.32px;
    width: 300px;
    height: 49px;
    justify-content: center;
    padding: 8.5px 0;
    background-color: white;
  }
  .componentThree > .advantageContainer > .advantageItem.even > .imageContainer > .textOverlay {
    position: absolute;
    top: 50%;
    left: 41%;
    transform: translate(-50%, -50%);
  }
  .componentThree > .advantageContainer > .advantageItem.odd > .imageContainer > .textOverlay {
    position: absolute;
    top: 50%;
    left: 54%;
    transform: translate(-50%, -50%);
    background-color: white;
  }
  .componentThree > .advantageContainer > .advantageItem > .textContainer > p {
    margin: 0;
    color: #525464;
    font-size: 16px;
    font-weight: 300;
    text-align: justify;
    line-height: 30px;
    letter-spacing: 0.32px;
  }
  
  .componentThree > .advantageContainer > .advantageItem > .textContainer h3 {
    color: #525464;
    font-size: 20px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: .4px;
    text-align: left;
    margin-bottom: 20px;
  }
  
  .nextFlex {
    display: flex;
  }
  
  .nextSubSection {
    width: 50%;
    text-align: left;
    padding: 50px;
  }
  .nextSubSection > h3{
    color: var(--black-525464-d, #525464);
    font-size: 20px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.4px;
  }
  .nextSubSection > p{
    color: var(--black-525464-d, #525464);
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0.32px;
    height: 200px;
  }
  .nextSubSection > button{
    font-size: 14px;
    line-height: 20px;
    padding: 10px 20px;
    margin-right: 50px;
    border: 1px solid #E7E3E9;
    background: #FFF;
    margin-top: 30px;
  }
  .nextSubSection:first-of-type{
    border-right: 1px solid #E7E3E9;
  }
  .nextSubSection:last-of-type{
    padding-right: 30px;
  }
  @media screen and (min-width: 1500px){
    .componentThree{
      width: 84%;
      margin: 0 auto;
    }
    .componentThree > .advantageContainer > .advantageItem{
      justify-content: space-around;
    }
    .componentThree > .advantageContainer > .advantageItem > .textContainer {
      width: 540px;
    }
  }
  @media screen and (max-width: 1240px){
    .componentThree{
      width: 95%;
  }
  .componentThree > .brandLogosContainer {
      padding: 70px;
    }  
    .componentThree > .advantageContainer > .advantageItem {
      margin-top: 75px;
      margin-bottom: 75px;
      margin-left: 8%;
      margin-right: 8%;
      width: 100%;
    }
    .componentThree > .advantageContainer > .advantageItem.odd > .imageContainer img {
      margin-left: 50px;
    }
  
    
    .componentThree > .advantageContainer > .advantageItem > .textContainer {
      min-width: 480px;
    }
  
    .componentThree > .advantageContainer > .advantageItem > .imageContainer{
      position: relative;
      width: 480px;
    }
    .componentThree > .advantageContainer > .advantageItem > .imageContainer > img{
      display: block;
      width: 400px;
      height: auto;
    }
    .componentThree > .advantageContainer > .advantageItem > .imageContainer > .textOverlay {
      font-size: 16px;
      font-weight: 300;
      line-height: 30px;
      letter-spacing: 0.32px;
      width: 300px;
      height: 49px;
      justify-content: center;
      padding: 8.5px 0;
      background-color: white;
    }
    .componentThree > .advantageContainer > .advantageItem.even > .imageContainer > .textOverlay {
      position: absolute;
      top: 50%;
      left: 41%;
      transform: translate(-50%, -50%);
    }
    .componentThree > .advantageContainer > .advantageItem.odd > .imageContainer > .textOverlay {
      position: absolute;
      top: 50%;
      left: 54%;
      transform: translate(-50%, -50%);
      background-color: white;
    }
    .componentThree > .advantageContainer > .advantageItem > .textContainer > p {
      margin: 0;
      color: #525464;
      font-size: 16px;
      font-weight: 300;
      text-align: left;
      line-height: 30px;
      letter-spacing: 0.32px;
    }
    
    .componentThree > .advantageContainer > .advantageItem > .textContainer h3 {
      color: #525464;
      font-size: 20px;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: .4px;
      text-align: left;
      margin-bottom: 20px;
    }
    
  }
  @media screen and (max-width: 1080px){  
    .componentThree > .advantageContainer > .advantageItem {
      margin: 0 auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-bottom: 1px solid #E7E3E9;
    }
    .componentThree > .advantageContainer > .advantageItem:last-of-type{
      border-bottom: none;
    }
    .componentThree > .advantageContainer > .advantageItem > .imageContainer > h3{
      display: block;
      color: #525464;
      font-size: 16px;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: .4px;
      text-align: center;
      margin-bottom: 10px;
      
    }    
    .componentThree > .advantageContainer > .advantageItem.odd > .imageContainer {
      order: 1;
    }
    
    .componentThree > .advantageContainer > .advantageItem.odd > .textContainer {
      order: 1;
    }
    .componentThree > .advantageContainer > .advantageItem.odd > .imageContainer img {
      margin: 0 auto;
    }
    .componentThree > .advantageContainer > .advantageItem.even > .imageContainer img {
      margin: 0 auto;
    }    
    .componentThree > .advantageContainer > .advantageItem > .textContainer {
      width: 65%;
      margin: 0 auto;
      min-width: 400px;
    }
    .componentThree > .advantageContainer > .advantageItem > .textContainer > h3{
      display: none;
    }
    
    .componentThree > .advantageContainer > .advantageItem > .imageContainer{
      position: relative;
      width: auto;
    }
    .componentThree > .advantageContainer > .advantageItem > .imageContainer > img{
      width: 65%;
      height: auto;
      min-width: 400px;
    }
    .componentThree > .advantageContainer > .advantageItem > .imageContainer > .textOverlay {
      display: flex;
      justify-content: center; /* Center horizontally */
      align-items: center; /* Center vertically */
      width: 250px;
      height: 250px;
      padding: .8rem;
      font-size: 20px;
    }
    .componentThree > .advantageContainer > .advantageItem.even > .imageContainer > .textOverlay {
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .componentThree > .advantageContainer > .advantageItem.odd > .imageContainer > .textOverlay {
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
    }
    .componentThree > .advantageContainer > .advantageItem > .textContainer > p {
      padding: 20px 0;
    }
    .componentThree > .advantageContainer > .advantageItem > .textContainer h3 {
      color: #525464;
      font-size: 20px;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: .4px;
      text-align: left;
      margin-bottom: 20px;
    }
    .nextFlex {
      display: flex;
      flex-direction: column;
    }    
    .nextSubSection {
      width: 100%;
    }
    .nextSubSection:first-child{
      border-bottom: 1px solid #E7E3E9;
    }
    .nextSubSection > p{
      height: auto;
    }  
    .nextSubSection:first-of-type{
      border-right: 0;
    }
  }
  @media screen and (max-width: 500px){  
    .componentThree > .brandLogosContainer {
      gap: 78px;
    }
    .componentThree > .advantageContainer > .advantageItem > .imageContainer > .textOverlay {
      width: 150px;
      height: 150px;
      font-size: 16px;
    }
    .componentThree > .advantageContainer > .advantageItem > .textContainer > p {
      line-height: 25px;      
    }
    .componentThree > .advantageContainer > .advantageItem > .textContainer{
      width: 70%;
      min-width: 250px;
    }
    .componentThree > .advantageContainer > .advantageItem > .imageContainer > img{
      width: 70%;
      min-width: 250px;
    }
    .nextSubSection{
      padding: 20px;
    }
  }
