.userProfileDetailedTalentPool {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;
}

.userProfileDetailedTalentPool .singleCategory {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 55px;
  border: 1px solid #e0e0e0;
  padding: 0 30px;
}

.userProfileDetailedTalentPool .singleCategory .categoryHeader {
  display: flex;
  align-items: center;
  gap: 15px;
}

.userProfileDetailedTalentPool .singleCategory .categoryInfo {
  font-size: 1.2rem;
  font-weight: 400;
}
.userProfileDetailedTalentPool .singleCategory .viewProfileButton {
  align-self: flex-end;
  background-color: transparent;
  display: none;
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
}

.userProfileDetailedTalentPool .filterSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  /* align-items: center; */
  /* width: 100%; */
  /* margin-bottom: 20px; */
}

.userProfileDetailedTalentPool .filterTab {
  display: grid;
  grid-template-columns: 135px 1fr;
  gap: 20px;
  font-size: 18px;
}

.userProfileDetailedTalentPool .selectedFiltersTab {
  display: grid;
  grid-template-columns: 135px 1fr 135px;
  gap: 20px;
  font-size: 18px;
  align-items: center;
  margin-bottom: 20px;
}

.userProfileDetailedTalentPool .filterTab .filterIcon {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px;
}

.userProfileDetailedTalentPool .togglePool .filteringOption {
  display: flex;
  justify-content: start;
  gap: 20px;
  align-items: center;
}

.userProfileDetailedTalentPool .togglePool .selectedFilters {
  display: flex;
  gap: 20px;
  justify-self: start;
  align-items: center;
  overflow-x: scroll;
  width: 1000px;
  scrollbar-width: none;
}

.userProfileDetailedTalentPool .togglePool .selectedFilters .singleFilter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #f7f7f7;
  background-color: #f7f7f7;
  border-radius: 20px;
  padding: 7px 15px;
  color: black;
  cursor: pointer;
  gap: 10px;
  width: fit-content;
  white-space: nowrap; /* Prevent line breaks */
}

.userProfileDetailedTalentPool .togglePool .crossIcon {
  cursor: pointer;
  display: block;
}

.userProfileDetailedTalentPool .selectedFiltersTab .resetFilterButton {
  color: #505050;
  font-weight: 400;
  font-size: 16px;
  text-align: right;
  cursor: pointer;
}

.userProfileDetailedTalentPool .togglePool .filterTabDropdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  font-size: 18px;
  color: #b1b1b1;
  border-radius: 30px;
  border: 1px solid #f7f7f7;
  padding: 7px 15px;
  background-color: #f7f7f7;
  position: relative;
}

.userProfileDetailedTalentPool .togglePool .filterTabDropdown .filterName {
  white-space: nowrap;
}

.userProfileDetailedTalentPool .togglePool .filterTabDropdown .variousOptions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}
.userProfileDetailedTalentPool
  .togglePool
  .filterTabDropdown
  .variousOptions
  > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 7px 15px;
  color: black;
  cursor: pointer;
  white-space: nowrap; /* Prevent line breaks */
  min-width: max-content;
}

.userProfileDetailedTalentPool
  .togglePool
  .filterTabDropdown
  .variousOptions
  > div:hover {
  background-color: #f7f7f7;
}

.userProfileDetailedTalentPool .selectAllOption {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.userProfileDetailedTalentPool .togglePool .singleFilter.Colleges {
  border-color: #e9e000;
  background-color: #fffb95;
}

.userProfileDetailedTalentPool .togglePool .singleFilter.Company {
  border-color: #ffa8a8;
  background-color: #ffd6d6;
}

.userProfileDetailedTalentPool .togglePool .singleFilter.Experience {
  border-color: #5182ff;
  background-color: #bacdff;
}

.userProfileDetailedTalentPool .togglePool {
  padding: 30px;
  border: 1px solid #e0e0e0;
}

.userProfileDetailedTalentPool .togglePool .entirePoolActivities {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between;
  align-items: center; */
  gap: 20px;
}

.poolSelectAndSend {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* gap: 20px; */
}
.poolSelectionInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.poolSelectionInfo .numberOfSelection {
  text-align: center;
}

.userProfileDetailedTalentPool
  .togglePool
  .entirePoolActivities
  .poolSelectAndSend
  div {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}
.userProfileDetailedTalentPool
  .togglePool
  .entirePoolActivities
  .poolSelectionInfo
  .numberOfSelection {
  font-weight: 600;
}

.userProfileDetailedTalentPool
  .togglePool
  .entirePoolActivities
  .poolSelectionInfo
  .inputTagWrapper {
  border: 1px solid #f7f7f7;
  background-color: #f7f7f7;
  border-radius: 30px;
  display: flex;
  justify-content: start;
  gap: 15px;
  align-items: center;
  height: 40px;
  min-height: 40px;
  width: 300px;
  max-width: 300px;
  padding: 0 15px;
}
.userProfileDetailedTalentPool
  .togglePool
  .entirePoolActivities
  .poolSelectionInfo
  .inputTagWrapper
  input {
  border: none;
  background-color: #f7f7f7;
  font-size: 20px;
  font-weight: 600;
  width: 90%;
  outline: none;
}

.iconSize20 {
  width: 20px;
  height: 20px;
}

.userProfileDetailedTalentPool .togglePool .poolMemberList {
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.userProfileDetailedTalentPool .togglePool .poolMemberList .members {
  display: flex;
  gap: 15px;
  justify-content: space-between;
}

.userProfileDetailedTalentPool
  .togglePool
  .poolMemberList
  .members
  .memberInfo {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  height: 60px;
  flex-grow: 1;
  gap: 10px;
}

.userProfileDetailedTalentPool
  .togglePool
  .poolMemberList
  .members
  .individualMemberPrice {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  gap: 5px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  padding: 0 15px;
  font-size: 17px;
  font-weight: 600;
}

.userProfileDetailedTalentPool
  .togglePool
  .poolMemberList
  .members
  .memberNameInfo {
  /* max-width: 230px;    */
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  font-weight: 600;
  padding: 0 15px;

  height: 60px;
  /* min-height: 55px; */
}

.userProfileDetailedTalentPool
  .togglePool
  .poolMemberList
  .members
  .memberInfo
  .memberOtherInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  gap: 10px;
  padding: 0 20px;

  height: 60px;
  min-height: 60px;
}

.userProfileDetailedTalentPool
  .togglePool
  .poolMemberList
  .members
  .memberInfo
  .memberOtherInfo
  .memberSingleOtherInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
  flex-wrap: nowrap;

  border-radius: 20px;
  border: 1px solid #e0e0e0;
  background-color: #e0e0e0;
  height: 30px;
  min-height: 30px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 400;
}

.userProfileDetailedTalentPool
  .togglePool
  .poolMemberList
  .members
  .poolMemberFunctions {
  display: flex;
  flex-direction: row;
  gap: 15px;

  height: 60px;
  min-height: 60px;
}

.userProfileDetailedTalentPool
  .togglePool
  .poolMemberList
  .members
  .poolMemberFunctions
  .paidData {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  height: 60px;
  min-height: 60px;
  width: fit-content;
  margin: 0;
}

.userProfileDetailedTalentPool
  .togglePool
  .poolMemberList
  .members
  .poolMemberFunctions
  .paidData
  .data-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0 15px;
  height: 60px;
  min-height: 60px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  /* width: fit-content;
  margin: 0; */
}


.userProfileDetailedTalentPool
  .togglePool
  .poolMemberList
  .members
  .memberProfilePreview {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  border-radius: 10px;
  border: 1px solid #e0e0e0;
  width: 60px;
  /* max-width: 55px; */
  cursor: pointer;
}

.userProfileDetailedTalentPool
  .togglePool
  .poolMemberList
  .members
  .wavingHand {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  border-radius: 10px;
  border: 1px solid #e0e0e0;
  width: 60px;
  /* max-width: 55px; */
  cursor: pointer;
}

.userProfileDetailedTalentPool .paymentFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  min-height: 55px;
  border: 1px solid #e0e0e0;
  padding: 0 30px;
  background-color: #f7f7f7;
  font-size: 20px;
}

.paymentFooter .unlockButton {
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  cursor: pointer;
}
.paymentFooter .individualPriceInfo {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  cursor: pointer;
}

.userProfileDetailedTalentPool .image-container {
  position: relative;
  width: 30px;
  height: 30px;
}

.userProfileDetailedTalentPool .image-container .image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.userProfileDetailedTalentPool .image-container .imageClear {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.userProfileDetailedTalentPool .image-container .icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s;
}

.userProfileDetailedTalentPool .image-container:hover .image {
  opacity: 0;
}

.userProfileDetailedTalentPool .image-container:hover .icon {
  opacity: 1;
}

.userProfileDetailedTalentPool .image-container .select-icon {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  width: 100%;
  height: 100%;
  scale: 0.8;
}

.dropdownButton {
  display: none;
}
.newmember {
  display: flex;
}

.md-screen {
  display: none;
}

.hideInLaptop {
  display: block;
}

@media (max-width: 600px) {
  .expanded .md-screen-function {
    display: none;
  }

  .userProfileDetailedTalentPool .togglePool {
    margin: 0 20px;
  }

  .userProfileDetailedTalentPool .togglePool .poolMemberList .userPreviewSlide {
    display: none !important;
  }

  .memberProfilePreview1 {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .unlockButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: black;
    color: #fff;
    padding: 2px;
    justify-content: center;
    margin: 2px;
    border-radius: 15px;
  }

  .paymentFooter p {
    width: 50%;
  }

  .userProfileDetailedTalentPool
    .togglePool
    .entirePoolActivities
    .filterButtonMobile {
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
    align-items: center;
    border: 1px solid #e0e0e0;
    height: 45px;
    padding: 0 15px;
    border-radius: 10px;
  }


  .userProfileDetailedTalentPool .togglePool .selectedFilters {
    display: flex;
    justify-content: start;
    gap: 10px;
    flex-wrap: wrap; /* Enable wrapping */
    width: 100%;
    /* height: 150px; */
    overflow-y: scroll; /* Enable vertical scroll if items overflow */
    overflow-x: hidden; 
  }



  .userProfileDetailedTalentPool
    .togglePool
    .poolMemberList
    .members
    .poolMemberFunctions {
    display: none;
  }

  .userProfileDetailedTalentPool
    .togglePool
    .poolMemberList
    .expanded
    .poolMemberFunctions {
    display: none;
  }

  .userProfileDetailedTalentPool
    .togglePool
    .poolMemberList
    .members
    .memberInfo
    .memberOtherInfo {
    display: none;
  }

  .userProfileDetailedTalentPool .togglePool .poolMemberList .members {
    display: flex;
    flex-direction: column;
    gap: 0;
    background-color: #f7f7f7;
    border: 1px solid #e0e0e0;
    padding: 10px;
    border-radius: 10px;
    /* flex-wrap: wrap; */
  }

  .userProfileDetailedTalentPool
    .togglePool
    .poolMemberList
    .memberInfo {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    align-items: center;
  }

  .expanded .md-screen {
    display: flex;
    width: 100%;
    border-color: red;
    margin-top: 10px;
    border-radius: 10px;
  }

  .userProfileDetailedTalentPool
    .togglePool
    .poolMemberList
    .members
    .memberNameInfo {
    /* max-width: 230px;    */
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 15px;
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    font-weight: 600;
    padding: 0 15px;

    height: 60px;
    /* min-height: 55px; */
    background-color: white;
  }

  .userProfileDetailedTalentPool
    .togglePool
    .poolMemberList
    .expanded
    .memberOtherInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    gap: 10px;
    padding: 20px;

    min-height: 60px;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    flex-wrap: wrap;
    background-color: white;
  }

  .userProfileDetailedTalentPool
    .togglePool
    .poolMemberList
    .expanded
    .memberOtherInfo
    .memberSingleOtherInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 5px;

    border-radius: 20px;
    border: 1px solid #e0e0e0;
    height: 30px;
    min-height: 30px;
    padding: 5px 10px;
    background-color: #e0e0e0;
  }

  .userProfileDetailedTalentPool
    .togglePool
    .poolMemberList
    .members
    .individualMemberPrice {
    border: 1px solid #e0e0e0;
    background-color: #e0e0e0;
  }

  .userProfileDetailedTalentPool
    .togglePool
    .poolMemberList
    .members.expanded
    .poolMemberFunctions {
    display: flex;
    height: 70px;
    justify-content: center;
    border: none;
    margin: 10px 0 0 0;
  }

  .userProfileDetailedTalentPool
    .togglePool
    .poolMemberList
    .members.expanded
    .poolMemberFunctions
    .paidData {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .userProfileDetailedTalentPool
    .togglePool
    .poolMemberList
    .members.expanded
    .poolMemberFunctions
    .paidData
    .data-container {
    background-color: white;
    border: 1px solid #e0e0e0;
  }

  .userProfileDetailedTalentPool
    .togglePool
    .poolMemberList
    .members
    .memberProfilePreview {
    height: 50px;
    background-color: white;
    color: black;
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid #e0e0e0;
    width: 100%;
  }

  .userProfileDetailedTalentPool
    .togglePool
    .poolMemberList
    .members
    .wavingHand {
    height: 50px;
    display: flex;
    width: 100%;
    border: none;
    background-color: white;

    border: 1px solid #e0e0e0;
  }
  .userProfileDetailedTalentPool
    .togglePool
    .poolMemberList
    .members
    .wavingHand
    .activeThisForMobile {
    height: 50px;
    background-color: white;
    color: black;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    border: 1px solid #e0e0e0;
  }

  .userProfileDetailedTalentPool
    .togglePool
    .poolMemberList
    .members
    .poolMemberFunctions
    .paidData {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    height: 60px;
    min-height: 60px;
    width: fit-content;
    margin: 0;
  }

  .userProfileDetailedTalentPool
    .togglePool
    .poolMemberList
    .members
    .poolMemberFunctions
    .paidData
    .data-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0 15px;
    height: 60px;
    min-height: 60px;
    border: 1px solid #e0e0e0;
    /* width: fit-content;
    margin: 0; */
  }

  .dropdownButton {
    display: flex;
    border: none;
    color: black;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }

  .userProfileDetailedTalentPool .paymentFooter {
    height: 100px;
    padding: 0 15px;
  }
  .paymentFooter .unlockButton {
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 0 20px;
    cursor: pointer;
    border-radius: 50px;
    height: 50px;
    width: 150px;
  }

  .paymentFooter .individualPriceInfo {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
    cursor: pointer;
    font-size: 1rem;
  }

  /* .iconSize20 {
  height: 24px;
  width: 24px;
} */
  .theColor {
    color: white;
  }

  .userProfileDetailedTalentPool .singleCategory .theExtraBar,
  .userProfileDetailedTalentPool .singleCategory .categoryNumber,
  .userProfileDetailedTalentPool .singleCategory .categoryPrice {
    display: none;
  }

  .userProfileDetailedTalentPool .singleCategory .categoryInfo {
    font-weight: 600;
    font-size: 22px;
  }

  .userProfileDetailedTalentPool .togglePool {
    padding: 15px 0;
    border: none;
  }
}
