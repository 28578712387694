@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.Ment {
  font-family: "Poppins", sans-serif;
  padding: 30px 0;
  position: relative;
}

.TabContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  margin-bottom: 20px;
}

.LeftTabs {
  display: flex;
  gap: 10px;
}

.RightTab {
  margin-left: auto;
}

.Tab {
  padding: 15px 20px;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  background-color: white;
  font-weight: 400;
}

.Tab.active1 {
  background-color: #e0e0e0;
  border: none;
}

.TabContent {
  display: block;
  padding: 20px;
}

.TabContent.active1 {
  display: block;
}

.availability-icon {
  display: flex;
  gap: 4px;
  align-items: center;
}

.noDataContainer{
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0 20px;
  height: 70px;
  border: 1px solid #F7F7F7;
  border-radius: 30px;
  background-color: #F7F7F7;
  font-size: 18px;
  font-weight: 400;
  color: #505050;
}

@media screen and (max-width: 870px) {   
  .Ment{
    padding: 10px 0;
  } 
  .LeftTabs{
    overflow-x: scroll;
    max-width: 100%;
  }
  .Tab {
    display: flex; /* Makes the Tab a flex container */
    align-items: center; /* Centers content vertically */
    justify-content: center; /* Centers content horizontally */
    height: 40px; /* Set the desired height */
    padding: 0 15px; /* Adds horizontal padding for spacing */
    border: 1px solid #ccc; /* Optional: Add border to each tab */
    cursor: pointer; /* Changes cursor to pointer on hover */
    min-width: fit-content;
  }
}
@media screen and (max-width: 710px) {   
  .RightTab{
    margin-top: 20px;
  }
}