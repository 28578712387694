@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Main container */
.slot-booking-container {
  margin-top: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
}

/* Header section */
.slot-booking-container .slot-booking-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: white;
}

.slot-booking-container .slot-booking-header .slot-booking-title-wrapper {
  display: flex;
  align-items: baseline;
  gap: 24px;
}

.slot-booking-container
  .slot-booking-header
  .slot-booking-title-wrapper
  .slot-booking-title {
  font-weight: 500;
  font-size: 32px;
  letter-spacing: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  text-align: left;
}

.slot-booking-container
  .slot-booking-header
  .slot-booking-title-wrapper
  .slot-booking-back-button {
  background-color: #f7f7f7;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slot-booking-container
  .slot-booking-header
  .slot-booking-title-wrapper
  .slot-booking-mobile-icon {
  display: none;
}

/* Duration display */
.slot-booking-container .slot-booking-header .slot-booking-duration-wrapper {
  color: black;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 9px;
  font-weight: 400;
}

.slot-booking-container
  .slot-booking-header
  .slot-booking-duration-wrapper
  .slot-booking-duration-item {
  border-radius: 40px;
  background: #f7f7f7;
  font-size: 15px;
  width: 236px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 23px;
  font-weight: 400;
}

/* Details section */
.slot-booking-container .slot-booking-details {
  background: #f7f7f7;
}

.slot-booking-container .slot-booking-details .slot-booking-details-container {
  display: flex;
  flex-direction: row;
  padding: 16px;
  justify-content: center;
  gap: 16px;
}

.slot-booking-container
  .slot-booking-details
  .slot-booking-details-container
  .slot-booking-reschedule {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.slot-booking-container
  .slot-booking-details
  .slot-booking-details-container
  .slot-booking-reschedule
  .slot-booking-calendar,
.slot-booking-container
  .slot-booking-details
  .slot-booking-details-container
  .slot-booking-reschedule
  .slot-booking-time-container {
  margin-top: 12px;
  width: 100%;
}

/* DatePicker custom styling */
.slot-sunday-holiday {
  background-color: #e7e7e7;
  border-radius: 50%;
  color: #ccc;
  cursor: not-allowed;
  pointer-events: none;
}

.slot-sunday-holiday .react-datepicker__day--selected {
  color: white !important;
  background-color: #e7e7e7 !important;
  border-radius: 50% !important;
}

/* Time slot section */
.slot-booking-container
  .slot-booking-details
  .slot-booking-details-container
  .slot-booking-reschedule
  .slot-booking-time-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slot-booking-container
  .slot-booking-details
  .slot-booking-details-container
  .slot-booking-reschedule
  .slot-booking-time-container
  .slot-booking-time-selector {
  border: 1px solid #e0e0e0;
  background-color: white;
  width: 400px;
  height: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  border-radius: 20px;
  margin-top: 5px;
}

.slot-booking-container
  .slot-booking-details
  .slot-booking-details-container
  .slot-booking-reschedule
  .slot-booking-time-container
  .slot-booking-time-selector
  .slot-booking-time-slot {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
  background-color: #fff;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.2s;
}

.slot-booking-container
  .slot-booking-details
  .slot-booking-details-container
  .slot-booking-reschedule
  .slot-booking-time-container
  .slot-booking-time-selector
  .slot-booking-time-slot:hover {
  background-color: #f7f7f7;
  color: black;
}

.slot-booking-container
  .slot-booking-details
  .slot-booking-details-container
  .slot-booking-reschedule
  .slot-booking-time-container
  .slot-booking-time-selector
  .slot-booking-time-slot.slot-booking-active {
  background-color: black;
  color: white;
}

/* Footer sections */
.slot-booking-container .slot-booking-details .slot-booking-duration-footer {
  display: none;
}

.slot-booking-container .slot-booking-details .slot-booking-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: white;
}

.slot-booking-container
  .slot-booking-details
  .slot-booking-footer
  .slot-booking-price {
  font-size: 22px;
  font-weight: 600;
  width: 128px;
  height: 50px;
  background: #f7f7f7;
  border-radius: 40px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slot-booking-container
  .slot-booking-details
  .slot-booking-footer
  .slot-booking-button {
  background-color: #000;
  color: #fff;
  padding: 10px 23px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Mobile responsive styles */
@media (max-width: 600px) {
  .slot-booking-container
    .slot-booking-details
    .slot-booking-details-container {
    flex-direction: column;
    justify-content: flex-start;
    max-width: 100%;
    padding: 5px;
  }

  .slot-booking-container
    .slot-booking-details
    .slot-booking-details-container
    .slot-booking-reschedule {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .slot-booking-container
    .slot-booking-header
    .slot-booking-duration-wrapper
    .slot-booking-duration-item {
    display: none;
  }

  .slot-booking-container
    .slot-booking-header
    .slot-booking-title-wrapper
    .slot-booking-back-button {
    display: none;
  }

  .slot-booking-container
    .slot-booking-header
    .slot-booking-title-wrapper
    .slot-booking-mobile-icon {
    display: flex;
    justify-content: center;
    text-align: right;
    height: 44px;
    width: 44px;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #e0e0e0;
  }

  .slot-booking-container .slot-booking-details .slot-booking-duration-footer {
    background-color: #f7f7f7;
    font-weight: 400;
    display: flex;
    flex-direction: column;
  }

  .slot-booking-container
    .slot-booking-details
    .slot-booking-duration-footer
    .slot-booking-footer-item {
    border-radius: 40px;
    background: white;
    font-size: 15px;
    width: 236px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 23px;
    font-weight: 400;
    margin: 4px;
    margin-left: 35px;
    border: 1px solid #e0e0e0;
  }

  .slot-booking-container
    .slot-booking-details
    .slot-booking-details-container
    .slot-booking-reschedule
    .slot-booking-calendar,
  .slot-booking-container
    .slot-booking-details
    .slot-booking-details-container
    .slot-booking-reschedule
    .slot-booking-time-container {
    margin-top: 0;
    width: 100%;
    max-width: 350px;
    justify-content: center;
  }

  .slot-booking-container
    .slot-booking-details
    .slot-booking-details-container
    .slot-booking-reschedule
    .slot-booking-time-container
    .slot-booking-time-selector {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
