.casestudies {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 25px;
    text-align: center;
    align-content: center;
    justify-content: center;
    justify-content: space-evenly;
}
.casestudies >.casestudy{
    display: flex;
    flex-direction: column;
    width: min-content;
}
.casestudies >.casestudy >p{
    background: #FFF1F1;
    border: 1px solid #FF656A;
    border-radius: 40px;
    font-family: "lexend";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    width: max-content;
    display: flex;
    align-items:left;
    text-align: left;
    padding: 5px;
    color: #FF656A;
}
.casestudies >.casestudy>div{
    border: 1px solid #DFE9F3;
    border-radius: 10px;
    padding: 15px;
}
.casestudies >.casestudy>div>.head{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.casestudies >.casestudy>div>.head > p{
    font-family: "lexend";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    gap: 3px;
    color: #0B1C39;
    background: #F2F8FF;
    border-radius: 44px;
    padding: 5px;
}
.casestudies >.casestudy> div>.head > p >img{
    height: 12px;
}
.casestudies >.casestudy> div>.head > img{
    height: 24px;
}
.casestudies >.casestudy>div >img{
    height: 250px;
    margin-bottom: 10px;
}
.casestudies >.casestudy>div > .bottom{
    text-align:justify;
}
.casestudies >.casestudy>div > .bottom > h5{
    font-family: "lexend";
    font-style: normal;
    /* font-weight: 00; */
    font-size: 15px;
    line-height: 12px;
    display: flex;
    align-items: left;
    text-align:justify;
    color: #FF656A;
}