.HeroComponent {
  padding-top: 80px;
}

.HeroComponent .Introduction-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 150px;
  gap: 30px;
}

.HeroComponent .Introduction-content .textAlignment {
  text-align: center;
}

.HeroComponent .HeroComponentCardSection {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 70px 20px;
  align-items: center;
}

.HeroComponent .HeroComponentCardSection .NavigationArrowContainer {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
}

.HeroComponent
  .HeroComponentCardSection
  .NavigationArrowContainer
  .NavigationArrowBox {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.HeroComponent .HeroComponentCardSection .SingleMentorInfo {
  width: 60%;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: start;
}

.HeroComponent .HeroComponentCardSection .SingleMentorInfo.hiddenInfo {
  display: none;
}

.HeroComponent
  .HeroComponentCardSection
  .SingleMentorInfo
  .MentorReviewSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.HeroComponent
  .HeroComponentCardSection
  .SingleMentorInfo
  .MentorReviewSection
  .SingleReviewContainer {
  display: flex;
  padding: 30px;
  flex-direction: column;
  gap: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  min-width: 552px;
}

.SingleReviewContainer .reviewComment {
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
}

.HeroComponent .HeroComponentCardSection > .SingleReviewContainer .reviewInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
}

.SingleReviewContainer .reviewInfo .reviewerName {
  font-size: 14px;
  font-weight: 600;
  line-height: 18.9px;
}

.SingleReviewContainer .reviewInfo .reviewerPosition {
  font-size: 14px;
  font-weight: 400;
  line-height: 18.9px;
}

.HeroComponent .HeroComponentCardSection .SingleReviewContainer {
  display: none;
}

@media screen and (max-width: 480px) {
  .HeroComponent .Introduction-content {
    margin: 0 0 0 30px;
    align-items: start;
  }

  .HeroComponent .Introduction-content .textAlignment {
    text-align: left;
  }

  .HeroComponent .HeroComponentCardSection {
    padding: 35px 0 35px 30px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    overflow-x: auto;
    justify-content: start;
    align-items: start;
  }

  .HeroComponent
    .HeroComponentCardSection
    .SingleMentorInfo
    .MentorCardSection {
    display: none;
  }

  .HeroComponent .HeroComponentCardSection .SingleMentorInfo {
    width: 100%;
    display: none;
  }

  .HeroComponent .HeroComponentCardSection .SingleReviewContainer {
    min-width: 250px;
    display: flex;
    padding: 30px;
    flex-direction: column;
    gap: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
  }

  .HeroComponent .HeroComponentCardSection .NavigationArrowContainer {
    display: none;
  }
}
