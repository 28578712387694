.mainContent > .partOne {
    width: 740px;
    height: auto;
    background-color: #ffffff;
    border-right: 1px solid #E7E3E9;
  }
  .mainContent > .partOne > .pageTitle{
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 28px;
    align-items: center;
    color: #2B3038;
    border-bottom: 1px solid #E7E3E9;
    padding: 10px 20px;
  }
  .mainContent > .partOne > .caseStudyTitle{
    padding: 50px 20px;
    border-bottom: 1px solid #E7E3E9;
  }
  .mainContent > .partOne > .caseStudyTitle > h1{
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 40px;
    color: #191E22;
    text-align: left;
  }
  .mainContent > .partOne > .agencyBrand{
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 28px;
    align-items: center;
    color: #2B3038;
    border-bottom: 1px solid #E7E3E9;
    padding: 10px 20px;
  }
  .mainContent > .partOne > .agencyBrand > p{
    margin: 0;
    padding-right: 4px;
  }
  .mainContent > .partOne > .agencyBrand > a{
    padding-right: 4px;
  }
  .mainContent > .partOne > .basicInfo{
    background: #F8F8F8;
    border-bottom: 1px solid #E7E3E9;
    display: flex;
    flex-wrap: wrap;
    padding:  50px  20px  ;
    gap: 30px 70px;
  }
  .mainContent > .partOne > .basicInfo>div{
    margin: auto 0px;
    text-align: left;
  }
  .mainContent > .partOne > .basicInfo>div>p{
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #191E22;
  }
  .mainContent > .partOne > .basicInfo>div>.fieldWrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    text-transform: capitalize;
  }
  .mainContent > .partOne > .basicInfo>div h4{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #191E22;
  }
  .mainContent > .partOne > .overview{
    padding-top: 30px;
    text-align: left;
    border-bottom: 1px solid #E7E3E9;
  }
  .mainContent > .partOne > .overview>p{
    margin: 0 20px 20px;
  }

  .mainContent > .partOne > .statastics{
    padding: 40px 0;
    border-bottom: 1px solid #E7E3E9;
    background: #F8F8F8;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #191E22;
    font-size: 16px;
    text-align: left;
    border-bottom: 1px solid #E7E3E9;
  }
  .mainContent > .partOne > .statastics > .statFigure{
    font-weight: 600;
    border-bottom: 1px solid #E7E3E9;
    margin: 10px 20px;
  }
  .mainContent > .partOne > .statastics > .statTitle{
    font-weight: 400;
    padding: 0 20px;
  }

  .mainContent > .partOne > .caseStudydata > .caseStudytags > .caseStudyheadings{
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    color: #191E22;    
    text-align: left;
    border-bottom: 1px solid #E7E3E9;
    padding: 35px 20px;
  }
  .mainContent > .partOne > .caseStudydata > .caseStudytags >  .caseStudycontent{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #191E22;
    text-align: left;
    border-bottom: 1px solid #E7E3E9;
    padding: 30px 20px 30px 20px;
  }
  .mainContent > .partOne > .caseStudydata > .caseStudytags > p{
    margin: 0;
  }
  .mainContent > .teamMembers{
    width: 1240px;
    margin: 0 -250px ;
    z-index: 2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #FFFFFF;
  }
  .mainContent > .teamMembers > .creditCard{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 620px;
    border-top: 0;
    border: 1px solid #E7E3E9;
    border-bottom: 0;
  }
  .mainContent > .teamMembers > .creditCard > .creditProfileimg{
        border-radius: 50%;
        width: 115px; /* set the desired width and height */
        height: 115px;
        margin: 10px 30px 20px 20px;
  }
  .mainContent > .teamMembers > .creditCard > .creditProfileinfo > p{
    margin: 0;
  }
  .mainContent > .teamMembers > .creditCard > .creditProfileinfo{
    margin-top: 10px;
  }
  .mainContent > .teamMembers > .creditCard > .creditProfileinfo > .creditName{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: #191E22;
  }
  .mainContent > .teamMembers > .creditCard > .creditProfileinfo > .creditRole{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #525464;
    text-align: left;
  }
  .mainContent > .teamMembers > .creditCard > .creditProfileinfo > .creditUsername{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #9A95A7;
    text-align: left;
  }

  @media (max-width: 1240px) {
    .mainContent {
      width: 740px;
      margin-left: auto;
      margin-right: auto;
      height: auto;
      border-left: 1px solid #E7E3E9;
      border-right: 1px solid #E7E3E9;
    }
  }
  @media (max-width: 1240px) {
    .mainContent > .teamMembers{
      width: auto;
      z-index: 2;
      margin: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      background-color: #FFFFFF;
    }
    .mainContent > .teamMembers > .creditCard{
      width: 50%;
      border-top: 0;
      border: 1px solid #E7E3E9;
    }
    .mainContent > .teamMembers > .creditCard > .creditProfileimg{
      border-radius: 50%;
      width: 115px; /* set the desired width and height */
      height: 115px;
      margin: 10px 30px 20px 20px;
    }
  
  }

  @media (max-width: 768px) {
    .mainContent {
      width: 100%;
      height: auto;
    }
    .mainContent > .partOne {
      width: 100%;
    }
    .mainContent > .teamMembers > .creditCard{
      width: 100%;
    }
    .mainContent > .teamMembers > .creditCard > .creditProfileimg{
      border-radius: 50%;
      width: 70px; /* set the desired width and height */
      height: 70px;
      margin: 10px 30px 20px 20px;
    }
  }

