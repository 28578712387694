.aprotemp {
  display: flex;
  flex-direction: column;
  /* margin: 0 4%; */
}
.aprotemp .top__home {
  display: flex;
  align-items: center;
  min-height: 100vh;
  /* margin: 0 -4%; */

  padding: 0 3%;
  position: relative;

  background: url("../images/leaves.png") center/cover no-repeat;
  /* overflow: hidden; */
}
.aprotemp .top__home .data__left {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  max-width: 500px;
  text-align: left;
}
.aprotemp .bigpara {
  font-size: 1.8rem;
  color: #243613;
}
.aprotemp .top__home .viewgocont {
  display: flex;
  column-gap: 0.7rem;
  row-gap: 0.7rem;
}
.aprotemp .top__home .viewgocont .viewprofile,
.aprotemp .top__home .viewgocont .gotowebsite {
  border: 1px solid black;
  padding: 0.4rem 1rem;
  cursor: pointer;
}
.aprotemp .top__home .viewgocont .viewprofile {
  background: black;
  color: white;
}
.aprotemp .top__home .data__left .scrolldown {
  color: #625656;
}
.aprotemp .top__home .data__left .ardownlong {
  object-fit: contain;
  max-height: 30px;
  cursor: pointer;
}
/* .aprotemp .top__home .banner-fadebottom {
  position: absolute;
  bottom: -20%;
  left: -50%;
  height: 50rem;
  width: 100rem;
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(37, 37, 37, 0.295),
    #111
  );
  transform: rotate(45deg);
  z-index: -2;
} */

/* about agency */
.aprotemp .aboutagency {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 2rem;
  row-gap: 2rem;
  margin: 3rem 4%;
  text-align: left;
}
.aprotemp .aboutagency .part__left {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}
.aprotemp .aboutagency .part__left .stats {
  display: flex;
  column-gap: 1rem;
  width: 100%;
}
.aprotemp .aboutagency .part__left .stats .part {
  /* background: purple; */
  width: 100%;
}
.aprotemp .aboutagency .part__right {
  display: flex;
}
.aprotemp .aboutagency .propgirl {
  object-fit: contain;
  height: auto;
  max-height: 450px;
}

/* Our relevant exp */
.aprotemp .ourexp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  column-gap: 2rem;
  row-gap: 2rem;
  margin: 3rem 4%;
  text-align: left;
}
.aprotemp .ourexp .exp__cards {
  display: grid;
  grid-template-columns: repeat(2, minmax(250px, 1fr));
  grid-gap: 2rem;
}
.aprotemp .ourexp .exp__cards .exp__card {
  display: flex;
  column-gap: 1.5rem;
  row-gap: 1rem;
  /* background: purple; */
}
.aprotemp .ourexp .exp__cards .exp__card .exp__card__img {
  object-fit: contain;
  height: auto;
  max-height: 200px;
  width: 100%;
}
.aprotemp .ourexp .exp__cards .exp__card .part__data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 0.5rem;
}
.ourexp .exp__cards .exp__card .part__data .part {
  display: flex;
  flex-direction: column;
  row-gap: 0.6rem;
}
.exp__cards .exp__card .alttextshow {
  display: none;
}
.exp__cards .exp__card .alttexthide {
  display: block;
}

@media screen and (max-width: 550px) {
  .aprotemp .ourexp .exp__cards .exp__card {
    flex-direction: column;
  }
  .exp__cards .exp__card .alttexthide {
    display: none;
  }
  .exp__cards .exp__card .alttextshow {
    display: block;
  }
}
/* attachments */
.aprotemp .attachments {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  column-gap: 2rem;
  row-gap: 2rem;
  margin: 3rem 4%;
  text-align: left;
}
.aprotemp .attachments .upload__btns {
  display: flex;
  column-gap: 2rem;
  row-gap: 1rem;
}
.aprotemp .attachments .upload__btns .upload__btn {
  display: flex;
  align-items: center;
  column-gap: 2rem;
  border: 1px solid lightgray;
  border-radius: 2px;
  padding: 0.2rem 0.5rem;
  outline: none;
  background: transparent;
  font-family: "Poppins";
  cursor: pointer;
}

/* the team */
.aprotemp .theteam {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  column-gap: 2rem;
  row-gap: 2rem;
  margin: 3rem 4%;
  text-align: left;
}
.aprotemp .theteam .theteamcards {
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  grid-gap: 1rem;
  width: 100%;
}

.aprotemp .theteam .theteamcards .theteamcard {
  display: flex;
  align-items: flex-start;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  border-radius: 2px;
  padding: 1rem;
}
.aprotemp .theteam .theteamcards .theteamcard .theteamcard__img__cont {
  position: relative;
}
.aprotemp
  .theteam
  .theteamcards
  .theteamcard
  .theteamcard__img__cont
  .linkedinrnd {
  position: absolute;
  bottom: 15%;
  right: -5%;
}
.aprotemp .theteam .theteamcards .theteamcard .theteamcard__data {
  text-align: left;
}
.aprotemp
  .theteam
  .theteamcards
  .theteamcard
  .theteamcard__data
  .redirect__btn {
  margin-left: 0.4rem;
  object-fit: contain;
  height: 13px;
  cursor: pointer;
}

.aprotemp .clients {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  margin: 3rem 4%;
  text-align: left;
}
.aprotemp .clients .part__cont {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.8rem;
  row-gap: 0.8rem;
  text-align: left;
}

.aprotemp .clients .part {
  display: flex;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  float: left;
  margin-bottom: 1.4rem;
  width: 100%;
}
.aprotemp .clients .part .comp {
  object-fit: contain;
  height: auto;
  max-width: 100px;
  border-radius: 10rem;
  cursor: pointer;
}

/* Reviews */
.aprotemp .propreviews {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  margin: 3rem 4%;
  text-align: left;
  box-sizing: border-box;
}
.aprotemp .propreviews .review__cards {
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  grid-gap: 2rem;
}
.aprotemp .propreviews .review__cards .agc__rew__card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.1rem;
  border-radius: 2px;
  border: 1.5px solid rgba(211, 211, 211, 0.39);
  padding: 1.2rem;
}
.aprotemp .propreviews .review__cards .agc__rew__card .stars {
  display: flex;
  column-gap: 0.2rem;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.8rem;
}

.aprotemp .propreviews .review__cards .agc__rew__card .review__title {
  margin-bottom: 1rem;
}

/* statement of work */

.aprotemp .stateofwork {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  margin: 3rem 4%;
  text-align: left;
  box-sizing: border-box;
}
.aprotemp .stateofwork .stateofwork__datas {
  display: flex;
  align-items: flex-start;
  column-gap: 3rem;
  row-gap: 2rem;
  width: 100%;
}

.aprotemp .stateofwork .stateofwork__datas .statetabs {
  flex: 0.15;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
}
.aprotemp .stateofwork .stateofwork__datas .statetabs .statetabbtn,
.aprotemp .stateofwork .stateofwork__datas .statetabs .quatationtabbtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  border-radius: 3px;
  padding: 0.4rem 0.5rem;
  cursor: pointer;
  border: 1.5px solid lightgrey;
}
.aprotemp .stateofwork .stateofwork__datas .statetabs .active {
  color: white;
  background: #ff0000;
  border-color: transparent;
  transition: all 0.25s ease;
}
.aprotemp .stateofwork .stateofwork__datas .statetabs .active .arrow__down {
  transform: rotate(0deg) !important;
  transition: all 0.25s ease;
}
.aprotemp .stateofwork .stateofwork__datas .statetabs .statetabbtn .arrow__down,
.aprotemp
  .stateofwork
  .stateofwork__datas
  .statetabs
  .quatationtabbtn
  .arrow__down {
  font-weight: 600;
  transform: rotate(90deg);
  transition: all 0.25s ease;
}
.aprotemp .stateofwork .stateofwork__datas .statedatas {
  flex: 0.45;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
}
.aprotemp .stateofwork .stateofwork__datas .quatationstatedatas {
  flex: 0.8;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
}
.aprotemp .stateofwork .stateofwork__datas .statedatas .statedata,
.aprotemp
  .stateofwork
  .stateofwork__datas
  .quatationstatedatas
  .quatationstatedata {
  /* display: flex; */
  display: none;
  flex-direction: column;
  row-gap: 1rem;
}
.aprotemp
  .stateofwork
  .stateofwork__datas
  .quatationstatedatas
  .quatationstatedata {
  row-gap: 0.5rem;
}
.aprotemp
  .stateofwork
  .stateofwork__datas
  .quatationstatedatas
  .quatationstatedata
  .qdatasingle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1.5px solid lightgray;
  border-radius: 3px;
  padding: 0.5rem;
}

.aprotemp .stateofwork .stateofwork__datas .statedatas .activetab,
.aprotemp .stateofwork .stateofwork__datas .quatationstatedatas .activetab {
  display: flex !important;
}
@media screen and (max-width: 950px) {
  .aprotemp .stateofwork .stateofwork__datas {
    column-gap: 2rem;
  }
  .aprotemp .stateofwork .stateofwork__datas .statetabs {
    flex: 0.3;
  }
  .aprotemp .stateofwork .stateofwork__datas .statedatas {
    flex: 0.7;
  }
}
@media screen and (max-width: 600px) {
  .aprotemp .stateofwork .stateofwork__datas {
    flex-direction: column;
  }
  .aprotemp .stateofwork .stateofwork__datas .statetabs {
    flex: 1;
  }
  .aprotemp .stateofwork .stateofwork__datas .statedatas,
  .aprotemp .stateofwork .stateofwork__datas .quatationstatedatas {
    flex: 1;
  }
}

/* News and Awards */
.aprotemp .anews__awards__cont {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 1.5rem;
  row-gap: 3rem;
  margin: 3rem 4%;
  text-align: left;
  box-sizing: border-box;
}
.aprotemp .anews__awards__cont .anews,
.aprotemp .anews__awards__cont .aawards {
  flex: 0.5;
  display: flex;
  flex-direction: column;
  column-gap: 1rem;
  row-gap: 1.5rem;
  /* background: purple; */
}
/* news */
.aprotemp .anews__awards__cont .news__cards {
  display: flex;
  column-gap: 1.5rem;
  row-gap: 1rem;
  width: 100%;
}
.anews__awards__cont .news__cards .news__card {
  border: 1.5px solid lightgray;
  padding: 0;
  width: 100%;
}
.anews__awards__cont .news__cards .news__card__img {
  object-fit: contain;
  height: auto;
  /* max-height: 160px; */
  width: 100%;
}
.anews__awards__cont .news__cards .news__card__detail {
  padding: 0.5rem;
  text-align: left;
}

/* awards */
.aprotemp .anews__awards__cont .aawards .awards__logos {
  display: flex;
  column-gap: 1.5rem;
  row-gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  float: left;
  width: 100%;
}
.aprotemp .anews__awards__cont .aawards .awards__logos .awards__logo {
  object-fit: contain;
  height: auto;
  max-height: 75px;
}

/* Terms and Conditions */
.aprotemp .termandcond {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  margin: 3rem 4%;
  margin-bottom: 4rem;
  text-align: left;
  box-sizing: border-box;
}
.aprotemp .termandcond .atermcondtext__cont {
  padding-right: 22%;
  box-sizing: border-box;
}
@media screen and (max-width: 1024px) {
  .aprotemp .termandcond .atermcondtext__cont {
    padding-right: 0;
  }
}

@media screen and (max-width: 1024px) {
  .aprotemp .anews__awards__cont {
    flex-direction: column;
  }
  .aprotemp .anews__awards__cont .anews,
  .aprotemp .anews__awards__cont .aawards {
    flex: 1;
    width: 100%;
  }
  /* .aprotemp .anews__awards__cont .news__cards .news__card {
    width: 100%;
    max-width: 100%;
  } */
  .aprotemp .anews__awards__cont .bigpara {
    text-align: center;
  }
}

@media screen and (max-width: 550px) {
  .aprotemp .anews__awards__cont .news__cards {
    width: 100%;
  }
  .aprotemp .anews__awards__cont .news__cards {
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
    width: 100%;
  }
  .aprotemp .anews__awards__cont .news__cards .news__card {
    width: 100%;
    max-width: 300px;
  }
}

@media screen and (max-width: 1024px) {
  .aprotemp .propreviews .review__cards {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    grid-gap: 1rem;
  }
}
@media screen and (max-width: 630px) {
  .aprotemp .propreviews .review__cards {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
    grid-gap: 0.7rem;
  }
  .aprotemp .propreviews .review__cards .agc__rew__card {
    padding: 0.7rem;
  }
}

@media screen and (max-width: 850px) {
  .aprotemp .theteam .theteamcards {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
  .aprotemp .theteam .theteamcards .theteamcard {
    padding: 0.8rem;
  }
  .aprotemp .theteam .theteamcards .agc__rew__card {
    padding: 0.7rem;
  }
}
@media screen and (max-width: 600px) {
  .aprotemp .theteam .theteamcards {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }
}

@media screen and (max-width: 860px) {
  .aprotemp .aboutagency {
    flex-direction: column;
  }
  .aprotemp .aboutagency .part__left .bigpara {
    text-align: center;
  }
  .aprotemp .aboutagency .propgirl {
    max-height: 380px;
    width: 100%;
  }
  .aprotemp .ourexp .exp__cards {
    grid-template-columns: repeat(1, minmax(250px, 1fr));
  }

  .aprotemp .ourexp .exp__cards .exp__card {
    display: flex;
    column-gap: 1rem;
  }
}
