.subscribenews {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
  width: 100%;
  background: #f8f8f8;
  padding: 3rem 0;
  box-sizing: border-box;
}

.subscribenews .subscribe__box {
  height: 3rem;
  max-width: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: white; */
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
}
.subscribenews input {
  height: 100%;
  font-size: 0.95rem;
  border: none;
  outline: none;
  flex: 1;
  padding: 0 10px;
}
.subscribenews button {
  height: 100%;
  border: none;
  color: white;
  background: #ff656a;
  width: 6rem;
  cursor: pointer;
}
@media screen and (max-width: 750px) {
  .subscribenews {
    padding-left: 5%;
    padding-right: 5%;
  }
}
