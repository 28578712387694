.agencySidebar{
  width: 250px;
  height: auto;
  background-color: #ffffff;
  border-right: 1px solid #E7E3E9;
  margin-right: auto;
  z-index:0;
}
.agencySidebar > .agencyDetails{
    padding: 20px;
    border-bottom: 1px solid #E7E3E9;
    text-align: left;
    padding-bottom: 36px;
}
.agencySidebar > .agencyDetails > .agencyLogo img{
    height: 100px;
    width: 100px;
    background: #D9D9D9;
    margin-bottom: 20px;
    border: 0.1px solid #E7E3E9;

}

.agencySidebar > .agencyDetails > .agencyData > .agencyName > img{
    margin-left: .5rem ;
}

.agencySidebar > .agencyDetails > .agencyData > .agencyName{
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #191E22;
    text-align: left;
}
.agencySidebar > .agencyDetails > .agencyData > .agencyProjects{
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    letter-spacing: 0.02em;
    color: #191E22;
    text-align: left;
}
.agencySidebar > .agencyDetails > .agencyData > .teamMembers > img{
    height: 20px;
    width: 20px;
    margin-right: -4px !important;  
} 
.agencySidebar > .agencyDetails > .agencyData > .teamMembers{
    display: flex;
    text-align: left;
    display: none;
}
  .agencySidebar > .agencyDetails > .agencyData > .teamMembers > p{
    margin-left: 13px;
}
@media (max-width: 1240px) {
    .agencySidebar {
        display: none;    
    }
  }
