.horizontal-carousel-two {
  overflow: hidden;
  width: 100vw;
  background: linear-gradient(180deg, #F7FAFD 0%, #EFF6FB 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.horizontal-carousel-two .img_wrapper {
  /* position: absolute;
  left: 50%; */
  /* transform: translateX(-50%);
  animation-name: example;
  animation-duration: 8s;
  animation-iteration-count:1; */
  min-width: 125px;
  min-height: 125px;
  border-radius: 50%;
  margin: 30px 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.horizontal-carousel-two .img-item {
  width: 80px;
  max-width: 100%;
  height: auto;
}

.horizontal-carousel-two .hidden_elem {
  min-width: 120px;
  min-height: 120px;
  background-color: transparent;
  border-radius: 50%;
  margin: 20px 65px;
}

/* @keyframes example {
  0%  {
    left: 50%;
    transform: translateX(-50%);
  }
  49% {
    left: -100px;
    transform: translateX(-50%);
  }
  50% {
    left: inherit; 
    right: -100px;
    transform: translateX(50%);
  }
  51%  {
    left: inherit; 
    right: -100px;
    transform: translateX(50%);
  }
  100% {
    left: inherit; 
    right: 50%;
    transform: translateX(50%);
  }
} */

@media screen and (max-width: 768px) {
  .horizontal-carousel-two .hidden_elem {
    min-width: 70px;
    min-height: 70px;
    margin: 15px 20px 20px 0;
  }

  .horizontal-carousel-two .img_wrapper {
    min-width: 70px;
    min-height: 70px;
    margin: 15px 20px 20px 0;
  }

  .horizontal-carousel-two .img_wrapper:first-child {
    margin-left: 40px;
  }

  .horizontal-carousel-two .img_wrapper:last-child {
    margin-right: 0px;
  }

  .horizontal-carousel-two .img-item {
    width: 50px;
  }
}