@media screen and (max-width: 1024px) {
    #error404_img{
     display: none;
    }
   }
   @media screen and (max-width:1200px ){
    #error_content{
    margin-left: 50px;
}
   }
@media screen and (max-width:1024px ){
    #error_content{
   text-align: center;
   justify-content: center;
}
   }
   @media screen and (max-width:450px ){
    #error_content{
    margin-left: 10px;
}
   }
   @media screen and (max-width: 1224px) {
    #error404_img img{
  height: 500px;
  width: 750px;
    }
   }
   @media screen and (max-width: 1150px) {
    #error404_img img{
  height: 500px;
  width: 650px;
    }
   }