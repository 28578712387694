@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.availability-form-unique {
  width: 100%;
  padding: 20px;
  background-color: #fff;
  border-radius: 30px;
  border: 1px solid #E0E0E0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  font-family: "Poppins", sans-serif;

}

.label-schedule-unique {
  font-weight: 500;
  align-self: flex-start;
  color: black;
  display: flex;
  font-size: 17px;

}

.form-group-unique {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;


}

.custom-select {
  width: 432px;
  margin-bottom: 1rem;
}

.custom-option {
  display: flex;
  align-items: center;
}

.custom-option strong {
  margin-right: 5px;
}


/* .form-timezone {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
} */

.custom-div-unique {
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  margin-bottom: 10px;
  flex: 1 1;
    max-width: 500px;
    width: 100%;
    color: #c2c0c0;
}
.custom-div-unique .handleSyncCalender{
  box-sizing: border-box;
  height: 50px;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;

} 
.handleSyncCalender input {
  border: none;
  outline: none;
  color: #c2c0c0;
}

.handleSyncCalender button {
  font-size: 18px;
  background-color: black;
  color: white;
  font-weight: 500;
  border-radius: 10px;
  padding: 5px 20px;
}
/* .form-timezone-unique select,.form-caption-unique select, 
.custom-select-unique{
  padding: 12px;
  font-size: 14px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  margin-bottom: 10px;
  flex: 1;
  color: #5e1e1e;
  appearance: none;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23c2c0c0" viewBox="0 0 24 24" width="24" height="24"><path d="M7 10l5 5 5-5z"/></svg>') no-repeat right 10px center ;
  padding-right: 30px;

} */

.form-caption-unique {
  display: flex;
  /* align-items: center; */
  gap: 10px;
  width: 100%;
  /* justify-content: baseline; */


}

.form-caption-unique span{
  line-height: 24px;
  height: 50px; /* Adjust this value to match the input box height */
}
.form-caption-unique select {
  display: flex;
  justify-content: baseline;

}


.form-group-unique label {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 17px;
  color: black;
  align-self: flex-start;
}

.form-div-unique {
  max-width: 100%;
  padding: 50px 100px;


}

.form-group-unique select,
.form-group-unique input[type="date"],
.form-group-unique input[type="time"],
.form-group-unique input[type="text"] {
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  flex: 1;
  max-width: 500px;
  width: 100%;
  

  color: #c2c0c0;
}

.notice-period-dropdown-unique{
  color: black;
}

.form-group-unique input[type="time"] {
  font-family: "Poppins", sans-serif; 
  font-weight: normal;
}

.schedule-group-unique {
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 20px;
  font-weight: 500;
  width: 100%;
  box-sizing: border-box;
}

.schedule-item-unique {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;


}

.schedule-item-unique label {
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-weight: 500;
}

.apply-to-all-unique {
  /* font-weight: 400; */
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  column-gap: 3px;
}
.apply-to-all-unique .title{
  font-weight: 400;
}

.form-buttons-unique {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.cancel-button-unique,
.save-button-unique {
  padding: 10px 30px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
}

.cancel-button-unique {
  background-color: white;
  color: black;
  border: 1px solid #c3c3c3;


}

.save-button-unique {
  background-color: black;
  color: white;
  border: none;

}

.form-group-unique .note-unique {
  color: lightgrey;
  font-size: 16px;
  font-weight: 400;
  
  display: flex;

  width: 50%;
  text-align: left;
  
}

.time-slots-icon-unique {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.input-caption-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
}

.input-caption-wrapper-unique select,
.input-caption-wrapper-unique input {
  flex: 1;
  margin-right: 10px;
}

.time-selector-unique {
  display: flex;
  align-items: center;
  gap: 10px;

}

.cancel-slot-icon-unique {
  cursor: pointer;
}

.add-slot-icon-unique {
  cursor: pointer;
}


.custom-dropdown .Dropdown-control {
  background-color:  #FFFFFF;
  color: #7F7F7F;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #E0E0E0;
  width: 432px;
  /* width: 100%; */
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
 
  flex: 1;
}

.custom-dropdown .Dropdown-menu {
  border-radius: 5px;
  margin-top: 5px;
  width: 432px;
  max-height:600px ;
  

 
  
}

.custom-dropdown .Dropdown-option {
  padding: 17px;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  border-bottom: 1px solid #E0E0E0;
  color: #7F7F7F;
  text-align: left;
  
}

.custom-dropdown .Dropdown-option:hover {
  background-color: #e0e0e0;
}

.custom-dropdown .Dropdown-arrow {
  border-color: black transparent transparent;
  margin-top: 7px;

}

.custom-dropdown .Dropdown-option.is-selected {
  background-color:#F7F7F7;
}

.custom-dropdown .custom-dropdown-option .custom-dropdown-period{
  color: #7F7F7F;
  font-weight: 600;
}
@media screen and (max-width: 870px) { 
  .form-div-unique {
    padding: 0px;    
  }
  .custom-dropdown .Dropdown-control {
    width: 100%;
  }
  .schedule-item-unique{
    flex-direction: column;
    align-items: center;
  }
  .apply-to-all-unique{
    align-items: center;
    justify-content: center;
  }
  .schedule-item-unique label{
    margin: 20px 0px !important;
    padding: 0 !important;
  }
  .schedule-item-unique label > span{
    margin-top: 10px;
  }
  .form-caption-unique{
    flex-direction: column;
  }
  .custom-select{
    width: 100%;
  }
  .form-group-unique .note-unique {
    width: 100%;
  }
  .form-caption-unique span {
    height: fit-content;
  }
}