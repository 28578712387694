.Userprofilecompo{
 
  padding: 30px 20px; 
  margin: 0;
  display: flex;
  flex-direction: row; 
  overflow-x: hidden;
}
.userCasestudies{
    width: 100% !important;
}

.userCasestudies > .caseStudySection{
    display: flex;
    /* border: none; */
    /* border-left: 1px solid #E3E7E9; */
    width: 100%;
}
.userCasestudies > .caseStudySection > .case__studies {
    display: grid;
    grid-template-columns: repeat(4, 310px);
    gap: 15px;
    justify-content: center;
}

@media screen and (min-width: 1500px){
    .userCasestudies > .caseStudySection > .case__studies {
        width: 100%;
        grid-template-columns: repeat(4, 25%);
    }
}
@media screen and (max-width: 1240px){
    .userCasestudies > .caseStudySection{
        display: flex;
        width: 100%;
    }
    .userCasestudies > .caseStudySection > .case__studies {
        width: 100%;
        grid-template-columns: repeat(4, 25%);
    }
}
@media screen and (max-width: 1080px){
    .userCasestudies{
        width: 100%;
    }
    .userCasestudies > .caseStudySection{
        display: flex;
        width: 100%;
    }
    .userCasestudies > .caseStudySection > .case__studies {
        width: 100%;
        grid-template-columns: repeat(2, 50%);
    }
    .userCasestudies > .caseStudySection > .case__studies >.caseStudy__card {
        width: -webkit-fill-available;
    }
    .userCasestudies > .caseStudySection > .case__studies >.caseStudy__card > .main > .display_image {
        border-radius: 5px;
        width: 88%;
        height: auto;
    }
    .userCasestudies > .caseStudySection > .case__studies > .caseStudy__card > .main {
        position: relative;
        margin-bottom: 1rem;
        height: auto;
      }
      
}

@media screen and (min-width: 1500px){
    .userCasestudies > .caseStudySection > .case__studies >.caseStudy__card {
      width: 100%;
    }
    .caseStudy__card > .main {
        height: fit-content;
    }
    .userCasestudies > .caseStudySection > .case__studies >.caseStudy__card > .caseStudy__card > .main {
      width: 100%;
    }
    .userCasestudies > .caseStudySection > .case__studies >.caseStudy__card > .main > .display_image {
      border-radius: 5px;
      width: 88%;
      height: auto;
      min-width: 270px;
      min-height: 200px;  
    }
  }
  @media screen and (max-width: 1240px) and (min-width: 1080px){
    .userCasestudies > .caseStudySection > .case__studies >.caseStudy__card {
      width: 100%;
    }
    .userCasestudies > .caseStudySection > .case__studies >.caseStudy__card > .caseStudy__card > .main {
      height: 210px;
    }
    .userCasestudies > .caseStudySection > .case__studies >.caseStudy__card > .main > .display_image {
      width: 85% !important;
      border-radius: 5px;
      width: auto;
      height: auto;
    }
  }
  @media screen and (max-width: 620px){
    .userCasestudies > .caseStudySection > .case__studies {
        width: 100%;
        grid-template-columns: repeat(1, 100%);
    }
    .Userprofilecompo {
      flex-direction: column; 
        width: 100%;
         padding: 0;
   
      }
  }