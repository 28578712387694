.secondUserProfileCompo {
    width:80vw;
    margin: auto;
    margin: 20px auto ;
    text-align: left;
}
.secondUserProfileCompo > .Fields{
    display: flex;
    flex-wrap: wrap;
    gap: 0px 30px;
    border: 1px solid #DFE9F3;
    border-radius: 10px;
    margin: 30px 0px;
    padding: 0px 10px;
}
 
 .slide__image{
    width: min-content;
    height: fit-content !important;
    font-family: "lexend";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    margin: 0px;
    padding: 40px 0px;
    background-color: transparent;
    border: transparent;
}
.active{
    color: #5C7793;
}
.unactive{
    color: #D9D9D9;
}
.active:focus{
    border-bottom : 3px solid #FF656A;
    font-weight: 700;
}

.slide__image:last-child{
    margin-right: 50px;
  
}
.secondUserProfileCompo .Fields.styles-module_sliderBase__swkx1 button {
    display: none;
  }
  .secondUserProfileCompo .Fields>  .styles-module_slider__o0fqa{
    gap: 50px;
    width: 90vw;
    justify-content: space-between;
  }
  .secondUserProfileCompo .Fields>  .styles-module_slider__o0fqa button{
    display: flex;
    justify-content: space-between;
  }
 
.creditField > .Filters{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    border-bottom:  1px solid #DFE9F3;
    padding-bottom: 10px;
    align-items: center;
    text-align: center;
}
.creditField > .Filters > p{
    font-family: "lexend";
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: #0B1C39;
}
.creditField > .Filters >div {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    align-items: center;
}
.creditField > .Filters >div >select{
    border: transparent;
    font-family: "lexend";
    font-style: normal; 
    font-weight: 100;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #566d95;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 0px;
    gap: 10px;
    border: 1px solid rgba(44, 68, 92, 0.1);
    border-radius: 20px;
}
.creditField > .Filters > button{
    font-family: "lexend";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: transparent;
    color: #FF656A;
}
.creditField >.casestudies {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 25px;
    text-align: center;
    align-content: center;
    justify-content: center;
    justify-content: space-evenly;

}
.creditField >.casestudies >.casestudy{
    display: flex;
    flex-direction: column;
    width: min-content;
}
.creditField >.casestudies >.casestudy >p{
    background: #FFF1F1;
    border: 1px solid #FF656A;
    border-radius: 40px;
    font-family: "lexend";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    width: max-content;
    display: flex;
    align-items:left;
    text-align: left;
    padding: 5px;
    color: #FF656A;
}
.creditField >.casestudies >.casestudy>div{
    border: 1px solid #DFE9F3;
    border-radius: 10px;
    padding: 15px;
}
.creditField >.casestudies >.casestudy>div>.head{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.creditField >.casestudies >.casestudy>div>.head > p{
    font-family: "lexend";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    gap: 3px;
    color: #0B1C39;
    background: #F2F8FF;
    border-radius: 44px;
    padding: 5px;
}
.creditField >.casestudies >.casestudy> div>.head > p >img{
    height: 12px;
}
.creditField >.casestudies >.casestudy> div>.head > img{
    height: 24px;
}
.creditField >.casestudies >.casestudy>div >img{
    height: 350px;
    margin-bottom: 10px;
}
.creditField >.casestudies >.casestudy>div > .bottom{
    text-align:justify;
}
.creditField >.casestudies >.casestudy>div > .bottom > h5{
    font-family: "lexend";
    font-style: normal;
    /* font-weight: 00; */
    font-size: 15px;
    line-height: 12px;
    display: flex;
    align-items: left;
    text-align:justify;
    color: #FF656A;
}
.achivementfield{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.achivementfield > .achivement{
    display: flex;
    /* align-items: center; */
    gap: 15px;
    border-bottom:  1px solid #DFE9F3;
}
.achivementfield > .achivement > img{
    height: 35px;
}
.achivementfield > .achivement >p{
    padding: 3px;
}
.aboutfield{
}
.aboutfield >.jobfield h5{
    padding: 20px;
    border-top:  1px solid #DFE9F3;
    border-bottom:  1px solid #DFE9F3;
    margin-bottom: 30px;
    font-family: "lexend";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;

    color: #5C7793;
}
.aboutfield >.jobfield >.jobs{
    display: flex;
    flex-wrap: wrap;
    gap: 20px 150px;
}
.aboutfield >.jobfield >.jobs >.job{
    display: flex;
    gap: 20px;
}
.aboutfield >.jobfield >.jobs >.job >div>p {
    margin: 3px;
    /* font-family: "lexend"; */
    font-style: normal;
    font-weight: 10;
    font-size: 14px;
    line-height: 17px;
    color: #5C7793;
}
.aboutfield >.jobfield >.jobs >.job >div>p >b{
    font-family: "lexend";
    font-weight: 500;
}
.aboutfield >.jobfield >.jobs >.job >div>h6{
    font-family: "lexend";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FF656A;
}
.aboutfield >.jobfield >.jobs >.job>img{
    height: 80px;
}

@media only screen and (max-width: 800px) {
    .secondUserProfileCompo {
        padding: 0px 15px;
        width:fit-content !important;
    }
}
