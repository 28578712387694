@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500&display=swap");

.Pannel {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  font-family: "Lexend";
  font-style: normal;
  padding: 10px 15px;
}

.Pannel > .profile {
  display: flex;
  gap: 2rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 50px;
  padding: 10px 0;
  margin-bottom: 20px;
}

.Pannel > .profile > h5 {
  margin: auto 10px;
  color: #525464;
  font-family: "Lexend";

  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
}

/* .Pannel > .profile > div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border: 1px solid #e7e3e9;
} */
.Pannel > .profile > div > div {
  display: flex;
  gap: 5px;
  width: fit-content !important;
  margin-left: 20px;
}

.Pannel > .profile > div > div > img {
  width: 20px;
  height: 20px;
  margin: auto 0;
  color: #b4aebd;
}

.Pannel > .profile > div > div > p {
  font-weight: 600;
  color: black;
  margin: auto 0;

  font-weight: 300;
  font-size: 16px;
  line-height: 32px;
  color: #b4aebd;
}

.Pannel > .profile > div > div > p {
  margin: auto 0;
  margin-right: 20px;
}

.Pannel > .profile > .extraButtonsContainers {
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
}
.Pannel > .profile > .extraButtonsContainers > button {
  padding: 5px 7px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
  font-size: 12px;
}

.Pannel > .profile > .extraButtonsContainers > button:hover {
  background-color: white;
  color: black;
  border: 1px solid black;
}
.Pannel > .marketers_breadcrum {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 10px;
  padding-left: 10px;
  border: 1px solid #e7e3e9;
  border-right: none;
  border-left: none;
  min-height: 50px;
}

.Pannel > .marketers_breadcrum > .connectLinkedIn > button {
  font-size: smaller;
  border: 1px solid black;
  color: black;
  padding: 2px 4px;
  border-radius: 8px;
}
.Pannel > .marketers_breadcrum > .connectLinkedIn > button:hover {
  background-color: black;
  color: white;
}

.Pannel > .marketers_breadcrum p {
  display: flex;
  flex-direction: row;
  margin: auto 0;
  margin-top: auto;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* or 133% */
  gap: 5px;
  display: flex;
  align-items: center;
  color: #b4aebd;
}

.Pannel > .marketers_breadcrum a,
.Pannel > .marketers_breadcrum span {
  letter-spacing: 0.01em;
  display: flex;
  flex-direction: row;
  gap: 5px;
  /* black/b4aebd/h */
  text-decoration: none;
  color: #b4aebd;
}

/* Credits */
.Pannel > .MarketersCredits > .searchResultsCr {
  border-bottom: 1px solid #e7e3e9;
}

.Pannel > .MarketersCredits {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  flex-grow: 1;
  overflow-y: auto;
}

.Pannel > .MarketersCredits > .searchResultsCr > p {
  text-align: start;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  /* black/b4aebd/h */

  color: #b4aebd;
  margin-left: 20px;
  margin: 10px;
  overflow-y: hidden;
}

.Pannel > .MarketersCredits > .marketers_casestudy_Filter {
  display: flex;
  border: 1px solid #e7e3e9;
}

.Pannel > .MarketersCredits > .marketers_casestudy_Filter > div {
  border-right: 1px solid #e7e3e9;
}

.Pannel
  > .MarketersCredits
  > .marketers_casestudy_Filter
  > .marketersFilterCont {
  width: 33.3333%;
}

.Pannel > .MarketersCredits > .marketers_casestudy_Filter > div > p {
  background-color: #f8f8f8;
  border-bottom: 1px solid #e7e3e9;
  margin: 0;
  height: 40px;
}

.Pannel
  > .MarketersCredits
  > .marketers_casestudy_Filter
  > div
  > .filter-dropdown {
  background-color: white;
}

.Pannel
  > .MarketersCredits
  > .marketers_casestudy_Filter
  > div
  > .filter-dropdown
  > .search-input {
  box-shadow: none;
  border: none;
  border-right: 1px solid #dfe9f3;
  outline: none;
}

.Pannel > .MarketersCredits > .MarketersCasestudies {
  display: flex;
  flex-wrap: wrap;
  gap: 0px;
  justify-content: left;
  overflow-y: auto;
  /* max-height: 40rem; */
  /* height: 100%; */
}

.Pannel > .MarketersCredits > .MarketersCasestudies .emptyCreditsPara {
  font-size: 13px;
  font-weight: 500;
  color: #6e6c71;
  text-align: center;
  margin-top: 10rem;
  margin-bottom: 10rem;
  margin: 100px auto;
}

.Pannel > .MarketersCredits > .MarketersCasestudies .emptyCreditsPara .link {
  color: #000000;
  text-decoration: underline;
  font-weight: 600;
}

.Pannel > .MarketersCredits > .MarketersCasestudies > .casestudy {
  display: flex;
  flex-direction: column;
  width: min-content;
  /* max-height: 24.7rem; */
  width: 25%;
  background-color: white;
}

.Pannel > .MarketersCredits > .MarketersCasestudies > .casestudy {
  border: 1px solid #dfe9f3;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-left: none;
}

.Pannel > .MarketersCredits > .MarketersCasestudies > .casestudy > .head {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 15px;
}

.Pannel
  > .MarketersCredits
  > .MarketersCasestudies
  > .casestudy
  > .head
  > .brandname {
  font-weight: 500;
  font-size: 12px;
  color: #807d91;
  margin: 0px;
  font-weight: 700;
}

.Pannel
  > .MarketersCredits
  > .MarketersCasestudies
  > .casestudy
  > .head
  > .deleteIcon {
  height: 18px;
  cursor: pointer;
}

.Pannel > .MarketersCredits > .MarketersCasestudies > .casestudy > .coverImage {
  height: auto;
  width: 100%;
  border-radius: 5px;
  margin: auto;
}

.Pannel > .MarketersCredits > .MarketersCasestudies > .casestudy > .body {
  text-align: left;
  padding: 0 15px;
}

.Pannel
  > .MarketersCredits
  > .MarketersCasestudies
  > .casestudy
  > .body
  > .heading,
.Pannel
  > .MarketersCredits
  > .MarketersCasestudies
  > .casestudy
  > .body
  > .agencyname {
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: left;
  text-align: justify;
  color: #b4aebd;
  font-weight: 500;
}

.Pannel
  > .MarketersCredits
  > .MarketersCasestudies
  > .casestudy
  > .body
  > .description {
  color: #191e22;
  font-weight: 300;
  font-size: 16px;
}

.Pannel
  > .MarketersCredits
  > .MarketersCasestudies
  > .casestudy
  > .body
  > .agencyname {
  font-weight: 700;
  color: #807d91;
}

.Pannel > .MarketersCredits > .MarketersCasestudies > .casestudy > .role {
  text-align: left;
  color: black;
  background: #f8f8f8;
  padding: 10px 15px;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 0px;
}

.Pannel > .MarketersCredits .filter-dropdown .dropdown-options-container {
  top: 20%;
}

.copyProfileLink > p {
  cursor: pointer;
}

/*Marketers Information*/

.profilePictureSet {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  border: 2px dashed #e7e3e9;
  padding: 30px 10px;
  width: 50%;
  margin: 20px auto;
}

.profilePictureSet > img {
  height: 41.67px;
  width: 41.67px;
  margin: 0 auto;
}

.profilePictureSet > p {
  font-weight: 500;
  /* font-size: 12px;
  line-height: 24px; */
  margin-bottom: 0;
  letter-spacing: 0.02em;
  color: #b4aebd;
}

.profilePictureSet > span {
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #b4aebd;
}

/* setting */
.setting {
  margin-top: 2rem;
  flex-grow: 1;
  overflow-y: hidden;
}

.setting > .Fields > h6 {
  background: #f8f8f8;
  border: 1px solid #e7e3e9;
  /* transform: matrix(-1, 0, 0, 1, 0, 0); */
  border-bottom: none;

  font-weight: 300;
  font-size: 16px;
  line-height: 32px;
  text-align: left;
  padding: 10px 20px;
  margin-bottom: 0;
}

.color__black {
  color: #191e22 !important;
}

.setting > .buttons {
}

@media screen and (max-width: 1130px) {
  .Pannel > .MarketersCredits > .MarketersCasestudies > .casestudy {
    width: 33.33%;
  }
}

@media screen and (max-width: 880px) {
  .Pannel > .MarketersCredits > .MarketersCasestudies > .casestudy {
    width: 50%;
  }
}

@media screen and (max-width: 580px) {
  .Pannel > .MarketersCredits > .MarketersCasestudies > .casestudy {
    width: 80%;
    margin: 0 10%;
    border-left: 1px solid #dfe9f3;
  }
}

@media screen and (max-width: 768px) {
  .copyProfileLink {
    display: none !important;
  }
}
