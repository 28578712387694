.aboutus .sp__top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 0.9rem;
  align-items: center;
  background: #0b1c38;
  padding: 6rem 5%;
}

.aboutus .color__white {
  color: rgba(255, 255, 255, 0.918) !important;
}

/* .aboutus .para {
  padding-top: 8px;
  padding-left: 25%;
  padding-right: 25%;
} */

.aboutus .normal__heading {
  line-height: 160%;
  font-size: 32px;
}

.aboutus .join-btn {
  margin-top: 3rem;
  height: 60px;
  width: 190px;
  background-color: #ff6566;
  color: white;
  border: none;
}

.aboutus .button {
  cursor: pointer;
  border: none;
  border-radius: 0;
}

.aboutus .aub2 .col1 {
  padding: 0 0 0 0;
  width: auto;
  margin-right: 43px;
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
}
.aboutus .aub2 .col2 {
  padding: 0 0 0 0;
  width: auto;
  margin-left: 43px;
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
}

.aboutus .aub2 {
  display: flex;
  margin-top: 4rem;
  margin-left: 9.5rem;
  margin-right: 9.5rem;
}

.aboutus .text {
  font-size: 16px;
}
@media (min-width: 1024px) {
  .aboutus .heading {
    align-items: center;
    text-align: left;
  }
}
.aboutus .newp {
  color: #6e6e6e;
  font-size: 16px;
  font-weight: 600;
  width: 80%;
}
.columns {
  margin-left: 0rem;
  margin-right: 0rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.aboutus .aub3 {
  display: flex;
  margin-top: 4rem;
  margin-left: 9.5rem;
  margin-right: 9.5rem;
}

.aboutus .aub3c1 {
  padding-top: 10%;
  padding-bottom: 10%;
  padding-left: 10%;
  padding-right: 10%;
  background-color: #fafafa;
}
.aboutus .aub3c11 {
  padding-bottom: 10%;
  padding-right: 3rem;
  width: max-content;
}
.aboutus .aub3c12 {
  padding-bottom: 10%;
  width: max-content;
  padding-left: 3rem;
}
.aboutus .aub3c13 {
  padding-top: 10%;

  width: max-content;
}

.aboutus .aub3c14 {
  padding-top: 10%;
  width: max-content;
}
.aboutus .aub3c2 {
  padding-left: 12% !important;
  padding-right: 5% !important;
}

.aboutus .aub3c2 {
  text-align: left;
}

/* .aboutus .aub3c2 h3 {
  text-align: justify;
} */
.aboutus .aub2 .column {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.aboutus .aub3 .col1 {
  display: flex;

  justify-content: space-between;
}
.aboutus .aub3 .col2 {
  display: flex;
  margin-left: -20px;
  margin-right: 30px;
  justify-content: space-between;
}
.aboutus .aub4 {
  display: flex;
  margin-top: 4rem;
  margin-left: 9.5rem;
  margin-right: 9.5rem;
}

.aboutus .aub4 .aub4c1 {
  text-align: left;
  padding: 10% 10% 10% 0%;
}
.aboutus .aboutus-prefoot {
  display: flex;
  margin-top: 3rem;
  height: auto;
  width: auto;
  background-color: #fafafa;
  color: #0b1c39;
  margin-left: 9.5rem;
  margin-right: 9.5rem;
  flex-wrap: wrap;
}
.aboutus .txt-box {
  padding-top: 7%;
  padding-bottom: 7%;
  padding-left: 8%;
  padding-right: 15%;
  max-width: 50%;
  text-align: left;
}
.aboutus .show-btn {
  border: none;
  outline: none;

  height: 60px;
  width: 190px;
  background-color: #ff6566;
  color: white;
}
.aboutus .btn {
  text-align: center;
  margin-top: 4.9rem;
  /* margin-right: 18%;
  margin-top: 4.9rem;
  float: right; */
}
.aboutus .pre-foot {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  height: auto;
  width: auto;
  background-color: #0b1c39;
  color: white;
  margin-left: 9.5rem;
  margin-right: 9.5rem;
}
.aboutus .girlteach {
  object-fit: contain;
  /* height: auto;
  width: 100%; */
}

/* The unispade team */
.aboutus .uteamcont {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  margin: 2rem 9.5rem;
}
.uteamcont .membs__cont {
  display: flex;
  flex-wrap: wrap;
  float: left;
  column-gap: 3rem;
  row-gap: 2rem;
  width: 100%;

  /* display: grid; 
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
  align-items: center;
  justify-content: center;
}
.uteamcont .membs__cont .memb__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.6rem;
}
.uteamcont .membs__cont .memb__card .memb__img {
  object-fit: contain;
  max-height: 210px;
  border-radius: 8px;
}

.uteamcont .membs__cont .memb__card .memb__name {
  color: #6e6e6e;
}

.uteamcont .membs__cont .memb__card .memb__linkedin {
  object-fit: contain;
  max-height: 18px;
  cursor: pointer;
}

.uteamcont .tmembs__cont {
  display: flex;
  flex-wrap: wrap;
  float: left;
  column-gap: 2rem;
  row-gap: 1rem;
  /* display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, minmax(200px, 1fr)); */

  align-items: center;
  justify-content: center;
}
.uteamcont .tmembs__cont .tmemb__card {
  width: 100%;
  max-width: 400px;
  display: flex;
  column-gap: 1rem;
  align-items: center;
  border: 1.5px solid rgba(224, 224, 224, 0.692);
  border-radius: 15px;
  padding: 1rem;
  box-sizing: border-box;
  transition: all 0.25s ease;
}
@media screen and (max-width: 1160px) {
  .uteamcont .tmembs__cont .tmemb__card {
    max-width: 300px;
  }
}
@media screen and (max-width: 720px) {
  .uteamcont .tmembs__cont .tmemb__card {
    max-width: 450px;
  }
}

.uteamcont .tmembs__cont .tmemb__card:hover {
  border: 1.5px solid #ffe8e9;
  background: #ffe8e9;
  transition: all 0.25s ease;
}
.tmembs__cont .tmemb__card .tmemb__card__data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.3rem;
  width: 100%;
}
.tmembs__cont .tmemb__card .tmemb__card__data .part {
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
}
.tmemb__card__data .part .tmemb__name {
  color: #6e6e6e;
}
.tmemb__card__data .part .tmemb__linkedin {
  object-fit: contain;
  max-height: 16px;
  cursor: pointer;
}
.tmembs__cont .tmemb__card .tmemb__img {
  object-fit: contain;
  max-height: 80px;
  border-radius: 50%;
}
@media (max-width: 768px) {
  .aboutus .uteamcont {
    margin: 0 5%;
  }

  .uteamcont .tmembs__cont {
    grid-template-columns: repeat(1, minmax(250px, 1fr));
  }
  .aboutus .girlteach {
    object-fit: contain;
    height: auto;
    max-height: 450px;
    width: 100%;
  }
  .aboutus .aboutus-prefoot {
    text-align: center;
    margin-left: 0rem;
    margin-right: 0rem;
    flex-direction: row;
    flex-wrap: nowrap;
    display: grid;
  }
  .aboutus .pre-foot {
    text-align: center;
    margin-left: 0rem;
    margin-right: 0rem;
    flex-direction: row;
    flex-wrap: nowrap;
    display: grid;
  }
  .aboutus .btn {
    text-align: center;
    margin-top: 1rem;
    padding-bottom: 40px;
  }
  .aboutus .newp {
    width: 100%;
  }
  .aboutus .txt-box {
    padding-top: 7%;
    padding-bottom: 7%;
    padding-left: 8%;
    padding-right: 15%;
    max-width: 100%;
    text-align: center;
  }
  .aboutus .text {
    font-size: 20px;
  }
  .aboutus .heading {
    align-items: center;
  }
  .aboutus .aub1 {
    background-color: #0b1c39;
    padding-top: 10%;
    padding-bottom: 7%;
  }

  .aboutus .aub2 {
    display: block;
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
    text-align: justify;
  }
  .aboutus .aub2 .col1 {
    margin-right: 0px;
  }
  .aboutus .aub2 .col2 {
    margin-left: 0px;
  }
  .aboutus .aub2 .column {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .aboutus .aub3 {
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .aboutus .aub3c2 {
    padding-left: 5% !important;
    padding-right: 5% !important;
  }

  .aboutus .aub3c2 h2 {
    text-align: center;
  }

  .aboutus .aub3c2 h3 {
    text-align: justify;
  }

  .aboutus .aub3 {
    display: flex;
    flex-flow: column;
  }
  .aboutus .aub3c2 {
    order: 1;
  }
  .aboutus .aub3c1 {
    order: 2;
  }

  .aboutus .aub4 {
    display: block;
    margin-left: 5%;
    margin-right: 5%;
  }
  .aboutus .aub4 .aub4c1 {
    padding: 5% 5% 5% 5%;
    text-align: justify;
  }

  .aboutus .aub4c1 h2 {
    text-align: center;
  }

  .aboutus .aub4c1 h3 {
    text-align: justify;
  }
}

@media (max-width: 550px) {
  .aboutus .aub1 {
    padding-top: 18%;
  }
  .aboutus .aub1 h3 {
    padding-left: 15% !important;
    padding-right: 15% !important;
  }
}
