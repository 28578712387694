.chatBoxComponent {
  /* max-height: 400px; */
  width: 100%;
  border-radius: 20px;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.chatBoxComponent .entireArea {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #f7f7f7;
  width: 100%; /* Ensure it takes the full width of the chatBoxComponent */
  box-sizing: border-box; 
  border-radius: 20px;
}

.chatBoxComponent .entireArea .chatArea {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 600;
  overflow-x: auto;
}

.chatBoxComponent .entireArea .chatArea::-webkit-scrollbar {
  display: none; /* This hides the scrollbar */
}

.chatBoxComponent .entireArea .chatArea {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.chatBoxComponent .entireArea .chatArea .messageBox {
  display: flex;
  gap: 15px;
}

.chatBoxComponent .entireArea .chatArea .messageBox.sent {
  display: flex;
  flex-direction: row-reverse;
  align-self: flex-end;
  justify-self: flex-end;
}

.chatBoxComponent .entireArea .chatArea .message {
  background-color: white;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 20px;
  max-width: 500px;
}

.chatBoxComponent .entireArea .sendTextArea {
  display: flex;
  gap: 20px;
}

.chatBoxComponent .entireArea .sendTextArea .searchBarElement {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  height: 45px;
  width: 100%;
  border-radius: 30px;
  border: 1px solid #ffffff;
  background-color: white;
}

.chatBoxComponent .entireArea .sendTextArea .searchBarElement input {
  width: 95%;
  padding: 10px;
  outline: none;
  border: none;
  background-color: transparent;
}

.chatBoxComponent .entireArea .sendTextArea .attachFile {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid #e0e0e0;
  background-color: #e0e0e0;
}

.chatBoxComponent .entireArea .sendTextArea .sendButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-color: black;
}
