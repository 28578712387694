.awards{
    margin: 0 auto;
    width: 80%;
}

.awards__heading{
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #DFE9F3;
    border-bottom: 1px solid #DFE9F3;
    padding: 14px 0;
}

.awards__title {
  font-weight: 500;
  font-size: 20px;
  color: #0b1c39;
  text-align: start;
  margin: 0;
}

.awards__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 40px 0;
}

/* Mobile Device */
@media only screen and (max-width:768px){
  .awards__container {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
}

/* Award */
.award {
  display: flex;
  column-gap: 20px;
}

.award__photo {
  width: 100px;
  height: 100px;
}

.award__info {
  display: flex;
  align-items: center;
  column-gap: 2px;
}

.award__name {
  font-weight: 700;
  font-size: 14px;
  color: #0b1c39;
  margin: 0;
}

.award__link {
  height: 0px;
  position: relative;
}

.link__icon {
  height: 10px;
  width: 10px;
  position: absolute;
  top: -5px;
}

.award__year {
  font-weight: 500;
  font-size: 14px;
  color: #5c7793;
  text-align: start;
  margin: 0;
}
