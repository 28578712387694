.login__cont {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff6f6;
}
.login {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 0 0 0;
  width: 100%;
  min-height: 550px;
  background: #FFFFFF;
  padding-top: 2rem;
  justify-content: center;
}
.login .sec__left {
  /* flex: 0.4; */
  background: #0b1c38;
  width: 100%;
  min-height: 750px;
  height: 100%;
  position: relative;
}
.login .sec__left .girlimg__cont {
  position: relative;
  z-index: 6;
}
.login .sec__left .girlimg {
  object-fit: contain;
  height: 480px;
  position: absolute;
  top: 50%;
  right: 15%;
  transform: translate(50%, -50%);
  z-index: 7;
}
.login .sec__left .dots {
  object-fit: contain;
  height: 100px;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 8;
}
.login .sec__right {
  /* flex: 0.6; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;
  margin: 2rem 5% 1rem 15%;
  width: 100%;
  color: #6e6e6e;
}
.login .sec__right .logreg__title {
  margin-bottom: 1rem;
}
.login .sec__right .login__form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.6rem;
  width: 100%;
  max-width: 480px;
  /* background: peru; */
}
.login .sec__right .login__form label {
  font-size: 0.85rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.login .sec__right .login__form .epass {
  color: #0b1c39;
  background: #fafafa;
  height: 2.7rem;
  border-radius: 1px;
  border: 1.5px solid rgb(230, 230, 230);
  font-family: "Poppins";
  outline: none;
  font-size: 0.85rem;
  padding: 0.5rem 1rem;
  width: 100%;
  box-sizing: border-box;
}
.login .sec__right .login__form .epass::placeholder {
  color: rgb(190, 190, 190);
  opacity: 1; /* Firefox */
}
.login .sec__right .login__form .epass::-webkit-outer-spin-button,
.login .sec__right .login__form .epass::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.login .sec__right .login__form .log__for {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 0.5rem;
  width: 100%;
}
.login .sec__right .login__form .log__for label {
  font-size: 0.75rem;
  font-weight: 500;
  display: flex;
  column-gap: 0.5rem;
  cursor: pointer;
  text-align: left;
}
.login .sec__right .login__form .login__btn {
  font-size: 0.9rem;
  margin-top: 1rem;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 1px;
  color: rgba(255, 255, 255, 0.89);
  background: #191E22 !important;
  height: 3rem;
  width: 100%;
  font-family: "Poppins";
}
.account_status{
  width: 100%;
}
.login-with-google-btn {
  transition: background-color 0.3s, box-shadow 0.3s;
  margin-top: 2rem;
  padding: 12px 16px 12px 42px;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 1px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
  height: 3rem;
  width: 100%;
  color: #757575;
  font-size: 0.9rem;
  font-weight: 500;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 6px 15px;

  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
  }

  &:active {
    background-color: #eeeeee;
  }

  &:focus {
    outline: none;
    box-shadow: 
      0 -1px 0 rgba(0, 0, 0, .04),
      0 2px 4px rgba(0, 0, 0, .25),
      0 0 0 3px #c8dafc;
  }

  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
  }
}
.agencyRegSec{
  align-items: center !important;
  margin: 3rem 5rem 5rem 5rem!important;
  padding: 2rem;
  border: 1px solid #E3E7E9;
  max-width: 600px;
}

@media screen and (max-width: 1024px) {
  .login {
    margin-right: 0;
  }
}
@media screen and (max-width: 880px) {
  .login {
    flex-direction: column;

    align-items: center;
    margin: 0 5%;
  }
  .login .sec__left {
    display: none;
  }
  .login .sec__right .logreg__title {
    font-size: 1.6rem;
  }
  .login .sec__right {
    margin: 3rem 5%;
    align-items: center;
  }
}
